"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._changeComplaint = _changeComplaint;
exports._changeHide = _changeHide;
exports._changeQuestionCategory = _changeQuestionCategory;
exports._createAnswer = _createAnswer;
exports._createCartgory = _createCartgory;
exports._createExpert = _createExpert;
exports._createResultApi = _createResultApi;
exports._deleteCartgory = _deleteCartgory;
exports._deleteExpert = _deleteExpert;
exports._deleteQuestion = _deleteQuestion;
exports._deleteResultApi = _deleteResultApi;
exports._distributionExpert = _distributionExpert;
exports._editCartgory = _editCartgory;
exports._editExpert = _editExpert;
exports._editResultApi = _editResultApi;
exports._getCartgorySetList = _getCartgorySetList;
exports._getComplaintList = _getComplaintList;
exports._getExpertData = _getExpertData;
exports._getExpertInfo = _getExpertInfo;
exports._getExpertList = _getExpertList;
exports._getExpertTable = _getExpertTable;
exports._getQuestionCategory = _getQuestionCategory;
exports._getQuestionData = _getQuestionData;
exports._getQuestionDetail = _getQuestionDetail;
exports._getQuestionList = _getQuestionList;
exports._getQuestionOrder = _getQuestionOrder;
exports._getQuestionStudyTable = _getQuestionStudyTable;
exports._getQuestionTable = _getQuestionTable;
exports._getResultListApi = _getResultListApi;
exports._getSelectExpert = _getSelectExpert;
exports._getSettingInfo = _getSettingInfo;
exports._getStudyOrderList = _getStudyOrderList;
exports._orderRefund = _orderRefund;
exports._reviewExpert = _reviewExpert;
exports._setCartgoryTag = _setCartgoryTag;
exports._setSettingInfo = _setSettingInfo;
exports._shenfenExpert = _shenfenExpert;
exports._sortCartgory = _sortCartgory;
exports._sortExpert = _sortExpert;
exports._zhidingExpert = _zhidingExpert;
var _request = _interopRequireDefault(require("./request"));
// 获取专家列表
function _getExpertList(query) {
  return _request.default.get("/identification/expert/getList", query);
}

// 获取专家详情信息
function _getExpertInfo(id) {
  return _request.default.get("/identification/expert/detail/".concat(id));
}

// 创建专家
function _createExpert(data) {
  return _request.default.post("/identification/expert/create", data);
}
// 修改专家
function _editExpert(data) {
  return _request.default.post("/identification/expert/update/".concat(data.id), data);
}
// 删除专家
function _deleteExpert(id) {
  return _request.default.post("/identification/expert/delete/".concat(id));
}

// 修改排序
function _sortExpert(data) {
  return _request.default.post("/identification/expert/switchSort", data);
}

// 专家审核
function _reviewExpert(data) {
  return _request.default.post("/identification/expert/review", data);
}

// 专家身份
function _shenfenExpert(data) {
  return _request.default.post("/identification/expert/switchCancelStatus", data);
}

// 置顶专家
function _zhidingExpert(data) {
  return _request.default.post("/identification/expert/switchZhiding", data);
}

/* ------------------------------------- */
// 鉴定分类列表
function _getCartgorySetList(data) {
  return _request.default.get("/identification/category/getList", data);
}

// 创建分类
function _createCartgory(data) {
  return _request.default.post("/identification/category/create", data);
}

// 编辑分类
function _editCartgory(data) {
  return _request.default.post("/identification/category/update/".concat(data.id), data);
}

// 删除分类
function _deleteCartgory(id) {
  return _request.default.post("/identification/category/delete/".concat(id));
}

// 管理标签
function _setCartgoryTag(data) {
  return _request.default.post("/identification/category/setLevel", data);
}

// 分类排序上移
function _sortCartgory(data) {
  return _request.default.post("/identification/category/updateSort", data);
}

/* --------------------- */
// 基础设置信息
function _getSettingInfo() {
  return _request.default.get("/identification/rule/getRule");
}

// 设置基础设置
function _setSettingInfo(data) {
  return _request.default.post("/identification/rule/setRule", data);
}

/* -------------------- */
// 鉴定问题贴子列表
function _getQuestionList(state) {
  return _request.default.get("/identification/v2/question/list", state);
}

// 数据报表  专家数据
function _getExpertData(state) {
  return _request.default.get("/identification/statistical/expert", state);
}

// 鉴定问题分类统计
function _getQuestionCategory(state) {
  return _request.default.get("/identification/statistical/questionCategory", state);
}

// 鉴定问题统计
function _getQuestionData(state) {
  return _request.default.get("/identification/v2/statistical/number", state);
}

// 鉴定订单
function _getQuestionOrder(state) {
  return _request.default.get("/identification/v2/statistical/line", state);
}

// 鉴定问题表格统计
function _getQuestionTable(state) {
  return _request.default.get("/identification/v2/statistical/orderTable", state);
}

// 鉴定问题偷学统计
function _getQuestionStudyTable(state) {
  return _request.default.get("/identification/v2/statistical/studyOrderTable", state);
}

// 鉴定专家收益
function _getExpertTable(state) {
  return _request.default.get("identification/v2/statistical/expertOrderTable", state);
}

/* ---------------------------------- */
// 删除问题
function _deleteQuestion(data) {
  return _request.default.post("/identification/v2/question/delete", data);
}

// 修改显示和隐藏
function _changeHide(state) {
  return _request.default.post("/identification/v2/question/switchShow", state);
}

// 获取偷学订单列表
function _getStudyOrderList(state) {
  return _request.default.get("/identification/studyOrder/getList", state);
}

// 获取鉴定问题详情
function _getQuestionDetail(id) {
  return _request.default.get("/identification/v2/question/detail/".concat(id));
}

//获取鉴定专家数据,下拉筛选使用
function _getSelectExpert(state) {
  return _request.default.get("/identification/expert/getData", state);
}

// 问题设置分类
function _changeQuestionCategory(state) {
  return _request.default.post("/identification/v2/question/updateCategory", state);
}

// 鉴定问题退款
function _orderRefund(state) {
  return _request.default.post("/identification/v2/question/refund", state);
}

// 问题分配专家
function _distributionExpert(state) {
  return _request.default.post("/identification/v2/question/distributionExpert", state);
}

// 回答问题
function _createAnswer(state) {
  return _request.default.post("/identification/v2/answer/create", state);
}

/* ---------------------- */
// 获取投诉列表
function _getComplaintList(state) {
  return _request.default.get("/identification/complaint/getList", state);
}
// 处理投诉
function _changeComplaint(state) {
  return _request.default.post("/identification/complaint/handle", state);
}

/* ------------------------------------- */
// 鉴定结果列表
function _getResultListApi(data) {
  return _request.default.get("/identification/resultConfig/list", data);
}

// 创建鉴定结果
function _createResultApi(data) {
  return _request.default.post("/identification/resultConfig/create", data);
}

// 编辑鉴定结果
function _editResultApi(data) {
  return _request.default.post("identification/resultConfig/update/".concat(data.id), data);
}

// 删除鉴定结果
function _deleteResultApi(id) {
  return _request.default.post("/identification/resultConfig/delete/".concat(id));
}