var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-popover", { attrs: { placement: "bottom", trigger: "hover" } }, [
        _c(
          "div",
          { staticClass: "dropdown-menu" },
          [
            _c("div", { staticClass: "aiToolBox" }, [_vm._v("AI工具")]),
            _vm._v(" "),
            _vm._l(_vm.menu, function (m, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass: "menu-item",
                  class: i == _vm.activeIndex ? "activeMenu" : "",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onClickItem(m, i)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "menu-img",
                    attrs: { src: m.icon, alt: "" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "menu-name" }, [
                    _vm._v(" " + _vm._s(m.name) + " "),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { slot: "reference" }, slot: "reference" },
          [_vm._t("default")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }