var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "单据审批", hasBack: true, isFixed: true },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                border: "",
                "row-key": function (row) {
                  return row.order_id
                },
                data: _vm.tableData.data,
                size: "mini",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("ytx-table-column", {
                attrs: {
                  label: "提交时间",
                  width: "160",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.create_time) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  prop: "submitAdmin.real_name",
                  label: "提交人",
                  width: "100",
                  "search-key": "submit_admin_id",
                  "search-type": "radio",
                  "search-query": _vm.searchQuery,
                  options: _vm.userList,
                },
                on: { changeVal: _vm.searchAction },
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "审批状态",
                  width: "90",
                  "search-key": "status",
                  "search-type": "radio",
                  "search-query": _vm.searchQuery,
                  options: _vm.statusOptionsList,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c("div", [_vm._v("待审批")])
                          : scope.row.status == 1
                          ? _c("div", [_vm._v("审批通过")])
                          : scope.row.status == -1
                          ? _c("div", [_vm._v("审批不通过")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  prop: "auditAdmin.real_name",
                  label: "审批人",
                  width: "110",
                  "search-key": "audit_admin_id",
                  "search-type": "radio",
                  "search-query": _vm.searchQuery,
                  options: _vm.userList,
                },
                on: { changeVal: _vm.searchAction },
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  prop: "link_item.audit_time",
                  label: "审批时间",
                  width: "160",
                  "search-key": "audit_date",
                  "search-type": "date",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.audit_time) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "审批类型",
                  width: "100",
                  prop: "",
                  "search-key": "type",
                  "search-type": "radio",
                  options: [
                    { label: "合同", value: "erp_v2_contract" },
                    { label: "入库单", value: "erp_v2_enter_warehouse" },
                    { label: "出库单", value: "erp_v2_out_warehouse" },
                    { label: "调拨单", value: "erp_v2_transfers_warehouse" },
                    { label: "营销", value: "market_media_make" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.type_text) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "单据编号",
                  width: "180",
                  prop: "",
                  "search-key": "order_no",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.order_no
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getdetail(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.order_no))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "审核备注",
                  "min-width": "150",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.remark) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "操作",
                  width: "80",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.revieworder(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            审批\n          ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审批", visible: _vm.showreview, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showreview = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { rows: 3, type: "textarea" },
            model: {
              value: _vm.mask,
              callback: function ($$v) {
                _vm.mask = $$v
              },
              expression: "mask",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showreview = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(-1)
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(1)
                    },
                  },
                },
                [_vm._v("同意")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showpreview, width: "1200px" },
          on: {
            "update:visible": function ($event) {
              _vm.showpreview = $event
            },
          },
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.html_content) } })]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showdetail, width: "1240px" },
          on: {
            "update:visible": function ($event) {
              _vm.showdetail = $event
            },
          },
        },
        [
          _vm.typeName == "erp_v2_enter_warehouse"
            ? _c("pushwareOrder", {
                attrs: { detailjson: _vm.detailjson, isShow: false },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.typeName == "erp_v2_out_warehouse"
            ? _c("transfersOrder", {
                attrs: { detailjson: _vm.detailjson, isShow: false },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.typeName == "erp_v2_transfers_warehouse"
            ? _c("outwareOrder", {
                attrs: { detailjson: _vm.detailjson, isShow: false },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            title: "",
            visible: _vm.moduleVisible,
            "close-on-click-modal": true,
            top: "5vh",
            "show-close": false,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.moduleVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "dialogBody" }, [
            _c("div", { staticClass: "flex dialogCon" }, [
              _c("span", [
                _vm._v("视频类型: \n          "),
                _vm.moduleForm.make_type == 1
                  ? _c("span", [_vm._v("拍卖会介绍视频")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleForm.make_type == 2
                  ? _c("span", [_vm._v("专场介绍视频")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleForm.make_type == 3 || _vm.moduleForm.make_type == 4
                  ? _c("span", [_vm._v("拍品介绍视频")])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "计划发布时间: \n          " +
                    _vm._s(_vm.moduleForm.released_plan_date) +
                    " " +
                    _vm._s(_vm.moduleForm.released_plan_time) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm.moduleForm.make_type == 3 || _vm.moduleForm.make_type == 4
                ? _c("span", [
                    _vm._v(
                      "拍品信息: \n            " +
                        _vm._s(
                          _vm.moduleForm.makeContentData.product.store_name
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [
                _vm.moduleForm.make_type == 2
                  ? _c("span", [
                      _vm._v(
                        "所属拍卖会: " +
                          _vm._s(_vm.moduleForm.makeContentData.venue.title)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleForm.make_type == 3 || _vm.moduleForm.make_type == 4
                  ? _c("span", [
                      _vm._v(
                        "\n            所属专场: " +
                          _vm._s(_vm.moduleForm.makeContentData.venue.title) +
                          " - " +
                          _vm._s(
                            _vm.moduleForm.makeContentData.album.album_name
                          ) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex" }, [
              _vm.moduleForm.extend_data &&
              _vm.moduleForm.extend_data.video_info
                ? _c("div", { staticStyle: { width: "50%" } }, [
                    _c("video", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        controls: "",
                        src: _vm.moduleForm.extend_data.video_info.url,
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }