var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "name"
        ? [
            _c(
              "div",
              {
                style: {
                  marginTop: _vm.facade.paddingTop / 2 + "px",
                  lineHeight: _vm.facade.meeting_lineHeight / 2 + "px",
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.area_background,
                },
              },
              [
                _c(
                  "span",
                  {
                    style: {
                      fontSize: _vm.facade.meeting_fontSize / 2 + "px",
                      fontWeight: _vm.facade.meeting_fontWeight,
                      color: _vm.facade.meeting_color,
                    },
                  },
                  [_vm._v("第三届大众艺术品拍卖会")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                style: {
                  lineHeight: _vm.facade.lineHeight / 2 + "px",
                  padding:
                    " " +
                    _vm.facade.nameMarginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.area_background,
                },
              },
              [
                _c(
                  "span",
                  {
                    style: {
                      fontSize: _vm.facade.fontSize / 2 + "px",
                      fontWeight: _vm.facade.fontWeight,
                      color: _vm.facade.color,
                    },
                  },
                  [_vm._v("书画专场传承经典抽象画艺术大展")]
                ),
              ]
            ),
          ]
        : _vm.content.type === "status"
        ? void 0
        : _vm.content.type === "detail"
        ? [
            _vm.facade.isHorizontalShow != 1
              ? _c(
                  "div",
                  { style: { background: _vm.facade.area_background } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "h-desc flex",
                        style: {
                          fontSize: (_vm.facade.lineFontSize || 28) / 2 + "px",
                          color: _vm.facade.lineColor,
                          fontWeight: _vm.facade.lineFontWeight,
                          padding:
                            _vm.facade.paddingTop / 2 +
                            "px " +
                            _vm.pageInfo.page_margin / 2 +
                            "px 0 " +
                            _vm.pageInfo.page_margin / 2 +
                            "px",
                        },
                      },
                      [_vm._m(0), _vm._v(" "), _vm._m(1)]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        style: {
                          fontSize:
                            (_vm.facade.statusFontSize || 28) / 2 + "px",
                          color: _vm.facade.statusColor,
                          fontWeight: _vm.facade.statusFontWeight,
                          paddingTop: _vm.facade.status_margin_top / 2 + "px",
                          margin: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                        },
                      },
                      [
                        _c("div", { staticClass: "flex-one flex" }, [
                          _c(
                            "div",
                            {
                              staticClass: "item",
                              style:
                                "display: flex;align-items: center;color: " +
                                _vm.facade.startStatusColor +
                                ";",
                            },
                            [
                              _c("span", [_vm._v("同步拍")]),
                              _vm._v(" "),
                              _c("span", [_vm._v("·")]),
                              _vm._v(" "),
                              _c("span", [_vm._v("预展中")]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(2),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "r-btn" }, [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.facade.likeIcon ||
                                "https://saas.cdn.yunzongbu.cn/merchant/23/20241119/image/1732007689025887807.png",
                            },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "relative overflow-hidden",
                        style: {
                          "border-radius": _vm.facade.img_radius / 2 + "px",
                          paddingTop: _vm.facade.img_margin_top / 2 + "px",
                          margin: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                        },
                      },
                      [
                        _vm.pageInfo.show_header_view
                          ? _c("img", {
                              style: { width: "100%", display: "block" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.pageInfo.show_header_view
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "img-pop absolute flex align-items-c",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c ml10",
                                    style: {
                                      color: _vm.facade.hotColor,
                                      "font-size":
                                        _vm.facade.hotFontSize / 2 + "px",
                                      "font-weight": _vm.facade.hotFontWeight,
                                    },
                                  },
                                  [
                                    _vm.facade.hotIcon
                                      ? _c("img", {
                                          style: {
                                            width:
                                              _vm.facade.hotFontSize / 2 + "px",
                                            height:
                                              _vm.facade.hotFontSize / 2 + "px",
                                          },
                                          attrs: { src: _vm.facade.hotIcon },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "txt" }, [
                                      _vm._v("6531"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c ml10",
                                    style: {
                                      color: _vm.facade.bidCountColor,
                                      "font-size":
                                        _vm.facade.bidCountFontSize / 2 + "px",
                                      "font-weight":
                                        _vm.facade.bidCountFontWeight,
                                    },
                                  },
                                  [
                                    _vm.facade.bidCountIcon
                                      ? _c("img", {
                                          style: {
                                            width:
                                              _vm.facade.hotFontSize / 2 + "px",
                                            height:
                                              _vm.facade.hotFontSize / 2 + "px",
                                          },
                                          attrs: {
                                            src: _vm.facade.bidCountIcon,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "txt" }, [
                                      _vm._v("6531"),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _c(
                  "div",
                  { style: { background: _vm.facade.area_background } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        style: {
                          fontSize:
                            (_vm.facade.statusFontSize || 28) / 2 + "px",
                          color: _vm.facade.statusColor,
                          fontWeight: _vm.facade.statusFontWeight,
                          paddingTop: _vm.facade.status_margin_top / 2 + "px",
                          margin: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                        },
                      },
                      [
                        _c("div", { staticClass: "flex-one flex" }, [
                          _c(
                            "div",
                            {
                              staticClass: "item",
                              style:
                                "display: flex;align-items: center;color: " +
                                _vm.facade.startStatusColor +
                                ";",
                            },
                            [
                              _c("span", [_vm._v("同步拍")]),
                              _vm._v(" "),
                              _c("span", [_vm._v("·")]),
                              _vm._v(" "),
                              _c("span", [_vm._v("预展中")]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(3),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "r-btn" }, [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.facade.likeIcon ||
                                "https://saas.cdn.yunzongbu.cn/merchant/23/20241119/image/1732007689025887807.png",
                            },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "relative overflow-hidden flex",
                        style: {
                          "border-radius": _vm.facade.img_radius / 2 + "px",
                          paddingTop: _vm.facade.img_margin_top / 2 + "px",
                          margin: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                        },
                      },
                      [
                        _vm.pageInfo.show_header_view
                          ? _c("img", {
                              style: {
                                width: "142px",
                                height: "190px",
                                display: "block",
                                objectFit: "cover",
                              },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png",
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style:
                              "margin-left: " +
                              (_vm.facade.infoMarginLeft || 32) / 2 +
                              "px",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex-one flex align-items-c",
                                style: {
                                  fontSize: _vm.facade.fontSize / 2 + "px",
                                  color: _vm.facade.color,
                                  height: _vm.facade.lineHeight / 2 + "px",
                                },
                              },
                              [
                                _c("div", { staticClass: "item flex-one" }, [
                                  _vm._v("拍品：17件 (LOT108-115)"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "flex-one flex align-items-c",
                                style: {
                                  fontSize: _vm.facade.fontSize / 2 + "px",
                                  color: _vm.facade.color,
                                  height: _vm.facade.lineHeight / 2 + "px",
                                  borderTop:
                                    "1px solid " + _vm.facade.divisionLine,
                                },
                              },
                              [
                                _c("div", { staticClass: "item flex-one" }, [
                                  _vm._v("保证金：RMB 150,000 或 1:5"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "flex-one flex align-items-c",
                                style: {
                                  fontSize: _vm.facade.fontSize / 2 + "px",
                                  color: _vm.facade.color,
                                  height: _vm.facade.lineHeight / 2 + "px",
                                  borderTop:
                                    "1px solid " + _vm.facade.divisionLine,
                                },
                              },
                              [
                                _c("div", { staticClass: "item flex-one" }, [
                                  _vm._v("佣金：15%"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "flex-one flex align-items-c",
                                style: {
                                  fontSize: _vm.facade.fontSize / 2 + "px",
                                  color: _vm.facade.color,
                                  height: _vm.facade.lineHeight / 2 + "px",
                                  borderTop:
                                    "1px solid " + _vm.facade.divisionLine,
                                },
                              },
                              [
                                _c("div", { staticClass: "item flex-one" }, [
                                  _vm._v("热地：5047"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "flex-one flex align-items-c",
                                style: {
                                  fontSize: _vm.facade.fontSize / 2 + "px",
                                  color: _vm.facade.color,
                                  height: _vm.facade.lineHeight / 2 + "px",
                                  borderTop:
                                    "1px solid " + _vm.facade.divisionLine,
                                },
                              },
                              [
                                _c("div", { staticClass: "item flex-one" }, [
                                  _vm._v("出价：385"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
          ]
        : _vm.content.type === "list"
        ? [
            _c(
              "div",
              {
                style: {
                  marginTop: _vm.facade.marginTop / 2 + "px",
                  background: _vm.facade.area_background,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tab flex align-items-c",
                    style: {
                      height: _vm.facade.tabHeight / 2 + "px",
                      padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-one flex align-items-c h-full",
                        style: {
                          "font-size": _vm.facade.tabFontSize / 2 + "px",
                          color: _vm.facade.tabColor,
                          "font-weight": _vm.facade.tabFontWeight,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "item curr",
                            style: {
                              "font-size":
                                _vm.facade.currTabFontSize / 2 + "px",
                              color: _vm.facade.currTabColor,
                              "font-weight": _vm.facade.currTabFontWeight,
                            },
                          },
                          [
                            _vm._v("拍品\n            "),
                            _c("div", {
                              staticClass: "bg",
                              style: { background: _vm.facade.currTabColor },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(4),
                        _vm._v(" "),
                        _vm._m(5),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "icon flex align-items-c" }, [
                      _vm.facade.listIcon
                        ? _c("div", { staticClass: "icon-view" }, [
                            _c("img", { attrs: { src: _vm.facade.listIcon } }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.searchIcon
                        ? _c("div", { staticClass: "icon-view" }, [
                            _c("img", {
                              attrs: { src: _vm.facade.searchIcon },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "list",
                staticStyle: { padding: "20px 0" },
                style: "background: " + _vm.facade.area_background,
              },
              [
                _vm.listFacade
                  ? _c("auctionList", {
                      attrs: {
                        facade: Object.assign(
                          {},
                          _vm.listFacade,
                          _vm.listFacade.type_setting[_vm.facade.listShow],
                          { pageMargin: _vm.pageInfo.page_margin }
                        ),
                        content: { type: "list", style: _vm.facade.listShow },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("span", [_vm._v("保证金 ")]),
      _vm._v(" "),
      _c("span", [_vm._v("1,000元")]),
      _vm._v(" "),
      _c("span", [_vm._v(" 或 ")]),
      _vm._v(" "),
      _c("span", [_vm._v("1:10")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("span", [_vm._v("佣金 ")]),
      _vm._v(" "),
      _c("span", [_vm._v("10%")]),
      _vm._v(" "),
      _c("span", [_vm._v(" - ")]),
      _vm._v(" "),
      _c("span", [_vm._v("15%")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "margin-left": "16px",
          display: "flex",
          "align-items": "center",
        },
      },
      [
        _c("span", [_vm._v("当前第")]),
        _vm._v(" "),
        _c("span", { staticStyle: { color: "rgb(255, 61, 61)" } }, [
          _vm._v("7"),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v("/37件")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "margin-left": "16px",
          display: "flex",
          "align-items": "center",
        },
      },
      [
        _c("span", [_vm._v("当前第")]),
        _vm._v(" "),
        _c("span", { staticStyle: { color: "rgb(255, 61, 61)" } }, [
          _vm._v("7"),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v("/37件")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _vm._v("我出价的\n            "),
      _c("div", { staticClass: "bg" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _vm._v("拍卖详情\n            "),
      _c("div", { staticClass: "bg" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }