var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.facade,
        "label-width": "100px",
        size: "mini",
        "label-position": "left",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("el-form-item", { staticClass: "mt20", attrs: { label: "装饰" } }, [
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.facade.personalizedDecoration,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "personalizedDecoration", $$v)
                  },
                  expression: "facade.personalizedDecoration",
                },
              },
              [
                _c("el-radio", { attrs: { label: "" } }, [_vm._v("不使用")]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: "1" } }, [_vm._v("装饰1")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "区域外间距" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageMarginTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageMarginTop", $$v)
                    },
                    expression: "facade.pageMarginTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageMarginBottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageMarginBottom", $$v)
                    },
                    expression: "facade.pageMarginBottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageMarginLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageMarginLeft", $$v)
                    },
                    expression: "facade.pageMarginLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageMarginRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageMarginRight", $$v)
                    },
                    expression: "facade.pageMarginRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "区域内间距" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pagePaddingTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pagePaddingTop", $$v)
                    },
                    expression: "facade.pagePaddingTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pagePaddingBottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pagePaddingBottom", $$v)
                    },
                    expression: "facade.pagePaddingBottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pagePaddingLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pagePaddingLeft", $$v)
                    },
                    expression: "facade.pagePaddingLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pagePaddingRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pagePaddingRight", $$v)
                    },
                    expression: "facade.pagePaddingRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "区域圆角" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list tl" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageRadiusTopLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageRadiusTopLeft", $$v)
                    },
                    expression: "facade.pageRadiusTopLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list tr" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageRadiusTopRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageRadiusTopRight", $$v)
                    },
                    expression: "facade.pageRadiusTopRight",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list bl" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageRadiusBottomLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageRadiusBottomLeft", $$v)
                    },
                    expression: "facade.pageRadiusBottomLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list br" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageRadiusBottomRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageRadiusBottomRight", $$v)
                    },
                    expression: "facade.pageRadiusBottomRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "背景颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#F5F5F5" },
            model: {
              value: _vm.facade.pageBackground,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "pageBackground", $$v)
              },
              expression: "facade.pageBackground",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "外边框大小" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.outBorderTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "outBorderTop", $$v)
                    },
                    expression: "facade.outBorderTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.outBorderBottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "outBorderBottom", $$v)
                    },
                    expression: "facade.outBorderBottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.outBorderLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "outBorderLeft", $$v)
                    },
                    expression: "facade.outBorderLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.outBorderRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "outBorderRight", $$v)
                    },
                    expression: "facade.outBorderRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "外边框类型" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.outBorderType,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "outBorderType", $$v)
                },
                expression: "facade.outBorderType",
              },
            },
            [
              _c("el-radio", { attrs: { label: "solid" } }, [_vm._v("直线")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "dashed" } }, [_vm._v("虚线")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "dotted" } }, [_vm._v("点线")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "double" } }, [_vm._v("双线")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "groove" } }, [_vm._v("凹槽")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "ridge" } }, [_vm._v("凸起")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "边框距离" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.outBorderMarginTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "outBorderMarginTop", $$v)
                    },
                    expression: "facade.outBorderMarginTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.outBorderMarginBottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "outBorderMarginBottom", $$v)
                    },
                    expression: "facade.outBorderMarginBottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.outBorderMarginLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "outBorderMarginLeft", $$v)
                    },
                    expression: "facade.outBorderMarginLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.outBorderMarginRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "outBorderMarginRight", $$v)
                    },
                    expression: "facade.outBorderMarginRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "内边框大小" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.borderTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "borderTop", $$v)
                    },
                    expression: "facade.borderTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.borderBottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "borderBottom", $$v)
                    },
                    expression: "facade.borderBottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.borderLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "borderLeft", $$v)
                    },
                    expression: "facade.borderLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.borderRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "borderRight", $$v)
                    },
                    expression: "facade.borderRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "内边框类型" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.borderType,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "borderType", $$v)
                },
                expression: "facade.borderType",
              },
            },
            [
              _c("el-radio", { attrs: { label: "solid" } }, [_vm._v("直线")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "dashed" } }, [_vm._v("虚线")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "dotted" } }, [_vm._v("点线")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "double" } }, [_vm._v("双线")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "groove" } }, [_vm._v("凹槽")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "ridge" } }, [_vm._v("凸起")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "边框颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#F5F5F5" },
            model: {
              value: _vm.facade.borderColor,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "borderColor", $$v)
              },
              expression: "facade.borderColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "图片圆角" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list tl" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.imgRadiusTopLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "imgRadiusTopLeft", $$v)
                    },
                    expression: "facade.imgRadiusTopLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list tr" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.imgRadiusTopRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "imgRadiusTopRight", $$v)
                    },
                    expression: "facade.imgRadiusTopRight",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list bl" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.imgRadiusBottomLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "imgRadiusBottomLeft", $$v)
                    },
                    expression: "facade.imgRadiusBottomLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list br" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.imgRadiusBottomRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "imgRadiusBottomRight", $$v)
                    },
                    expression: "facade.imgRadiusBottomRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "内容间距" } },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("slider", {
                attrs: { min: 0, max: 100 },
                model: {
                  value: _vm.facade.itemPaddingTop,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "itemPaddingTop", $$v)
                  },
                  expression: "facade.itemPaddingTop",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字宽度" } },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("slider", {
                attrs: { min: 0, max: 100 },
                model: {
                  value: _vm.facade.textWidth,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "textWidth", $$v)
                  },
                  expression: "facade.textWidth",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "拍卖会名称" } },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.facade.showVenueName,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "showVenueName", $$v)
                    },
                    expression: "facade.showVenueName",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("展示")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v("不展示"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "拍卖位置" } },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.facade.showVenueAddress,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "showVenueAddress", $$v)
                    },
                    expression: "facade.showVenueAddress",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("展示")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v("不展示"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "展示图片" } },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.facade.noShowImg,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "noShowImg", $$v)
                    },
                    expression: "facade.noShowImg",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("展示")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v("不展示"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字垂直位置" } },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.facade.textItemArea,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "textItemArea", $$v)
                    },
                    expression: "facade.textItemArea",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "flex-start" } }, [
                    _vm._v("顶部"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "center" } }, [
                    _vm._v("垂直居中"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "flex-end" } }, [
                    _vm._v("底部"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字对齐" } },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.facade.textAlign,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "textAlign", $$v)
                    },
                    expression: "facade.textAlign",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "left" } }, [
                    _vm._v("居左"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "center" } }, [
                    _vm._v("居中"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "right" } }, [
                    _vm._v("居右"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字背景" } },
        [
          _c("colour", {
            attrs: { colour: "#F5F5F5" },
            model: {
              value: _vm.facade.textBackground,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "textBackground", $$v)
              },
              expression: "facade.textBackground",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字距离" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.textPaddingTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "textPaddingTop", $$v)
                    },
                    expression: "facade.textPaddingTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.textPaddingBottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "textPaddingBottom", $$v)
                    },
                    expression: "facade.textPaddingBottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.textPaddingLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "textPaddingLeft", $$v)
                    },
                    expression: "facade.textPaddingLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.textPaddingRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "textPaddingRight", $$v)
                    },
                    expression: "facade.textPaddingRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.facade.showVenueName
        ? [
            _c("div", { staticClass: "h30" }),
            _vm._v(" "),
            _c("div", { staticClass: "fWeight500" }, [
              _vm._v("拍卖会名称区域"),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "区域距离" } },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "radius-list t" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value: _vm.facade.venueNameMarginTop,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "venueNameMarginTop", $$v)
                          },
                          expression: "facade.venueNameMarginTop",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radius-list b" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value: _vm.facade.venueNameMarginBottom,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "venueNameMarginBottom", $$v)
                          },
                          expression: "facade.venueNameMarginBottom",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "拍卖会名称" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.facade.venueName,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "venueName", $$v)
                    },
                    expression: "facade.venueName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "背景颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#F5F5F5" },
                  model: {
                    value: _vm.facade.venueNameBackground,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "venueNameBackground", $$v)
                    },
                    expression: "facade.venueNameBackground",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#999999" },
                  model: {
                    value: _vm.facade.venueNameColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "venueNameColor", $$v)
                    },
                    expression: "facade.venueNameColor",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 50 },
                  model: {
                    value: _vm.facade.venueNameFontSize,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "venueNameFontSize", $$v)
                    },
                    expression: "facade.venueNameFontSize",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字样式" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.facade.venueNameFontWeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "venueNameFontWeight", $$v)
                      },
                      expression: "facade.venueNameFontWeight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500" }, [_vm._v("标题区域")]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "距上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.titleMarginTop,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "titleMarginTop", $$v)
              },
              expression: "facade.titleMarginTop",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#999999" },
            model: {
              value: _vm.facade.titleColor,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "titleColor", $$v)
              },
              expression: "facade.titleColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.titleFontSize,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "titleFontSize", $$v)
              },
              expression: "facade.titleFontSize",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.titleFontWeight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "titleFontWeight", $$v)
                },
                expression: "facade.titleFontWeight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500" }, [_vm._v("预展时间标签区域")]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "标签文字" } },
        [
          _c("el-input", {
            model: {
              value: _vm.facade.preTimeText,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "preTimeText", $$v)
              },
              expression: "facade.preTimeText",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字类型" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.preTimeTextStyle,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "preTimeTextStyle", $$v)
                },
                expression: "facade.preTimeTextStyle",
              },
            },
            [
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("单独展示")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "2" } }, [_vm._v("同行展示")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "距上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.preTimeTextMarginTop,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "preTimeTextMarginTop", $$v)
              },
              expression: "facade.preTimeTextMarginTop",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#999999" },
            model: {
              value: _vm.facade.preTimeTextColor,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "preTimeTextColor", $$v)
              },
              expression: "facade.preTimeTextColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.preTimeTextFontSize,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "preTimeTextFontSize", $$v)
              },
              expression: "facade.preTimeTextFontSize",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.preTimeTextFontWeight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "preTimeTextFontWeight", $$v)
                },
                expression: "facade.preTimeTextFontWeight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500" }, [_vm._v("预展时间区域")]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "距上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.preTimeMarginTop,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "preTimeMarginTop", $$v)
              },
              expression: "facade.preTimeMarginTop",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#999999" },
            model: {
              value: _vm.facade.preTimeColor,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "preTimeColor", $$v)
              },
              expression: "facade.preTimeColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.preTimeFontSize,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "preTimeFontSize", $$v)
              },
              expression: "facade.preTimeFontSize",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.preTimeFontWeight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "preTimeFontWeight", $$v)
                },
                expression: "facade.preTimeFontWeight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500" }, [_vm._v("开拍时间标签区域")]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "标签文字" } },
        [
          _c("el-input", {
            model: {
              value: _vm.facade.timeText,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "timeText", $$v)
              },
              expression: "facade.timeText",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字类型" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.timeTextStyle,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "timeTextStyle", $$v)
                },
                expression: "facade.timeTextStyle",
              },
            },
            [
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("单独展示")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "2" } }, [_vm._v("同行展示")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "距上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.timeTextMarginTop,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "timeTextMarginTop", $$v)
              },
              expression: "facade.timeTextMarginTop",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#999999" },
            model: {
              value: _vm.facade.timeTextColor,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "timeTextColor", $$v)
              },
              expression: "facade.timeTextColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.timeTextFontSize,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "timeTextFontSize", $$v)
              },
              expression: "facade.timeTextFontSize",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.timeTextFontWeight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "timeTextFontWeight", $$v)
                },
                expression: "facade.timeTextFontWeight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500" }, [_vm._v("开拍时间区域")]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "距上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.timeMarginTop,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "timeMarginTop", $$v)
              },
              expression: "facade.timeMarginTop",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#999999" },
            model: {
              value: _vm.facade.timeColor,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "timeColor", $$v)
              },
              expression: "facade.timeColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.timeFontSize,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "timeFontSize", $$v)
              },
              expression: "facade.timeFontSize",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.timeFontWeight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "timeFontWeight", $$v)
                },
                expression: "facade.timeFontWeight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500" }, [_vm._v("直播时间标签区域")]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "标签文字" } },
        [
          _c("el-input", {
            model: {
              value: _vm.facade.liveTimeText,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "liveTimeText", $$v)
              },
              expression: "facade.liveTimeText",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字类型" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.liveTimeTextStyle,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "liveTimeTextStyle", $$v)
                },
                expression: "facade.liveTimeTextStyle",
              },
            },
            [
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("单独展示")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "2" } }, [_vm._v("同行展示")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "距上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.liveTimeTextMarginTop,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "liveTimeTextMarginTop", $$v)
              },
              expression: "facade.liveTimeTextMarginTop",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#999999" },
            model: {
              value: _vm.facade.liveTimeTextColor,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "liveTimeTextColor", $$v)
              },
              expression: "facade.liveTimeTextColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.liveTimeTextFontSize,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "liveTimeTextFontSize", $$v)
              },
              expression: "facade.liveTimeTextFontSize",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.liveTimeTextFontWeight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "liveTimeTextFontWeight", $$v)
                },
                expression: "facade.liveTimeTextFontWeight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500" }, [_vm._v("开拍时间区域")]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "距上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.liveTimeMarginTop,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "liveTimeMarginTop", $$v)
              },
              expression: "facade.liveTimeMarginTop",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#999999" },
            model: {
              value: _vm.facade.liveTimeColor,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "liveTimeColor", $$v)
              },
              expression: "facade.liveTimeColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.liveTimeFontSize,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "liveTimeFontSize", $$v)
              },
              expression: "facade.liveTimeFontSize",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.liveTimeFontWeight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "liveTimeFontWeight", $$v)
                },
                expression: "facade.liveTimeFontWeight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.facade.showVenueAddress
        ? [
            _c("div", { staticClass: "h30" }),
            _vm._v(" "),
            _c("div", { staticClass: "fWeight500" }, [
              _vm._v("拍卖会位置区域"),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "区域距离" } },
              [
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    { staticClass: "radius-list t" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value: _vm.facade.venueAddressMarginTop,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "venueAddressMarginTop", $$v)
                          },
                          expression: "facade.venueAddressMarginTop",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "radius-list b" },
                    [
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: { controls: false },
                        model: {
                          value: _vm.facade.venueAddressMarginBottom,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade,
                              "venueAddressMarginBottom",
                              $$v
                            )
                          },
                          expression: "facade.venueAddressMarginBottom",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "地址标签" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.facade.addressTag,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "addressTag", $$v)
                    },
                    expression: "facade.addressTag",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "标签颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#999999" },
                  model: {
                    value: _vm.facade.addressTagColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "addressTagColor", $$v)
                    },
                    expression: "facade.addressTagColor",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 50 },
                  model: {
                    value: _vm.facade.addressTagFontSize,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "addressTagFontSize", $$v)
                    },
                    expression: "facade.addressTagFontSize",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字样式" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.facade.addressTagFontWeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "addressTagFontWeight", $$v)
                      },
                      expression: "facade.addressTagFontWeight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "简要位置" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.facade.venueAddressBrief,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "venueAddressBrief", $$v)
                    },
                    expression: "facade.venueAddressBrief",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "简要颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#999999" },
                  model: {
                    value: _vm.facade.addressBriefColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "addressBriefColor", $$v)
                    },
                    expression: "facade.addressBriefColor",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "距上距离" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value: _vm.facade.addressBriefMarginTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "addressBriefMarginTop", $$v)
                    },
                    expression: "facade.addressBriefMarginTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 50 },
                  model: {
                    value: _vm.facade.addressBriefFontSize,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "addressBriefFontSize", $$v)
                    },
                    expression: "facade.addressBriefFontSize",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字样式" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.facade.addressBriefFontWeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "addressBriefFontWeight", $$v)
                      },
                      expression: "facade.addressBriefFontWeight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "详细地址" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.facade.venueAddressName,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "venueAddressName", $$v)
                    },
                    expression: "facade.venueAddressName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "详细颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#999999" },
                  model: {
                    value: _vm.facade.addressNameColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "addressNameColor", $$v)
                    },
                    expression: "facade.addressNameColor",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "距上距离" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value: _vm.facade.addressNameMarginTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "addressNameMarginTop", $$v)
                    },
                    expression: "facade.addressNameMarginTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 50 },
                  model: {
                    value: _vm.facade.addressNameFontSize,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "addressNameFontSize", $$v)
                    },
                    expression: "facade.addressNameFontSize",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字样式" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.facade.addressNameFontWeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "addressNameFontWeight", $$v)
                      },
                      expression: "facade.addressNameFontWeight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }