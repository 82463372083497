var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "w-full min-h-full relative",
      staticStyle: { background: "#F2F3F8" },
      on: { click: _vm.closeImageProcessingPop },
    },
    [
      _c(
        "div",
        { staticStyle: { width: "150px" } },
        [
          _c(
            "ImageHeader",
            { ref: "imageHeaderEL", on: { onClickItem: _vm.onAiChange } },
            [
              _c("div", { staticClass: "menu-title" }, [
                _c("div", { staticClass: "menu-text" }, [
                  _vm._v(_vm._s(_vm.headTit)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "menu-icon rotate-right" },
                  [
                    _c("Icon", {
                      staticStyle: { width: "20px", height: "20px" },
                      attrs: { icon: "ic:round-chevron-right" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isStart,
              expression: "isStart",
            },
          ],
          staticClass: "absolute right-0 bottom-0 z-10 top-0",
          staticStyle: { width: "calc(100% - 176px)" },
        },
        [
          _c("ImageProcessing", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: ["imageScratch", "qcsy"].includes(
                  _vm.editImageData.handleType
                ),
                expression:
                  "['imageScratch','qcsy'].includes(editImageData.handleType)",
              },
            ],
            ref: "imageProcessingEL",
            on: { submit: _vm.submitAction },
          }),
          _vm._v(" "),
          _c("ImageOtherHandle", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: [
                  "imageReplace",
                  "imageClear",
                  "imageCut",
                  "imageColorEnhance",
                  "imageOutward",
                ].includes(_vm.editImageData.handleType),
                expression:
                  "['imageReplace','imageClear','imageCut','imageColorEnhance','imageOutward'].includes(editImageData.handleType)",
              },
            ],
            ref: "imageOtherEL",
            on: { submit: _vm.submitAction },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "absolute top-84px left-0 right-0 flex bottom-0",
          staticStyle: { padding: "0 20px" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-176px h-full pr-4 pl-6 pb-6 flex flex-col overflow-hidden",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.originallyInfo.img,
                      expression: "originallyInfo.img",
                    },
                  ],
                  staticClass:
                    "w-128px h-128px mb-10px border-2 bg-white border-6432E7 overflow-hidden dark:bg-23262f4D dark:border-23262fCC rounded-8px mt-10px relative cursor-pointer",
                  staticStyle: {
                    "box-shadow": "0px 0px 20px 0px rgba(0,0,0,0.05)",
                    border: "2px solid transparent",
                  },
                  style:
                    _vm.activeUrl == _vm.originallyInfo.img
                      ? "border: 2px solid #6c49e6;"
                      : "",
                  on: {
                    click: function ($event) {
                      return _vm.changeImageData(_vm.originallyInfo.img)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "w-full h-full object-contain",
                    attrs: { src: _vm.originallyInfo.img + "!webp600" },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "absolute rounded-6px text-ffffff",
                      staticStyle: {
                        top: "0.25rem",
                        left: "0.25rem",
                        padding: "0 8px",
                        "font-size": "12px",
                        background: "#6c49e6",
                      },
                    },
                    [_vm._v("\n          原图\n        ")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-1 overflow-hidden pt-10px scorllYBox" },
                _vm._l(_vm.drawList, function (m, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass:
                        "w-128px h-128px mb-10px border bg-white border-e6e7eb dark:bg-23262f4D dark:border-23262fCC rounded-8px cursor-pointer overflow-hidden relative",
                      staticStyle: {
                        "box-shadow": "0px 0px 20px 0px rgba(0,0,0,0.05)",
                        border: "2px solid transparent",
                      },
                      style:
                        _vm.activeUrl == m ? "border: 2px solid #6c49e6;" : "",
                      on: {
                        click: function ($event) {
                          return _vm.changeImageData(m)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "w-full h-full object-contain",
                        attrs: { src: m + "!webp600" },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "absolute bg-0088FF rounded-6px text-ffffff",
                          staticStyle: {
                            top: "0.25rem",
                            left: "0.25rem",
                            padding: "0 8px",
                            "font-size": "12px",
                          },
                        },
                        [
                          _vm._v(
                            "\n            修图" +
                              _vm._s(i + 1) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "absolute",
                          staticStyle: { top: "0.25rem", right: "0.25rem" },
                        },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: { placement: "bottom", trigger: "hover" },
                            },
                            [
                              _c("span", [_vm._v("删除")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "bg-0088FF rounded-6px text-ffffff flex items-center cursor-pointer",
                                  staticStyle: { padding: "2px" },
                                  attrs: { slot: "reference" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleDeleteDraw(m)
                                    },
                                  },
                                  slot: "reference",
                                },
                                [
                                  _c("Icon", {
                                    staticStyle: {
                                      width: "16px",
                                      height: "16px",
                                    },
                                    attrs: {
                                      icon: "material-symbols:delete-outline-rounded",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: {
                    background: "#6c49e6",
                    color: "#fff",
                    width: "128px",
                    margin: "20px 0",
                  },
                  on: { click: _vm.onValidated },
                },
                [_vm._v("确认使用")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isStart
            ? _c("div", { staticClass: "flex-1 min-h-full" }, [
                _c(
                  "div",
                  {
                    staticClass: "w-full h-full flex items-center flex-col",
                    class: _vm.isMobile ? "" : " pr-4 pb-6",
                    on: {
                      mousemove: _vm.caseControlMove,
                      touchmove: _vm.caseControlMove,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pt-28px text-24px dark:text-white font-medium",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.caseList[_vm.changeCase].name) +
                            "示例\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-6" }, [
                      _c("div", {}, [
                        _c(
                          "div",
                          {
                            ref: "caseControlEL",
                            staticClass:
                              "relative select-none overflow-hidden rounded-lg",
                            staticStyle: {
                              "user-select": "none",
                              "touch-action": "none",
                            },
                          },
                          [
                            _c("img", {
                              staticClass:
                                "overflow-hidden object-contain mobile-max-h mobile-max-w desktop-max-h desktop-max-w",
                              staticStyle: { opacity: "1" },
                              attrs: {
                                src:
                                  _vm.caseList[_vm.changeCase].img +
                                  "?image/auto-orient,1/resize,m_lfit,w_1200/quality,q_90/format,webp",
                                alt: "image",
                              },
                              on: { load: _vm.imgLoaded },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass:
                                "overflow-hidden absolute left-0 top-0 bg-bg-0 object-contain mobile-max-h mobile-max-w desktop-max-h desktop-max-w",
                              style:
                                "clip-path: polygon(" +
                                _vm.imgOpenPixel +
                                "px 0%, 100% 0%, 100% 100%, " +
                                _vm.imgOpenPixel +
                                "px 100%); opacity: 1;",
                              attrs: {
                                src:
                                  _vm.caseList[_vm.changeCase].img2 +
                                  "?image/auto-orient,1/resize,m_lfit,w_1200/quality,q_90/format,webp",
                                alt: "image",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showControl,
                                    expression: "showControl",
                                  },
                                ],
                                staticClass:
                                  "absolute top-0 h-full cursor-ew-resize",
                                style:
                                  "left: " +
                                  _vm.imgOpenPixel +
                                  "px;cursor: ew-resize;",
                                on: {
                                  mousedown: _vm.caseControlDown,
                                  mouseup: _vm.caseControlUp,
                                  touchstart: _vm.caseControlDown,
                                  touchend: _vm.caseControlUp,
                                },
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "h-full w-1px bg-white dark:bg-2F2E34",
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "absolute",
                                    staticStyle: {
                                      top: "50%",
                                      left: "50%",
                                      transform:
                                        "translateX(-50%) translateY(-50%)",
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "28",
                                          height: "36",
                                          viewBox: "0 0 28 36",
                                          fill: "none",
                                        },
                                      },
                                      [
                                        _c("rect", {
                                          attrs: {
                                            x: "0.75",
                                            y: "1.25",
                                            width: "26.5",
                                            height: "33.5",
                                            rx: "7.25",
                                            fill: "#6432E7",
                                            stroke: "white",
                                            "stroke-width": "1.5",
                                          },
                                        }),
                                        _c("circle", {
                                          attrs: {
                                            cx: "10.6518",
                                            cy: "12.2116",
                                            r: "1.53458",
                                            fill: "#ffffff",
                                          },
                                        }),
                                        _c("circle", {
                                          attrs: {
                                            cx: "10.6518",
                                            cy: "17.9999",
                                            r: "1.53458",
                                            fill: "#ffffff",
                                          },
                                        }),
                                        _c("circle", {
                                          attrs: {
                                            cx: "10.6518",
                                            cy: "23.7885",
                                            r: "1.53458",
                                            fill: "#ffffff",
                                          },
                                        }),
                                        _c("circle", {
                                          attrs: {
                                            cx: "17.3471",
                                            cy: "12.2116",
                                            r: "1.53458",
                                            fill: "#ffffff",
                                          },
                                        }),
                                        _c("circle", {
                                          attrs: {
                                            cx: "17.3471",
                                            cy: "17.9999",
                                            r: "1.53458",
                                            fill: "#ffffff",
                                          },
                                        }),
                                        _c("circle", {
                                          attrs: {
                                            cx: "17.3471",
                                            cy: "23.7885",
                                            r: "1.53458",
                                            fill: "#ffffff",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mt-8" },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              background: "#6c49e6",
                              color: "#fff",
                            },
                            on: { click: _vm.onKnow },
                          },
                          [_vm._v("知道了")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }