"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _user = require("@/api/user");
var _accounts = require("@/api/accounts");
var _index = require("@/utils/index");
var _auctionrecord = _interopRequireDefault(require("./auctionrecord"));
var _upauctionrecord = _interopRequireDefault(require("./upauctionrecord"));
var _nft = require("@/api/nft");
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _areaOtherList = _interopRequireDefault(require("@/assets/js/areaOtherList.js"));
var _addUser = _interopRequireDefault(require("../../../components/addUser.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserDetails',
  components: {
    auctionrecord: _auctionrecord.default,
    upauctionrecord: _upauctionrecord.default,
    fileList: _fileList.default,
    AddUserCom: _addUser.default
  },
  props: {
    uid: {
      type: Number,
      default: null
    },
    row: {
      type: Object,
      default: null
    },
    showdetail: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      showview: false,
      moren: require('@/assets/images/f.png'),
      loading: false,
      columns: [],
      Visible: false,
      list: [{
        val: '0',
        label: '消费记录'
      }, {
        val: '3',
        label: '持有优惠券'
      }],
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 6
      },
      nftTableData: {
        data: [],
        total: 0
      },
      nftTableFrom: {
        page: 1,
        limit: 6,
        status: ''
      },
      psInfo: null,
      type: '0',
      drawtype: 'detail',
      userdetail: {},
      formatPriceNumber: _index.formatPriceNumber,
      listLoading: false,
      nftListLoading: false,
      userjson: {},
      showusercard: false,
      showImg: false,
      imgT: '查看凭证',
      imgSrc: '',
      addressList: [],
      addressForm: {
        area: []
      },
      addressVisible: false,
      areaList: [],
      isNo: true,
      isPhone: false,
      areaIndex: '',
      districtIndex: '',
      cityIndex: ''
    };
  },
  computed: {
    pression: function pression() {
      return this.$store.getters.userPression['merchantDetailUser'] || {};
    },
    pressionColumn: function pressionColumn() {
      return this.$store.getters.userPression || {};
    },
    pressionListField: function pressionListField() {
      if (this.pression && this.pression.properties && this.pression.properties.display_fields) {
        var json = {};
        this.pression.properties.display_fields.forEach(function (item) {
          json[item] = true;
        });
        return json;
      } else {
        return {};
      }
    },
    districtList: function districtList() {
      if (!this.areaList.length) return [];
      if (this.areaIndex || this.areaIndex === 0) {
        return this.areaList[this.areaIndex].children || [];
      } else {
        return [];
      }
    },
    cityList: function cityList() {
      if (!this.areaList.length) return [];
      if (this.areaIndex || this.areaIndex === 0) {
        if (this.districtIndex || this.districtIndex === 0) {
          return this.areaList[this.areaIndex].children[this.districtIndex].children || [];
        } else {
          return [];
        }
      } else {
        return [];
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (!this.$store.getters.userPression['/user/details']) {
      this.$message.error('暂无权限，请联系管理员');
      this.$emit('close');
      return;
    }
    var obj = this.$store.getters.userPression['merchantUserVerifyList'] ? this.$store.getters.userPression['merchantUserVerifyList'].properties : {};
    this.isPhone = obj.display_fields && obj.display_fields.includes("idcard") ? true : false;
    console.log('111---', this.isPhone);
    if (this.uid) {
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.nftTableFrom.own_uid = this.uid;
      if (this.$store.getters.userPression['merchantDetailUser']) {
        (0, _user.getUserDetail)(this.uid).then(function (res) {
          console.log(res.data);
          _this.userdetail = res.data;
          _this.showview = true;
          loading.close();
          (0, _user.userAddressListApi)({
            uid: _this.uid
          }).then(function (result) {
            _this.addressList = result.data.list;
          });
        });
      } else {
        this.showview = true;
      }
      this.tableFrom.uid = this.uid;
      // this.tableFrom.type = 'order';
      if (this.$store.getters.userPression['merchantWalletUserWalletHistoryList']) {
        (0, _accounts.userWalletHistoryList)(this.tableFrom).then(function (res) {
          _this.tableData.data = res.data.list;
          _this.tableData.total = res.data.count;
          _this.listLoading = false;
        }).catch(function (res) {
          // this.$message.error(res.message);
          _this.listLoading = false;
        });
      }
      this.getNft();
      // this.getHeader()
      // this.getInfo('0')
    }
  },
  methods: {
    editAreaAction: function editAreaAction(type) {
      if (type === 1) {
        this.districtIndex = '';
        this.cityIndex = '';
      } else if (type === 2) {
        this.cityIndex = '';
        if (this.districtList[this.districtIndex] && !this.districtList[this.districtIndex].children) {
          this.addressForm.area = [this.areaList[this.areaIndex].city_id, this.districtList[this.districtIndex].city_id, ''];
          this.handleChange(this.addressForm.area);
        }
      } else if (type === 3) {
        this.addressForm.area = [this.areaList[this.areaIndex].city_id, this.districtList[this.districtIndex].city_id, this.cityList[this.cityIndex].city_id];
        this.handleChange(this.addressForm.area);
      }
    },
    editMarkAction: function editMarkAction() {
      var _this2 = this;
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.userdetail.mark
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _user.updateUserMarkApi)(_this2.userdetail.uid, {
          mark: value
        }).then(function (res) {
          _this2.getRefresh();
          _this2.$emit('editMark');
        });
      }).catch(function () {});
    },
    getRefresh: function getRefresh() {
      var _this3 = this;
      if (this.$store.getters.userPression['merchantDetailUser']) {
        (0, _user.getUserDetail)(this.uid).then(function (res) {
          _this3.userdetail = res.data;
        });
      }
    },
    onSubmit: function onSubmit() {
      var _this4 = this;
      var data = Object.assign({}, this.addressForm);
      if (data.province && !data.city && !data.district) {
        data.address_type = 2;
      } else {
        data.address_type = 1;
      }
      if (this.addressForm.isUpd) {
        (0, _user.userAddressUpdApi)(this.addressForm.address_id, data).then(function (res) {
          _this4.addressVisible = false;
          _this4.$message.success('操作成功');
          (0, _user.userAddressListApi)({
            uid: _this4.uid
          }).then(function (result) {
            _this4.addressList = result.data.list;
          });
        });
      } else {
        (0, _user.userAddressAddApi)(data).then(function (res) {
          _this4.addressVisible = false;
          _this4.$message.success('操作成功');
          (0, _user.userAddressListApi)({
            uid: _this4.uid
          }).then(function (result) {
            _this4.addressList = result.data.list;
          });
        });
      }
    },
    onAdd: function onAdd() {
      var _this5 = this;
      this.isNo = false;
      this.areaIndex = '';
      this.districtIndex = '';
      this.cityIndex = '';
      this.addressForm = {
        uid: this.uid,
        isUpd: false
      };
      this.addressVisible = true;
      if (this.areaList.length) return;
      (0, _system.getCityApi)().then(function (res) {
        var otherData = [];
        _areaOtherList.default.forEach(function (one) {
          otherData = otherData.concat(one.areaCodeInitialVOS);
        });
        var child = [];
        otherData.forEach(function (m, i) {
          if (m.code !== 'TW' && m.code !== 'MO' && m.code !== 'HK') {
            child.push({
              name: m.name,
              id: 900000 + (i + 1) * 10,
              city_id: m.name
            });
          }
        });
        res.data.push({
          area_code: 0,
          name: '其他',
          city_id: '其他',
          id: 900000,
          children: child
        });
        _this5.areaList = res.data;
      });
    },
    onAddupd: function onAddupd(row) {
      var _this6 = this;
      this.isNo = true;
      this.areaIndex = '';
      this.districtIndex = '';
      this.cityIndex = '';
      this.addressForm = (0, _objectSpread2.default)({
        uid: this.uid,
        isUpd: true
      }, row);
      this.addressVisible = true;
      if (this.areaList.length) return;
      (0, _system.getCityApi)().then(function (res) {
        var otherData = [];
        _areaOtherList.default.forEach(function (one) {
          otherData = otherData.concat(one.areaCodeInitialVOS);
        });
        var child = [];
        otherData.forEach(function (m, i) {
          if (m.code !== 'TW' && m.code !== 'MO' && m.code !== 'HK') {
            child.push({
              name: m.name,
              id: 900000 + (i + 1) * 10,
              city_id: m.name
            });
          }
        });
        res.data.push({
          area_code: 0,
          name: '其他',
          city_id: '其他',
          id: 900000,
          children: child
        });
        _this6.areaList = res.data;
      });
    },
    handleChange: function handleChange(e) {
      if (this.addressForm.area[0] == '其他') {
        this.addressForm.province = this.addressForm.area[1];
        this.addressForm.city = '';
        this.addressForm.district = '';
        this.addressForm.city_id = '';
      } else {
        var proRow = this.areaList.find(function (item) {
          return item.city_id == e[0];
        });
        var cityRow = proRow.children.find(function (item) {
          return item.city_id == e[1];
        });
        var disRow = cityRow.children.find(function (item) {
          return item.city_id == e[2];
        });
        this.addressForm.province = proRow.name;
        this.addressForm.city = cityRow.name;
        this.addressForm.district = disRow.name;
        this.addressForm.city_id = cityRow.city_id;
      }
    },
    changedrawtype: function changedrawtype(type) {
      this.drawtype = type;
    },
    showvimage: function showvimage(item) {
      this.userjson = item;
      this.showusercard = true;
    },
    changeshowdetail: function changeshowdetail() {
      this.$emit('close');
    },
    changeType: function changeType(key) {
      this.type = key;
      this.tableFrom.page = 1;
      this.getInfo(key);
    },
    getInfo: function getInfo(key) {
      var _this7 = this;
      this.loading = true;
      switch (key) {
        case '0':
          (0, _user.userOrderApi)(this.uid, this.tableFrom).then(function (res) {
            _this7.tableData.data = res.data.list;
            _this7.tableData.total = res.data.count;
            _this7.columns = [{
              title: '订单ID',
              key: 'order_id',
              minWidth: 250
            }, {
              title: '收货人',
              key: 'real_name',
              minWidth: 90
            }, {
              title: '商品数量',
              key: 'total_num',
              minWidth: 80
            }, {
              title: '商品总价',
              key: 'total_price',
              minWidth: 90
            }, {
              title: '实付金额',
              key: 'pay_price',
              minWidth: 90
            }, {
              title: '交易完成时间',
              key: 'pay_time',
              minWidth: 160
            }];
            _this7.loading = false;
          }).catch(function () {
            _this7.loading = false;
          });
          break;
        case '3':
          (0, _user.userCouponApi)(this.uid, this.tableFrom).then(function (res) {
            _this7.tableData.data = res.data.list;
            _this7.tableData.total = res.data.count;
            _this7.columns = [{
              title: '优惠券名称',
              key: 'coupon_title',
              minWidth: 120
            }, {
              title: '面值',
              key: 'coupon_price',
              minWidth: 120
            }, {
              title: '最低消费额',
              key: 'use_min_price',
              minWidth: 120
            }, {
              title: '兑换时间',
              key: 'use_time',
              minWidth: 120
            }];
            _this7.loading = false;
          }).catch(function () {
            _this7.loading = false;
          });
          break;
      }
    },
    pageChange: function pageChange(page) {
      var _this8 = this;
      this.tableFrom.page = page;
      (0, _accounts.userWalletHistoryList)(this.tableFrom).then(function (res) {
        _this8.tableData.data = res.data.list;
        _this8.tableData.total = res.data.count;
        _this8.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this8.listLoading = false;
      });
      // this.getInfo(this.type)
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this9 = this;
      this.tableFrom.limit = val;
      (0, _accounts.userWalletHistoryList)(this.tableFrom).then(function (res) {
        _this9.tableData.data = res.data.list;
        _this9.tableData.total = res.data.count;
        _this9.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this9.listLoading = false;
      });
      // this.getInfo(this.type)
    },
    nftPageChange: function nftPageChange(page) {
      this.nftTableFrom.page = page;
      this.getNft();
    },
    nftHandleSizeChange: function nftHandleSizeChange(val) {
      this.nftTableFrom.page = 1;
      this.nftTableFrom.limit = val;
      this.getNft();
    },
    getNft: function getNft() {
      var _this10 = this;
      this.nftListLoading = true;
      (0, _nft.dataListApi)(this.nftTableFrom).then(function (res) {
        _this10.nftTableData.data = res.data.list;
        _this10.nftTableData.total = res.data.count;
        _this10.nftListLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this10.nftListLoading = false;
      });
    },
    getHeader: function getHeader() {
      this.psInfo = this.row;
    },
    showImgAction: function showImgAction(img, t) {
      this.imgT = t || '查看凭证';
      this.imgSrc = img;
      this.showImg = true;
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this11 = this;
      var data = JSON.parse(JSON.stringify(this.nftTableFrom));
      data.export_type = 'nftData';
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this11.$createElement;
        _this11.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this11.$msgbox.close();
                _this11.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('nftData');
    },
    goOrderAction: function goOrderAction() {
      if (this.$route.name === 'AuctionMeetingOrder') {
        this.$emit('editPhone', this.userdetail.phone);
      } else {
        this.$router.push("/merchant/order/list/auctionMeeting?user_phone=".concat(this.userdetail.uid));
      }
    },
    onUpdUser: function onUpdUser(row) {
      this.$refs.userREf.onUpdRow(JSON.parse(JSON.stringify(this.userdetail)), this.userdetail.entrust);
    },
    // 修改标签
    setLabel: function setLabel(id) {
      var _this12 = this;
      this.$modalForm((0, _user.changeGroupApi)(id)).then(function () {
        _this12.getRefresh();
        _this12.$emit('editMark');
      });
    },
    // 批量分组
    batchGroup: function batchGroup(id) {
      var _this13 = this;
      this.$modalForm((0, _user.batchChangeGroupApi)({
        ids: id
      })).then(function () {
        _this13.getRefresh();
        _this13.$emit('editMark');
      });
    }
  }
};