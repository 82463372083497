"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _basic = require("@/api/basic");
var _editAuthor = _interopRequireDefault(require("./components/editAuthor.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    EditAuthorEL: _editAuthor.default
  },
  data: function data() {
    return {
      listLoading: false,
      listLoadingNew: true,
      tableFrom: {
        page: 1,
        limit: 20
      },
      olddata: [],
      tableData: {
        data: [],
        total: 0
      },
      searchQuery: {},
      inputname: '',
      checkedIds: []
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      if (this.listLoading) return;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      var json = JSON.parse(JSON.stringify(this.tableFrom));
      // json.create_date = this.timeVal.join("-");
      return (0, _basic.artistListApi)(json).then(function (res) {
        var oD = JSON.parse(JSON.stringify(res.data.list));
        _this2.olddata = oD;
        _this2.tableData.data = res.data.list;
        // if (json.page == 1) {
        //   this.olddata = oD;
        //   this.tableData.data = oD;
        // } else {
        //   this.olddata = this.olddata.concat(oD);
        //   this.tableData.data = this.tableData.data.concat(res.data.list);
        // }
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
        _this2.listLoadingNew = false;
        _this2.$nextTick(function () {
          if (_this2.$refs.userRef) {
            _this2.$refs.userRef.clearSelection();
          }
        });
      }).catch(function (res) {
        _this2.listLoading = false;
        _this2.listLoadingNew = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.checkedIds = val;
    },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this3 = this;
      this.$modalSure('删除').then(function () {
        (0, _basic.artistDeleteApi)({
          ids: id
        }).then(function (_ref) {
          var message = _ref.message;
          _this3.$message.success(message);
          _this3.getList('');
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      });
    },
    // 删除
    handleDeleteAll: function handleDeleteAll() {
      var _this4 = this;
      if (!this.checkedIds.length) {
        return this.$message.error('请选择作者');
      }
      var model = {
        ids: this.checkedIds.map(function (m) {
          return m.id;
        }).join(',')
      };
      this.$modalSure('批量删除').then(function () {
        (0, _basic.artistDeleteApi)(model).then(function (_ref3) {
          var message = _ref3.message;
          _this4.$message.success(message);
          _this4.checkedIds = [];
          _this4.getList();
        }).catch(function (_ref4) {
          var message = _ref4.message;
        });
      });
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, this.searchQuery);
      this.getList();
    },
    clearSearchquery: function clearSearchquery() {
      this.searchQuery = {};
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, this.searchQuery);
      this.getList();
    },
    cellClick: function cellClick(row, column, cell, event) {
      var _this5 = this;
      // if (!this.$store.getters.userPression.merchantErpV2ProductUpdateField) return; // 权限
      if (!column.property) return;
      this.inputname = column.property + row.id;
      this.$nextTick(function () {
        if (column.property == 'sex' && _this5.$refs.sexSelect) {
          _this5.$refs.sexSelect.toggleMenu();
        }
      });
    },
    saveoneflied: function saveoneflied(index, type, id, row, am) {
      var _this6 = this;
      var oldrow = {};
      oldrow = this.olddata.filter(function (item) {
        return item.id == id;
      })[0] || {};
      if (oldrow[type] === row[type]) {
        return;
      }
      this.inputname = '';
      (0, _basic.artistUpdateApi)(row).then(function (res) {
        oldrow[type] = row[type];
        _this6.$message.success('修改成功');
      }).catch(function () {
        row[type] = oldrow[type];
      });
    },
    changeTab: function changeTab(index) {
      if (index != 2) {
        this.$router.replace('/merchant/basic/goodslist?type=' + index);
      }
    },
    changeaddgoods: function changeaddgoods() {
      if (this.$refs.editAuthorEL) {
        this.$refs.editAuthorEL.changeaddgoods();
      }
    },
    editgoods: function editgoods(row) {
      if (this.$refs.editAuthorEL) {
        this.$refs.editAuthorEL.editgoods(row);
      }
    },
    batchUploadAuction: function batchUploadAuction() {
      console.log('上传文件');
    },
    downloadTemplate: function downloadTemplate() {},
    handleRemove: function handleRemove(key) {
      this.formValidate[key] = '';
    },
    // 点击上传头像图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit === '1' && !num) {
          _this.formValidate.avatar = img[0];
        }
      }, '1');
    }
  }
};