"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user.js");
var _system = require("@/api/system.js");
var _index = require("@/utils/index");
var _order = require("@/api/order");
var _printJs = _interopRequireDefault(require("print-js"));
var _zhTW = _interopRequireDefault(require("@/utils/zh-TW.json"));
var _system2 = require("@/api/system");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  filters: {
    filterPercent: function filterPercent(val) {
      var remainder = val % 10;
      if (remainder > 0) val;else return val / 10;
    }
  },
  props: {
    preview: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      parseTime: _index.parseTime,
      ids: '',
      uid: '',
      source: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      fee_type: '',
      feeTypeInfo: {},
      isOutbound: false,
      qrcodeImg: '',
      merData: {},
      no: '',
      showSettings: false,
      settingKey: '',
      detail: {
        title: {
          china: '委托方付款单',
          english: 'Collection settlement bill'
        },
        buyerCompany: {
          china: '您好！非常感谢您对我司的厚爱。现将以下拍品交付给您，期待您一如既往的支持，谢谢！',
          english: "Thanks for your support to our company, we beg your understanding and also hope you can support us as usual!"
        },
        tableColumn: [{
          open: true,
          name: '图录号',
          showName: '',
          english: 'LOT NO.',
          w: 86
        }, {
          open: true,
          name: '拍品名称',
          showName: '',
          english: 'DESCRIPTION'
        }, {
          open: true,
          name: '成交状态',
          showName: '',
          english: 'SALES STATUS',
          w: 130
        }, {
          open: true,
          name: '落槌价',
          showName: '',
          english: 'HAMMER PRICE',
          w: 130
        }, {
          open: true,
          name: '佣金明细',
          showName: '',
          english: 'COMMISSION',
          w: 260
        }, {
          open: true,
          name: '金额',
          showName: '',
          english: 'SUBTOTAL',
          w: 130
        }],
        settleText: {
          china: '尊敬的委托人您好！非常感谢对我公司的支持和帮助，现将您委托我公司拍卖的作品成交结果呈上，请您查收并核对。恳请您一如既往地支持我公司。谢谢。',
          english: 'Dear client, thank you very much for your support and assistance to our company. We are now submitting the transaction results of the works you entrusted our company for auction. Please check and verify them. Please continue to support our company as always. Thank you.'
        },
        signColumnTab: 'system',
        signColumn: {
          accountName: {
            open: true,
            name: '开户名称',
            showName: '',
            w: 'auto'
          },
          accountBank: {
            open: true,
            name: '开户行',
            showName: '',
            w: 'auto'
          },
          accountNumber: {
            open: true,
            name: '帐号',
            showName: '',
            w: 'auto'
          },
          accountAddress: {
            open: true,
            name: '公司地址',
            showName: '',
            w: '100'
          },
          clientAccountName: {
            open: false,
            name: '委托方开户名称',
            showName: '',
            w: 'auto'
          },
          clientAccountBank: {
            open: false,
            name: '委托方开户行',
            showName: '',
            w: 'auto'
          },
          clientAccountNumber: {
            open: false,
            name: '委托方帐号',
            showName: '',
            w: 'auto'
          },
          creator: {
            open: true,
            name: '制单人',
            showName: '',
            w: '25'
          },
          companySeal: {
            open: false,
            name: '单位盖章',
            showName: '',
            w: '25'
          },
          recipient: {
            open: true,
            name: '收款人',
            showName: '',
            w: '25'
          },
          documentNumber: {
            open: true,
            name: '单据编号',
            showName: '',
            w: '25'
          }
        },
        signColumnCustome: ''
      },
      editKey: ''
    };
  },
  computed: {
    feeTypeObj: function feeTypeObj() {
      return this.$store.getters.feeTypeInfo;
    },
    getWidth: function getWidth() {
      return 'width: 130px;';
    },
    getTwoWidth: function getTwoWidth() {
      var w = 0;
      var jsI = 0;
      for (var i = 2; i < 6; i += 1) {
        if (this.detail.tableColumn[i] && this.detail.tableColumn[i].open && this.detail.tableColumn[i].w) {
          w += this.detail.tableColumn[2].w;
          jsI += 1;
        }
        if (jsI === 2) {
          break;
        }
      }
      return "width: ".concat(w, "px;");
    },
    getForeWidth: function getForeWidth() {
      var w = 0;
      if (this.detail.tableColumn[2] && this.detail.tableColumn[2].open && this.detail.tableColumn[2].w) {
        w += this.detail.tableColumn[2].w;
      }
      if (this.detail.tableColumn[3] && this.detail.tableColumn[3].open && this.detail.tableColumn[3].w) {
        w += this.detail.tableColumn[3].w;
      }
      if (this.detail.tableColumn[4] && this.detail.tableColumn[4].open && this.detail.tableColumn[4].w) {
        w += this.detail.tableColumn[4].w;
      }
      if (this.detail.tableColumn[5] && this.detail.tableColumn[5].open && this.detail.tableColumn[5].w) {
        w += this.detail.tableColumn[5].w;
      }
      return "width: ".concat(w, "px;");
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
    (0, _system2.getConfigClassKeys)('print_config').then(function (res) {
      if (res.data.print_erp_contract_payment) {
        var d = JSON.parse(res.data.print_erp_contract_payment);
        if (!d.signColumnTab) d.signColumnTab = 'system';
        if (d.signColumn && !d.signColumn.clientAccountName) {
          var data = {};
          for (var i in d.signColumn) {
            if (i === 'accountAddress') {
              data[i] = d.signColumn[i];
              data['clientAccountName'] = {
                open: false,
                name: '委托方开户名称',
                showName: '',
                w: 'auto'
              };
              data['clientAccountBank'] = {
                open: false,
                name: '委托方开户行',
                showName: '',
                w: 'auto'
              };
              data['clientAccountNumber'] = {
                open: false,
                name: '委托方帐号',
                showName: '',
                w: 'auto'
              };
            } else {
              data[i] = d.signColumn[i];
            }
          }
          d.signColumn = data;
        }
        // if (d.signColumnCustome) {
        //   this.$refs['editor'].setContent(d.signColumnCustome);
        // }
        _this.detail = d;
      }
    });
  },
  methods: {
    saveAction: function saveAction() {
      var _this2 = this;
      (0, _system2.configClassKeysSave)('print_config', {
        print_erp_contract_payment: JSON.stringify(this.detail)
      }).then(function () {
        _this2.$message.success('保存成功');
      });
    },
    focus: function focus(e) {
      e.currentTarget.select();
    },
    goEditAction: function goEditAction(key) {
      this.settingKey = key;
      this.showSettings = true;
    },
    switchLang: function switchLang(filed) {
      if (this.feeTypeObj.mer_fee_type == 'HKD') {
        return _zhTW.default.TW[filed];
      } else return _zhTW.default.CN[filed];
    }
  }
};