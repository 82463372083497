var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container goodsBox" },
    [
      _c("header-breadcrumb", { attrs: { name: "货品" } }),
      _vm._v(" "),
      _c("el-card", { staticClass: "box-card goods-list-custom" }, [
        _c("div", [
          _c("div", { staticClass: "top-view" }, [
            _c(
              "div",
              {
                staticStyle: {
                  padding: "0 20px",
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                },
              },
              [
                _c(
                  "el-menu",
                  {
                    staticClass: "el-menu-demo default-tabs mt10",
                    attrs: { "default-active": "2", mode: "horizontal" },
                    on: { select: _vm.changeTab },
                  },
                  [
                    _c("el-menu-item", { attrs: { index: "0" } }, [
                      _vm._v("货品列表"),
                    ]),
                    _vm._v(" "),
                    _c("el-menu-item", { attrs: { index: "2" } }, [
                      _vm._v("作者列表"),
                    ]),
                    _vm._v(" "),
                    _c("el-menu-item", { attrs: { index: "1" } }, [
                      _vm._v("回收站"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "merchantErpV2ProductCreate",
                            expression: "'merchantErpV2ProductCreate'",
                          },
                        ],
                        staticStyle: { height: "32px" },
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-circle-plus-outline",
                        },
                        on: { click: _vm.changeaddgoods },
                      },
                      [_vm._v("新增")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: { overflow: "hidden", height: "calc(100vh - 190px)" },
          },
          [
            _c(
              "el-table",
              {
                ref: "userRef",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData.data,
                  "row-key": "id",
                  height: "100%",
                  size: "mini",
                  border: "",
                },
                on: {
                  "cell-click": _vm.cellClick,
                  "selection-change": _vm.handleSelectionChange,
                },
              },
              [
                _c("template", { slot: "empty" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.listLoadingNew,
                          expression: "!listLoadingNew",
                        },
                      ],
                      staticClass: "tableEmpty",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tableDiv" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    width: "36",
                    type: "selection",
                    resizable: false,
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    label: "ID",
                    "min-width": "80",
                    "search-key": "id",
                    prop: "id",
                    "search-query": _vm.searchQuery,
                  },
                  on: { changeVal: _vm.searchAction },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.id) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    label: "姓名",
                    "min-width": "100",
                    "search-key": "name",
                    prop: "name",
                    "search-query": _vm.searchQuery,
                  },
                  on: { changeVal: _vm.searchAction },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.inputname == "name" + scope.row.id
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "fo", rawName: "v-fo" },
                                    ],
                                    staticClass: "edit-textareabox",
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "edit-textareabox-span",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          scope.row.name || "&nbsp;"
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: scope.row.name,
                                          expression: "scope.row.name",
                                        },
                                      ],
                                      staticClass: "edit-textarea",
                                      style: { resize: "none" },
                                      attrs: { id: "", resize: "none" },
                                      domProps: { value: scope.row.name },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.saveoneflied(
                                            scope.$index,
                                            "name",
                                            scope.row.id,
                                            scope.row
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            scope.row,
                                            "name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _c("div", { staticClass: "nowrap" }, [
                                _vm._v(_vm._s(scope.row.name)),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    label: "籍贯",
                    "min-width": "100",
                    "search-key": "native",
                    prop: "native",
                    "search-query": _vm.searchQuery,
                  },
                  on: { changeVal: _vm.searchAction },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.inputname == "native" + scope.row.id
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "fo", rawName: "v-fo" },
                                    ],
                                    staticClass: "edit-textareabox",
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "edit-textareabox-span",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          scope.row.native || "&nbsp;"
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: scope.row.native,
                                          expression: "scope.row.native",
                                        },
                                      ],
                                      staticClass: "edit-textarea",
                                      style: { resize: "none" },
                                      attrs: { id: "", resize: "none" },
                                      domProps: { value: scope.row.native },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.saveoneflied(
                                            scope.$index,
                                            "native",
                                            scope.row.id,
                                            scope.row
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            scope.row,
                                            "native",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _c("div", { staticClass: "nowrap" }, [
                                _vm._v(_vm._s(scope.row.native)),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    label: "作者介绍",
                    "min-width": "160",
                    "search-key": "intro",
                    prop: "intro",
                    "search-query": _vm.searchQuery,
                  },
                  on: { changeVal: _vm.searchAction },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.inputname == "intro" + scope.row.id
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "fo", rawName: "v-fo" },
                                    ],
                                    staticClass: "edit-textareabox",
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "edit-textareabox-span",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          scope.row.intro || "&nbsp;"
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: scope.row.intro,
                                          expression: "scope.row.intro",
                                        },
                                      ],
                                      staticClass: "edit-textarea",
                                      style: { resize: "none" },
                                      attrs: { id: "", resize: "none" },
                                      domProps: { value: scope.row.intro },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.saveoneflied(
                                            scope.$index,
                                            "intro",
                                            scope.row.id,
                                            scope.row
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            scope.row,
                                            "intro",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _c("div", { staticClass: "nowrap" }, [
                                _vm._v(_vm._s(scope.row.intro)),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    label: "作者介绍EN",
                    "min-width": "160",
                    "search-key": "english_descipt",
                    prop: "english_descipt",
                    "search-query": _vm.searchQuery,
                  },
                  on: { changeVal: _vm.searchAction },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.extend_data
                                  ? scope.row.extend_data.english_descipt
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    label: "性别",
                    "min-width": "80",
                    "search-key": "sex",
                    "search-type": "radio",
                    prop: "sex",
                    options: [
                      {
                        value: 1,
                        label: "男",
                      },
                      {
                        value: 2,
                        label: "女",
                      },
                    ],
                    "search-query": _vm.searchQuery,
                  },
                  on: { changeVal: _vm.searchAction },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.sex) +
                              "\n            "
                          ),
                          _vm.inputname == "sex" + scope.row.id
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "fo", rawName: "v-fo" },
                                    ],
                                    staticClass: "edit-textareabox",
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        ref: "sexSelect",
                                        staticStyle: { width: "80px" },
                                        attrs: {
                                          placeholder: "请选择",
                                          "value-key": "sex",
                                          "automatic-dropdown": true,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.saveoneflied(
                                              scope.$index,
                                              "sex",
                                              scope.row.id,
                                              scope.row
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.sex,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "sex", $$v)
                                          },
                                          expression: "scope.row.sex",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { label: "男", value: "男" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "女", value: "女" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _c("div", { staticClass: "nowrap" }, [
                                _vm._v(_vm._s(scope.row.sex)),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    label: "生卒年",
                    "min-width": "160",
                    "search-key": "birth_year",
                    prop: "birth_year",
                    "search-query": _vm.searchQuery,
                  },
                  on: { changeVal: _vm.searchAction },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.inputname == "birth_year" + scope.row.id
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "fo", rawName: "v-fo" },
                                    ],
                                    staticClass: "edit-textareabox",
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "edit-textareabox-span",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          scope.row.birth_year || "&nbsp;"
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: scope.row.birth_year,
                                          expression: "scope.row.birth_year",
                                        },
                                      ],
                                      staticClass: "edit-textarea",
                                      style: { resize: "none" },
                                      attrs: { id: "", resize: "none" },
                                      domProps: { value: scope.row.birth_year },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.saveoneflied(
                                            scope.$index,
                                            "birth_year",
                                            scope.row.id,
                                            scope.row
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            scope.row,
                                            "birth_year",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _c("div", { staticClass: "nowrap" }, [
                                _vm._v(_vm._s(scope.row.birth_year)),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  key: "20",
                  attrs: {
                    label: "",
                    width: "40",
                    fixed: "right",
                    align: "center",
                    "search-clear": true,
                    "search-query": _vm.searchQuery,
                  },
                  on: { changeVal: _vm.clearSearchquery },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "TableRightBtn",
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { margin: "0 1px" },
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editgoods(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "icon-class": "commonedit",
                                      "class-name": "blue",
                                    },
                                  }),
                                  _vm._v("编辑"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    margin: "0 1px",
                                    color: "red",
                                  },
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(scope.row.id)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "icon-class": "commondelete",
                                      color: "red",
                                    },
                                  }),
                                  _vm._v("删除"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c goodslist-page-box mt10" },
          [
            _c(
              "div",
              { staticClass: "flex-one flex" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      disabled: !_vm.checkedIds.length,
                      size: "small",
                      icon: "el-icon-delete",
                    },
                    on: { click: _vm.handleDeleteAll },
                  },
                  [_vm._v("批量删除")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-pagination", {
              staticStyle: { "margin-right": "10px" },
              attrs: {
                "page-sizes": [20, 40, 60, 80, 100],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, prev, pager, next, sizes ",
                total: _vm.tableData.total,
                background: "",
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("EditAuthorEL", {
        ref: "editAuthorEL",
        on: {
          saveAction: function ($event) {
            return _vm.getList(1)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }