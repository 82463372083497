var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          {
            staticClass: "flex align-items-c",
            staticStyle: { "margin-bottom": "10px" },
          },
          [
            _c(
              "div",
              { staticClass: "mr20" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", filterable: "" },
                    on: { change: _vm.changeAuctionId },
                    model: {
                      value: _vm.lastAuctionId,
                      callback: function ($$v) {
                        _vm.lastAuctionId = $$v
                      },
                      expression: "lastAuctionId",
                    },
                  },
                  _vm._l(_vm.lastAuction, function (item) {
                    return _c("el-option", {
                      key: item.venue_id,
                      attrs: { label: item.title, value: item.venue_id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "mr20 ft14 fWeight500" }, [
              _vm._v("订单结算时间："),
            ]),
            _vm._v(" "),
            _c("el-date-picker", {
              staticStyle: { width: "300px" },
              attrs: {
                "value-format": "yyyy/MM/dd",
                format: "yyyy/MM/dd",
                type: "daterange",
                placement: "bottom-start",
                placeholder: "订单结算时间",
              },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.timeValue,
                callback: function ($$v) {
                  _vm.timeValue = $$v
                },
                expression: "timeValue",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 240px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "letterRef",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                height: "100%",
                border: "",
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.id
                },
                data: _vm.tableData.data,
                size: "mini",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                key: "1",
                attrs: { type: "selection", width: "30" },
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托方",
                  "min-width": "160",
                  prop: "",
                  "search-key": "entrust_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrustUser
                          ? _c(
                              "div",
                              { staticClass: "nowrap" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetails(
                                          scope.row.entrustUser
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "line1",
                                        staticStyle: { "text-align": "left" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              scope.row.entrustUser.real_name
                                            ) +
                                            "\n                "
                                        ),
                                        scope.row.entrustUser.phone
                                          ? [
                                              _vm._v(
                                                "\n                  (" +
                                                  _vm._s(
                                                    scope.row.entrustUser.phone
                                                  ) +
                                                  ")\n                "
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                scope.row.entrustUser.mark
                                  ? [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(scope.row.entrustUser.mark) +
                                          "\n            "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "图录号",
                  "min-width": "100",
                  prop: "",
                  "search-key": "lot",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pmAuction
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            LOT " +
                                  _vm._s(scope.row.pmAuction.lot) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "拍品名称",
                  "min-width": "160",
                  "show-overflow-tooltip": "",
                  prop: "",
                  "search-key": "auction_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pmAuction && scope.row.pmAuction.product
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.pmAuction.product.store_name
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "落槌价",
                  align: "center",
                  "min-width": "100",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatPriceNumber(
                                scope.row.auction_now_price,
                                2
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "费用明细",
                  align: "center",
                  "min-width": "350",
                  center: "",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "ft12 item-flex-center",
                            staticStyle: { width: "100%" },
                          },
                          [
                            row.auction_status <= 4
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      flex: "1",
                                      "justify-content": "center",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      row.price_data[1],
                                      function (e, ind) {
                                        return _c(
                                          "div",
                                          {
                                            key: "1" + ind,
                                            staticStyle: {
                                              "line-height": "20px",
                                              display: "flex",
                                              "flex-direction": "column",
                                              "align-items": "center",
                                              margin: "0 5px",
                                            },
                                          },
                                          [
                                            e.name
                                              ? [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(e.name) +
                                                        _vm._s(
                                                          e.unit == 1 ||
                                                            e.unit == 3
                                                            ? ""
                                                            : e.number
                                                        ) +
                                                        _vm._s(
                                                          e.unit == 1 ||
                                                            e.unit == 3
                                                            ? ""
                                                            : "%"
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "-" +
                                                        _vm._s(
                                                          _vm.formatPriceNumber(
                                                            e.deduction_amount,
                                                            2
                                                          )
                                                        ) +
                                                        "元"
                                                    ),
                                                  ]),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      row.price_data[2],
                                      function (e, ind) {
                                        return _c(
                                          "div",
                                          {
                                            key: "2" + ind,
                                            staticStyle: {
                                              "line-height": "20px",
                                              display: "flex",
                                              "flex-direction": "column",
                                              "align-items": "center",
                                              margin: "0 5px",
                                            },
                                          },
                                          [
                                            e.name
                                              ? [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(e.name) +
                                                        _vm._s(
                                                          e.unit == 1 ||
                                                            e.unit == 3
                                                            ? ""
                                                            : e.number
                                                        ) +
                                                        _vm._s(
                                                          e.unit == 1 ||
                                                            e.unit == 3
                                                            ? ""
                                                            : "%"
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "-" +
                                                        _vm._s(
                                                          _vm.formatPriceNumber(
                                                            e.deduction_amount,
                                                            2
                                                          )
                                                        ) +
                                                        "元"
                                                    ),
                                                  ]),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      flex: "1",
                                      "justify-content": "center",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      row.price_data[0],
                                      function (e, ind) {
                                        return _c(
                                          "div",
                                          {
                                            key: "0" + ind,
                                            staticStyle: {
                                              "line-height": "20px",
                                              display: "flex",
                                              "flex-direction": "column",
                                              "align-items": "center",
                                              margin: "0 5px",
                                            },
                                          },
                                          [
                                            e.name
                                              ? [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(e.name) +
                                                        _vm._s(
                                                          e.unit == 1 ||
                                                            e.unit == 3
                                                            ? ""
                                                            : e.number
                                                        ) +
                                                        _vm._s(
                                                          e.unit == 1 ||
                                                            e.unit == 3
                                                            ? ""
                                                            : "%"
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "-" +
                                                        _vm._s(
                                                          _vm.formatPriceNumber(
                                                            e.deduction_amount,
                                                            2
                                                          )
                                                        ) +
                                                        "元"
                                                    ),
                                                  ]),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      row.price_data[2],
                                      function (e, ind) {
                                        return _c(
                                          "div",
                                          {
                                            key: "2" + ind,
                                            staticStyle: {
                                              "line-height": "20px",
                                              display: "flex",
                                              "flex-direction": "column",
                                              "align-items": "center",
                                              margin: "0 5px",
                                            },
                                          },
                                          [
                                            e.name
                                              ? [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(e.name) +
                                                        _vm._s(
                                                          e.unit == 1 ||
                                                            e.unit == 3
                                                            ? ""
                                                            : e.number
                                                        ) +
                                                        _vm._s(
                                                          e.unit == 1 ||
                                                            e.unit == 3
                                                            ? ""
                                                            : "%"
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "-" +
                                                        _vm._s(
                                                          _vm.formatPriceNumber(
                                                            e.deduction_amount,
                                                            2
                                                          )
                                                        ) +
                                                        "元"
                                                    ),
                                                  ]),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托成交结算",
                  align: "center",
                  width: "120",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatPriceNumber(
                                scope.row.settlement_amount,
                                2
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "买入订单抵扣",
                  align: "center",
                  width: "120",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        Number(scope.row.store_order_deduction_amount)
                          ? _c("div", [
                              _vm._v(
                                "\n            -" +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      scope.row.store_order_deduction_amount,
                                      2
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _c("div", [_vm._v("0")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "应付款",
                  align: "center",
                  width: "120",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatPriceNumber(
                                scope.row.reconciliation_amount,
                                2
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "合同号",
                  width: "150",
                  prop: "",
                  "search-key": "contract_no",
                  "show-overflow-tooltip": "",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.contract
                          ? _c(
                              "div",
                              { staticClass: "nowrap" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.viewContractAction(
                                          scope.row.contract
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.contract.contract_no)
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "保留价",
                  align: "center",
                  "min-width": "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.erpProduct
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.erpProduct.retain_price ||
                                      scope.row.erpProduct.retain_price === 0
                                      ? _vm.formatPriceNumber(
                                          scope.row.erpProduct.retain_price,
                                          2
                                        )
                                      : ""
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "专场",
                  width: "130",
                  prop: "",
                  "search-key": "album_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pmAlbum
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.pmAlbum.album_name) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "销售状态",
                  width: "90",
                  prop: "",
                  align: "center",
                  "search-key": "auction_status",
                  "search-type": "radio",
                  options: [
                    { label: "交割失败", value: "-1" },
                    { label: "流拍", value: "4" },
                    { label: "成交", value: "5" },
                    { label: "私洽成交", value: "7" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "nowrap",
                            style:
                              scope.row.auction_status == -1 ||
                              scope.row.auction_status == 4
                                ? "color: #999;"
                                : "",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.auctionStatusText) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "买家付款状态",
                  width: "160",
                  prop: "",
                  align: "center",
                  "search-key": "store_order_status",
                  "search-type": "radio",
                  options: [
                    { label: "待支付", value: "0" },
                    { label: "已结算", value: "1" },
                    { label: "已违约", value: "-2" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "nowrap",
                            style:
                              scope.row.store_order_status == 1
                                ? ""
                                : "color: #ae0000;",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.storeOrderStatusText) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        scope.row.store_order_pay_time
                          ? _c("div", [
                              _vm._v(_vm._s(scope.row.store_order_pay_time)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "对账时间",
                  width: "160",
                  align: "center",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.reconciliationAdmin
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.reconciliationAdmin.real_name
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.reconciliationAdmin &&
                        scope.row.reconciliation_time
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.reconciliation_time) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "确认时间",
                  width: "160",
                  align: "center",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.reconciliationConfirmAdmin
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.reconciliationConfirmAdmin
                                      .real_name
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.reconciliationConfirmAdmin &&
                        scope.row.reconciliation_confirm_time
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.reconciliation_confirm_time
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "付款单",
                  width: "160",
                  align: "center",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.payment_order_create_status &&
                        scope.row.paymentOrder
                          ? _c(
                              "div",
                              { staticClass: "nowrap" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "0", padding: "0" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.printPaymentAction(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.paymentOrder.order_no)
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.paymentOrder.create_time)
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "操作",
                  width: "100",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "item-flex-center",
                            staticStyle: { "flex-direction": "column" },
                          },
                          [
                            _c(
                              "div",
                              [
                                !scope.row.payment_order_create_status
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "2px 0" },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkdan(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("创建付款单")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      padding: "2px 0",
                                      "margin-left": "0",
                                    },
                                    attrs: {
                                      loading: _vm.loadPre == scope.row.id,
                                      type: "text",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onReconc(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("重新对账")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c goodslist-page-box" },
        [
          _c(
            "div",
            {
              staticClass: "flex-one flex",
              staticStyle: { "margin-left": "4px" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.loadPre == "all",
                  },
                  on: { click: _vm.checkmore },
                },
                [_vm._v("批量创建付款单")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.printAllAction("pay")
                    },
                  },
                },
                [_vm._v("打印对账单")]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown",
                { attrs: { type: "primary", placement: "top" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary", size: "small" },
                    },
                    [_vm._v("批量导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "100%",
                                "text-align": "left",
                              },
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.exportOrder },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "100%",
                                "text-align": "left",
                              },
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.getExportFileList },
                            },
                            [_vm._v("导出记录")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: { "margin-right": "10px" },
            attrs: {
              "page-sizes": [20, 40, 60, 80, 100],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes ",
              total: _vm.tableData.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c("SettlementPrint", { ref: "printView", on: { load: _vm.getList } }),
      _vm._v(" "),
      _c("SettlementPayPrint", {
        ref: "printPayView",
        on: { load: _vm.getList },
      }),
      _vm._v(" "),
      _c("AfterNoticePrint", {
        ref: "printAfterView",
        on: { load: _vm.getList },
      }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.showcheck,
            width: "1100px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showcheck = $event
            },
          },
        },
        [
          _c("div", { staticClass: "prop-title" }, [
            _vm._v(_vm._s(_vm.venueName)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "prop-name" }, [
            _vm._v(
              "委托方" +
                _vm._s(_vm.checkoneJson.entrustUser.real_name) +
                "对账单"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "prop-list" }, [
            _c("div", { staticClass: "prop-list-c" }, [
              _vm._v(
                "\n        对账人：" +
                  _vm._s(_vm.$store.getters.admin_name) +
                  "\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "diaTabel" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    data: _vm.checkoneJson.check_auction_data,
                  },
                },
                [
                  _c("template", { slot: "empty" }, [
                    _c("div", { staticClass: "tableEmpty" }, [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tableDiv" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "LOT", width: "50" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.pmAuction.lot) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "拍品名称", width: "140" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return scope.row.pmAuction.product
                              ? [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        scope.row.pmAuction.product.store_name
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "销售状态", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.auctionStatusText) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "落锤价", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  Number(scope.row.auction_now_price)
                                    ? _vm.formatPriceNumber(
                                        scope.row.auction_now_price,
                                        2
                                      )
                                    : _vm.formatPriceNumber(
                                        scope.row.auction.now_price,
                                        2
                                      )
                                ) +
                                "\n            "
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editNowPriceAction(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "服务费用明细", "min-width": "200" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return scope.row.price_data
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex edit-input",
                                      staticStyle: {
                                        "flex-direction": "column",
                                        margin: "10px 0",
                                      },
                                    },
                                    [
                                      scope.row.auction_status <= 4
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                width: "100%",
                                                "justify-content":
                                                  "space-around",
                                              },
                                            },
                                            _vm._l(
                                              scope.row.price_data[1],
                                              function (e, ind) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: "1" + ind,
                                                    staticStyle: {
                                                      "line-height": "20px",
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                    },
                                                  },
                                                  [
                                                    e.name
                                                      ? [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex align-items-c",
                                                              staticStyle: {
                                                                "padding-bottom":
                                                                  "5px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(e.name) +
                                                                  "\n                      "
                                                              ),
                                                              e.unit != 1
                                                                ? [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "40px",
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.onUnitChange(
                                                                                scope.$index,
                                                                                1,
                                                                                ind
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            e.number,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                e,
                                                                                "number",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "e.number",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    e.unit ==
                                                                      1 ||
                                                                      e.unit ==
                                                                        3
                                                                      ? ""
                                                                      : "%"
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            [
                                                              _vm._v("-"),
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  width: "60px",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onAmountChange(
                                                                        scope.$index,
                                                                        1,
                                                                        ind
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    e.deduction_amount,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        e,
                                                                        "deduction_amount",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "e.deduction_amount",
                                                                },
                                                              }),
                                                              _vm._v("元"),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                width: "100%",
                                                "justify-content":
                                                  "space-around",
                                              },
                                            },
                                            _vm._l(
                                              scope.row.price_data[0],
                                              function (e, ind) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: "0" + ind,
                                                    staticStyle: {
                                                      "line-height": "20px",
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                    },
                                                  },
                                                  [
                                                    e.name
                                                      ? [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex align-items-c",
                                                              staticStyle: {
                                                                "padding-bottom":
                                                                  "5px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(e.name) +
                                                                  "\n                      "
                                                              ),
                                                              e.unit != 1
                                                                ? [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "40px",
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.onUnitChange(
                                                                                scope.$index,
                                                                                0,
                                                                                ind
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            e.number,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                e,
                                                                                "number",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "e.number",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    e.unit ==
                                                                      1 ||
                                                                      e.unit ==
                                                                        3
                                                                      ? ""
                                                                      : "%"
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            [
                                                              _vm._v("-"),
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  width: "60px",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onAmountChange(
                                                                        scope.$index,
                                                                        0,
                                                                        ind
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    e.deduction_amount,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        e,
                                                                        "deduction_amount",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "e.deduction_amount",
                                                                },
                                                              }),
                                                              _vm._v("元"),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            width: "100%",
                                            "justify-content": "space-around",
                                          },
                                        },
                                        _vm._l(
                                          scope.row.price_data[2],
                                          function (e, ind) {
                                            return _c(
                                              "div",
                                              {
                                                key: "2" + ind,
                                                staticStyle: {
                                                  "line-height": "20px",
                                                  display: "flex",
                                                  "flex-direction": "column",
                                                },
                                              },
                                              [
                                                e.name
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex align-items-c",
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "5px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(e.name) +
                                                              "\n                      "
                                                          ),
                                                          e.unit != 1
                                                            ? [
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "40px",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onUnitChange(
                                                                          scope.$index,
                                                                          2,
                                                                          ind
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      e.number,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          e,
                                                                          "number",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "e.number",
                                                                  },
                                                                }),
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                e.unit == 1 ||
                                                                  e.unit == 3
                                                                  ? ""
                                                                  : "%"
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        [
                                                          _vm._v("-"),
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "60px",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.onAmountChange(
                                                                  scope.$index,
                                                                  2,
                                                                  ind
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                e.deduction_amount,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    e,
                                                                    "deduction_amount",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "e.deduction_amount",
                                                            },
                                                          }),
                                                          _vm._v("元"),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "买入订单抵扣",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "item-flex-center edit-input" },
                              [
                                _c("span", [_vm._v("-")]),
                                _vm._v(" "),
                                _c("el-input-number", {
                                  staticStyle: { width: "60px" },
                                  attrs: {
                                    type: "number",
                                    controls: false,
                                    min: 0,
                                    max: Number(scope.row.settlement_amount),
                                    placeholder: "请输入金额",
                                    "prefix-icon": "el-icon-minus",
                                  },
                                  model: {
                                    value:
                                      scope.row.store_order_deduction_amount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "store_order_deduction_amount",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "scope.row.store_order_deduction_amount",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("元")]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "应付金额", align: "center", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  scope.row.settlement_amount -
                                    (scope.row.store_order_deduction_amount ||
                                      0)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-end",
                "font-weight": "bold",
                padding: "10px 0",
                "border-bottom": "1px solid #f4f6fd",
                "border-left": "1px solid #f4f6fd",
                "border-right": "1px solid #f4f6fd",
                "margin-bottom": "20px",
              },
            },
            [
              _c("div", { staticStyle: { "text-align": "right" } }, [
                _vm._v("\n        合计：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100px",
                    "text-align": "center",
                    "font-size": "16px",
                    "font-weight": "500",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.checkoneJson.check_auction_data.reduce(function (
                        sum,
                        item
                      ) {
                        return (
                          sum +
                          (Number(item.settlement_amount) -
                            Number(item.store_order_deduction_amount || 0))
                        )
                      },
                      0)
                    )
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _vm.isNo
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "default" },
                      on: { click: _vm.cursorCheck },
                    },
                    [_vm._v("确认对账")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isNo
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "default" },
                      on: { click: _vm.checkSubmit },
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editNowPrice.show,
            "close-on-click-modal": false,
            center: "",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editNowPrice, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              staticStyle: { "font-size": "30px" },
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("修改落槌价")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                color: "#ae0000",
                "font-weight": "600",
                "text-align": "center",
              },
            },
            [_vm._v("（此修改只对委托结算有效）")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20 ed" },
            [
              _c("el-input-number", {
                staticStyle: { width: "400px" },
                attrs: { size: "large", controls: false },
                model: {
                  value: _vm.editNowPrice.data,
                  callback: function ($$v) {
                    _vm.$set(_vm.editNowPrice, "data", $$v)
                  },
                  expression: "editNowPrice.data",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20 mb20" },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    width: "400px",
                    height: "50px",
                    "font-size": "24px",
                  },
                  attrs: { type: "primary" },
                  on: { click: _vm.saveAction },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showpreview, width: "840px" },
          on: {
            "update:visible": function ($event) {
              _vm.showpreview = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "max-height": "70vh", "overflow-y": "auto" } },
            [
              _vm.contactImg && _vm.contactImg.length
                ? _vm._l(_vm.contactImg, function (m, i) {
                    return _c("div", { key: i }, [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { src: m, alt: "" },
                      }),
                    ])
                  })
                : _c("div", { attrs: { id: "printContent" } }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.html_content) },
                    }),
                  ]),
            ],
            2
          ),
          _vm._v(" "),
          _vm.contactUploadId
            ? _c(
                "div",
                { staticClass: "mt20 pb20 item-flex-center" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.bindPrint },
                    },
                    [_vm._v("打印合同")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }