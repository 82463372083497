"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _index4 = require("@/utils/index");
var _accounts = require("@/api/accounts");
var _system = require("@/api/system");
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index4.formatPriceNumber,
      parseTime: _index4.parseTime,
      status: 0,
      keywords: '',
      loadStatus: '1',
      showdetail: null,
      uid: '',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20
        // status: 0,
        // date: ''
      },
      chkName: '',
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      showImg: false,
      showImgUrl: '',
      showCreated: false,
      createdData: {
        price: '',
        use: 'wallet_recharge',
        pay_type: 'offline_pos',
        pay_evidence: '',
        mark: '',
        user: {}
      },
      searchQuery: {},
      allCheck: false,
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据

      viodVisble: false,
      payVisble: false,
      payForm: {
        payment_evidence: ''
      },
      viodForm: {
        fail_msg: ''
      }
    };
  },
  created: function created() {
    if (this.$route.query.status && this.$route.query.status != '') {
      this.status = this.$route.query.status;
    }
    var per = this.$store.getters.userPression;
    if (this.status == 0 && !per.merchantFinanceRefundOrderList) {
      this.status = 1;
    }
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    changeStatusAction: function changeStatusAction() {
      this.getList(1);
    },
    onOpenPay: function onOpenPay(row) {
      this.payForm = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, row), {}, {
        payment_evidence: ''
      });
      this.payVisble = true;
    },
    onPaySubmit: function onPaySubmit() {
      var _this = this;
      if (!this.payForm.payment_evidence) {
        this.$message.error('请上传付款凭证');
        return;
      }
      (0, _accounts.batchApplyExtractApi)({
        extract_ids: this.payForm.extract_id,
        payment_evidence: this.payForm.payment_evidence
      }).then(function (res) {
        _this.payVisble = false;
        _this.$message.success('操作成功');
        _this.getList(1);
      });
    },
    onPayCheck: function onPayCheck() {
      var _this2 = this;
      (0, _accounts.batchApplyExtractApi)({
        extract_ids: this.checkedIds.join(',')
      }).then(function (res) {
        _this2.$message.success('操作成功');
        _this2.allCheck = false;
        _this2.checkedPage = [];
        _this2.checkedIds = [];
        _this2.noChecked = [];
        _this2.getList(1);
      });
    },
    onOpenVoid: function onOpenVoid(row) {
      this.viodForm = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, row), {}, {
        fail_msg: ''
      });
      this.viodVisble = true;
    },
    onViodSubmit: function onViodSubmit() {
      var _this3 = this;
      if (!this.viodForm.fail_msg) {
        this.$message.error('请输入作废理由');
        return;
      }
      (0, _accounts.batchInvalidExtractApi)({
        extract_ids: this.viodForm.extract_id,
        fail_msg: this.viodForm.fail_msg
      }).then(function (res) {
        _this3.viodVisble = false;
        _this3.$message.success('操作成功');
      });
    },
    onVoid: function onVoid() {
      var _this4 = this;
      (0, _accounts.batchInvalidExtractApi)({
        extract_ids: this.checkedIds.join(',')
      }).then(function (res) {
        _this4.$message.success('操作成功');
        _this4.allCheck = false, _this4.checkedPage = [];
        _this4.checkedIds = [];
        _this4.noChecked = [];
      });
    },
    modalPicTap: function modalPicTap(tit, num, i) {
      var that = this;
      this.$modalUpload(function (img) {
        that.payForm.payment_evidence = img[0];
        that.$forceUpdate();
      }, 1);
    },
    exportOrder: function exportOrder(val) {
      var _this5 = this;
      var data = (0, _objectSpread2.default)({
        export_type: val == 1 ? "financeRefundOrderList" : "entrustUserWalletExtract"
      }, this.tableFrom);
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this5.$createElement;
        _this5.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this5.$msgbox.close();
                _this5.getExportFileList(val);
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList(val) {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(val == 1 ? "financeRefundOrderList" : "entrustUserWalletExtract");
    },
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? '' : name;
      this.changeType(!(this.chkName === ''));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = 'dan';
        }
      } else {
        this.chkName = '';
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.tableFrom.page);
      if (this.chkName === 'dan') {
        this.checkedPage.push(this.tableFrom.page);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this6 = this;
      var ids = this.tableData.data.map(function (v) {
        return v.extract_id;
      });
      if (this.chkName === 'duo') {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === 'dan') {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this6.checkedIds.indexOf(id);
          if (index === -1) {
            _this6.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this6.checkedIds.indexOf(id);
          if (index > -1) {
            _this6.checkedIds.splice(index, 1);
          }
        });
      }
    },
    // 分开选择
    changeOne: function changeOne(v, user) {
      if (v) {
        if (this.chkName === 'duo') {
          var index = this.noChecked.indexOf(user.extract_id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(user.extract_id);
          if (_index === -1) this.checkedIds.push(user.extract_id);
        }
      } else {
        if (this.chkName === 'duo') {
          var _index2 = this.noChecked.indexOf(user.extract_id);
          if (_index2 === -1) this.noChecked.push(user.extract_id);
        } else {
          var _index3 = this.checkedIds.indexOf(user.extract_id);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    onDetails: function onDetails(uid) {
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    },
    createdAction: function createdAction() {
      this.createdData = {
        price: '',
        use: 'wallet_recharge',
        pay_type: 'offline_pos',
        pay_evidence: '',
        mark: '',
        user: {}
      };
      this.showCreated = true;
    },
    editUserAction: function editUserAction() {
      this.$refs['changeUser'].init(this.createdData.user ? this.createdData.user.uid : '');
    },
    showEvidenceAction: function showEvidenceAction(img) {
      if (img) {
        this.showImgUrl = img;
        this.showImg = true;
      } else {
        this.$message.error('此记录没有凭证');
      }
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.date = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    getList: function getList(num) {
      var _this7 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      var actionUrl;
      if (this.status == 0) actionUrl = _accounts.refundOrderList;else {
        actionUrl = _accounts.userEntrustExtractList;
        // this.tableFrom.extract_status = '1';
        this.tableFrom.account_type = 2;
      }
      actionUrl(this.tableFrom).then(function (res) {
        _this7.tableData.data = res.data.list;
        _this7.tableData.total = res.data.count;
        _this7.listLoading = false;
        if (_this7.status == 1) {
          _this7.$nextTick(function () {
            if (_this7.$refs.auctionRef) _this7.$refs.auctionRef.doLayout();
          });
        }
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this7.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    }
  }
};