"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.constructor");
var _typeof2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _contract = require("@/api/contract");
var _settings = require("@/settings");
var _preview = _interopRequireDefault(require("./preview.vue"));
var _system = require("@/api/system");
var _user = require("@/api/user");
var _setting = require("@/api/setting");
var _basic = require("@/api/basic");
var _warehouse = require("@/api/warehouse");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _addUser = _interopRequireDefault(require("@/components/addUser.vue"));
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
var _utils = require("@/utils");
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    preview: _preview.default,
    fileList: _fileList.default,
    AddUserCom: _addUser.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      formatPriceNumber: _utils.formatPriceNumber,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 20
      },
      tempName: '',
      tempDesc: '',
      createflag: false,
      timeVal: [],
      templateData: [],
      status: {
        hasSigned: 0,
        invalid: 0,
        waitAudit: 0,
        waitSign: 0,
        waitSubmitAudit: 0
      },
      html_attachment_content: '',
      html_content: '',
      attachment: [],
      showpreview: false,
      signature_area_fields_data: {},
      title_fields_data: {},
      content: '',
      expiry_date_switch: false,
      entrust_order_switch: false,
      title: '',
      contractno: '',
      showList: [],
      showconfig: false,
      hideList: [],
      roterPre: _settings.roterPre,
      userList: [],
      searchLoading: false,
      formValidate: {
        contract_type: '2',
        salesman: [],
        sign_admin_id: '',
        template: '',
        label: ''
      },
      adminarr: [],
      searchAdminarr: [],
      templist: [],
      inventoryConfig: {
        switch: {
          other_price_switch: 0,
          sold_price_switch: 0,
          unsold_price_switch: 0
        },
        price: {
          other_price: [{
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }],
          sold_price: [{
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }],
          unsold_price: [{
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }]
        }
      },
      showsearch: false,
      searchjson: {},
      rowkeys: [],
      reductjson: {
        erp_v2_contract: '0',
        is_approval_admin: 0,
        adminData: []
      },
      showreview: false,
      reviewrow: {},
      reviewAduitId: '',
      staffid: '',
      searchQuery: {},
      erpsettingList: [],
      contactImg: '',
      contactUploadId: '',
      selectTableRows: [],
      row: '',
      uid: '',
      showdetail: false,
      goodsRuleConfigForm: {},
      viewH: 0,
      labelList: [],
      showChangeAuction: {
        show: false,
        data: [],
        change: [],
        id: ''
      },
      inputname: '',
      tableInputValue: '',
      categoryLabelProp: {
        emitPath: false,
        value: 'name',
        expandTrigger: 'hover',
        label: 'name',
        children: 'labelData'
      },
      categoryLabel: []
    };
  },
  computed: {
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.viewH = document.getElementById('viewH').offsetHeight;
    (0, _system.getConfigClassKeys)('erp_config').then(function (res) {
      // console.log('1---',res);
      _this.goodsRuleConfigForm = res.data;
    });
    (0, _setting.getApprovalApi)({
      type: 'erp_v2_contract'
    }).then(function (res) {
      _this.reductjson = res.data;
    });
    this.getCustomHeadder();
    this.getList();
    this.getcontractchart();
    this.getconfig();
    this.getadminList();
    (0, _user.getBaseInfo)().then(function (res) {
      if (res.data.isStaff) {
        _this.staffid = res.data.merchantAdmin.merchant_admin_id;
      }
    }).catch(function (_ref) {
      var message = _ref.message;
    } //
    );
    (0, _warehouse.getFieldSetAPI)({
      types: 'erp_v2_contract_create_product'
    }).then(function (res) {
      if (res.data.erp_v2_contract_create_product) {
        res.data.erp_v2_contract_create_product.show_list.forEach(function (item) {
          if (/^\d*$/.test(item.field)) {
            item.newtype = 'attribute';
          }
        });
        _this.erpsettingList = res.data.erp_v2_contract_create_product.show_list;
      }
    });
  },
  created: function created() {
    // labelLstApi({ system_page: 'erp_contract_create' }).then((res) => {
    //   this.labelList = res.data.list;
    // });
    this.getCategoryLabelSelect();
  },
  methods: {
    // 商户标签分类；
    getCategoryLabelSelect: function getCategoryLabelSelect() {
      var _this2 = this;
      (0, _basic.labelCategoryListApi)({
        system_page: 'erp_contract_create'
      }).then(function (res) {
        var d = [];
        var label = [];
        res.data.forEach(function (m) {
          d.push(m);
          if (m.labelData && m.labelData.length) {
            label = label.concat(m.labelData.map(function (l) {
              return {
                label_name: l.name,
                label_id: l.id
              };
            }));
          }
        });
        _this2.labelList = label;
        _this2.categoryLabel = d;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    onUpdStatus: function onUpdStatus(row, status) {
      var _this3 = this;
      (0, _contract.switchAuditRecordStatusApi)({
        id: row.id,
        status: status
      }).then(function (res) {
        _this3.$message.success('操作成功');
        _this3.getList(1);
      });
    },
    bindPrint: function bindPrint() {
      (0, _printJs.default)({
        printable: 'printContent',
        type: 'html',
        header: null,
        targetStyles: ['*'],
        style: 'body {font-size: 0.4em;font-weight: bold; line-height:0.82;margin:0;padding:0;}',
        font_size: 'llllll'
      });
    },
    getStatusAction: function getStatusAction(row) {
      if (row.status === 0) {
        return true;
      } else {
        return false;
      }
    },
    cancelAction: function cancelAction() {
      this.showChangeAuction = {
        show: false,
        data: [],
        change: [],
        id: ''
      };
    },
    confirmAction: function confirmAction() {
      if (!this.showChangeAuction.change || !this.showChangeAuction.change.length) {
        this.$message.error('请先选择货品信息');
        return false;
      }
      this.isChangeAuction = JSON.parse(JSON.stringify(this.showChangeAuction.change));
      this.isChangeId = this.showChangeAuction.id;
      this.cancelAction();
      this.createContract();
    },
    selectionChange: function selectionChange(data) {
      this.showChangeAuction.change = data;
    },
    resetAuction: function resetAuction(row) {
      var _this4 = this;
      this.isChangeAuction = [];
      this.isChangeId = '';
      this.showChangeAuction.id = row.id;
      this.showChangeAuction.data = [];
      this.showChangeAuction.change = [];
      this.showChangeAuction.show = true;
      (0, _contract.contactV2ProductListApi)({
        contract_id: row.id
      }).then(function (res) {
        res.data.list.forEach(function (item) {
          if (item.price_data && item.price_data[0] && typeof item.price_data[0] === 'string') {
            item.price_data = JSON.parse(item.price_data);
          } else if (item.price_data && item.price_data.length) {} else {
            item.price_data = [JSON.parse(JSON.stringify(_this4.inventoryConfig.price.sold_price)), JSON.parse(JSON.stringify(_this4.inventoryConfig.price.unsold_price)), JSON.parse(JSON.stringify(_this4.inventoryConfig.price.other_price))];
          }
          var successData = [];
          if (_this4.inventoryConfig && _this4.inventoryConfig.price && _this4.inventoryConfig.price.sold_price) {
            _this4.inventoryConfig.price.sold_price.forEach(function (m, i) {
              if (item.price_data[0][i]) {
                successData[i] = item.price_data[0][i];
              } else {
                successData[i] = {
                  name: '',
                  number: '',
                  unit: 1
                };
              }
            });
          }
          // item.success = item.price_data[0].length == 3 ? [...item.price_data[0],{name: "", number: "", unit: 1}] : item.price_data[0]
          item.success = successData;
          item.fail = item.price_data[1];
          item.other = item.price_data[2];
          if (item.erpProduct.attribute) {
            for (var k in item.erpProduct.attribute) {
              _this4.erpsettingList.forEach(function (ele) {
                if (ele.field == k && ele.attr_type == 3 && item.erpProduct.attribute[k] && item.erpProduct.attribute[k]) item.erpProduct.attribute[k] = item.erpProduct.attribute[k].split(',');
              });
              // if (item.erpProduct.attribute[k].indexOf(',')!=-1) {
              //   item.erpProduct.attribute[k] = item.erpProduct.attribute[k].split(',')
              // }
            }
            item.attribute = item.erpProduct.attribute;
          } else {
            item.attribute = {};
          }
          if (item.erpProduct) {
            item.product_no = item.erpProduct.product_no;
            item.image = item.erpProduct.contract_image;
          }
        });
        _this4.showChangeAuction.data = res.data.list;
      });
    },
    sortAction: function sortAction(_ref2) {
      var column = _ref2.column,
        prop = _ref2.prop,
        order = _ref2.order;
      if (order === 'ascending') {
        this.orderBy = "".concat(prop, "_asc");
      } else if (order === 'descending') {
        this.orderBy = "".concat(prop, "_desc");
      } else {
        this.orderBy = '';
      }
      this.getList(1);
    },
    sortProductAction: function sortProductAction(_ref3) {
      var column = _ref3.column,
        prop = _ref3.prop,
        order = _ref3.order;
      if (order === 'ascending') {
        this.productOrderBy = "".concat(prop, "_asc");
      } else if (order === 'descending') {
        this.productOrderBy = "".concat(prop, "_desc");
      } else {
        this.productOrderBy = '';
      }
      if (this.tableData.data[this.changeIndex]) {
        this.exchangeExpand(this.tableData.data[this.changeIndex], 1);
      }
    },
    onAdd: function onAdd() {
      this.$refs.userREf.adduser(true);
    },
    onDetails: function onDetails(row) {
      if (row.uid === 8888) {
        // this.editOrderAction(row);
        return false;
      }
      this.row = row;
      this.uid = row.uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    handleSelectionChange: function handleSelectionChange(data) {
      this.selectTableRows = data;
    },
    saleChange: function saleChange() {
      if (!this.formValidate.sign_admin_id) {
        // this.formValidate.sign_admin_id = this.formValidate.salesman;
        this.$set(this.formValidate, 'sign_admin_id', this.formValidate.salesman[0]);
      }
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    editcontract: function editcontract(row) {
      var _this5 = this;
      this.gettemplist(1);
      // this.searchUserAction('');
      this.formValidate = {
        contract_type: "".concat(row.contract_type),
        salesman: row.salesmanAdminData.map(function (item) {
          return item.merchant_admin_id;
        }),
        sign_admin_id: row.sign_admin_id || '',
        template: Number(row.template_id) ? row.template_id : '',
        entrust: row.entrust_uid,
        is_special: row.is_special,
        label: '',
        id: row.id
      };
      this.labelList.forEach(function (m) {
        if (row.label_ids == m.label_id) {
          _this5.formValidate.label = m.label_name;
        }
      });
      if (row.entrustUser) {
        this.userList = [JSON.parse(JSON.stringify(row.entrustUser))];
      }
      this.createflag = true;
      return;
      if (row.contract_type == 2) {
        this.$router.push("/merchant/contract/contracttwo?id=".concat(row.id, "&tempid=").concat(row.template_id));
      } else {
        this.$router.push("/merchant/contract/contracttwo?id=".concat(row.id));
      }
    },
    cellClick: function cellClick(row, column, cell, event) {
      if (this.rowkeys.includes(row.id)) this.rowkeys = [];else {
        this.rowkeys = [row.id];
        this.exchangeExpand(row);
      }
      // } else {
      //   this.rowkeys.splice(this.rowkeys.indexOf(row.id), 1);
      // }
    },
    downloadf: function downloadf(item) {
      window.open(item.attachment_url);
    },
    setconfig: function setconfig() {
      this.showconfig = true;
    },
    getCustomHeadder: function getCustomHeadder() {
      var _this6 = this;
      (0, _system.getCustomHeadder)({
        type: 'contract'
      }).then(function (res) {
        _this6.showList = res.data.show_list;
        _this6.hideList = res.data.hide_list;
      });
    },
    cancelsigncontract: function cancelsigncontract(row) {
      var _this7 = this;
      var that = this;
      this.$prompt('', '提示', {
        confirmButtonText: '确认撤销',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputPlaceholder: '请输入撤销理由',
        inputErrorMessage: '请输入撤销理由'
      }).then(function (_ref4) {
        var value = _ref4.value;
        (0, _contract.contractV2CancelAuditApi)(row.id, {
          reason: value
        }).then(function () {
          that.$message.success('撤销成功');
          that.getList();
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    getdetaildownload: function getdetaildownload(row, type) {
      if (row.contract_type == 1 && !type) {
        if (!row.contract_url) {
          this.$message.error('该合同未上传附件');
          return false;
        }
        var list = row.contract_url.split(',');
        var _iterator = (0, _createForOfIteratorHelper2.default)(list),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var m = _step.value;
            window.open(m);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        return;
      }
      (0, _contract.getDownloadContractApi)(row.id).then(function (res) {
        window.open(res.data.downloadUrl);
      });
    },
    getdetail: function getdetail(row, type) {
      var _this8 = this;
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      this.html_content = '';
      this.contactImg = '';
      this.contactUploadId = '';
      (0, _contract.contractV2DetailApi)(row.id).then(function (res) {
        if (res.data.template_id && !type && res.data.content) {
          _this8.html_content = res.data.content.html_content.replace('width: 0px; height: 0px; overflow: hidden;', '');
          _this8.contactImg = '';
          _this8.contactUploadId = row.id;
          _this8.showpreview = true;
        } else if (res.data.contract_type == 1) {
          _this8.contactImg = res.data.contract_url ? res.data.contract_url.split(',') : [];
          _this8.showpreview = true;
        } else if (res.data.content) {
          _this8.html_content = res.data.content.html_content.replace('width: 0px; height: 0px; overflow: hidden;', '');
          _this8.contactImg = '';
          _this8.showpreview = true;
        }
      });
      return;
      (0, _contract.getcontractDetail)(row.id).then(function (res) {
        console.log(res);
        loading.close();
        _this8.signature_area_fields_data = JSON.parse(res.data.signature_area_fields_data);
        _this8.title_fields_data = JSON.parse(res.data.title_fields_data);
        _this8.content = res.data.content.content;
        _this8.title = res.data.contract_name;
        _this8.contractno = res.data.contract_no;
        _this8.html_attachment_content = res.data.content.html_attachment_content;
        _this8.html_content = res.data.content.html_content;
        _this8.attachment = res.data.attachment;
        if (res.data.entrustOrder && res.data.entrustOrder.length) {
          _this8.entrust_order_switch = true;
        }
        _this8.showpreview = true;
      }).catch(function (res) {
        loading.close();
        // this.$message.error(res.message);
      });
    },
    getcontractchart: function getcontractchart() {
      var _this9 = this;
      (0, _contract.getcontractchart)().then(function (res) {
        console.log(res);
        if (res.status == 200) {
          _this9.templateData = res.data.templateData.map(function (item) {
            return {
              label: item.name + '(' + item.contractCount + ')',
              value: item.template_id
            };
          });
          _this9.status = res.data.status;
        }
      });
    },
    losecontract: function losecontract(row) {
      var _this10 = this;
      this.$confirm('确认作废此合同？', '提示', {
        confirmButtonText: '作废',
        cancelButtonText: '不作废',
        type: 'warning'
      }).then(function () {
        (0, _contract.contractV2EditStatusApi)(row.id, {
          status: -1
        }).then(function () {
          _this10.$message.success('作废成功');
          _this10.getList();
        });
      });
    },
    delcontract: function delcontract(row) {
      var _this11 = this;
      this.$confirm('确认删除此合同？', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '不删除',
        type: 'warning'
      }).then(function () {
        (0, _contract.contractV2deleteApi)(row.id).then(function () {
          _this11.$message.success('删除成功');
          _this11.getList();
        });
      });
    },
    cancelreview: function cancelreview(row) {
      var _this12 = this;
      this.$confirm('确认撤销审批？', '提示', {
        confirmButtonText: '撤销',
        cancelButtonText: '不撤销',
        type: 'warning'
      }).then(function () {
        (0, _contract.contractV2EditStatusApi)(row.id, {
          status: 1
        }).then(function () {
          _this12.$message.success('撤销成功');
          _this12.getList();
        });
      });
    },
    sendreview: function sendreview(row) {
      if (!this.reductjson.adminData || !this.reductjson.adminData.length) {
        this.$message.error('请先添加审核人');
        return;
      }
      this.showreview = true;
      this.reviewrow = row;
      this.reviewAduitId = this.reductjson.adminData[0].merchant_admin_id;
      // this.$confirm("确认发送审批？", "提示", {
      //   confirmButtonText: "发送审批",
      //   cancelButtonText: "不发送",
      //   type: "warning",
      // }).then(() => {
      //   contractV2EditStatusApi(row.id, { status: 2 }).then(() => {
      //     this.$message.success("操作成功");
      //     this.getList();
      //   });
      // });
    },
    cursorsendreview: function cursorsendreview(row) {
      var _this13 = this;
      (0, _contract.contractV2EditStatusApi)(this.reviewrow.id, {
        status: 2,
        audit_admin_id: this.reviewAduitId
      }).then(function () {
        _this13.$message.success('操作成功');
        _this13.showreview = false;
        _this13.getList();
      });
    },
    aduitreview: function aduitreview(row) {
      var _this14 = this;
      this.$confirm('确认审批通过吗？', '提示', {
        confirmButtonText: '通过',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var status = 3;
        if (row.contract_type == 1) {
          status = 4;
        }
        (0, _contract.contractV2EditStatusApi)(row.id, {
          status: status
        }).then(function () {
          _this14.$message.success('操作成功');
          _this14.getList();
        });
      });
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.contract_expiry_time = e ? this.timeVal.join('-') : '';
      // this.getList(1);
    },
    createContract: function createContract() {
      var _this15 = this;
      this.createflag = true;
      this.formValidate = {
        contract_type: '1',
        salesman: [],
        sign_admin_id: '',
        template: '',
        entrust: '',
        is_special: '',
        id: '',
        label: ''
      };
      this.$store.dispatch('user/getEnterInfo').then(function (res) {
        _this15.formValidate.salesman = [res.merchantAdmin.merchant_admin_id];
        _this15.formValidate.sign_admin_id = res.merchantAdmin.merchant_admin_id;
      });
      this.gettemplist();
      this.searchUserAction('');
    },
    cursoradd: function cursoradd() {
      var _this16 = this;
      if (!this.formValidate.salesman || !this.formValidate.salesman.length) {
        return this.$message.error('请选择业务员');
      }
      if (!this.formValidate.sign_admin_id) {
        return this.$message.error('请选择签约人');
      }
      if (!this.formValidate.template && Number(this.formValidate.contract_type) === 2) {
        return this.$message.error('请选择模板');
      }
      if (!this.formValidate.entrust) {
        return this.$message.error('请选择委托人');
      }
      if (this.labelList && this.labelList.length && !this.formValidate.label) {
        return this.$message.error('请选择标签');
      }
      // if (!this.formValidate.entrust.entrust){
      //   return this.$message.error("委托人信息不完整，去补充");
      // }

      var json = JSON.parse(JSON.stringify(this.formValidate));
      var data = [];
      this.formValidate.salesman.forEach(function (item) {
        data.push(_this16.checkSelectAdmin(item));
      });
      json.salesman_name = data.map(function (m) {
        return m.real_name;
      }).join(',');
      // json.salesman = this.checkSelectAdmin(this.formValidate.salesman);
      for (var i = 0; i < this.templist.length; i++) {
        if (this.formValidate.template == this.templist[i].template_id) {
          json.template = this.templist[i];
          break;
        }
      }
      for (var _i = 0; _i < this.userList.length; _i++) {
        if (this.formValidate.entrust == this.userList[_i].uid) {
          json.entrust = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.userList[_i]), {}, {
            real_name: this.userList[_i].userVerify ? this.userList[_i].userVerify.real_name : this.userList[_i].nickname
          });
          break;
        }
      }
      json.origin_contract_id = this.isChangeId;
      json.changeList = this.isChangeAuction;
      if (this.formValidate.label) {
        json.label_ids = this.formValidate.label;
      }
      localStorage.setItem('contractOne', JSON.stringify(json));
      if (this.formValidate.id) {
        if (this.formValidate.contract_type == '2') {
          this.$router.push("/merchant/contract/contracttwo?id=".concat(this.formValidate.id, "&tempid=").concat(this.formValidate.template));
        } else {
          this.$router.push("/merchant/contract/contracttwo?id=".concat(this.formValidate.id, "&tempid=").concat(this.formValidate.template));
        }
      } else {
        if (this.formValidate.contract_type == '2') {
          this.$router.push("/merchant/contract/contracttwo?tempid=".concat(this.formValidate.template));
        } else {
          this.$router.push("/merchant/contract/contracttwo?tempid=".concat(this.formValidate.template));
        }
      }
    },
    // showdetail(row) {
    //   this.$router.push({
    //     path: roterPre + '/contract/contractdetail/' + row.template_id
    //   });
    // },
    // 列表
    getList: function getList(num) {
      var _this17 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      var json = JSON.parse(JSON.stringify(this.tableFrom));
      json.order_by = this.orderBy || '';
      (0, _contract.contractV2ListApi)(json).then(function (res) {
        res.data.list.forEach(function (item) {
          item.expandData = [];
        });
        if (_this17.showsearch) {
          res.data.list.unshift({});
        }
        _this17.tableData.data = res.data.list;
        _this17.tableData.total = res.data.count;
        _this17.listLoading = false;
        _this17.$nextTick(function () {
          if (_this17.$refs.conRef) _this17.$refs.conRef.doLayout();
        });
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this17.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    handleClose: function handleClose() {
      this.createflag = false;
      this.isChangeAuction = [];
      this.isChangeId = '';
    },
    parseTime: function parseTime(time, cFormat) {
      if (arguments.length === 0) {
        return null;
      }
      var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
      var date;
      if ((0, _typeof2.default)(time) === 'object') {
        date = time;
      } else {
        if (typeof time === 'string') {
          if (/^[0-9]+$/.test(time)) {
            // support "1548221490638"
            time = parseInt(time);
          } else {
            // support safari
            // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
            time = time.replace(new RegExp(/-/gm), '/');
          }
        }
        if (typeof time === 'number' && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      var formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      };
      var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
        var value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
          return ['日', '一', '二', '三', '四', '五', '六'][value];
        }
        return value.toString().padStart(2, '0');
      });
      return time_str;
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this18 = this;
      this.tableFrom.export_type = 'erpV2Contract';
      if (this.selectTableRows.length > 0) {
        var arr = this.selectTableRows.map(function (item) {
          return item.id;
        });
        this.tableFrom.ids = arr.join(',');
      }
      // return
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this18.$createElement;
        _this18.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this18.$msgbox.close();
                _this18.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('erpV2Contract');
    },
    searchUserAction: function searchUserAction(data) {
      var _this19 = this;
      var params = {
        // keyword: data,
        precise_keyword: data,
        // is_entrust: 1,
        page: 1,
        size: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this19.userList = res.data.list;
      });
    },
    getadminList: function getadminList() {
      var _this20 = this;
      (0, _setting.adminListApi)({
        page: 1,
        limit: 100
      }).then(function (res) {
        _this20.searchAdminarr = res.data.list.map(function (item) {
          return {
            label: item.real_name,
            value: item.merchant_admin_id
          };
        });
        _this20.adminarr = res.data.list;
      });
    },
    gettemplist: function gettemplist(isChange) {
      var _this21 = this;
      (0, _contract.modalList)({
        page: 1,
        limit: 20
      }).then(function (res) {
        _this21.templist = res.data.list;
        if (!isChange) {
          _this21.formValidate.template = res.data.list[0].template_id;
        }
      });
    },
    changetemp: function changetemp(e) {
      console.log(e);
      //  this.templist.forEach(item=>{
      //    if(item.template_id == e){
      //      this.formValidate.contract_name = item.name;
      //    }
      //  })
    },
    loadExpandData: function loadExpandData(row, treeNode, resolve) {
      console.log(row);
      // contactV2ProductListApi({
      //   contract_id: row.id
      // }).then((res) => {

      // })
      // 模拟异步加载数据
      setTimeout(function () {
        // 模拟从服务器获取数据
        var expandData = [
          // 这里是扩展行的数据
        ];
        row.expandData = expandData; // 将数据赋值给行的扩展数据属性
        resolve(expandData); // 调用resolve来结束加载状态
      }, 1000);
    },
    exchangeExpand: function exchangeExpand(row, refresh) {
      if (!this.$store.getters.userPression.merchantErpV2ContractProductList) return;
      if (refresh !== 1) {
        this.productOrderBy = '';
      }
      var ids = this.tableData.data.map(function (m) {
        return m.id;
      });
      var index = ids.indexOf(row.id);
      if (index !== -1) {
        this.changeIndex = index;
      }
      this.rowkeys = [row.id];
      if (row.expandData.length && refresh !== 1) {
        return;
      }
      if (!row.id) {
        return;
      }
      row.loading = true;
      (0, _contract.contactV2ProductListApi)({
        contract_id: row.id,
        order_by: this.productOrderBy || ''
      }).then(function (res) {
        res.data.list.forEach(function (item) {
          if (item.price_data && item.price_data[0] && typeof item.price_data[0] === 'string') {
            item.price_data = JSON.parse(item.price_data[0]);
          } else {
            var data = item.price_data[0].length == 3 ? [].concat((0, _toConsumableArray2.default)(item.price_data[0]), [{
              name: '',
              number: '',
              unit: 1
            }]) : item.price_data[0];
            item.price_data[0] = data;
          }
        });
        row.expandData = res.data.list;
        row.loading = false;
      });
    },
    getconfig: function getconfig(callback) {
      var _this22 = this;
      (0, _basic.contractConfig)().then(function (res) {
        if (res.status == 200) {
          _this22.inventoryConfig = res.data;
        }
        if (callback) callback();
      });
    },
    clearsearch: function clearsearch(type) {
      if (type == 'all') {
        for (var i in this.tableFrom) {
          this.tableFrom[i] = '';
        }
        for (var _i2 in this.searchjson) {
          this.searchjson[_i2] = null;
        }
      } else if (type == 'sign_end_time') {
        this.tableFrom.contract_expiry_time = '';
        this.searchjson.sign_end_time = null;
      } else if (type == 'contract_no') {
        this.searchjson.contract_no = '';
        this.tableFrom.contract_no = '';
      } else if (type == 'sign_phone') {
        this.searchjson.sign_phone = '';
        this.tableFrom.user_keyword = '';
      } else if (type == 'template') {
        this.searchjson.template = null;
        this.tableFrom.template_id = '';
      } else if (type == 'contract_type') {
        this.searchjson.contract = null;
        this.tableFrom.contract_type = '';
      } else if (type == 'status') {
        this.searchjson.statusText = '';
        this.tableFrom.status = '';
      } else if (type == 'salesmanAdmin') {
        this.searchjson.salesman = null;
        this.tableFrom.salesman_admin_id = '';
      } else if (type == 'auditAdmin') {
        this.searchjson.audit = null;
        this.tableFrom.audit_admin_id = '';
      } else if (type == 'createAdmin') {
        this.searchjson.create = null;
        this.tableFrom.create_admin_id = '';
      }
      var flag = false;
      for (var _i3 in this.searchjson) {
        if (this.searchjson[_i3]) {
          flag = true;
        }
      }
      if (!flag) {
        this.showsearch = false;
      }
      this.getList(1);
    },
    searchList: function searchList(type) {
      this.showsearch = true;
      if (type == 'sign_end_time') {
        this.searchjson.sign_end_time = {
          start: this.timeVal[0],
          end: this.timeVal[1]
        };
      } else if (type == 'contract_no') {
        this.searchjson.contract_no = this.tableFrom.contract_no || '';
      } else if (type == 'sign_phone') {
        this.searchjson.sign_phone = this.tableFrom.user_keyword;
      } else if (type == 'template') {
        for (var i = 0; i < this.templateData.length; i++) {
          if (this.tableFrom.template_id == this.templateData[i].template_id) {
            this.searchjson.template = this.templateData[i];
          }
        }
      } else if (type == 'contract_type') {
        if (this.tableFrom.contract_type == 1) {
          this.searchjson.contract = {
            type: '1',
            text: '纸质合同'
          };
        } else if (this.tableFrom.contract_type == 2) {
          this.searchjson.contract = {
            type: '2',
            text: '电子合同'
          };
        }
      } else if (type == 'status') {
        if (this.tableFrom.status == 4) {
          this.searchjson.statusText = '已签约';
        } else if (this.tableFrom.status == 2) {
          this.searchjson.statusText = '待审批';
        } else if (this.tableFrom.status == 3) {
          this.searchjson.statusText = '待签约';
        } else if (this.tableFrom.status == -1) {
          this.searchjson.statusText = '已作废';
        }
      } else if (type == 'salesmanAdmin') {
        this.searchjson.salesman = this.checkSelectAdmin(this.tableFrom.salesman_admin_id);
      } else if (type == 'auditAdmin') {
        this.searchjson.audit = this.checkSelectAdmin(this.tableFrom.audit_admin_id);
      } else if (type == 'createAdmin') {
        this.searchjson.create = this.checkSelectAdmin(this.tableFrom.create_admin_id);
      }
      console.log(this.searchjson);
      this.getList(1);
    },
    checkSelectAdmin: function checkSelectAdmin(id) {
      var item = null;
      for (var i = 0; i < this.adminarr.length; i++) {
        if (this.adminarr[i].merchant_admin_id == id) {
          item = this.adminarr[i];
          break;
        }
      }
      return item;
    },
    createUser: function createUser() {
      this.$refs.userREf.adduser();
      // this.createuserflag = true;
      // this.createuserflag = false;
      // let value = this.inputvalue || "";
      // if (value.length != 11) {
      //   return this.$message.error("请输入正确的手机号");
      // }
      // createUserApi({
      //   nickname: value,
      //   phone: value,
      // }).then((res) => {
      //   this.$message.success("保存成功");
      //   this.searchUserAction(value);
      // });
    },
    createUserb: function createUserb(e) {
      this.inputvalue = e.target.value;
    },
    onUserChange: function onUserChange() {
      var _this23 = this;
      var row = this.userList.find(function (item) {
        return item.uid == _this23.formValidate.entrust;
      });
      if (row.is_entrust == 0) {
        this.$confirm('当前人还不是委托方', '提示', {
          confirmButtonText: '补全资料',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this23.createflag = false;
          _this23.$refs.userREf.onUpdRow(row);
          _this23.formValidate.entrust = '';
        }).catch(function () {
          _this23.formValidate.entrust = '';
        });
      }
    },
    onAfterEnter: function onAfterEnter(type, field) {
      this.$refs[type].focus();
    },
    saveoneflied: function saveoneflied(index, type, id, row, am) {
      this.inputname = '';
      if (this.oldTableInputValue == this.tableInputValue) return false;
      this.tableData.data[index][type] = this.tableInputValue;
      if (type == 'contract_inter_no') {
        (0, _contract.editContractInterNoApi)({
          contract_inter_no: this.tableInputValue,
          id: id
        }).then(function (res) {}).catch(function () {});
      }
    },
    checkinput: function checkinput(index, type, id, row, am) {
      this.tableInputValue = this.tableInputValue.replaceAll('\"', '').replaceAll('\\n', '');
    },
    click: function click(index, type, id, row, am) {
      this.tableInputValue = row[type] || '';
      this.oldTableInputValue = row[type] || '';
      this.inputname = "".concat(type, "_").concat(id);
    }
  }
};