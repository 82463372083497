"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var authenticateRouter = {
  path: "".concat(_settings.roterPre, "/authenticate"),
  name: "authenticate",
  meta: {
    title: "在线鉴定"
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/authenticate/expert"),
  children: [{
    path: "expert",
    name: "Expert",
    meta: {
      title: "图文",
      cacheable: false,
      activeMenu: "".concat(_settings.roterPre, "/authenticate/expert"),
      permissionKey: '/authenticate/expert'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/authenticate/appraisalQuestions"));
      });
    }
  }, {
    path: "appraisal",
    name: "Appraisal",
    meta: {
      title: "专家",
      activeMenu: "".concat(_settings.roterPre, "/authenticate/expert"),
      permissionKey: '/authenticate/appraisal'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/authenticate/expert"));
      });
    },
    children: []
  }, {
    path: "addExpert",
    name: "AddExpert",
    meta: {
      title: "编辑专家",
      activeMenu: "".concat(_settings.roterPre, "/authenticate/expert"),
      permissionKey: '/authenticate/expert'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/authenticate/addExpert"));
      });
    }
  }, {
    path: "praisalReport",
    name: "PraisalReport",
    meta: {
      title: "数据报表",
      activeMenu: "".concat(_settings.roterPre, "/authenticate/expert"),
      permissionKey: '/authenticate/praisalReport'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/authenticate/praisalReport"));
      });
    }
  }, {
    path: "praisalCategory",
    name: "PraisalCategory",
    meta: {
      title: "分类设置",
      activeMenu: "".concat(_settings.roterPre, "/authenticate/expert"),
      permissionKey: '/authenticate/praisalCategory'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/authenticate/praisalCategory"));
      });
    }
  }, {
    path: "praisalResult",
    name: "praisalResult",
    meta: {
      title: "鉴定结果",
      activeMenu: "".concat(_settings.roterPre, "/authenticate/expert"),
      permissionKey: '/authenticate/praisalCategory'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/authenticate/praisalResult"));
      });
    }
  }, {
    path: "praisalSetting",
    name: "PraisalSetting",
    meta: {
      title: "基础设置",
      activeMenu: "".concat(_settings.roterPre, "/authenticate/expert"),
      permissionKey: '/authenticate/praisalSetting'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/authenticate/praisalSetting"));
      });
    }
  }, {
    path: "appraisalComplaint",
    name: "AppraisalComplaint",
    meta: {
      title: "投诉",
      activeMenu: "".concat(_settings.roterPre, "/authenticate/expert"),
      permissionKey: '/authenticate/appraisalComplaint'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/authenticate/appraisalComplaint"));
      });
    }
  }]
};
var _default = exports.default = authenticateRouter;