"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _contract = require("@/api/contract");
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: [],
      reductjson: {
        erp_v2_out_warehouse: '0',
        is_approval_admin: 0,
        adminData: []
      },
      reviewAduitId: '',
      loading: true
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (!this.$route.query.ids) {
      this.$message.error("请选择拍品");
      return;
    }
    (0, _contract.preEntrustRefundApi)({
      ids: this.$route.query.ids
    }).then(function (res) {
      var list = [];
      res.data.forEach(function (m) {
        m.forEach(function (item, index) {
          if (index === 0) {
            item.merge = m.length;
          } else {
            item.merge = 0;
          }
          list.push(item);
        });
      });
      _this.list = list;
      _this.loading = false;
    }).catch(function () {
      _this.$router.back();
    });
    // getApprovalApi({
    //   type: `erp_v2_out_warehouse_refund`
    // }).then(res=>{
    //   console.log(res);
    //   this.reductjson.erp_v2_out_warehouse = res.data[`erp_v2_out_warehouse_refund`];
    //   this.reductjson.adminData = res.data.adminData;
    // })
  },
  methods: {
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (column.label == '委托方' || column.label == '合同号' || column.label == '库房' || column.label == '发货方式' || column.label == '快递公司' || column.label == '收件地址') {
        // if (columnIndex > 0 && columnIndex < 4 || column.label == '号牌') {
        if (row.merge >= 1) {
          return {
            rowspan: row.merge,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    createPushOrder: function createPushOrder() {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4\u521B\u5EFA\u51FA\u5E93\u5355?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var model = {
          ids: _this2.$route.query.ids
        };
        // if(this.reductjson.erp_v2_out_warehouse == 1){
        //   if (!this.reviewAduitId) {
        //     this.$message.error('请选择审批人');
        //     return
        //   }
        //   model.audit_admin_id = this.reviewAduitId
        // }
        (0, _contract.entrustRefundApi)(model).then(function (res) {
          _this2.$message.success("创建成功");
          _this2.$router.back();
        });
      }).catch(function () {});
    }
  }
};