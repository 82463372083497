"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _authenticate = require("@/api/authenticate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var selectType = {
  '1': 'Expert',
  '3': 'PraisalReport',
  '4': 'PraisalCategory',
  '5': 'PraisalSetting',
  '2': 'Appraisal',
  '6': 'praisalResult'
};
var _default = exports.default = {
  name: 'PraisalCategory',
  data: function data() {
    return {
      activeMenu: '4',
      showDetail: false,
      loading: false,
      categoryId: '',
      // 分类id
      aa: '',
      categoryName: '',
      // 分类名称
      resultData: [],
      showChild: [],
      // 子类
      level: 1,
      isEdit: false,
      // 编辑
      editParentCategoryList: [],
      pid: null,
      uploadImg: '',
      visible: false,
      // 查看图片
      categoryLevel: [{
        id: 0,
        level_name: ''
      }],
      showEditLevelDetail: false
    };
  },
  created: function created() {
    this.load();
  },
  methods: {
    onSelectTit: function onSelectTit(index) {
      var _this = this;
      this.activeMenu = index;
      this.$router.replace({
        name: selectType[index]
      });
      setTimeout(function () {
        _this.activeMenu = '4';
      });
    },
    onUpload: function onUpload() {
      var _this2 = this;
      this.$modalUpload(function (img) {
        if (img.length > 1) return _this2.$message.error('只能选择一张');
        _this2.uploadImg = img[0];
      });
    },
    handleView: function handleView() {
      this.visible = true;
    },
    handleRemoveNew: function handleRemoveNew() {
      this.uploadImg = '';
    },
    getCartgorySetList: function getCartgorySetList(state) {
      var _this3 = this;
      this.loading = true;
      (0, _authenticate._getCartgorySetList)(state).then(function (res) {
        _this3.resultData = res.data.list;
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    },
    load: function load() {
      var params = {
        page: 1,
        limit: 1000
      };
      this.getCartgorySetList(params);
    },
    createdAction: function createdAction(e) {
      this.showDetail = true;
      this.pid = null;
      this.level = 1;
      e.preventDefault();
    },
    descAction: function descAction(e, index) {
      var _this4 = this;
      e.preventDefault();
      if (this.isDesc) {
        this.$message.error('网络好像慢了，请稍等重试');
        return false;
      }
      this.isDesc = true;
      var parent = JSON.parse(JSON.stringify(this.resultData));
      var editI = index;
      parent.splice(editI - 1, 0, parent[editI]);
      parent.splice(editI + 1, 1);
      var sort_arr = parent.map(function (m) {
        return m.id;
      });
      (0, _authenticate._sortCartgory)({
        sort_arr: sort_arr
      }).then(function (res) {
        if (res.status === 200) {
          _this4.$message.success('排序成功');
          _this4.load();
        }
        _this4.isDesc = false;
      });
      return false;
    },
    removeAction: function removeAction(e, index) {
      var _this5 = this;
      var category = this.resultData[index];
      (0, _authenticate._deleteCartgory)(category.id).then(function (res) {
        if (res.status == 200) {
          _this5.$message.success('操作成功');
          _this5.load();
        }
      }).catch(function (err) {
        console.log(err);
      });
      return false;
    },
    linkUrl: function linkUrl(file) {
      window.open(file.response.url);
    },
    editAction: function editAction(e, index) {
      var category = this.resultData[index];
      this.categoryId = category.id;
      this.categoryName = category.category_name;
      this.uploadImg = category.category_img;
      this.isEdit = true;
      this.showDetail = true;
      e.preventDefault();
    },
    saveDetail: function saveDetail() {
      var _this6 = this;
      var img = this.uploadImg;
      var name = this.categoryName;
      if (!name) {
        this.$message.error('请输入分类名称');
        return false;
      }
      if (!img) {
        this.$message.error('请上传图片');
        return false;
      }
      var data = {
        category_name: name,
        category_img: img
      };
      if (this.isEdit) {
        if (this.categoryId) {
          data.id = this.categoryId;
          (0, _authenticate._editCartgory)(data).then(function (res) {
            if (res.status == 200) {
              _this6.$message.success('保存成功');
              _this6.load();
              _this6.showDetail = false;
              _this6.categoryName = '';
              _this6.uploadImg = '';
              _this6.isEdit = false;
            }
          }).catch(function (err) {
            console.log(err);
          });
        }
      } else {
        (0, _authenticate._createCartgory)(data).then(function (res) {
          if (res.status === 200) {
            _this6.$message.success('保存成功');
            _this6.load();
            _this6.showDetail = false;
            _this6.categoryName = '';
            _this6.uploadImg = '';
          }
        });
      }
      return false;
    },
    editLevelAction: function editLevelAction(e, index) {
      var category = this.resultData[index];
      this.categoryId = category.id;
      if (category.category_level && category.category_level.length > 0) {
        this.categoryLevel = category.category_level;
      } else {
        this.categoryLevel = [{
          id: 0,
          level_name: ''
        }];
      }
      this.showEditLevelDetail = true;
    },
    createLevel: function createLevel() {
      this.categoryLevel = this.categoryLevel.concat([{
        id: 0,
        level_name: ''
      }]);
    },
    delLevel: function delLevel(i) {
      if (this.categoryLevel.length > 1) {
        this.categoryLevel.splice(i, 1);
      } else {
        this.categoryLevel = [{
          id: 0,
          level_name: ''
        }];
      }
    },
    saveLevelDetail: function saveLevelDetail() {
      var _this7 = this;
      var categoryLevel = [];
      this.categoryLevel.forEach(function (m) {
        if (m.level_name) {
          categoryLevel.push(m);
        }
      });
      var data = {
        level_json: categoryLevel.length > 0 ? JSON.stringify(categoryLevel) : '',
        category_id: this.categoryId
      };
      (0, _authenticate._setCartgoryTag)(data).then(function (res) {
        if (res.status === 200) {
          _this7.$message.success('操作成功');
          _this7.load();
          _this7.showEditLevelDetail = false;
          _this7.isEdit = false;
        }
      });
    },
    closeDetail: function closeDetail() {
      this.showDetail = false;
      this.categoryId = '';
      this.categoryName = '';
      this.isEdit = false;
      this.uploadImg = '';
    },
    closeEditLevelDetail: function closeEditLevelDetail() {
      this.showEditLevelDetail = false;
    },
    goUpAction: function goUpAction(index) {
      var parent = JSON.parse(JSON.stringify(this.categoryLevel));
      parent.splice(index - 1, 0, parent[index]);
      parent.splice(index + 1, 1);
      this.categoryLevel = parent;
    }
  }
};