"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _throttle = _interopRequireDefault(require("lodash/throttle"));
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ElTableVirtualScroll',
  props: {
    data: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      default: 60
    },
    buffer: {
      type: Number,
      default: 500
    },
    keyProp: {
      type: String,
      default: 'id'
    },
    throttleTime: {
      type: Number,
      default: 100
    }
  },
  data: function data() {
    return {
      sizes: {} // 尺寸映射（依赖响应式）
    };
  },
  computed: {
    // 计算出每个item（的key值）到滚动容器顶部的距离
    offsetMap: function offsetMap(_ref) {
      var keyProp = _ref.keyProp,
        height = _ref.height,
        sizes = _ref.sizes,
        data = _ref.data;
      var res = {};
      var total = 0;
      for (var i = 0; i < data.length; i++) {
        var key = data[i][keyProp];
        res[key] = total;
        var curSize = sizes[key];
        var size = typeof curSize === 'number' ? curSize : height;
        total += size;
      }
      return res;
    }
  },
  watch: {
    data: function data() {
      this.update();
    }
  },
  created: function created() {
    var _this = this;
    this.$nextTick(function () {
      console.log(123);
      _this.initData();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (this.scroller) {
      this.scroller.removeEventListener('scroll', this.onScroll);
      window.removeEventListener('resize', this.onScroll);
    }
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this2 = this;
      // 可视范围内显示数据
      this.renderData = [];
      // 页面可视范围顶端、底部
      this.top = undefined;
      this.bottom = undefined;
      // 截取页面可视范围内显示数据的开始和结尾索引
      this.start = 0;
      this.end = undefined;
      this.scroller = this.$el.querySelector('.el-table__body-wrapper');

      // 初次执行
      setTimeout(function () {
        _this2.handleScroll();
      }, 100);

      // 监听事件
      this.onScroll = (0, _throttle.default)(this.handleScroll, this.throttleTime);
      this.scroller.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.onScroll);
    },
    // 更新尺寸（高度）
    updateSizes: function updateSizes() {
      var _this3 = this;
      var rows = this.$el.querySelectorAll('.el-table__body > tbody > .el-table__row');
      Array.from(rows).forEach(function (row, index) {
        var item = _this3.renderData[index];
        if (!item) return;
        var key = item[_this3.keyProp];
        var offsetHeight = row.offsetHeight;
        if (_this3.sizes[key] !== offsetHeight) {
          _this3.$set(_this3.sizes, key, offsetHeight);
        }
      });
    },
    // 处理滚动事件
    handleScroll: function handleScroll() {
      var shouldUpdate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      // 更新当前尺寸（高度）
      this.updateSizes();
      // 计算renderData
      this.calcRenderData();
      // 计算位置
      this.calcPosition();
      shouldUpdate && this.updatePosition();
      // 触发事件
      this.$emit('change', this.renderData, this.start, this.end);
    },
    // 获取某条数据offsetTop
    getOffsetTop: function getOffsetTop(index) {
      var item = this.data[index];
      if (item) {
        return this.offsetMap[item[this.keyProp]] || 0;
      }
      return 0;
    },
    // 获取某条数据的尺寸
    getSize: function getSize(index) {
      var item = this.data[index];
      if (item) {
        var key = item[this.keyProp];
        return this.sizes[key] || this.height;
      }
      return this.height;
    },
    // 计算只在视图上渲染的数据
    calcRenderData: function calcRenderData() {
      var scroller = this.scroller,
        data = this.data,
        buffer = this.buffer;
      // 计算可视范围顶部、底部
      var top = scroller.scrollTop - buffer;
      var bottom = scroller.scrollTop + scroller.offsetHeight + buffer;

      // 二分法计算可视范围内的开始的第一个内容
      var l = 0;
      var r = data.length - 1;
      var mid = 0;
      while (l <= r) {
        mid = Math.floor((l + r) / 2);
        var midVal = this.getOffsetTop(mid);
        if (midVal < top) {
          var midNextVal = this.getOffsetTop(mid + 1);
          if (midNextVal > top) break;
          l = mid + 1;
        } else {
          r = mid - 1;
        }
      }

      // 计算渲染内容的开始、结束索引
      var start = mid;
      var end = data.length - 1;
      for (var i = start + 1; i < data.length; i++) {
        var offsetTop = this.getOffsetTop(i);
        if (offsetTop >= bottom) {
          end = i;
          break;
        }
      }

      // 开始索引始终保持偶数，如果为奇数，则加1使其保持偶数【确保表格行的偶数数一致，不会导致斑马纹乱序显示】
      if (start % 2) {
        start = start - 1;
      }
      // console.log(start, end, 'start end')

      this.top = top;
      this.bottom = bottom;
      this.start = start;
      this.end = end;
      this.renderData = data.slice(start, end + 1);
    },
    // 计算位置
    calcPosition: function calcPosition() {
      var _this4 = this;
      var last = this.data.length - 1;
      // 计算内容总高度
      var wrapHeight = this.getOffsetTop(last) + this.getSize(last);
      // 计算当前滚动位置需要撑起的高度
      var offsetTop = this.getOffsetTop(this.start);

      // 设置dom位置
      var classNames = ['.el-table__body-wrapper', '.el-table__fixed-right .el-table__fixed-body-wrapper', '.el-table__fixed .el-table__fixed-body-wrapper'];
      classNames.forEach(function (className) {
        var el = _this4.$el.querySelector(className);
        if (!el) return;

        // 创建wrapEl、innerEl
        if (!el.wrapEl) {
          var wrapEl = document.createElement('div');
          var innerEl = document.createElement('div');
          wrapEl.appendChild(innerEl);
          innerEl.appendChild(el.children[0]);
          el.insertBefore(wrapEl, el.firstChild);
          el.wrapEl = wrapEl;
          el.innerEl = innerEl;
        }
        if (el.wrapEl) {
          // 设置高度
          el.wrapEl.style.height = wrapHeight + 'px';
          // 设置transform撑起高度
          // el.innerEl.style.transform = `translateY(${offsetTop}px)`;
          // 设置paddingTop撑起高度
          el.innerEl.style.paddingTop = "".concat(offsetTop, "px");
        }
      });
    },
    // 空闲时更新位置
    updatePosition: function updatePosition() {
      var _this5 = this;
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        _this5.timer && clearTimeout(_this5.timer);
        // 传入false，避免一直循环调用
        _this5.handleScroll(false);
      }, this.throttleTime + 10);
    },
    // 【外部调用】更新
    update: function update() {
      console.log('update');
      this.handleScroll();
    },
    // 【外部调用】滚动到第几行
    scrollTo: function scrollTo(index) {
      var _this6 = this;
      var stop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var item = this.data[index];
      if (item && this.scroller) {
        this.updateSizes();
        this.calcRenderData();
        this.$nextTick(function () {
          var offsetTop = _this6.getOffsetTop(index);
          _this6.scroller.scrollTop = offsetTop;

          // 调用两次scrollTo，第一次滚动时，如果表格行初次渲染高度发生变化时，会导致滚动位置有偏差，此时需要第二次执行滚动，确保滚动位置无误
          if (!stop) {
            setTimeout(function () {
              _this6.scrollTo(index, true);
            }, 50);
          }
        });
      }
    },
    // 【外部调用】重置
    reset: function reset() {
      this.sizes = {};
      this.scrollTo(0, false);
    }
  }
};