"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _basic = require("@/api/basic");
var _contract = require("@/api/contract");
var _system = require("@/api/system");
var _setting = require("@/api/setting");
var _user = require("@/api/user");
var _settings = require("@/settings");
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Inventory',
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      unitconfig: {
        1: '(元/件)',
        2: '%'
      },
      configflag: false,
      showconfig: false,
      logisticsName: 'refund',
      id: 0,
      type: '',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        username: '',
        type: '',
        date: '',
        page: 1,
        limit: 20,
        receipt_sn: '',
        order_type: '',
        keyword: '',
        status: ''
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      selectionList: [],
      tableFromLog: {
        page: 1,
        limit: 20
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      invoiceInfo: {},
      invoiceData: {
        number: '',
        mark: ''
      },
      inventoryConfig: {
        switch: {
          other_price_switch: 0,
          sold_price_switch: 0,
          unsold_price_switch: 0
        },
        price: {
          other_price: [{
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }],
          sold_price: [{
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }],
          unsold_price: [{
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }]
        }
      },
      inventoryDetailAll: {
        entrustOrderProduct: []
      },
      inventoryDetailFlag: false,
      lastAuction: [],
      lastAuctionId: '',
      formvisible: {
        delivery_type: '1',
        entrust_contact_name: '',
        entrust_contact_phone: '',
        contact_address: ''
      },
      showflag: false,
      searchQuery: {},
      expressList: [],
      searchAdminarr: [],
      adminarr: [],
      uid: '',
      showdetail: false,
      showpreview: false,
      html_content: '',
      contactImg: '',
      contactUploadId: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    // this.getList(1);
    this.getconfig();
    (0, _system.expressListApi)().then(function (res) {
      _this.expressList = res.data;
    });
    this.getadminList();
    (0, _contract.getLastAuctionApi)().then(function (res) {
      _this.lastAuction = res.data.list;
      if (localStorage.getItem('lastAuctionId')) {
        _this.lastAuctionId = Number(localStorage.getItem('lastAuctionId'));
        _this.venueName = localStorage.getItem('venueName');
        _this.getList(1);
      } else if (res.data.list[0]) {
        _this.lastAuctionId = res.data.list[0].venue_id;
        _this.venueName = res.data.list[0].title;
        _this.getList(1);
      }
    }).catch(function (res) {
      //
    });
  },
  methods: {
    bindPrint: function bindPrint() {
      (0, _printJs.default)({
        printable: 'printContent',
        type: 'html',
        header: null,
        targetStyles: ['*'],
        style: 'body {font-size: 0.4em;font-weight: bold; line-height:0.82;margin:0;padding:0;}',
        font_size: 'llllll'
      });
    },
    getdetail: function getdetail(id, type) {
      var _this2 = this;
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      this.html_content = '';
      this.contactImg = '';
      this.contactUploadId = '';
      (0, _contract.contractV2DetailApi)(id).then(function (res) {
        if (res.data.template_id && !type && res.data.content) {
          _this2.html_content = res.data.content.html_content.replace('width: 0px; height: 0px; overflow: hidden;', '');
          _this2.contactImg = '';
          _this2.contactUploadId = id;
          _this2.showpreview = true;
        } else if (res.data.contract_type == 1) {
          if (!res.data.contract_url) {
            _this2.$message.error('该合同未上传附件');
            return false;
          }
          _this2.contactImg = res.data.contract_url ? res.data.contract_url.split(',') : [];
          _this2.showpreview = true;
        } else if (res.data.content) {
          _this2.html_content = res.data.content.html_content.replace('width: 0px; height: 0px; overflow: hidden;', '');
          _this2.contactImg = '';
          _this2.showpreview = true;
        }
      });
    },
    onDetails: function onDetails(row) {
      if (row.uid === 8888) {
        // this.editOrderAction(row);
        return false;
      }
      this.uid = row.uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    getadminList: function getadminList() {
      var _this3 = this;
      (0, _setting.adminListApi)({
        page: 1,
        limit: 100
      }).then(function (res) {
        _this3.searchAdminarr = res.data.list.map(function (item) {
          return {
            label: item.real_name,
            value: item.merchant_admin_id
          };
        });
        _this3.adminarr = res.data.list;
      });
    },
    onCancel: function onCancel(row) {
      var _this4 = this;
      console.log(row);
      (0, _contract.cancelRefundApi)({
        check_auction_ids: row.id
      }).then(function (res) {
        _this4.$message.success('操作成功');
        _this4.getList(1);
      });
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    logisticsOrder: function logisticsOrder() {
      if (!this.multipleSelection.length) {
        this.$message({
          message: '请选择拍品',
          type: 'warning'
        });
        return;
      }
      var row = this.multipleSelection[0];
      var arr = this.multipleSelection.filter(function (item) {
        return item.entrustUser.uid != row.entrustUser.uid;
      });
      if (arr.length > 0) {
        this.$message({
          message: '请选择同一委托人',
          type: 'warning'
        });
        return;
      }
      var d = {};
      if (row.entrustUser && row.entrustUser.entrust) {
        d = JSON.parse(JSON.stringify(row.entrustUser.entrust));
      }
      var ids = this.multipleSelection.map(function (item) {
        return item.id;
      });
      this.formvisible = {
        delivery_type: '1',
        delivery_name: '顺丰速运',
        entrust_contact_name: d.entrust_contact_name || '',
        entrust_contact_phone: d.entrust_contact_phone || '',
        contact_address: d.contact_address || '',
        id: ids.join(','),
        warehouse_order_id: row.warehouse_order_id,
        erpProduct: JSON.parse(JSON.stringify(row.erpProduct))
      };
      this.row = row;
      this.showflag = true;
    },
    createPushOrder: function createPushOrder() {
      if (!this.multipleSelection.length) {
        this.$message({
          message: '请选择拍品',
          type: 'warning'
        });
        return;
      }
      var _iterator = (0, _createForOfIteratorHelper2.default)(this.multipleSelection),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          if (!item.erpProduct.warehouse_id) {
            this.$message.error('所选货品存在未关联仓库的货品');
            return false;
          }
          if (!item.contract_id) {
            this.$message.error('所选货品存在未关联合同的货品');
            return false;
          }
          if (!item.delivery_type) {
            this.$message.error('所选货品存在未设置物流方式的货品');
            return false;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      var ids = [];
      this.multipleSelection.forEach(function (item) {
        ids.push(item.id);
      });
      this.$router.push({
        path: '/merchant/contract/returntooutorder',
        query: {
          ids: ids.join(',')
        }
      });
    },
    changeAuctionId: function changeAuctionId() {
      var _this5 = this;
      // 查找lastAuction里的id
      this.lastAuction.forEach(function (item) {
        if (item.venue_id == _this5.lastAuctionId) {
          _this5.venueName = item.title;
        }
      });
      localStorage.setItem('lastAuctionId', this.lastAuctionId);
      localStorage.setItem('venueName', this.venueName);
      this.getList(1);
    },
    onSubmit: function onSubmit() {
      var _this6 = this;
      if (this.formvisible.delivery_type == '1' || this.formvisible.delivery_type == '4') {
        if (this.formvisible.delivery_type == '4' && !this.formvisible.delivery_name) {
          this.$message({
            message: '请选择员工',
            type: 'warning'
          });
          return;
        }
        if (!this.formvisible.entrust_contact_name) {
          this.$message({
            message: '请填写姓名',
            type: 'warning'
          });
          return;
        }
        if (!this.formvisible.entrust_contact_phone) {
          this.$message({
            message: '请填写电话',
            type: 'warning'
          });
          return;
        }
        if (!this.formvisible.contact_address) {
          this.$message({
            message: '请填写地址',
            type: 'warning'
          });
          return;
        }
      }
      (0, _contract.updateDeliveryApi)(this.formvisible).then(function (res) {
        _this6.$message({
          message: '修改成功',
          type: 'success'
        });
        _this6.multipleSelection = [];
        if (_this6.$refs.multipleSelection) _this6.$refs.multipleSelection.clearSelection();
        _this6.getList();
        _this6.showflag = false;
      });
    },
    editstatic: function editstatic(row) {
      var d = {};
      if (row.entrustUser && row.entrustUser.entrust) {
        d = JSON.parse(JSON.stringify(row.entrustUser.entrust));
      }
      this.formvisible = {
        delivery_type: '1',
        delivery_name: '顺丰速运',
        entrust_contact_name: d.entrust_contact_name || '',
        entrust_contact_phone: d.entrust_contact_phone || '',
        contact_address: d.contact_address || '',
        id: row.id,
        warehouse_order_id: row.warehouse_order_id,
        erpProduct: JSON.parse(JSON.stringify(row.erpProduct))
      };
      this.row = row;
      this.showflag = true;
      // getUserDetail(row.entrustUser.uid).then((res) => {
      //   console.log(res);
      //   if (res.data.userVerify) {
      //     this.formvisible.entrust_contact_name = res.data.userVerify.real_name;
      //   }
      //   this.formvisible.entrust_contact_phone = res.data.phone;
      //   // this.formvisible.contact_address = res.data.userAddress || "";
      //   this.formvisible.contact_address = res.data.userAddress
      //     ? `${res.data.userAddress.province}${res.data.userAddress.city}${res.data.userAddress.district}${res.data.userAddress.detail}` : row.contact_address;
      // });
    },
    getconfig: function getconfig() {
      var _this7 = this;
      (0, _basic.contractConfig)().then(function (res) {
        console.log(res);
        if (res.status == 200) {
          if (res.data.price && res.data.price.sold_price && res.data.price.sold_price.length > 1) {
            _this7.inventoryConfig = res.data;
            _this7.configflag = true;
            _this7.$forceUpdate();
          }
        }
      });
    },
    saveconfig: function saveconfig() {
      var _this8 = this;
      (0, _basic.editcontractConfig)({
        entrust_order_field_config: JSON.stringify(this.inventoryConfig)
      }).then(function (res) {
        if (res.status == 200) {
          _this8.showconfig = false;
          _this8.$message.success('保存成功');
        }
      });
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: 'OrderList',
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList('');
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList('');
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this9 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _contract.contractEntrustRefundApi)(this.tableFrom).then(function (res) {
        _this9.orderChartType = res.data.stat;
        _this9.tableData.data = res.data.list;
        _this9.tableData.total = res.data.count;
        _this9.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this9.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    getDeliveryAction: function getDeliveryAction() {},
    closeAction: function closeAction() {
      this.multipleSelection = [];
      if (this.$refs.multipleSelection) this.$refs.multipleSelection.clearSelection();
    }
  }
};