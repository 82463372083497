var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "userbox" },
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _vm.$route.query.is_entrust
              ? _c(
                  "el-dropdown",
                  { attrs: { type: "primary", placement: "bottom" } },
                  [
                    _c(
                      "el-button",
                      { attrs: { type: "primary", size: "small" } },
                      [_vm._v("导入")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "uploadImg",
                                staticStyle: { height: "100%" },
                                attrs: {
                                  action: _vm.fileUrl,
                                  "on-success": _vm.handleSuccess,
                                  headers: _vm.myHeaders,
                                  data: { import_type: "import_user_entrust" },
                                  "show-file-list": false,
                                  name: "excel",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "ft12",
                                    attrs: { size: "text", type: "primary" },
                                  },
                                  [_vm._v("上传文件")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: { click: _vm.downloadTemplate },
                              },
                              [_vm._v("下载模板")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "merchantCreateUser",
                    expression: "'merchantCreateUser'",
                  },
                ],
                attrs: {
                  type: "primary",
                  size: "small",
                  icon: "el-icon-circle-plus-outline",
                },
                on: { click: _vm.onAdd },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$route.query.is_entrust ? "新增委托方" : "新增用户"
                  )
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 175px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "userRef",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                height: "100%",
                data: _vm.tableData.data,
                size: "small",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "30", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            staticClass: "tabPop",
                            attrs: {
                              placement: "top-start",
                              width: "100",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "spBlock onHand",
                                  class: { check: _vm.chkName === "dan" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandle("dan", scope.$index)
                                    },
                                  },
                                },
                                [_vm._v("选中本页")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", {
                              attrs: {
                                slot: "reference",
                                value:
                                  (_vm.chkName === "dan" &&
                                    _vm.checkedPage.indexOf(_vm.userFrom.page) >
                                      -1) ||
                                  _vm.chkName === "duo",
                              },
                              on: { change: _vm.changeType },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            value:
                              _vm.checkedIds.indexOf(scope.row.uid) > -1 ||
                              (_vm.chkName === "duo" &&
                                _vm.noChecked.indexOf(scope.row.uid) === -1),
                          },
                          on: {
                            change: function (v) {
                              return _vm.changeOne(v, scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "ID",
                  width: "60",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "line1" }, [
                          _vm._v(_vm._s(row.mer_uid)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "头像",
                  width: "50",
                  prop: "",
                  align: "center",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.avatar
                          ? _c("img", {
                              staticStyle: {
                                width: "25px",
                                height: "25px",
                                "margin-top": "5px",
                                cursor: "pointer",
                              },
                              attrs: {
                                src: scope.row.avatar
                                  ? scope.row.avatar
                                  : _vm.moren,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onDetails(scope.row)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "用户昵称",
                  permission: "merchantUserList",
                  "permission-column": "list.mark",
                  width: "180",
                  prop: "",
                  "search-key": "nickname",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "tabHead" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#6699dd",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onDetails(row)
                                },
                              },
                            },
                            [
                              _vm._v(" " + _vm._s(row.nickname) + " "),
                              row.mark
                                ? _c("span", [
                                    _vm._v("(" + _vm._s(row.mark) + ")"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.editMarkAction(row)
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-edit updIcon" })]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "真实姓名",
                  permission: "merchantUserList",
                  "permission-column": "list.real_name",
                  width: "120",
                  prop: "",
                  "search-key": "real_name",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " + _vm._s(row.real_name) + "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "手机号",
                  permission: "merchantUserList",
                  "permission-column": "list.phone",
                  width: "100",
                  prop: "",
                  "search-key": "phone",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " + _vm._s(row.phone) + "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "收货地址",
                  permission: "merchantUserList",
                  "permission-column": "list.userAddressData",
                  width: "200",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return _vm._l(
                        row.userAddressData,
                        function (item, index) {
                          return _c("div", { key: index }, [
                            _vm._v(
                              _vm._s(item.province) +
                                _vm._s(item.city) +
                                _vm._s(item.district) +
                                _vm._s(item.detail)
                            ),
                          ])
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  permission: "merchantUserList",
                  "permission-column": "list.group",
                  label: "分组",
                  width: "100",
                  prop: "",
                  "search-key": "group_id",
                  "search-type": "radio",
                  options: _vm.groupList,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.group
                          ? [_vm._v(_vm._s(row.group.group_name))]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  permission: "merchantUserList",
                  "permission-column": "list.label",
                  prop: "",
                  label: "标签",
                  width: "100",
                  "search-key": "label_id",
                  multiple: "",
                  "search-type": "radio",
                  "search-query": _vm.searchQuery,
                  options: _vm.labelList,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.label
                          ? _c("span", [
                              _vm._v(" " + _vm._s(row.label.join("、"))),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.$route.query.is_entrust
                ? [
                    _c("ytx-table-column", {
                      attrs: {
                        label: "证件类型",
                        permission: "merchantUserList",
                        "permission-column": "list.is_verify",
                        width: "100",
                        prop: "",
                        "search-key": "idcard_type",
                        "search-type": "radio",
                        options: _vm.cardOption,
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.userVerify
                                  ? [
                                      row.userVerify.idcard_type == 1
                                        ? _c("span", [_vm._v("中国")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.userVerify.idcard_type == 2
                                        ? _c("span", [_vm._v("香港")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.userVerify.idcard_type == 3
                                        ? _c("span", [_vm._v("澳门")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.userVerify.idcard_type == 4
                                        ? _c("span", [_vm._v("台湾")])
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4122834906
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "证件号码",
                        permission: "merchantUserList",
                        "permission-column": "list.is_verify",
                        width: "170",
                        prop: "",
                        "search-key": "cardno",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.userVerify
                                  ? [_vm._v(_vm._s(row.userVerify.idcard))]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1832487669
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "国籍",
                        width: "70",
                        prop: "",
                        "search-key": "nationality",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.entrust
                                  ? [_vm._v(_vm._s(row.entrust.nationality))]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        711356438
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "用户类型",
                        width: "100",
                        prop: "",
                        "search-key": "is_company",
                        "search-type": "radio",
                        options: [
                          { label: "企业用户", value: "1" },
                          { label: "个人用户", value: "0" },
                        ],
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      row.is_company == 1
                                        ? "企业用户"
                                        : "个人用户"
                                    ) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3230991170
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "地区",
                        width: "100",
                        prop: "",
                        "search-key": "area",
                        "search-type": "radio",
                        options: _vm.phoneCode,
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.area) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2430457288
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "详细地址",
                        width: "200",
                        prop: "",
                        "show-overflow-tooltip": "",
                        "search-key": "contact_address",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.entrust
                                  ? _c("div", { staticClass: "nowrap" }, [
                                      _vm._v(
                                        _vm._s(row.entrust.contact_address)
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1788811100
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "邮编",
                        width: "100",
                        prop: "",
                        "search-query": _vm.searchQuery,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.entrust
                                  ? [_vm._v(_vm._s(row.entrust.postal_code))]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        579508299
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "微信号",
                        width: "100",
                        prop: "",
                        "search-key": "weixin",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.entrust
                                  ? [_vm._v(_vm._s(row.entrust.weixin))]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3010453640
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "E-mail",
                        width: "140",
                        prop: "",
                        "search-key": "email",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.entrust
                                  ? [_vm._v(_vm._s(row.entrust.email))]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1387012032
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "固定电话",
                        width: "180",
                        prop: "",
                        "search-query": _vm.searchQuery,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.entrust
                                  ? [_vm._v(_vm._s(row.entrust.fixed_phone))]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3109035193
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "传真",
                        width: "100",
                        prop: "",
                        "search-query": _vm.searchQuery,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.entrust
                                  ? [_vm._v(_vm._s(row.entrust.fax))]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2697569555
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "开户银行",
                        width: "200",
                        prop: "",
                        "search-key": "account_bank",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.entrust
                                  ? [_vm._v(_vm._s(row.entrust.account_bank))]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        424969076
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "开户名",
                        width: "200",
                        prop: "",
                        "search-key": "account_name",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.entrust
                                  ? [_vm._v(_vm._s(row.entrust.account_name))]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        648029717
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "银行账号",
                        "min-width": "200",
                        prop: "",
                        "search-key": "account_bank_card_number",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.entrust
                                  ? [
                                      _vm._v(
                                        _vm._s(
                                          row.entrust.account_bank_card_number
                                        )
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        22271203
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "备注",
                        width: "200",
                        prop: "",
                        "search-query": _vm.searchQuery,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.entrust.remark) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        803015724
                      ),
                    }),
                  ]
                : [
                    _c("ytx-table-column", {
                      attrs: {
                        permission: "merchantUserList",
                        "permission-column": "list.is_verify",
                        label: "实名认证",
                        width: "100",
                        prop: "",
                        "search-key": "is_verify",
                        "search-type": "radio",
                        options: [
                          { label: "未认证", value: "0" },
                          { label: "已认证", value: "1" },
                        ],
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.is_verify == 0 ? "-" : "已认证") +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        permission: "merchantUserList",
                        "permission-column": "list.isSalesman",
                        label: "推广员",
                        width: "80",
                        prop: "",
                        "search-key": "is_salesman",
                        "search-type": "radio",
                        options: [
                          { label: "不是", value: "0" },
                          { label: "是", value: "1" },
                        ],
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.is_salesman == 0 ? "-" : "是") +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        permission: "merchantUserList",
                        "permission-column": "list.isMembership",
                        label: "会员卡",
                        width: "80",
                        prop: "",
                        "search-key": "is_membership",
                        "search-type": "radio",
                        options: [
                          { label: "不是", value: "0" },
                          { label: "是", value: "1" },
                        ],
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.is_membership == 0 ? "-" : "是") +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "性别",
                        width: "80",
                        prop: "",
                        "search-key": "sex",
                        "search-type": "radio",
                        options: [
                          { label: "男", value: "0" },
                          { label: "女", value: "1" },
                        ],
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.sex_text) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "委托方",
                        width: "80",
                        prop: "",
                        "search-key": "is_entrust",
                        "search-type": "radio",
                        options: [
                          { label: "不是", value: "0" },
                          { label: "是", value: "1" },
                        ],
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.is_entrust == 0 ? "-" : "是") +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        permission: "merchantUserList",
                        "permission-column": "list.pay_count",
                        label: "有效订单",
                        width: "120",
                        prop: "",
                        "search-key": "paymin",
                        "search-other-key": "paymax",
                        "search-type": "inputPacker",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [_vm._v(_vm._s(row.pay_count))]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "注册渠道",
                        width: "100",
                        prop: "",
                        "search-key": "user_type",
                        "search-type": "radio",
                        options: [
                          { label: "微信小程序", value: "wechat_mini" },
                          { label: "抖音小程序", value: "tiktok_mini" },
                          { label: "浏览器H5", value: "h5" },
                          { label: "公众号H5", value: "wechat_h5" },
                          { label: "安卓APP", value: "android_app" },
                          { label: "苹果APP", value: "ios_app" },
                          { label: "后台创建", value: "mer_create" },
                        ],
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.user_type_text) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        permission: "merchantUserList",
                        "permission-column": "list.spread",
                        label: "邀请人",
                        width: "80",
                        prop: "",
                        "search-key": "spread_name",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.spread
                                ? [
                                    _vm._v(
                                      _vm._s(
                                        row.spread.real_name
                                          ? row.spread.real_name
                                          : row.spread.nickname
                                      )
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        permission: "merchantUserList",
                        "permission-column": "list.pay_price",
                        label: "消费金额",
                        width: "100",
                        prop: "",
                        "search-key": "primin",
                        "search-other-key": "primax",
                        "search-type": "inputPacker",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [_vm._v(_vm._s(row.pay_price))]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "链账户",
                        width: "80",
                        prop: "",
                        "search-key": "nft_account",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.nft_account) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        prop: "",
                        label: "最后访问",
                        width: "160",
                        "search-key": "last_time",
                        "search-type": "date",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.last_time) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        prop: "",
                        label: "注册时间",
                        width: "160",
                        "search-key": "create_time",
                        "search-type": "date",
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.create_time) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "状态",
                        width: "80",
                        prop: "",
                        "search-key": "status",
                        "search-type": "radio",
                        options: [
                          { label: "封禁", value: "0" },
                          { label: "正常", value: "1" },
                          { label: "功能封禁", value: "2" },
                        ],
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.status == 0
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: { size: "small", type: "danger" },
                                    },
                                    [_vm._v(_vm._s(row.status_text))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == 1
                                ? _c(
                                    "el-tag",
                                    { attrs: { size: "small", type: "info" } },
                                    [_vm._v(_vm._s(row.status_text))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.status == 2
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: { size: "small", type: "warning" },
                                    },
                                    [_vm._v(_vm._s(row.status_text))]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: {
                        label: "创建",
                        "min-width": "80",
                        prop: "",
                        "search-query": _vm.searchQuery,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    row.createAdmin
                                      ? row.createAdmin.real_name
                                      : "系统"
                                  ) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "20",
                attrs: {
                  label: "",
                  width: "36",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isSearch
                          ? [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "font-size": "14px" },
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clearsearch("all")
                                    },
                                  },
                                },
                                [_vm._v("清除")]
                              ),
                            ]
                          : _c(
                              "TableRightBtn",
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: "merchantEditUser",
                                        expression: "'merchantEditUser'",
                                      },
                                    ],
                                    staticStyle: { "font-size": "14px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onUpdUser(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "commonedit",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v(
                                      "编辑" +
                                        _vm._s(
                                          scope.row.is_entrust
                                            ? "委托方"
                                            : "用户"
                                        )
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: "merchantUserChangeLabel",
                                        expression: "'merchantUserChangeLabel'",
                                      },
                                    ],
                                    staticStyle: { "font-size": "14px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setLabel(scope.row.uid)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "kehu-set",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("设置标签"),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "template",
                                  { slot: "pop_btn" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "mechantUserDisable",
                                            expression: "'mechantUserDisable'",
                                          },
                                        ],
                                        staticStyle: { "font-size": "14px" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showUserProhibitSetAction(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "kehu-jinyong",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("禁用设置"),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    scope.row.status
                                      ? _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "permission",
                                                rawName: "v-permission",
                                                value: "merchantChangeIntegral",
                                                expression:
                                                  "'merchantChangeIntegral'",
                                              },
                                            ],
                                            staticStyle: {
                                              "font-size": "14px",
                                            },
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sendIntegralAction(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "kehu-jifen",
                                                "class-name": "blue",
                                              },
                                            }),
                                            _vm._v("积分变动"),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "mechantUserInvite",
                                            expression: "'mechantUserInvite'",
                                          },
                                        ],
                                        staticStyle: { "font-size": "14px" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.xcxShareAction(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "kehu-yaoqing",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("邀请海报"),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-b orderFoot" },
        [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c("span", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.allCheck
                        ? "已选择  " + _vm.tableData.total + "  条"
                        : "已选择  " + _vm.checkedIds.length + "  条"
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantUserChangeLabel",
                      expression: "'merchantUserChangeLabel'",
                    },
                  ],
                  staticStyle: { "margin-left": "4px" },
                  attrs: {
                    disabled: this.checkedIds.length == 0,
                    type: "primary",
                    size: "small",
                  },
                  on: { click: _vm.onOpenLabel },
                },
                [_vm._v("设置标签")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantUserChangeGroup",
                      expression: "'merchantUserChangeGroup'",
                    },
                  ],
                  attrs: {
                    disabled: this.checkedIds.length == 0,
                    type: "primary",
                    size: "small",
                  },
                  on: { click: _vm.batchGroup },
                },
                [_vm._v("设置分组")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantChangeIntegral",
                      expression: "'merchantChangeIntegral'",
                    },
                  ],
                  attrs: {
                    disabled: this.checkedIds.length == 0,
                    type: "primary",
                    size: "small",
                  },
                  on: { click: _vm.sendIntegralAllAction },
                },
                [_vm._v("积分变动")]
              ),
              _vm._v(" "),
              _vm.$route.query.is_entrust
                ? [
                    _c(
                      "el-dropdown",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value:
                              "merchantSystemExcelExport:merchant_user_entrust",
                            expression:
                              "'merchantSystemExcelExport:merchant_user_entrust'",
                          },
                        ],
                        attrs: { type: "primary", placement: "top" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", size: "small" },
                          },
                          [_vm._v("导出")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.exportOrder },
                                  },
                                  [_vm._v("导出表格")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.getExportFileList },
                                  },
                                  [_vm._v("导出记录")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "el-dropdown",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "merchantSystemExcelExport:merchant_user",
                            expression:
                              "'merchantSystemExcelExport:merchant_user'",
                          },
                        ],
                        attrs: { type: "primary", placement: "top" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", size: "small" },
                          },
                          [_vm._v("导出")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.exportOrder },
                                  },
                                  [_vm._v("导出表格")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.getExportFileList },
                                  },
                                  [_vm._v("导出记录")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.userFrom.limit,
              "current-page": _vm.userFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visibleCoupon
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "优惠券列表",
                visible: _vm.visibleCoupon,
                width: "1000px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleCoupon = $event
                },
              },
            },
            [
              _vm.visibleCoupon
                ? _c("coupon-List", {
                    ref: "couponList",
                    attrs: {
                      couponForm: _vm.couponForm,
                      checkedIds: _vm.checkedIds,
                      allCheck: _vm.allCheck,
                      userFrom: _vm.userFrom,
                    },
                    on: { sendSuccess: _vm.sendSuccess },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleIntegral
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "积分变动",
                visible: _vm.visibleIntegral,
                "close-on-click-modal": false,
                width: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleIntegral = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "item-flex-center" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.sendIntegralType,
                        callback: function ($$v) {
                          _vm.sendIntegralType = $$v
                        },
                        expression: "sendIntegralType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("赠送积分"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("扣减积分"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c("el-input-number", {
                    attrs: {
                      controls: false,
                      placeholder: "积分数量",
                      min: 0,
                      "step-strictly": true,
                    },
                    model: {
                      value: _vm.sendIntegralVal,
                      callback: function ($$v) {
                        _vm.sendIntegralVal = $$v
                      },
                      expression: "sendIntegralVal",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.visibleIntegral = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.sendIntegralConfirm },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.labelVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "设置标签",
                visible: _vm.labelVisible,
                "close-on-click-modal": false,
                width: "300px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.labelVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  attrs: { "label-width": "80px" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户标签：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { multiple: "", placeholder: "请选择" },
                          model: {
                            value: _vm.labelFrom.label_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.labelFrom, "label_id", $$v)
                            },
                            expression: "labelFrom.label_id",
                          },
                        },
                        _vm._l(_vm.labelList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.labelVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.checkLabelSub },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户禁用设置",
            visible: _vm.userProhibitSet.show,
            "close-on-click-modal": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.userProhibitSet, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.userProhibitSet.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.userProhibitSet, "status", $$v)
                    },
                    expression: "userProhibitSet.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("不封禁")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("账号封禁")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("功能封禁")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-checkbox-group",
                {
                  attrs: { disabled: _vm.userProhibitSet.status != 2 },
                  model: {
                    value: _vm.userProhibitSet.ban_function,
                    callback: function ($$v) {
                      _vm.$set(_vm.userProhibitSet, "ban_function", $$v)
                    },
                    expression: "userProhibitSet.ban_function",
                  },
                },
                [
                  _c("div", { staticClass: "flex align-items-c" }, [
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-checkbox", { attrs: { label: "buy_nft" } }, [
                          _vm._v("购买数字藏品"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-checkbox", { attrs: { label: "buy_nft_box" } }, [
                          _vm._v("购买数字盲盒"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-checkbox", { attrs: { label: "buy_product" } }, [
                          _vm._v("购买商品"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex align-items-c mt10" }, [
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-checkbox", { attrs: { label: "buy_box" } }, [
                          _vm._v("购买实物盲盒"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-checkbox", { attrs: { label: "transfers" } }, [
                          _vm._v("转赠"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c(
                          "el-checkbox",
                          { attrs: { label: "sell_consign" } },
                          [_vm._v("寄售售出")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex align-items-c mt10" }, [
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-checkbox", { attrs: { label: "buy_consign" } }, [
                          _vm._v("寄售购买"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-checkbox", { attrs: { label: "auction_bid" } }, [
                          _vm._v("拍卖出价"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-one" }),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.userProhibitSet.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.sendUserProhibitSet },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImg,
            "before-close": function () {
              return (_vm.showImg = false)
            },
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imgUrl },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("AddUserCom", { ref: "userREf", on: { onRefresh: _vm.getList } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }