"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _basic = require("@/api/basic");
var _contract = require("@/api/contract");
var _settings = require("@/settings");
var _warehouse = require("@/api/warehouse");
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _SettlementPrintAccountNew = _interopRequireDefault(require("@/components/order/SettlementPrintAccountNew"));
var _AfterNoticePrint = _interopRequireDefault(require("@/components/order/AfterNoticePrint"));
var _system = require("@/api/system");
var _userdetail = _interopRequireDefault(require("../../views/user/list/userdetail"));
var _index = require("@/utils/index");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import SettlementPrint from "@/components/order/SettlementPrintAccount";
var _default = exports.default = {
  name: "Inventoryfinance",
  components: {
    fileList: _fileList.default,
    SettlementPrint: _SettlementPrintAccountNew.default,
    AfterNoticePrint: _AfterNoticePrint.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      orderno: "",
      showcert: false,
      formatPriceNumber: _index.formatPriceNumber,
      isNo: true,
      cert: {
        image: "",
        type: ""
      },
      checkjson: {
        addprice: "",
        cutprice: "",
        remark: ""
      },
      roterPre: _settings.roterPre,
      unitconfig: {
        1: "(元/件)",
        2: "%"
      },
      configflag: false,
      showconfig: false,
      logisticsName: "refund",
      id: 0,
      type: "",
      tableData: {
        data: [],
        total: 0,
        rdata: []
      },
      listLoading: true,
      tableFrom: {
        username: "",
        type: "",
        date: "",
        page: 1,
        limit: 20,
        receipt_sn: "",
        order_type: "",
        keyword: "",
        status: "",
        deal_status: "",
        check_status: "",
        refund_status: ""
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      selectionList: [],
      tableFromLog: {
        page: 1,
        limit: 20
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      invoiceInfo: {},
      invoiceData: {
        number: "",
        mark: ""
      },
      inventoryConfig: {
        switch: {
          other_price_switch: 0,
          sold_price_switch: 0,
          unsold_price_switch: 0
        },
        price: {
          other_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }],
          sold_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }],
          unsold_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }]
        }
      },
      inventoryDetailAll: {
        entrustOrderProduct: []
      },
      inventoryDetailFlag: false,
      showreturn: false,
      showdetail: false,
      reviewdetail: {},
      mannagename: "",
      changeOrder: [],
      editInventoryDetailFlag: false,
      editInventoryDetailAll: {
        entrustOrderProduct: []
      },
      lastAuction: [],
      lastAuctionId: "",
      searchType: 'venue_id',
      timeValue: [],
      showcheck: false,
      proptype: "1",
      propmoney: "0",
      propmark: "",
      checkstatus: 1,
      venueName: "",
      checkoneJson: {
        auction: [],
        check_auction_data: [],
        entrustUser: {},
        account: "",
        settlement_amount: ''
      },
      searchQuery: {},
      row: '',
      uid: '',
      entrust_check_order_people_number: 1,
      editNowPrice: {
        show: false
      }
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    this.getconfig();
    // this.getList(1);
    (0, _contract.getLastAuctionApi)().then(function (res) {
      _this2.lastAuction = res.data.list;
      if (localStorage.getItem('lastAuctionId')) {
        _this2.lastAuctionId = Number(localStorage.getItem('lastAuctionId'));
        _this2.venueName = localStorage.getItem('venueName');
        _this2.getList(1);
      } else if (res.data.list[0]) {
        _this2.lastAuctionId = res.data.list[0].venue_id;
        _this2.venueName = res.data.list[0].title;
        _this2.getList(1);
      }
    }).catch(function (res) {
      //
    });
    (0, _system.getConfigClassKeys)('entrust_check_order_config').then(function (res) {
      console.log(res);
      _this2.entrust_check_order_people_number = res.data.entrust_check_order_people_number;
    });
  },
  methods: {
    editNowPriceAction: function editNowPriceAction(row, index) {
      var price = undefined;
      if (Number(row.auction_now_price)) {
        price = Number(row.auction_now_price);
      } else if (row.auction.now_price) {
        price = Number(row.auction.now_price);
      }
      this.editNowPrice = {
        show: true,
        data: price,
        row: row,
        index: index
      };
    },
    saveAction: function saveAction() {
      var _this3 = this;
      if (!this.editNowPrice.data) {
        this.$message.error("请输入落槌价");
        return;
      }
      this.$confirm("\u786E\u8BA4\u4FEE\u6539\u843D\u69CC\u4EF7?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _contract.updateAuctionNowPriceApi)({
          check_auction_id: _this3.editNowPrice.row.id,
          auction_now_price: _this3.editNowPrice.data
        }).then(function (res) {
          if (res.status == 200) {
            _this3.$message.success("修改成功");
            _this3.checkoneJson.check_auction_data[_this3.editNowPrice.index].auction_now_price = _this3.editNowPrice.data;
            _this3.checkoneJson.check_auction_data[_this3.editNowPrice.index].price_data.forEach(function (m, i) {
              m.forEach(function (n, j) {
                if (n.unit == 2 && n.number) {
                  _this3.onUnitChange(_this3.editNowPrice.index, i, j);
                }
              });
            });
            _this3.editNowPrice.show = false;
            _this3.getList(1);
          }
        });
      });
    },
    onUnitChange: function onUnitChange(tabInd, rowInd, index) {
      var row = this.checkoneJson.check_auction_data[tabInd];
      var num = Number(row.price_data[rowInd][index].number);
      var money = 0;
      if (Number(row.auction_now_price)) {
        money = Number(row.auction_now_price);
      } else if (row.auction.now_price) {
        money = Number(row.auction.now_price);
      }
      row.price_data[rowInd][index].deduction_amount = money / 100 * num;
      var sum = 0;
      if (row.auction_status <= 4) {
        row.price_data[1].forEach(function (item) {
          if (item.deduction_amount) sum += Number(item.deduction_amount);
        });
      } else {
        row.price_data[0].forEach(function (item) {
          console.log(item);
          if (item.deduction_amount) sum += Number(item.deduction_amount);
        });
      }
      row.price_data[2].forEach(function (item) {
        if (item.deduction_amount) sum += Number(item.deduction_amount);
      });
      row.settlement_amount = (money - sum).toFixed(2);
      this.$set(this.checkoneJson.check_auction_data, tabInd, row);
      this.handData(this.checkoneJson.check_auction_data);
      this.countmoney();
    },
    onAmountChange: function onAmountChange(tabInd, rowInd, index) {
      var row = this.checkoneJson.check_auction_data[tabInd];
      var acount = Number(row.price_data[rowInd][index].deduction_amount);
      var money = 0;
      if (Number(row.auction_now_price)) {
        money = Number(row.auction_now_price);
      } else if (row.auction.now_price) {
        money = Number(row.auction.now_price);
      }
      row.price_data[rowInd][index].number = ((acount / money * 100).toFixed(2) + '').replace('.00', '');
      var sum = 0;
      if (row.auction_status <= 4) {
        row.price_data[1].forEach(function (item) {
          if (item.deduction_amount) sum += Number(item.deduction_amount);
        });
      } else {
        row.price_data[0].forEach(function (item) {
          console.log(item);
          if (item.deduction_amount) sum += Number(item.deduction_amount);
        });
      }
      row.price_data[2].forEach(function (item) {
        if (item.deduction_amount) sum += Number(item.deduction_amount);
      });
      row.settlement_amount = (money - sum).toFixed(2);
      this.$set(this.checkoneJson.check_auction_data, tabInd, row);
      this.handData(this.checkoneJson.check_auction_data);
      this.countmoney();
    },
    onDetails: function onDetails(row) {
      if (!row || row.uid === 8888) {
        // this.editOrderAction(row);
        return false;
      }
      this.row = row;
      this.uid = row.uid;
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    changecert: function changecert(row, type) {
      this.row = row;
      this.cert.type = type;
      this.showcert = true;
    },
    cutgoods: function cutgoods(index) {
      this.inventoryDetailAll.entrustOrderProductCheck.splice(index, 1);
      var count = 0;
      this.inventoryDetailAll.entrustOrderProductCheck.forEach(function (item) {
        count += item.should_pay_price;
      });
      this.inventoryDetailAll.count = count;
      if (count > 0) {
        this.checkjson.addprice = count.toFixed(2);
      } else {
        this.checkjson.cutprice = Math.abs(count).toFixed(2);
      }
    },
    getconfig: function getconfig() {
      var _this4 = this;
      (0, _basic.contractConfig)().then(function (res) {
        console.log(res);
        if (res.status == 200) {
          if (res.data.price && res.data.price.sold_price && res.data.price.sold_price.length > 1) {
            _this4.inventoryConfig = res.data;
            _this4.configflag = true;
            _this4.$forceUpdate();
          }
        }
      });
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: "OrderList",
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      this.changeOrder = val;
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList("");
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList("");
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.changestatus();
      // this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num, cb) {
      var _this5 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      if (this.searchType == 'orderTime') {
        delete this.tableFrom.venue_id;
        this.tableFrom.store_order_pay_date = this.timeValue ? this.timeValue.join('-') : '';
        this.tableFrom.store_order_status = 1;
      } else {
        this.tableFrom.venue_id = this.lastAuctionId;
      }
      console.log(this.tableFrom);
      (0, _contract.getCheckOrderListApi)(this.tableFrom).then(function (res) {
        _this5.orderChartType = res.data.stat;
        _this5.tableData.data = res.data.list;
        _this5.tableData.total = res.data.count;
        _this5.listLoading = false;
        if (cb) cb();
        _this5.$nextTick(function () {
          if (_this5.$refs.letterRef) _this5.$refs.letterRef.doLayout();
        });
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this5.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.page = 1;
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this6 = this;
      console.log(tit, num, i);
      var _this = this;
      this.$modalUpload(function (img) {
        console.log(img);
        _this6.cert.image = img[0];
        _this6.$forceUpdate();
      }, 1);
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this7 = this;
      this.tableFrom.export_type = "erpV2ContractCheckAuction";
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this7.$createElement;
        _this7.$msgbox({
          title: "提示",
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this7.$msgbox.close();
                _this7.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList("erpV2ContractCheckAuction");
    },
    // printAction(item, source) {
    //   this.$refs["printView"].loadData(item.check_order_id, item.uid, source);
    // },
    printAllAction: function printAllAction(source) {
      if (!this.changeOrder.length) {
        this.$message.error("请选择对账记录");
        return false;
      }
      var error = "";
      // return
      var uid = this.changeOrder[0].entrust_uid;
      var paid = this.changeOrder[0].check_status;
      this.changeOrder.forEach(function (m) {
        if (m.entrust_uid !== uid) {
          error = "只能选择同一委托方的对账记录";
        }
        if (m.check_status == 1) {
          error = "请选择未对账的记录";
        }
        // if (m.check_order_id < 1) {
        //   error = "请选择已对账的记录";
        // }
      });
      if (error) {
        this.$message.error(error);
        return false;
      }
      var ids = this.changeOrder.map(function (m) {
        return m.id;
      });
      var item = this.changeOrder[0];
      this.$refs["printView"].loadData(ids.join(","), item.entrust_uid, source);
    },
    printAction: function printAction(item) {
      var error = "";
      this.$refs["printView"].loadData(item.id, item.entrust_uid, 'pay');
    },
    // printAfter(source) {
    //   if (!this.changeOrder.length) {
    //     this.$message.error("请选择对账记录");
    //     return false;
    //   }
    //   let error = "";
    //   // return
    //   const uid = this.changeOrder[0].entrust_uid;
    //   const paid = this.changeOrder[0].check_status;
    //   this.changeOrder.forEach((m) => {
    //     if (m.entrust_uid !== uid) {
    //       error = "只能选择同一委托方的对账记录";
    //     }
    //     if (m.check_status == 1) {
    //       error = "请选择未对账的记录";
    //     }
    //   });
    //   if (error) {
    //     this.$message.error(error);
    //     return false;
    //   }
    //   const ids = this.changeOrder.map((m) => m.id);
    //   const item = this.changeOrder[0];
    //   this.$refs["printAfterView"].loadData(ids.join(","), item.entrust_uid, source);
    // },
    onPayment: function onPayment() {
      var _this8 = this;
      if (!this.multipleSelection.length) {
        this.$message.error("请选择对账记录");
        return;
      }
      var index = this.multipleSelection.findIndex(function (item) {
        return item.is_create_extract_order != 0;
      });
      if (index != -1) {
        this.$message.error("请选择已对账未生成付款单的货品");
        return;
      }
      var ids = this.multipleSelection.map(function (item) {
        return item.id;
      });
      this.$confirm('确定创建付款单？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _contract.createExtractOrderApi)({
          check_auction_ids: ids.join(',')
        }).then(function (res) {
          _this8.multipleSelection = [];
          _this8.changeOrder = [];
          _this8.$message.success("操作成功");
        });
      }).catch(function () {});
    },
    checkmore: function checkmore() {
      // console.log(this.multipleSelection);
      if (!this.multipleSelection.length) {
        this.$message.error("请选择对账记录");
        return;
      }
      var euid = this.multipleSelection[0].entrust_uid;
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (euid != this.multipleSelection[i].entrust_uid) {
          this.$message.error("请选择同一委托对账记录");
          return;
        }
      }
      // 判断audit_status是否等于1
      for (var _i = 0; _i < this.multipleSelection.length; _i++) {
        if (this.multipleSelection[_i].audit_status == 1) {
          this.$message.error("请选择未审核的委托对账记录");
          return;
        }
      }
      var selectarr = JSON.parse(JSON.stringify(this.multipleSelection));
      this.ids = selectarr.map(function (item) {
        return item.id;
      }).join(',');
      this.showcheck = true;
      this.isNo = true;
      this.checkstatus = 1;
      this.proptype = "1";
      this.propmoney = "0";
      this.propmark = "";
      this.checkoneJson.entrustUser = selectarr[0].entrustUser;
      selectarr.forEach(function (item) {
        item.auction = item.pmAuction;
      });
      this.checkoneJson.check_auction_data = selectarr;
      this.handData(selectarr);
      this.checkoneJson.settlement_amount = this.checkoneJson.account;
      // console.log(this.checkoneJson);
    },
    handData: function handData(list) {
      var obj = {
        succ: 0,
        err: 0
      };
      var configPrice = JSON.parse(JSON.stringify(list[0].price_data));
      configPrice.forEach(function (item) {
        item.forEach(function (ele) {
          ele.deduction_amount = 0;
        });
      });
      var money = 0;
      list.forEach(function (item) {
        money += Number(item.settlement_amount);
        if (item.auction_status <= 4) {
          obj.err += 1;
        } else {
          obj.succ += 1;
        }
        item.price_data.forEach(function (a, i) {
          a.forEach(function (ele, index) {
            if (configPrice[i][index]) configPrice[i][index].deduction_amount += ele.deduction_amount ? Number(ele.deduction_amount) : 0;
          });
        });
      });
      this.checkoneJson.account = money.toFixed(2);
      this.$set(this.checkoneJson, 'configPrice', configPrice);
      this.$set(this.checkoneJson, 'obj', obj);
    },
    onReconc: function onReconc(row) {
      this.ids = [row.id];
      row.auction = row.pmAuction;
      this.checkstatus = 1;
      this.proptype = "1";
      this.propmoney = "0";
      this.propmark = "";
      this.isNo = true;
      this.checkoneJson.entrustUser = row.entrustUser;
      this.checkoneJson.check_auction_data = [row];
      this.checkoneJson.account = Number(row.settlement_amount).toFixed(2);
      this.checkoneJson.settlement_amount = row.settlement_amount;
      this.showcheck = true;
      // console.log(this.checkoneJson);
    },
    changeAuctionId: function changeAuctionId() {
      var _this9 = this;
      // 查找lastAuction里的id
      this.lastAuction.forEach(function (item) {
        if (item.venue_id == _this9.lastAuctionId) {
          _this9.venueName = item.title;
        }
      });
      localStorage.setItem("lastAuctionId", this.lastAuctionId);
      localStorage.setItem("venueName", this.venueName);
      this.getList(1);
    },
    getcheckOrder: function getcheckOrder(row) {
      var _this10 = this;
      if (!row.entrustCheckOrder) {
        return;
      }
      this.orderid = row.entrustCheckOrder.id;
      this.checkoneJson = {
        auction: [],
        check_auction_data: [],
        entrustUser: {},
        account: "",
        settlement_amount: ''
      }, (0, _contract.getCheckOrderDetailApi)({
        order_id: row.entrustCheckOrder.id
      }).then(function (res) {
        _this10.propmark = res.data.remark;
        _this10.propmoney = Math.abs(Number(res.data.extra_settlement_amount));
        if (Number(res.data.extra_settlement_amount) < 0) {
          _this10.proptype = "2";
        } else {
          _this10.proptype = "1";
        }
        var money = 0;
        res.data.check_auction_data.forEach(function (item) {
          money += Number(item.settlement_amount);
        });
        res.data.account = money.toFixed(2);
        // console.log('1---',res.data);
        _this10.checkoneJson = res.data;
        _this10.checkoneJson.settlement_amount = (money + Number(res.data.extra_settlement_amount)).toFixed(2);
      });
      this.showcheck = true;
      this.isNo = row.entrustCheckOrder && row.entrustCheckOrder.check_order_status == 0 ? true : false;
      this.checkstatus = 2;
    },
    countmoney: function countmoney() {
      var money = Number(this.checkoneJson.account);
      if (this.proptype == "1") {
        money += Number(this.propmoney);
      } else if (this.proptype == "2") {
        money -= Number(this.propmoney);
      }
      this.checkoneJson.settlement_amount = money.toFixed(2);
    },
    cursorCheck: function cursorCheck() {
      var _this11 = this;
      var str = this.proptype == "1" ? "+" : "-";
      // console.log(this.checkoneJson.check_auction_data);
      // return
      var arr = this.checkoneJson.check_auction_data.map(function (item) {
        return {
          check_auction_id: item.id,
          price_data: item.price_data,
          settlement_amount: item.settlement_amount
        };
      });
      if (this.checkstatus == 1) {
        (0, _contract.createCheckOrderApi)({
          check_auction_ids: this.ids,
          extra_settlement_amount: str + this.propmoney,
          remark: this.propmark,
          check_auction_price_data: arr
        }).then(function (res) {
          _this11.$message.success('操作成功');
          _this11.showcheck = false;
          _this11.getList();
        });
      } else if (this.checkstatus == 2) {
        (0, _contract.createCompositeCheckOrderApi)({
          order_id: this.orderid,
          extra_settlement_amount: str + this.propmoney,
          remark: this.propmark,
          check_auction_price_data: arr
        }).then(function (res) {
          _this11.$message.success('操作成功');
          _this11.showcheck = false;
          _this11.getList();
        });
      }
    },
    checkSubmit: function checkSubmit() {
      var _this12 = this;
      var arr = this.checkoneJson.check_auction_data.map(function (item) {
        return {
          check_auction_id: item.id,
          price_data: item.price_data,
          settlement_amount: item.settlement_amount
        };
      });
      (0, _contract.savePriceData)({
        check_auction_price_data: arr
      }).then(function (res) {
        // console.log(res);
        _this12.$message.success('操作成功');
        _this12.showcheck = false;
      });
    }
  }
};