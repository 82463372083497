var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.content,
        "label-width": "80px",
        size: "mini",
        "label-position": "left",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _vm.content.type != 4
        ? _c(
            "div",
            {
              staticClass: "auction-select-add",
              on: { click: _vm.handleAdditem },
            },
            [
              _c("i", {
                staticClass: "el-icon-plus",
                staticStyle: { "margin-right": "8px" },
              }),
              _vm._v(" "),
              _c("a", { staticClass: "fontW500 ft16" }, [_vm._v("新增一条")]),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "auction-select-add",
              on: { click: _vm.handleExchange },
            },
            [
              _c("i", {
                staticClass: "el-icon-refresh",
                staticStyle: { "margin-right": "8px" },
              }),
              _vm._v(" "),
              _c("a", { staticClass: "fontW500 ft16" }, [_vm._v("换一换")]),
            ]
          ),
      _vm._v(" "),
      _vm.content.type == 4
        ? _c(
            "div",
            {
              staticClass: "auction-select-add mt20",
              on: { click: _vm.addAlbum },
            },
            [_c("a", { staticClass: "fontW500 ft16" }, [_vm._v("选择专场")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mt20" }, [
        _c(
          "div",
          { staticClass: "flex align-items-c mt10" },
          [
            _c(
              "el-radio-group",
              {
                on: { change: _vm.changeType },
                model: {
                  value: _vm.content.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.content, "type", $$v)
                  },
                  expression: "content.type",
                },
              },
              [
                _c("el-radio", { attrs: { label: "1" } }, [_vm._v("图文排列")]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: "2" } }, [_vm._v("推荐阅读")]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: "4" } }, [_vm._v("专场封面")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pt10" },
        _vm._l(_vm.content.data, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "kuang1 item-align-center mt10" },
            [
              index === 0
                ? _c(
                    "div",
                    {
                      staticClass: "go-down-img",
                      on: {
                        click: function ($event) {
                          return _vm.goDown(index)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              index > 0
                ? _c(
                    "div",
                    {
                      staticClass: "go-up-img",
                      on: {
                        click: function ($event) {
                          return _vm.goUp(index)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "del-img",
                  on: {
                    click: function ($event) {
                      return _vm.handleDelete(index)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                      alt: "",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.content.type != 4
                ? _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center ml10",
                      staticStyle: { width: "40px", height: "40px" },
                      on: {
                        click: function ($event) {
                          return _vm.uploadIconAction(index)
                        },
                      },
                    },
                    [
                      item.url
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.url
                        ? _c("img", {
                            staticStyle: { width: "40px", height: "40px" },
                            attrs: { src: item.url + "!120a" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type == 4
                ? _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center ml10",
                      staticStyle: { width: "40px", height: "40px" },
                      on: {
                        click: function ($event) {
                          return _vm.handleExchange(true)
                        },
                      },
                    },
                    [
                      item.url
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.url
                        ? _c("img", {
                            staticStyle: { width: "40px", height: "40px" },
                            attrs: {
                              src:
                                item.url +
                                (item.url.indexOf(
                                  "//poster-design-screenshot.yunzongbu.cn"
                                ) > -1
                                  ? ""
                                  : "!120a"),
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-one ml10" },
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "设置文字" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return (function () {})($event)
                      },
                    },
                    model: {
                      value: item.title,
                      callback: function ($$v) {
                        _vm.$set(item, "title", $$v)
                      },
                      expression: "item.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              item.link.name1 || item.link.name
                ? _c(
                    "div",
                    {
                      staticClass:
                        "item-align-center flex-one justify-end ml10 cur-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.setCategory(index)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "ft14" }, [
                        _vm._v(_vm._s(item.link.name1 || item.link.name)),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "arrow-img",
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "item-align-center flex-one justify-end color-link ml10 cur-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.setCategory(index)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "ft14" }, [
                        _vm._v("设置跳转链接"),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "arrow-img",
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                          alt: "",
                        },
                      }),
                    ]
                  ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择专场封面图",
            visible: _vm.showselectImage,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showselectImage = $event
            },
            close: function ($event) {
              _vm.showselectImage = false
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "100%", display: "flex" } },
            _vm._l(_vm.selectImageArr, function (item, index) {
              return _c(
                "div",
                {
                  key: "select" + index,
                  staticClass: "albumPreviewList",
                  class: index === _vm.selectedIndex ? "select" : "",
                  on: {
                    click: function ($event) {
                      return _vm.selectCoverAlbumImage(item, index)
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "200px", "margin-right": "20px" },
                    attrs: { src: item.url, alt: "" },
                  }),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "30px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.cursorCoverAlbumImage },
                },
                [_vm._v("\n        确认选择此海报\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "30px" },
                  attrs: { size: "small" },
                  on: { click: _vm.handleExchange },
                },
                [_vm._v("\n        换一换\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: { "is-one": "", "current-data": [] },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }