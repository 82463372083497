var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-full w-full flex flex-col items-center 123 min-w-1100px pr-6",
      staticStyle: { "user-select": "none", "touch-action": "none" },
    },
    [
      _c(
        "div",
        {
          staticClass: "fixed right-0 top-0 z-10 flex justify-center",
          staticStyle: { left: "332px" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-900px max-w-95vw flex items-center border bg-ffffff border-E6E7EB h-80px rounded-b-8px",
              staticStyle: {
                "box-shadow": "0px 0px 20px 0px rgba(0,0,0,0.05)",
              },
              style: _vm.isMobile ? "" : "border-top: none;",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "rounded-8px flex text-000000 bg-F4F5F9 font-medium overflow-hidden w-255px h-40px text-14px ml-6",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-1 h-full flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 ",
                      class: _vm.markType === 1 ? "bg-F0EAFF text-6432E7 " : "",
                      on: {
                        click: function ($event) {
                          return _vm.editMarkTypeAction(1)
                        },
                      },
                    },
                    [
                      _c("Icon", {
                        staticStyle: { width: "16px", height: "16px" },
                        attrs: { icon: "ph:hand-pointing" },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "relative bottom-1px left-1" },
                        [_vm._v("涂抹标记")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-1 h-full flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 ",
                      class: _vm.markType === 2 ? "bg-F0EAFF text-6432E7 " : "",
                      on: {
                        click: function ($event) {
                          return _vm.editMarkTypeAction(2)
                        },
                      },
                    },
                    [
                      _c("Icon", {
                        staticStyle: { width: "16px", height: "16px" },
                        attrs: { icon: "ph:polygon" },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "relative bottom-1px" }, [
                        _vm._v("套索标记"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.markType === 1,
                      expression: "markType === 1",
                    },
                  ],
                  staticClass:
                    "rounded-8px relative flex text-000000 bg-F4F5F9 font-medium w-144px h-40px text-14px ml-10px",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex-1",
                      staticStyle: {
                        "border-top-left-radius": "8px",
                        "border-bottom-left-radius": "8px",
                        overflow: "hidden",
                      },
                    },
                    [
                      _c(
                        "el-popover",
                        { attrs: { placement: "bottom", trigger: "hover" } },
                        [
                          _c("span", [_vm._v("笔刷，用于标记重绘部分")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "h-full flex justify-center rounded-l-lg items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 ",
                              class:
                                _vm.lineManipulateType === 1
                                  ? "bg-F0EAFF text-6432E7 "
                                  : "",
                              attrs: { slot: "reference" },
                              on: {
                                click: function ($event) {
                                  return _vm.editLineManipulateTypeAction(1)
                                },
                              },
                              slot: "reference",
                            },
                            [
                              _c("Icon", {
                                staticStyle: { width: "16px", height: "16px" },
                                attrs: { icon: "ph:pencil-simple" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c(
                        "el-popover",
                        { attrs: { placement: "bottom", trigger: "hover" } },
                        [
                          _c("span", [_vm._v("橡皮，用于擦除重绘标记")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "h-full flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 ",
                              class:
                                _vm.lineManipulateType === 2
                                  ? "bg-F0EAFF text-6432E7 "
                                  : "",
                              attrs: { slot: "reference" },
                              on: {
                                click: function ($event) {
                                  return _vm.editLineManipulateTypeAction(2)
                                },
                              },
                              slot: "reference",
                            },
                            [
                              _c("Icon", {
                                staticStyle: { width: "16px", height: "16px" },
                                attrs: { icon: "ph:eraser" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c(
                        "el-popover",
                        { attrs: { placement: "bottom", trigger: "hover" } },
                        [
                          _c("span", [
                            _vm._v("位移，用于移动图像在画布中的位置"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "h-full flex justify-center rounded-r-lg items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 ",
                              class:
                                _vm.lineManipulateType === 3
                                  ? "bg-F0EAFF text-6432E7 "
                                  : "",
                              attrs: { slot: "reference" },
                              on: {
                                click: function ($event) {
                                  return _vm.editLineManipulateTypeAction(3)
                                },
                              },
                              slot: "reference",
                            },
                            [
                              _c("Icon", {
                                staticStyle: { width: "16px", height: "16px" },
                                attrs: { icon: "ph:arrows-out" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.lineManipulateType === 1 ||
                            _vm.lineManipulateType === 2,
                          expression:
                            "lineManipulateType === 1 || lineManipulateType === 2",
                        },
                      ],
                      staticClass: "absolute w-full bottom-17px left-0",
                    },
                    [
                      _c("el-slider", {
                        attrs: { step: 1, min: 10, max: 150 },
                        model: {
                          value: _vm.lineSize,
                          callback: function ($$v) {
                            _vm.lineSize = $$v
                          },
                          expression: "lineSize",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.markType === 1,
                      expression: "markType === 1",
                    },
                  ],
                  staticClass:
                    "flex rounded-8px text-000000 bg-F4F5F9 overflow-hidden w-96px h-40px text-14px ml-10px",
                  staticStyle: { overflow: "hidden" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-1 relative" },
                    [
                      _c(
                        "el-popover",
                        { attrs: { placement: "bottom", trigger: "hover" } },
                        [
                          _c("span", [_vm._v("撤消操作")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "relative h-full",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-full flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 relative",
                                  on: { click: _vm.undoLastDraw },
                                },
                                [
                                  _c("Icon", {
                                    staticStyle: {
                                      width: "16px",
                                      height: "16px",
                                    },
                                    attrs: { icon: "ph:arrow-arc-left" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.drawingHistory.length <= 0,
                                    expression: "drawingHistory.length <= 0",
                                  },
                                ],
                                staticClass:
                                  "absolute left-0 top-0 w-full h-full bg-ffffff80  cursor-not-allowed",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-1 relative" },
                    [
                      _c(
                        "el-popover",
                        { attrs: { placement: "bottom", trigger: "hover" } },
                        [
                          _c("span", [_vm._v("不撤销了")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "relative h-full",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-full flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7  relative",
                                  on: { click: _vm.nextStepDraw },
                                },
                                [
                                  _c("Icon", {
                                    staticStyle: {
                                      width: "16px",
                                      height: "16px",
                                    },
                                    attrs: { icon: "ph:arrow-arc-right" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.drawingForwardHistory.length <= 0,
                                    expression:
                                      "drawingForwardHistory.length <= 0",
                                  },
                                ],
                                staticClass:
                                  "absolute left-0 top-0 w-full h-full bg-ffffff80  cursor-not-allowed",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.markType === 2,
                      expression: "markType === 2",
                    },
                  ],
                  staticClass:
                    "flex rounded-8px text-000000 bg-F4F5F9 overflow-hidden w-96px h-40px text-14px ml-10px",
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom",
                            width: "300",
                            trigger: "hover",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "套索工具，用于标记重绘部分。在需要重绘区域的边缘上，点击鼠标并拖动到另一点，再次点击鼠标，定义一条直线。重复此操作，直到点击“开始处”点，完成整个重绘区域的标记。也可以点击上方的“闭环”按钮完成整个重绘区域的标记。"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "h-full flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 ",
                              class:
                                _vm.circleManipulateType === 1
                                  ? "bg-F0EAFF text-6432E7 "
                                  : "",
                              attrs: { slot: "reference" },
                              on: {
                                click: function ($event) {
                                  return _vm.editCircleManipulateTypeAction(1)
                                },
                              },
                              slot: "reference",
                            },
                            [
                              _c("Icon", {
                                staticStyle: { width: "16px", height: "16px" },
                                attrs: { icon: "ph:polygon" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c(
                        "el-popover",
                        { attrs: { placement: "bottom", trigger: "hover" } },
                        [
                          _c("span", [
                            _vm._v("位移，用于移动图像在画布中的位置"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "h-full flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 ",
                              class:
                                _vm.circleManipulateType === 2
                                  ? "bg-F0EAFF text-6432E7 "
                                  : "",
                              attrs: { slot: "reference" },
                              on: {
                                click: function ($event) {
                                  return _vm.editCircleManipulateTypeAction(2)
                                },
                              },
                              slot: "reference",
                            },
                            [
                              _c("Icon", {
                                staticStyle: { width: "16px", height: "16px" },
                                attrs: { icon: "ph:arrows-out" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.markType === 2,
                      expression: "markType === 2",
                    },
                  ],
                  staticClass:
                    "flex rounded-8px text-000000 bg-F4F5F9 overflow-hidden w-40px h-40px text-14px ml-10px",
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-1 relative" },
                    [
                      _c(
                        "el-popover",
                        { attrs: { placement: "bottom", trigger: "hover" } },
                        [
                          _c("span", [
                            _vm._v(
                              "闭合，用于完成整个重绘区域的标记的一种可选方式，仅在有三个及以上的标记点时该按钮可点击。"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "relative h-full",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-full flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 relative",
                                  on: {
                                    click: function ($event) {
                                      return _vm.closedLoop(1)
                                    },
                                  },
                                },
                                [
                                  _c("Icon", {
                                    staticStyle: {
                                      width: "16px",
                                      height: "16px",
                                    },
                                    attrs: { icon: "ic:baseline-loop" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.hasFinish,
                                    expression: "hasFinish",
                                  },
                                ],
                                staticClass:
                                  "absolute left-0 top-0 w-full h-full bg-ffffff80  cursor-not-allowed",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.markType === 2,
                      expression: "markType === 2",
                    },
                  ],
                  staticClass:
                    "flex rounded-8px text-000000 bg-F4F5F9 overflow-hidden w-96px h-40px text-14px ml-10px",
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-1 relative" },
                    [
                      _c(
                        "el-popover",
                        { attrs: { placement: "bottom", trigger: "hover" } },
                        [
                          _c("span", [_vm._v("撤消操作")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "relative h-full",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-full flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7  relative",
                                  on: { click: _vm.previousStepAction },
                                },
                                [
                                  _c("Icon", {
                                    staticStyle: {
                                      width: "16px",
                                      height: "16px",
                                    },
                                    attrs: { icon: "ph:arrow-arc-left" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.previousStepList.length <= 0,
                                    expression: "previousStepList.length <= 0",
                                  },
                                ],
                                staticClass:
                                  "absolute left-0 top-0 w-full h-full bg-ffffff80  cursor-not-allowed",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-1 relative" },
                    [
                      _c(
                        "el-popover",
                        { attrs: { placement: "bottom", trigger: "hover" } },
                        [
                          _c("span", [_vm._v("不撤销了")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "relative h-full",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-full flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7  relative",
                                  on: { click: _vm.nextStepAction },
                                },
                                [
                                  _c("Icon", {
                                    staticStyle: {
                                      width: "16px",
                                      height: "16px",
                                    },
                                    attrs: { icon: "ph:arrow-arc-right" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.nextStepList.length <= 0,
                                    expression: "nextStepList.length <= 0",
                                  },
                                ],
                                staticClass:
                                  "absolute left-0 top-0 w-full h-full bg-ffffff80  cursor-not-allowed",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex rounded-8px text-000000 bg-F4F5F9  relative w-128px h-40px text-14px ml-10px",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded-l-lg  flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 w-40px h-40px",
                      on: { click: _vm.reducePercentageAction },
                    },
                    [
                      _c("Icon", {
                        staticStyle: { width: "16px", height: "16px" },
                        attrs: { icon: "material-symbols:remove" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-1 h-full flex items-center justify-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 ",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.showChangePercentage = true
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "relative bottom-[-1px]" }, [
                        _vm._v(_vm._s(_vm.percentage) + "%"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded-r-lg flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 w-40px h-40px",
                      on: { click: _vm.increasePercentageAction },
                    },
                    [
                      _c("Icon", {
                        staticStyle: { width: "16px", height: "16px" },
                        attrs: { icon: "material-symbols:add" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showChangePercentage,
                          expression: "showChangePercentage",
                        },
                      ],
                      staticClass:
                        "absolute left-0 right-0 top-44px border h-160px bg-#ffffff border-#E6E7EB rounded-8px z-50",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-40px leading-[40px] flex items-center justify-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7",
                          on: {
                            click: function ($event) {
                              _vm.percentage = 50
                            },
                          },
                        },
                        [_vm._v("\n            50%\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-40px leading-[40px] flex items-center justify-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7",
                          on: {
                            click: function ($event) {
                              _vm.percentage = 100
                            },
                          },
                        },
                        [_vm._v("\n            100%\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-40px leading-[40px] flex items-center justify-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7",
                          on: {
                            click: function ($event) {
                              _vm.percentage = 150
                            },
                          },
                        },
                        [_vm._v("\n            150%\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-40px leading-[40px] flex items-center justify-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7",
                          on: {
                            click: function ($event) {
                              _vm.percentage = 200
                            },
                          },
                        },
                        [_vm._v("\n            200%\n          ")]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex justify-center items-center ml-10px rounded-8px cursor-pointer text-000000 bg-F4F5F9  overflow-hidden relative w-44px h-40px text-14px ml-10px",
                  on: { click: _vm.clearAll },
                },
                [
                  _c(
                    "el-popover",
                    { attrs: { placement: "bottom", trigger: "hover" } },
                    [
                      _c("span", [_vm._v("擦掉所有标记")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "relative w-full h-full",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full h-full flex justify-center items-center hover-bg-F0EAFF hover-text-6432E7 relative",
                            },
                            [
                              _c("Icon", {
                                staticStyle: { width: "16px", height: "16px" },
                                attrs: { icon: "ph:backspace" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  (_vm.markType === 1 &&
                                    _vm.drawingHistory.length <= 0) ||
                                  (_vm.markType === 2 &&
                                    _vm.previousStepList.length <= 0),
                                expression:
                                  "(markType === 1 && drawingHistory.length <= 0) || (markType === 2 && previousStepList.length <= 0)",
                              },
                            ],
                            staticClass:
                              "absolute left-0 top-0 w-full h-full bg-ffffff80  cursor-not-allowed",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.markType === 1,
                    expression: "markType === 1",
                  },
                ],
                staticClass: "w-2px h-40px",
              }),
              _vm._v(" "),
              _c("div", { staticClass: "w-120px h-40px ml-10px mr-6" }, [
                _c(
                  "div",
                  { staticClass: "flex-1" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          background: "#6c49e6",
                          color: "#fff",
                          height: "40px",
                        },
                        attrs: { loading: _vm.imgStatus === 0 },
                        on: { click: _vm.drawTwoImage },
                      },
                      [_vm._v("开始清除")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "containerEL",
          staticClass: "flex-1 overflow-hidden flex mb-0 pt-42px",
          staticStyle: { "z-index": "1", width: "100%" },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.markType === 1,
                  expression: "markType === 1",
                },
              ],
              staticClass: "flex w-full h-full justify-center items-center",
              on: {
                mousemove: _vm.containerMove,
                touchmove: _vm.containerMove,
              },
            },
            [
              _vm.imgUrl
                ? _c(
                    "div",
                    {
                      staticClass: "max-w-full relative",
                      staticStyle: {
                        "user-select": "none",
                        "touch-action": "none",
                      },
                      style: [
                        {
                          transform:
                            "scale(" +
                            _vm.percentage / 100 +
                            ") translate(" +
                            _vm.moveX +
                            "px, " +
                            _vm.moveY +
                            "px)",
                        },
                        {
                          "aspect-ratio": _vm.imgWidth + " / " + _vm.imgHeight,
                        },
                        { cursor: _vm.lineManipulateType === 3 ? "move" : "" },
                      ],
                      on: {
                        mousedown: _vm.containerDown,
                        mouseup: _vm.containerUp,
                        touchstart: _vm.containerDown,
                        touchend: _vm.containerUp,
                      },
                    },
                    [
                      _c("img", {
                        ref: "orgImgEL",
                        staticClass: "max-w-full max-h-full object-cover",
                        style:
                          "width: " +
                          _vm.imgWidth +
                          "px;height: " +
                          _vm.imgHeight +
                          "px;",
                        attrs: { src: _vm.imgUrl },
                        on: { load: _vm.imgLoadedAction },
                      }),
                      _vm._v(" "),
                      _c("canvas", {
                        ref: "$canvasRefReplace",
                        staticClass:
                          "absolute left-0 top-0 w-full h-full opacity-60 z-10",
                      }),
                      _vm._v(" "),
                      _c("canvas", {
                        ref: "$canvasRef",
                        staticClass:
                          "absolute left-0 top-0 w-full h-full opacity-60 z-10",
                      }),
                      _vm._v(" "),
                      _c("canvas", {
                        ref: "$circularRef",
                        staticClass:
                          "absolute left-0 top-0 w-full h-full opacity-60 z-20",
                        class:
                          _vm.lineManipulateType !== 3 ? "cursor-pointer" : "",
                        on: {
                          mousedown: _vm.startDrawing,
                          touchstart: _vm.startDrawing,
                          mousemove: _vm.drawCircular,
                          touchmove: _vm.draw,
                          mouseup: _vm.stopDrawing,
                          touchend: _vm.stopDrawing,
                          mouseout: _vm.leaveDraw,
                        },
                      }),
                      _vm._v(" "),
                      _vm.imgStatus !== -10 && _vm.imgStatus !== 1
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "absolute left-0 right-0 bottom-0 top-0 z-50 bg-00000080 text-FDBA46 flex items-center justify-center flex-col",
                            },
                            [
                              _vm.imgStatus === -1 || _vm.imgStatus === -4
                                ? _c("img", {
                                    staticClass: "w-48px h-48px text-#ffffff",
                                    attrs: { src: _vm.worriedFace },
                                  })
                                : _c("Icon", {
                                    staticStyle: {
                                      width: "48px",
                                      height: "48px",
                                    },
                                    attrs: { icon: "eos-icons:bubble-loading" },
                                  }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-18px mt-3" },
                                [
                                  _vm.imgStatus === -2
                                    ? [
                                        _vm._v(
                                          "\n              等待处理中...\n            "
                                        ),
                                      ]
                                    : _vm.imgStatus === -3
                                    ? [
                                        _vm._v(
                                          "\n              资源加载中...\n            "
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n              图片处理中...\n            "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.markType === 2,
                  expression: "markType === 2",
                },
              ],
              staticClass:
                "flex min-h-full justify-center  w-full h-full justify-center items-center relative",
              on: {
                mousemove: _vm.containerMove,
                touchmove: _vm.containerMove,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "max-w-full relative",
                  staticStyle: {
                    "user-select": "none",
                    "touch-action": "none",
                  },
                  style: [
                    {
                      transform:
                        "scale(" +
                        _vm.percentage / 100 +
                        ") translate(" +
                        _vm.moveX +
                        "px, " +
                        _vm.moveY +
                        "px)",
                    },
                    {
                      "aspect-ratio":
                        _vm.imgWidth + " / " + _vm.imgHeight + ";",
                    },
                    { cursor: _vm.lineManipulateType === 3 ? "move" : "" },
                  ],
                  attrs: { id: "simpleSquare" },
                  on: {
                    mousedown: _vm.containerDown,
                    mouseup: _vm.containerUp,
                    touchstart: _vm.containerDown,
                    touchend: _vm.containerUp,
                  },
                },
                [
                  _c("img", {
                    staticClass: "max-w-full max-h-full object-cover",
                    style:
                      "width: " +
                      _vm.imgWidth +
                      "px;height: " +
                      _vm.imgHeight +
                      "px;",
                    attrs: { src: _vm.imgUrl },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.imgStatus !== -10 && _vm.imgStatus !== 1
                ? _c(
                    "div",
                    {
                      staticClass:
                        "absolute left-0 right-0 bottom-0 top-0 z-50 bg-00000080 text-FDBA46 flex items-center justify-center flex-col",
                    },
                    [
                      _vm.imgStatus === -1 || _vm.imgStatus === -4
                        ? _c("img", {
                            staticClass: "w-48px h-48px text-#ffffff",
                            attrs: { src: _vm.worriedFace },
                          })
                        : _c("Icon", {
                            staticStyle: { width: "48px", height: "48px" },
                            attrs: { icon: "eos-icons:bubble-loading" },
                          }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "text-18px mt-3" },
                        [
                          _vm.imgStatus === -2
                            ? [
                                _vm._v(
                                  "\n              等待处理中...\n            "
                                ),
                              ]
                            : _vm.imgStatus === -3
                            ? [
                                _vm._v(
                                  "\n              资源加载中...\n            "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n              图片处理中...\n            "
                                ),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }