var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contactlist" },
    [
      _c(
        "div",
        {
          staticClass: "goods-list-custom",
          staticStyle: { height: "calc(100vh - 170px)" },
          attrs: { id: "viewH" },
        },
        [
          _vm.viewH
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "conRef",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData.data,
                    height: "100%",
                    size: "mini",
                    "highlight-current-row": "",
                    "row-key": "id",
                    lazy: true,
                    load: _vm.loadExpandData,
                    "expand-row-keys": _vm.rowkeys,
                    border: "",
                  },
                  on: {
                    "expand-change": _vm.exchangeExpand,
                    "sort-change": _vm.sortAction,
                    "cell-click": _vm.cellClick,
                    "selection-change": _vm.handleSelectionChange,
                  },
                },
                [
                  _c("template", { slot: "empty" }, [
                    _c("div", { staticClass: "tableEmpty" }, [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tableDiv" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-table-column", {
                    key: "1",
                    attrs: { type: "selection", width: "36", align: "center" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { type: "expand", width: "36" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm.showsearch && props.$index == 0
                                ? void 0
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "expand-table-box" },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        data: props.row.expandData,
                                        size: "small",
                                        border: "",
                                        "highlight-current-row": "",
                                        "max-height": _vm.viewH - 50 + "px",
                                      },
                                      on: {
                                        "sort-change": _vm.sortProductAction,
                                      },
                                    },
                                    [
                                      _c("template", { slot: "empty" }, [
                                        props.row.loading
                                          ? _c("div", [_vm._v("加载中...")])
                                          : _c("div", [
                                              _vm.$store.getters.userPression
                                                .merchantErpV2ContractProductList
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "tableEmpty",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                                                          alt: "",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "tableDiv",
                                                        },
                                                        [_vm._v("暂无数据")]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass: "tableEmpty",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718954313071950674.png!120a",
                                                          alt: "",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "tableDiv",
                                                        },
                                                        [_vm._v("无权限查看")]
                                                      ),
                                                    ]
                                                  ),
                                            ]),
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.erpsettingList,
                                        function (item, index) {
                                          return [
                                            item.types ==
                                            "erp_v2_contract_create_product"
                                              ? [
                                                  item.field == "product_no"
                                                    ? [
                                                        _c("el-table-column", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.name,
                                                            prop: "product_no",
                                                            sortable: "custom",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    scope.row
                                                                      .erpProduct &&
                                                                    scope.row
                                                                      .erpProduct
                                                                      .product_no
                                                                      ? [
                                                                          _vm._v(
                                                                            "\n                          " +
                                                                              _vm._s(
                                                                                scope
                                                                                  .row
                                                                                  .erpProduct
                                                                                  .product_no
                                                                              ) +
                                                                              "\n                        "
                                                                          ),
                                                                        ]
                                                                      : scope
                                                                          .row
                                                                          .erpProduct &&
                                                                        scope
                                                                          .row
                                                                          .erpProduct
                                                                          .product_no_prefix
                                                                      ? [
                                                                          _vm._v(
                                                                            "\n                          " +
                                                                              _vm._s(
                                                                                scope
                                                                                  .row
                                                                                  .erpProduct
                                                                                  .product_no_prefix
                                                                              ) +
                                                                              "\n                        "
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ]
                                                    : item.field ==
                                                      "product_name"
                                                    ? [
                                                        _c("el-table-column", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.name,
                                                            prop: "product_name",
                                                            width: "200",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .erpProduct
                                                                            ? scope
                                                                                .row
                                                                                .erpProduct
                                                                                .product_name
                                                                            : ""
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ]
                                                    : item.field == "order_num"
                                                    ? [
                                                        _c("el-table-column", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.name,
                                                            prop: "order_num",
                                                            sortable: "custom",
                                                            width: "80",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .order_num
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ]
                                                    : item.field ==
                                                      "remark_secret"
                                                    ? [
                                                        _c("el-table-column", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.name,
                                                            prop: "remark_secret",
                                                            sortable: "custom",
                                                            width: "130",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .remark_secret
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ]
                                                    : item.field == "image"
                                                    ? [
                                                        _c("el-table-column", {
                                                          key: index,
                                                          attrs: {
                                                            label: "图片",
                                                            width: "80",
                                                            align: "center",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    scope.row
                                                                      .erpProduct &&
                                                                    scope.row
                                                                      .erpProduct
                                                                      .contract_image &&
                                                                    scope.row
                                                                      .erpProduct
                                                                      .contract_image[0]
                                                                      ? [
                                                                          _c(
                                                                            "el-tooltip",
                                                                            {
                                                                              staticClass:
                                                                                "item",
                                                                              attrs:
                                                                                {
                                                                                  effect:
                                                                                    "dark",
                                                                                  content:
                                                                                    "合同图片",
                                                                                  placement:
                                                                                    "top",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "item-flex-center",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-image",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          src:
                                                                                            "" +
                                                                                            (scope
                                                                                              .row
                                                                                              .erpProduct
                                                                                              .contract_image[0] +
                                                                                              "!120a"),
                                                                                          "preview-src-list":
                                                                                            scope
                                                                                              .row
                                                                                              .erpProduct
                                                                                              .contract_image,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      : scope
                                                                          .row
                                                                          .erpProduct &&
                                                                        scope
                                                                          .row
                                                                          .erpProduct
                                                                          .warehouse_image &&
                                                                        scope
                                                                          .row
                                                                          .erpProduct
                                                                          .warehouse_image[0]
                                                                      ? [
                                                                          _c(
                                                                            "el-tooltip",
                                                                            {
                                                                              staticClass:
                                                                                "item",
                                                                              attrs:
                                                                                {
                                                                                  effect:
                                                                                    "dark",
                                                                                  content:
                                                                                    "入库图片",
                                                                                  placement:
                                                                                    "top",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "item-flex-center",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-image",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          src:
                                                                                            "" +
                                                                                            (scope
                                                                                              .row
                                                                                              .erpProduct
                                                                                              .warehouse_image[0] +
                                                                                              "!120a"),
                                                                                          "preview-src-list":
                                                                                            scope
                                                                                              .row
                                                                                              .erpProduct
                                                                                              .warehouse_image,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      : scope
                                                                          .row
                                                                          .erpProduct &&
                                                                        scope
                                                                          .row
                                                                          .erpProduct
                                                                          .auction_image &&
                                                                        scope
                                                                          .row
                                                                          .erpProduct
                                                                          .auction_image[0]
                                                                      ? [
                                                                          _c(
                                                                            "el-tooltip",
                                                                            {
                                                                              staticClass:
                                                                                "item",
                                                                              attrs:
                                                                                {
                                                                                  effect:
                                                                                    "dark",
                                                                                  content:
                                                                                    "上拍图片",
                                                                                  placement:
                                                                                    "top",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "item-flex-center",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-image",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          src:
                                                                                            "" +
                                                                                            (scope
                                                                                              .row
                                                                                              .erpProduct
                                                                                              .auction_image[0] +
                                                                                              "!120a"),
                                                                                          "preview-src-list":
                                                                                            scope
                                                                                              .row
                                                                                              .erpProduct
                                                                                              .auction_image,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ]
                                                    : item.field == "lot"
                                                    ? [
                                                        _c("el-table-column", {
                                                          key: index,
                                                          attrs: {
                                                            label: "图录号",
                                                            prop: "lot",
                                                            sortable: "custom",
                                                            width: "80",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .erpProduct
                                                                            .lot
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ]
                                                    : item.field ==
                                                      "retain_price"
                                                    ? [
                                                        _c("el-table-column", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.name,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .retain_price
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ]
                                                    : item.field ==
                                                      "take_home_price"
                                                    ? [
                                                        _c("el-table-column", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.name,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .erpProduct
                                                                            .take_home_price
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ]
                                                    : item.field == "label"
                                                    ? [
                                                        _c("el-table-column", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.name,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .erpProduct &&
                                                                            scope
                                                                              .row
                                                                              .erpProduct
                                                                              .labelNames &&
                                                                            scope
                                                                              .row
                                                                              .erpProduct
                                                                              .labelNames
                                                                              .length
                                                                            ? scope.row.erpProduct.labelNames.join(
                                                                                ","
                                                                              )
                                                                            : ""
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ]
                                                    : item.newtype ==
                                                      "attribute"
                                                    ? [
                                                        item.name == "尺寸"
                                                          ? [
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    label:
                                                                      item.name,
                                                                    "show-overflow-tooltip":
                                                                      "",
                                                                    width:
                                                                      "100",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function (
                                                                            scope
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "nowrap cur",
                                                                                  staticStyle:
                                                                                    {
                                                                                      height:
                                                                                        "100%",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      innerHTML:
                                                                                        _vm._s(
                                                                                          scope
                                                                                            .row
                                                                                            .erpProduct
                                                                                            .attribute[
                                                                                            item
                                                                                              .field
                                                                                          ]
                                                                                        ),
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              ),
                                                            ]
                                                          : _c(
                                                              "el-table-column",
                                                              {
                                                                key: index,
                                                                attrs: {
                                                                  label:
                                                                    item.name,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "default",
                                                                        fn: function (
                                                                          scope
                                                                        ) {
                                                                          return [
                                                                            _vm._v(
                                                                              "\n                        " +
                                                                                _vm._s(
                                                                                  scope
                                                                                    .row
                                                                                    .erpProduct
                                                                                    ? scope
                                                                                        .row
                                                                                        .erpProduct
                                                                                        .attribute[
                                                                                        item
                                                                                          .field
                                                                                      ]
                                                                                    : ""
                                                                                ) +
                                                                                "\n                      "
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                      ]
                                                    : item.field ==
                                                      "start_price"
                                                    ? [
                                                        _c("el-table-column", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.name,
                                                            prop: "start_price",
                                                            sortable: "custom",
                                                            width: "130",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .erpProduct &&
                                                                            scope
                                                                              .row
                                                                              .erpProduct
                                                                              .extend_data &&
                                                                            scope
                                                                              .row
                                                                              .erpProduct
                                                                              .extend_data
                                                                              .auction_info
                                                                            ? scope
                                                                                .row
                                                                                .erpProduct
                                                                                .extend_data
                                                                                .auction_info
                                                                                .start_price
                                                                            : ""
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ]
                                                    : [
                                                        _c("el-table-column", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.name,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .erpProduct[
                                                                            item
                                                                              .field
                                                                          ]
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ],
                                                ]
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.types == "store_attribute"
                                              ? [
                                                  _c("el-table-column", {
                                                    key: index,
                                                    attrs: { label: item.name },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .erpProduct
                                                                      ? scope
                                                                          .row
                                                                          .erpProduct
                                                                          .attribute[
                                                                          item
                                                                            .field
                                                                        ]
                                                                      : ""
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ]
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                      _vm._v(" "),
                                      _vm.inventoryConfig.switch
                                        .sold_price_switch
                                        ? _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "成交收费",
                                                align: "center",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.inventoryConfig.price
                                                  .sold_price,
                                                function (item, index) {
                                                  return [
                                                    item.name
                                                      ? _c("el-table-column", {
                                                          key: "s_" + index,
                                                          attrs: {
                                                            label: item.name,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    scope.row
                                                                      .price_data
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                scope
                                                                                  .row
                                                                                  .price_data[0][
                                                                                  index
                                                                                ]
                                                                                  .number
                                                                              ) +
                                                                                _vm._s(
                                                                                  item.unit ==
                                                                                    1
                                                                                    ? "元"
                                                                                    : "%"
                                                                                )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.inventoryConfig.switch
                                        .unsold_price_switch
                                        ? _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "未成交收费",
                                                align: "center",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.inventoryConfig.price
                                                  .unsold_price,
                                                function (item, index) {
                                                  return [
                                                    item.name
                                                      ? _c("el-table-column", {
                                                          key: "s_" + index,
                                                          attrs: {
                                                            label: item.name,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    scope.row
                                                                      .price_data
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                scope
                                                                                  .row
                                                                                  .price_data[1][
                                                                                  index
                                                                                ]
                                                                                  .number
                                                                              ) +
                                                                                _vm._s(
                                                                                  item.unit ==
                                                                                    1
                                                                                    ? "元"
                                                                                    : "%"
                                                                                )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.inventoryConfig.switch
                                        .other_price_switch
                                        ? _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "其他费用",
                                                align: "center",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.inventoryConfig.price
                                                  .other_price,
                                                function (item, index) {
                                                  return [
                                                    item.name
                                                      ? _c("el-table-column", {
                                                          key: "s_" + index,
                                                          attrs: {
                                                            label: item.name,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    scope.row
                                                                      .price_data
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                scope
                                                                                  .row
                                                                                  .price_data[2][
                                                                                  index
                                                                                ]
                                                                                  .number
                                                                              ) +
                                                                                _vm._s(
                                                                                  item.unit ==
                                                                                    1
                                                                                    ? "元"
                                                                                    : "%"
                                                                                )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: { label: "状态", width: "100" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row.status == -1
                                                          ? "已作废"
                                                          : ""
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2576513917
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "合同编号",
                      width: "150",
                      prop: "contract_no",
                      "show-overflow-tooltip": "",
                      "search-key": "contract_no",
                      sortable: "custom",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "nowrap" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.contract_no) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      154323662
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "内部编号",
                      width: "150",
                      prop: "contract_inter_no",
                      "search-key": "contract_inter_no",
                      sortable: "custom",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.inputname ==
                              "contract_inter_no_" + scope.row.id
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "inputfo",
                                            rawName: "v-inputfo",
                                          },
                                        ],
                                        staticClass: "edit-textareabox",
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "edit-textareabox-span",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              scope.row.contract_inter_no ||
                                                "&nbsp;"
                                            ),
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.tableInputValue,
                                              expression: "tableInputValue",
                                            },
                                          ],
                                          staticClass: "edit-textarea",
                                          staticStyle: { resize: "none" },
                                          attrs: { resize: "none" },
                                          domProps: {
                                            value: _vm.tableInputValue,
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.saveoneflied(
                                                scope.$index,
                                                "contract_inter_no",
                                                scope.row.id,
                                                scope.row
                                              )
                                            },
                                            input: [
                                              function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.tableInputValue =
                                                  $event.target.value
                                              },
                                              _vm.checkinput,
                                            ],
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _c(
                                    "div",
                                    {
                                      staticClass: "nowrap",
                                      staticStyle: { height: "100%" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.click(
                                            scope.$index,
                                            "contract_inter_no",
                                            scope.row.id,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(scope.row.contract_inter_no) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      52869393
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "数量",
                      width: "70",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "nowrap" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.contractProductCount) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1270610776
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "客户名称",
                      width: "130",
                      prop: "",
                      "search-key": "entrust_keyword",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.entrustUser
                                ? [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "nowrap",
                                        staticStyle: {
                                          "padding-bottom": "0",
                                          width: "100%",
                                          "text-align": "left",
                                        },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.onDetails(
                                              scope.row.entrustUser
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              scope.row.entrustUser.real_name
                                                ? scope.row.entrustUser
                                                    .real_name
                                                : scope.row.entrustUser.nickname
                                            ) +
                                            "(" +
                                            _vm._s(
                                              scope.row.entrustUser.phone
                                            ) +
                                            ")\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      171019472
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "合同模板",
                      width: "130",
                      prop: "",
                      "show-overflow-tooltip": "",
                      "search-key": "template_id",
                      "search-type": "radio",
                      options: _vm.templateData,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.template
                                ? [
                                    _c("div", { staticClass: "nowrap" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(scope.row.template.name) +
                                          "\n            "
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2143304370
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "合同类型",
                      width: "100",
                      prop: "",
                      "search-key": "contract_type",
                      "search-type": "radio",
                      options: [
                        { label: "纸质合同", value: "1" },
                        { label: "电子合同", value: "2" },
                      ],
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    scope.row.contract_type == 2
                                      ? "电子合同"
                                      : "纸质合同"
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      410879050
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "状态",
                      width: "80",
                      prop: "",
                      "search-key": "status",
                      "search-type": "radio",
                      options: [
                        { label: "已签约", value: "4" },
                        { label: "待审批", value: "2" },
                        { label: "待签约", value: "3" },
                        { label: "已作废", value: "-1" },
                      ],
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.status == -4
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "danger",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.statusText))]
                                      ),
                                    ],
                                    1
                                  )
                                : scope.row.status == -3
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "danger",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.statusText))]
                                      ),
                                    ],
                                    1
                                  )
                                : scope.row.status == -2 ||
                                  scope.row.status == -1
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "info",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.statusText))]
                                      ),
                                    ],
                                    1
                                  )
                                : scope.row.status == 0 || scope.row.status == 1
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "danger",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.statusText))]
                                      ),
                                    ],
                                    1
                                  )
                                : scope.row.status == 2
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "danger",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.statusText))]
                                      ),
                                    ],
                                    1
                                  )
                                : scope.row.status == 3
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "warning",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.statusText))]
                                      ),
                                    ],
                                    1
                                  )
                                : scope.row.status == 4
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "info",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.statusText))]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3047585261
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "业务员",
                      width: "80",
                      prop: "",
                      "search-key": "salesman_admin_id",
                      "show-overflow-tooltip": "",
                      "search-type": "radio",
                      options: _vm.searchAdminarr,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "nowrap" }, [
                                scope.row.salesmanAdminData &&
                                scope.row.salesmanAdminData.length
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.salesmanAdminData
                                            .map(function (m) {
                                              return m.real_name
                                            })
                                            .join(",")
                                        )
                                      ),
                                    ])
                                  : scope.row.salesmanAdmin
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.salesmanAdmin.real_name
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3327829130
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "签约人",
                      width: "80",
                      prop: "",
                      "search-key": "sign_admin_keyword",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.signAdmin
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row.signAdmin.real_name)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1972905589
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "审批人",
                      width: "80",
                      prop: "",
                      "search-key": "audit_admin_id",
                      "search-type": "radio",
                      options: _vm.searchAdminarr,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.auditAdmin
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row.auditAdmin.real_name)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2738451509
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "创建时间",
                      "min-width": "130",
                      prop: "create_time",
                      sortable: "custom",
                      "search-key": "date",
                      "search-type": "date",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "nowrap" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.create_time) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4244647788
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      prop: "",
                      label: "签约时间",
                      width: "160",
                      "search-key": "sign_date",
                      "search-type": "date",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "nowrap" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.sign_date) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3695791482
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "制单人",
                      width: "80",
                      prop: "",
                      "search-key": "create_admin_id",
                      "search-type": "radio",
                      options: _vm.searchAdminarr,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.createAdmin
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row.createAdmin.real_name)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1307536565
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "审核记录",
                      width: "100",
                      prop: "",
                      "search-key": "audit_record_status",
                      "search-type": "radio",
                      options: [
                        { label: "YES", value: 1 },
                        { label: "NO", value: -1 },
                      ],
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.audit_record_status
                                ? [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          scope.row.audit_record_status == 1
                                            ? "YES"
                                            : "NO"
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                : [
                                    _c(
                                      "el-dropdown",
                                      {
                                        attrs: {
                                          type: "primary",
                                          placement: "bottom",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          { attrs: { type: "text" } },
                                          [_vm._v("点击审核")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-dropdown-menu",
                                          {
                                            attrs: { slot: "dropdown" },
                                            slot: "dropdown",
                                          },
                                          [
                                            _c(
                                              "el-dropdown-item",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "center",
                                                    },
                                                    attrs: {
                                                      size: "small",
                                                      type: "text",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onUpdStatus(
                                                          scope.row,
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("YES")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-item",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "text-align": "center",
                                                    },
                                                    attrs: {
                                                      size: "small",
                                                      type: "text",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onUpdStatus(
                                                          scope.row,
                                                          -1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("NO")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2581376051
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "",
                      width: "36",
                      fixed: "right",
                      align: "center",
                      "search-clear": true,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "TableRightBtn",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: "merchantErpV2ContractUpdate",
                                          expression:
                                            "'merchantErpV2ContractUpdate'",
                                        },
                                      ],
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editcontract(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "class-name": "blue",
                                          "icon-class": "commonedit",
                                        },
                                      }),
                                      _vm._v("编辑"),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  (scope.row.status == 0 ||
                                    scope.row.status == 1) &&
                                  _vm.reductjson.erp_v2_contract == 1
                                    ? _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value:
                                                "merchantErpV2ContractSwitchStatus",
                                              expression:
                                                "'merchantErpV2ContractSwitchStatus'",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sendreview(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "class-name": "blue",
                                              "icon-class":
                                                "hetongfasongshenpi",
                                            },
                                          }),
                                          _vm._v("发送审批"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 2
                                    ? _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value:
                                                "merchantErpV2ContractRevokeApproval",
                                              expression:
                                                "'merchantErpV2ContractRevokeApproval'",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.cancelreview(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "class-name": "blue",
                                              "icon-class":
                                                "hetong-cehuishenpi ",
                                            },
                                          }),
                                          _vm._v("撤回审批\n            "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status != 4
                                    ? [
                                        scope.row.template_id
                                          ? _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "permission",
                                                    rawName: "v-permission",
                                                    value:
                                                      "merchantErpV2ContractDetail",
                                                    expression:
                                                      "'merchantErpV2ContractDetail'",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getdetail(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "class-name": "blue",
                                                    "icon-class":
                                                      "hetongyulanhetong",
                                                  },
                                                }),
                                                _vm._v("合同预览"),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.contract_type == 1
                                          ? _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "permission",
                                                    rawName: "v-permission",
                                                    value:
                                                      "merchantErpV2ContractDetail",
                                                    expression:
                                                      "'merchantErpV2ContractDetail'",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getdetail(
                                                      scope.row,
                                                      1
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "class-name": "blue",
                                                    "icon-class":
                                                      "hetongyulanhetong",
                                                  },
                                                }),
                                                _vm._v("查看附件"),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    : [
                                        scope.row.contract_type == 1 &&
                                        scope.row.template_id
                                          ? [
                                              _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "permission",
                                                      rawName: "v-permission",
                                                      value:
                                                        "merchantErpV2ContractDetail",
                                                      expression:
                                                        "'merchantErpV2ContractDetail'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "text",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getdetail(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "class-name": "blue",
                                                      "icon-class":
                                                        "hetongyulanhetong",
                                                    },
                                                  }),
                                                  _vm._v("合同预览"),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "permission",
                                                rawName: "v-permission",
                                                value:
                                                  "merchantErpV2ContractGetDownloadUrl",
                                                expression:
                                                  "'merchantErpV2ContractGetDownloadUrl'",
                                              },
                                            ],
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getdetaildownload(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "class-name": "blue",
                                                "icon-class": "commonlook",
                                              },
                                            }),
                                            _vm._v("查看合同"),
                                          ],
                                          1
                                        ),
                                      ],
                                  _vm._v(" "),
                                  scope.row.status == 3
                                    ? _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value:
                                                "merchantErpV2ContractRevokeSign",
                                              expression:
                                                "'merchantErpV2ContractRevokeSign'",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.cancelsigncontract(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "class-name": "blue",
                                              "icon-class":
                                                "hetong-cehuishenpi ",
                                            },
                                          }),
                                          _vm._v("撤销签约"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 2 &&
                                  _vm.reductjson.erp_v2_contract == 1
                                    ? _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value:
                                                "merchantErpV2ContractSwitchStatus",
                                              expression:
                                                "'merchantErpV2ContractSwitchStatus'",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.aduitreview(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "class-name": "blue",
                                              "icon-class": "common-shenhe ",
                                            },
                                          }),
                                          _vm._v("审核"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status != -1
                                    ? _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value:
                                                "merchantErpV2ContractSwitchStatus",
                                              expression:
                                                "'merchantErpV2ContractSwitchStatus'",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.losecontract(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "class-name": "blue",
                                              "icon-class": "hetongzuofei ",
                                            },
                                          }),
                                          _vm._v("作废"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 4
                                    ? _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value:
                                                "merchantErpV2ContractSwitchStatus",
                                              expression:
                                                "'merchantErpV2ContractSwitchStatus'",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.resetAuction(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "class-name": "blue",
                                              "icon-class": "hetongzuofei ",
                                            },
                                          }),
                                          _vm._v("作废重签"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == -1
                                    ? _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value:
                                                "merchantErpV2ContractDelete",
                                              expression:
                                                "'merchantErpV2ContractDelete'",
                                            },
                                          ],
                                          staticStyle: { color: "red" },
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delcontract(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              color: "red",
                                              "icon-class": "commondelete",
                                            },
                                          }),
                                          _vm._v("删除"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      812950542
                    ),
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c goodslist-page-box" },
        [
          _c(
            "div",
            { staticClass: "flex-one flex" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantSystemExcelExport:erpV2Contract",
                      expression: "'merchantSystemExcelExport:erpV2Contract'",
                    },
                  ],
                  staticStyle: { "margin-left": "4px" },
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-download",
                  },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出合同")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantSystemExcelExport:erpV2Contract",
                      expression: "'merchantSystemExcelExport:erpV2Contract'",
                    },
                  ],
                  attrs: { size: "small", icon: "el-icon-document" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出记录")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: { "margin-right": "10px" },
            attrs: {
              "page-sizes": [20, 40, 60, 80, 100],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes ",
              total: _vm.tableData.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.formValidate.id ? "编辑合同" : "创建合同",
            visible: _vm.createflag,
            width: "700px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.createflag = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "divBox" },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "合同类型：",
                        "label-width": "120px",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.formValidate.contract_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "contract_type", $$v)
                            },
                            expression: "formValidate.contract_type",
                          },
                        },
                        [_vm._v("电子合同")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.formValidate.contract_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "contract_type", $$v)
                            },
                            expression: "formValidate.contract_type",
                          },
                        },
                        [_vm._v("纸质合同")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "业务员：",
                        "label-width": "120px",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selwidth",
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择业务员",
                            clearable: "",
                            multiple: "",
                            filterable: "",
                          },
                          on: { change: _vm.saleChange },
                          model: {
                            value: _vm.formValidate.salesman,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "salesman", $$v)
                            },
                            expression: "formValidate.salesman",
                          },
                        },
                        _vm._l(_vm.adminarr, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "签约人：",
                        "label-width": "120px",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selwidth",
                          attrs: {
                            placeholder: "请选择签约人",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formValidate.sign_admin_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "sign_admin_id", $$v)
                            },
                            expression: "formValidate.sign_admin_id",
                          },
                        },
                        _vm._l(_vm.adminarr, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "合同模板：",
                        "label-width": "120px",
                        required: _vm.formValidate.contract_type == 2,
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selwidth",
                          attrs: {
                            placeholder: "请选择模板",
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.changetemp },
                          model: {
                            value: _vm.formValidate.template,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "template", $$v)
                            },
                            expression: "formValidate.template",
                          },
                        },
                        _vm._l(_vm.templist, function (item) {
                          return _c("el-option", {
                            key: item.template_id,
                            attrs: {
                              label: item.name,
                              value: item.template_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "委托人：",
                        "label-width": "120px",
                        required: "",
                      },
                    },
                    [
                      _vm.createflag
                        ? _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择委托人",
                                filterable: "",
                                remote: "",
                                "remote-method": _vm.searchUserAction,
                                loading: _vm.searchLoading,
                              },
                              on: { blur: _vm.createUserb },
                              model: {
                                value: _vm.formValidate.entrust,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "entrust", $$v)
                                },
                                expression: "formValidate.entrust",
                              },
                            },
                            [
                              _vm._l(_vm.userList, function (m, i) {
                                return _c(
                                  "el-option",
                                  {
                                    key: "user" + i,
                                    attrs: { value: m.uid, label: m.nickname },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        m.avatar
                                          ? _c("img", {
                                              staticStyle: {
                                                width: "40px",
                                                height: "40px",
                                                "border-radius": "50%",
                                                "margin-right": "12px",
                                              },
                                              attrs: { src: m.avatar, alt: "" },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              "line-height": "18px",
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(m.nickname) +
                                                  " "
                                              ),
                                              m.real_name
                                                ? _c("span", [
                                                    _vm._v(
                                                      "(" +
                                                        _vm._s(m.real_name) +
                                                        ")"
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _vm._v(_vm._s(m.phone)),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _c("template", { slot: "empty" }, [
                                _c("div", { staticClass: "select-empty" }, [
                                  _vm._v("没有此用户"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "select-button" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: { click: _vm.createUser },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  确认创建\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("创建委托方")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.categoryLabel && _vm.categoryLabel.length
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "货品标签：",
                            "label-width": "120px",
                            required: "",
                          },
                        },
                        [
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.categoryLabel,
                              props: _vm.categoryLabelProp,
                              "show-all-levels": false,
                              filterable: "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formValidate.label,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "label", $$v)
                              },
                              expression: "formValidate.label",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.goodsRuleConfigForm.erp_contract_create_special_switch ==
                  1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "特别：", "label-width": "120px" } },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.formValidate.is_special,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "is_special", $$v)
                              },
                              expression: "formValidate.is_special",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", "label-width": "120px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.cursoradd },
                        },
                        [_vm._v("下一步")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showpreview, width: "840px" },
          on: {
            "update:visible": function ($event) {
              _vm.showpreview = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "max-height": "70vh", "overflow-y": "auto" } },
            [
              _vm.contactImg && _vm.contactImg.length
                ? _vm._l(_vm.contactImg, function (m, i) {
                    return _c("div", { key: i }, [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { src: m, alt: "" },
                      }),
                    ])
                  })
                : _c("div", { attrs: { id: "printContent" } }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.html_content) },
                    }),
                  ]),
            ],
            2
          ),
          _vm._v(" "),
          _vm.contactUploadId
            ? _c(
                "div",
                { staticClass: "mt20 pb20 item-flex-center" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.bindPrint },
                    },
                    [_vm._v("打印合同")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "发送审批", visible: _vm.showreview, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showreview = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "divBox" },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "审批人",
                        "label-width": "120px",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selwidth",
                          attrs: {
                            placeholder: "请选择审批人",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.reviewAduitId,
                            callback: function ($$v) {
                              _vm.reviewAduitId = $$v
                            },
                            expression: "reviewAduitId",
                          },
                        },
                        _vm._l(_vm.reductjson.adminData, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", "label-width": "120px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.cursorsendreview },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.showChangeAuction.show,
            "show-close": false,
            width: "1200px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showChangeAuction, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("选择拍品后，重新发起合同")]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.showChangeAuction.data,
                    size: "small",
                    border: "",
                    "highlight-current-row": "",
                    width: "100%",
                  },
                  on: { "selection-change": _vm.selectionChange },
                },
                [
                  _c("el-table-column", {
                    key: "1",
                    attrs: {
                      type: "selection",
                      width: "40",
                      align: "center",
                      selectable: _vm.getStatusAction,
                    },
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.erpsettingList, function (item, index) {
                    return [
                      item.types == "erp_v2_contract_create_product"
                        ? [
                            item.field == "product_no"
                              ? [
                                  _c("el-table-column", {
                                    key: "erpsettingList_" + index,
                                    attrs: {
                                      label: item.name,
                                      prop: "product_no",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.erpProduct
                                                      ? scope.row.erpProduct
                                                          .product_no
                                                      : ""
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : item.field == "product_name"
                              ? [
                                  _c("el-table-column", {
                                    key: "erpsettingList_" + index,
                                    attrs: {
                                      label: item.name,
                                      prop: "product_name",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.erpProduct
                                                      ? scope.row.erpProduct
                                                          .product_name
                                                      : ""
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : item.field == "image"
                              ? [
                                  _c("el-table-column", {
                                    key: "erpsettingList_" + index,
                                    attrs: {
                                      label: "合同图片",
                                      width: "80",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.erpProduct &&
                                              scope.row.erpProduct
                                                .contract_image &&
                                              scope.row.erpProduct
                                                .contract_image[0]
                                                ? _c("el-image", {
                                                    attrs: {
                                                      src:
                                                        "" +
                                                        (scope.row.erpProduct
                                                          .contract_image[0] +
                                                          "!120a"),
                                                      "preview-src-list":
                                                        scope.row.erpProduct
                                                          .contract_image,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : item.field == "retain_price"
                              ? [
                                  _c("el-table-column", {
                                    key: "erpsettingList_" + index,
                                    attrs: { label: item.name },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.retain_price
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : item.field == "label"
                              ? [
                                  _c("el-table-column", {
                                    key: "erpsettingList_" + index,
                                    attrs: { label: item.name },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.erpProduct &&
                                                      scope.row.erpProduct
                                                        .labelNames &&
                                                      scope.row.erpProduct
                                                        .labelNames.length
                                                      ? scope.row.erpProduct.labelNames.join(
                                                          ","
                                                        )
                                                      : ""
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : item.newtype == "attribute"
                              ? [
                                  item.name == "尺寸"
                                    ? [
                                        _c("el-table-column", {
                                          key: "erpsettingList_" + index,
                                          attrs: {
                                            label: item.name,
                                            "show-overflow-tooltip": "",
                                            width: "100",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("div", {
                                                      staticClass: "nowrap cur",
                                                      staticStyle: {
                                                        height: "100%",
                                                      },
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          scope.row.erpProduct
                                                            .attribute[
                                                            item.field
                                                          ]
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ]
                                    : _c("el-table-column", {
                                        key: "erpsettingList_" + index,
                                        attrs: { label: item.name },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        scope.row.erpProduct
                                                          ? scope.row.erpProduct
                                                              .attribute[
                                                              item.field
                                                            ]
                                                          : ""
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      item.types == "store_attribute"
                        ? [
                            _c("el-table-column", {
                              key: "erpsettingList_" + index,
                              attrs: { label: item.name },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              scope.row.erpProduct
                                                ? scope.row.erpProduct
                                                    .attribute[item.field]
                                                : ""
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  _vm.inventoryConfig.switch.sold_price_switch
                    ? _c(
                        "el-table-column",
                        { attrs: { label: "成交收费", align: "center" } },
                        [
                          _vm._l(
                            _vm.inventoryConfig.price.sold_price,
                            function (item, index) {
                              return [
                                item.name
                                  ? _c("el-table-column", {
                                      key: "s_" + index,
                                      attrs: {
                                        "min-width": 100,
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(item.name) +
                                                    " " +
                                                    _vm._s(item.number) +
                                                    " " +
                                                    _vm._s(
                                                      item.unit == 1
                                                        ? "元"
                                                        : "%"
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      scope.row.success[index]
                                                        .number
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inventoryConfig.switch.unsold_price_switch
                    ? _c(
                        "el-table-column",
                        { attrs: { label: "未成交收费", align: "center" } },
                        [
                          _vm._l(
                            _vm.inventoryConfig.price.unsold_price,
                            function (item, index) {
                              return [
                                item.name
                                  ? _c("el-table-column", {
                                      key: "s_" + index,
                                      attrs: { align: "center" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(item.name) +
                                                    " " +
                                                    _vm._s(item.number) +
                                                    " " +
                                                    _vm._s(
                                                      item.unit == 1
                                                        ? "元"
                                                        : "%"
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      scope.row.fail[index]
                                                        .number
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inventoryConfig.switch.other_price_switch
                    ? _c(
                        "el-table-column",
                        { attrs: { label: "其他费用", align: "center" } },
                        [
                          _vm._l(
                            _vm.inventoryConfig.price.other_price,
                            function (item, index) {
                              return [
                                item.name
                                  ? _c("el-table-column", {
                                      key: "s_" + index,
                                      attrs: { align: "center" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(item.name) +
                                                    " " +
                                                    _vm._s(item.number) +
                                                    " " +
                                                    _vm._s(
                                                      item.unit == 1
                                                        ? "元"
                                                        : "%"
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      scope.row.other[index]
                                                        .number
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              staticStyle: { padding: "40px 0 30px 0" },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { padding: "15px 50px", "font-size": "20px" },
                  on: { click: _vm.cancelAction },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: {
                    padding: "15px 50px",
                    "font-size": "20px",
                    "margin-left": "20px",
                  },
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmAction },
                },
                [_vm._v("下一步")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: { visible: _vm.showconfig, direction: "rtl", size: "450px" },
          on: {
            "update:visible": function ($event) {
              _vm.showconfig = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _vm._l(_vm.showList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "show_" + index,
                    staticClass: "draw-list margin-t-16",
                    attrs: { draggable: "false" },
                  },
                  [
                    _c("el-checkbox", {
                      staticClass: "checkbox",
                      attrs: { disabled: item.type == "diabled" },
                      model: {
                        value: item.select,
                        callback: function ($$v) {
                          _vm.$set(item, "select", $$v)
                        },
                        expression: "item.select",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.hideList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "hide_" + index,
                    staticClass: "draw-list margin-t-16",
                    attrs: { draggable: "false" },
                  },
                  [
                    _c("el-checkbox", {
                      staticClass: "checkbox",
                      attrs: { disabled: item.type == "diabled" },
                      model: {
                        value: item.select,
                        callback: function ($$v) {
                          _vm.$set(item, "select", $$v)
                        },
                        expression: "item.select",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c("AddUserCom", { ref: "userREf" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }