"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _basic = require("@/api/basic");
var _accounts = require("@/api/accounts");
var _contract = require("@/api/contract");
var _settings = require("@/settings");
var _warehouse = require("@/api/warehouse");
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _SettlementPrintAccountNew = _interopRequireDefault(require("@/components/order/SettlementPrintAccountNew"));
var _AfterNoticePrint = _interopRequireDefault(require("@/components/order/AfterNoticePrint"));
var _system = require("@/api/system");
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
var _index = require("@/utils/index");
var _printJs = _interopRequireDefault(require("print-js"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import SettlementPrint from "@/components/order/SettlementPrintAccount";
var _default = exports.default = {
  name: 'Inventoryfinance',
  components: {
    fileList: _fileList.default,
    SettlementPrint: _SettlementPrintAccountNew.default,
    AfterNoticePrint: _AfterNoticePrint.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      orderno: '',
      showcert: false,
      formatPriceNumber: _index.formatPriceNumber,
      isNo: true,
      cert: {
        image: '',
        type: ''
      },
      checkjson: {
        addprice: '',
        cutprice: '',
        remark: ''
      },
      roterPre: _settings.roterPre,
      unitconfig: {
        1: '(元/件)',
        2: '%'
      },
      configflag: false,
      showconfig: false,
      logisticsName: 'refund',
      id: 0,
      type: '',
      tableData: {
        data: [],
        total: 0,
        rdata: []
      },
      listLoading: true,
      tableFrom: {
        username: '',
        type: '',
        date: '',
        page: 1,
        limit: 20,
        receipt_sn: '',
        order_type: '',
        keyword: '',
        status: '',
        deal_status: '',
        check_status: '',
        refund_status: ''
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      selectionList: [],
      tableFromLog: {
        page: 1,
        limit: 20
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      invoiceInfo: {},
      invoiceData: {
        number: '',
        mark: ''
      },
      inventoryConfig: {
        switch: {
          other_price_switch: 0,
          sold_price_switch: 0,
          unsold_price_switch: 0
        },
        price: {
          other_price: [{
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }],
          sold_price: [{
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }],
          unsold_price: [{
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }, {
            name: '',
            number: '',
            unit: 1
          }]
        }
      },
      inventoryDetailAll: {
        entrustOrderProduct: []
      },
      inventoryDetailFlag: false,
      showreturn: false,
      showdetail: false,
      reviewdetail: {},
      mannagename: '',
      changeOrder: [],
      editInventoryDetailFlag: false,
      editInventoryDetailAll: {
        entrustOrderProduct: []
      },
      lastAuction: [],
      lastAuctionId: '',
      searchType: 'venue_id',
      timeValue: [],
      showcheck: false,
      proptype: '1',
      propmoney: '0',
      propmark: '',
      checkstatus: 1,
      venueName: '',
      checkoneJson: {
        auction: [],
        check_auction_data: [],
        entrustUser: {},
        account: '',
        settlement_amount: ''
      },
      searchQuery: {},
      row: '',
      uid: '',
      entrust_check_order_people_number: 1,
      editNowPrice: {
        show: false
      },
      loadPre: false,
      showpreview: false,
      html_content: '',
      contactImg: '',
      contactUploadId: ''
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    this.getconfig();
    // this.getList(1);
    (0, _contract.getLastAuctionApi)().then(function (res) {
      _this2.lastAuction = res.data.list;
      if (localStorage.getItem('lastAuctionId')) {
        _this2.lastAuctionId = Number(localStorage.getItem('lastAuctionId'));
        _this2.venueName = localStorage.getItem('venueName');
        _this2.getList(1);
      } else if (res.data.list[0]) {
        _this2.lastAuctionId = res.data.list[0].venue_id;
        _this2.venueName = res.data.list[0].title;
        _this2.getList(1);
      }
    }).catch(function (res) {
      //
    });
    (0, _system.getConfigClassKeys)('entrust_check_order_config').then(function (res) {
      console.log(res);
      _this2.entrust_check_order_people_number = res.data.entrust_check_order_people_number;
    });
  },
  methods: {
    viewContractAction: function viewContractAction(row) {
      var _this3 = this;
      if (!this.$store.getters.userPression.merchantErpV2ContractDetail) {
        return this.$message.error('暂无权限，请联系管理员');
      }
      this.html_content = '';
      this.contactImg = '';
      this.contactUploadId = '';
      (0, _contract.contractV2DetailApi)(row.id).then(function (res) {
        if (res.data.template_id && res.data.content) {
          _this3.html_content = res.data.content.html_content.replace('width: 0px; height: 0px; overflow: hidden;', '');
          _this3.contactImg = '';
          _this3.contactUploadId = row.id;
          _this3.showpreview = true;
        } else if (res.data.contract_type == 1) {
          _this3.contactImg = res.data.contract_url ? res.data.contract_url.split(',') : [];
          _this3.showpreview = true;
        } else if (res.data.content) {
          _this3.html_content = res.data.content.html_content.replace('width: 0px; height: 0px; overflow: hidden;', '');
          _this3.contactImg = '';
          _this3.showpreview = true;
        }
      });
    },
    bindPrint: function bindPrint() {
      (0, _printJs.default)({
        printable: 'printContent',
        type: 'html',
        header: null,
        targetStyles: ['*'],
        style: 'body {font-size: 0.4em;font-weight: bold; line-height:0.82;margin:0;padding:0;}',
        font_size: 'llllll'
      });
    },
    onConfirmReconc: function onConfirmReconc(item) {
      var _this4 = this;
      this.$confirm("\u786E\u8BA4\u5E2E\u4E1A\u52A1\u786E\u8BA4\u5BF9\u8D26?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _accounts.entrustConfirmReconciliationApi)({
          ids: item.id
        }).then(function (res) {
          if (res.status == 200) {
            _this4.$message.success('操作成功');
            _this4.getList();
          }
        });
      });
    },
    editNowPriceAction: function editNowPriceAction(row, index, t) {
      var price;
      if (Number(row.auction_now_price)) {
        price = Number(row.auction_now_price);
      } else if (row.auction.now_price) {
        price = Number(row.auction.now_price);
      }
      this.editNowPrice = {
        show: true,
        data: price,
        row: row,
        index: index,
        type: t || ''
      };
    },
    saveAction: function saveAction() {
      var _this5 = this;
      if (!this.editNowPrice.data) {
        this.$message.error('请输入落槌价');
        return;
      }
      this.$confirm("\u786E\u8BA4\u4FEE\u6539\u843D\u69CC\u4EF7?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _accounts.updateAuctionNowPriceApi)({
          id: _this5.editNowPrice.row.id,
          auction_now_price: _this5.editNowPrice.data
        }).then(function (res) {
          if (res.status == 200) {
            _this5.$message.success('修改成功');
            // this.getPreAction();
            _this5.checkoneJson.check_auction_data[_this5.editNowPrice.index].auction_now_price = _this5.editNowPrice.data;
            _this5.checkoneJson.check_auction_data[_this5.editNowPrice.index].price_data.forEach(function (m, i) {
              m.forEach(function (n, j) {
                if (n.unit == 2 && n.number) {
                  _this5.onUnitChange(_this5.editNowPrice.index, i, j);
                }
              });
            });
            _this5.editNowPrice.show = false;
            _this5.getList();
          }
        });
      });
    },
    onUnitChange: function onUnitChange(tabInd, rowInd, index) {
      var row = this.checkoneJson.check_auction_data[tabInd];
      var num = Number(row.price_data[rowInd][index].number);
      var money = 0;
      if (Number(row.auction_now_price)) {
        money = Number(row.auction_now_price);
      } else if (row.auction.now_price) {
        money = Number(row.auction.now_price);
      }
      row.price_data[rowInd][index].deduction_amount = money / 100 * num;
      var sum = 0;
      if (row.auction_status <= 4) {
        row.price_data[1].forEach(function (item) {
          if (item.deduction_amount) sum += Number(item.deduction_amount);
        });
      } else {
        row.price_data[0].forEach(function (item) {
          console.log(item);
          if (item.deduction_amount) sum += Number(item.deduction_amount);
        });
      }
      row.price_data[2].forEach(function (item) {
        if (item.deduction_amount) sum += Number(item.deduction_amount);
      });
      row.settlement_amount = (money - sum).toFixed(2);
      this.$set(this.checkoneJson.check_auction_data, tabInd, row);
      this.handData(this.checkoneJson.check_auction_data);
      this.countmoney();
    },
    onAmountChange: function onAmountChange(tabInd, rowInd, index) {
      var row = this.checkoneJson.check_auction_data[tabInd];
      var acount = Number(row.price_data[rowInd][index].deduction_amount);
      var money = 0;
      if (Number(row.auction_now_price)) {
        money = Number(row.auction_now_price);
      } else if (row.auction.now_price) {
        money = Number(row.auction.now_price);
      }
      row.price_data[rowInd][index].number = ((acount / money * 100).toFixed(2) + '').replace('.00', '');
      if (row.price_data[rowInd][index].unit != 2) {
        row.price_data[rowInd][index].number = acount;
      }
      var sum = 0;
      if (row.auction_status <= 4) {
        row.price_data[1].forEach(function (item) {
          if (item.deduction_amount) sum += Number(item.deduction_amount);
        });
      } else {
        row.price_data[0].forEach(function (item) {
          console.log(item);
          if (item.deduction_amount) sum += Number(item.deduction_amount);
        });
      }
      row.price_data[2].forEach(function (item) {
        if (item.deduction_amount) sum += Number(item.deduction_amount);
      });
      row.settlement_amount = (money - sum).toFixed(2);
      this.$set(this.checkoneJson.check_auction_data, tabInd, row);
      this.handData(this.checkoneJson.check_auction_data);
      this.countmoney();
    },
    onDetails: function onDetails(row) {
      if (!row || row.uid === 8888) {
        // this.editOrderAction(row);
        return false;
      }
      this.row = row;
      this.uid = row.uid;
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    changecert: function changecert(row, type) {
      this.row = row;
      this.cert.type = type;
      this.showcert = true;
    },
    cutgoods: function cutgoods(index) {
      this.inventoryDetailAll.entrustOrderProductCheck.splice(index, 1);
      var count = 0;
      this.inventoryDetailAll.entrustOrderProductCheck.forEach(function (item) {
        count += item.should_pay_price;
      });
      this.inventoryDetailAll.count = count;
      if (count > 0) {
        this.checkjson.addprice = count.toFixed(2);
      } else {
        this.checkjson.cutprice = Math.abs(count).toFixed(2);
      }
    },
    getconfig: function getconfig() {
      var _this6 = this;
      (0, _basic.contractConfig)().then(function (res) {
        console.log(res);
        if (res.status == 200) {
          if (res.data.price && res.data.price.sold_price && res.data.price.sold_price.length > 1) {
            _this6.inventoryConfig = res.data;
            _this6.configflag = true;
            _this6.$forceUpdate();
          }
        }
      });
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: 'OrderList',
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      this.changeOrder = val;
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList('');
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList('');
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.changestatus();
      // this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num, cb) {
      var _this7 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      if (this.lastAuctionId) {
        this.tableFrom.venue_id = this.lastAuctionId;
      }
      // timeValue
      var data = JSON.parse(JSON.stringify(this.tableFrom));
      data.reconciliation_confirm_status = 0;
      if (this.timeValue && this.timeValue.length) {
        data.store_order_pay_date = "".concat((0, _index.parseTime)(this.timeValue[0], '{y}/{m}/{d}'), "-").concat((0, _index.parseTime)(this.timeValue[1], '{y}/{m}/{d}'));
      }
      (0, _accounts.entrustReconciliationListApi)(data).then(function (res) {
        _this7.orderChartType = res.data.stat;
        _this7.tableData.data = res.data.list;
        _this7.tableData.total = res.data.count;
        _this7.listLoading = false;
        if (cb) cb();
        _this7.$nextTick(function () {
          if (_this7.$refs.letterRef) _this7.$refs.letterRef.doLayout();
        });
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this7.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.page = 1;
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this8 = this;
      console.log(tit, num, i);
      var _this = this;
      this.$modalUpload(function (img) {
        console.log(img);
        _this8.cert.image = img[0];
        _this8.$forceUpdate();
      }, 1);
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this9 = this;
      this.tableFrom.export_type = 'exportEntrustReconciliation';
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this9.$createElement;
        _this9.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this9.$msgbox.close();
                _this9.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('exportEntrustReconciliation');
    },
    // printAction(item, source) {
    //   this.$refs["printView"].loadData(item.check_order_id, item.uid, source);
    // },
    printAllAction: function printAllAction(source) {
      if (!this.changeOrder.length) {
        this.$message.error('请选择对账记录');
        return false;
      }
      var error = '';
      // return
      var uid = this.changeOrder[0].entrust_uid;
      var paid = this.changeOrder[0].check_status;
      this.changeOrder.forEach(function (m) {
        if (m.entrust_uid !== uid) {
          error = '只能选择同一委托方的对账记录';
        }
        // if (m.check_status == 1) {
        //   error = '请选择未对账的记录';
        // }
        // if (m.check_order_id < 1) {
        //   error = "请选择已对账的记录";
        // }
      });
      if (error) {
        this.$message.error(error);
        return false;
      }
      var ids = this.changeOrder.map(function (m) {
        return m.id;
      });
      var item = this.changeOrder[0];
      this.$refs['printView'].loadData(ids.join(','), item.entrust_uid, source);
    },
    printAction: function printAction(item) {
      var error = '';
      this.$refs['printView'].loadData(item.id, item.entrust_uid, 'pay');
    },
    // printAfter(source) {
    //   if (!this.changeOrder.length) {
    //     this.$message.error("请选择对账记录");
    //     return false;
    //   }
    //   let error = "";
    //   // return
    //   const uid = this.changeOrder[0].entrust_uid;
    //   const paid = this.changeOrder[0].check_status;
    //   this.changeOrder.forEach((m) => {
    //     if (m.entrust_uid !== uid) {
    //       error = "只能选择同一委托方的对账记录";
    //     }
    //     if (m.check_status == 1) {
    //       error = "请选择未对账的记录";
    //     }
    //   });
    //   if (error) {
    //     this.$message.error(error);
    //     return false;
    //   }
    //   const ids = this.changeOrder.map((m) => m.id);
    //   const item = this.changeOrder[0];
    //   this.$refs["printAfterView"].loadData(ids.join(","), item.entrust_uid, source);
    // },
    onPayment: function onPayment() {
      var _this10 = this;
      if (!this.multipleSelection.length) {
        this.$message.error('请选择对账记录');
        return;
      }
      var index = this.multipleSelection.findIndex(function (item) {
        return item.is_create_extract_order != 0;
      });
      if (index != -1) {
        this.$message.error('请选择已对账未生成付款单的货品');
        return;
      }
      var ids = this.multipleSelection.map(function (item) {
        return item.id;
      });
      this.$confirm('确定创建付款单？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _contract.createExtractOrderApi)({
          check_auction_ids: ids.join(',')
        }).then(function (res) {
          _this10.multipleSelection = [];
          _this10.changeOrder = [];
          _this10.$message.success('操作成功');
        });
      }).catch(function () {});
    },
    onConfirmmore: function onConfirmmore() {
      if (!this.multipleSelection.length) {
        this.$message.error('请选择对账记录');
        return;
      }
      var euid = this.multipleSelection[0].entrust_uid;
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (euid != this.multipleSelection[i].entrust_uid) {
          this.$message.error('请选择同一委托对账记录');
          return;
        }
      }
      var selectarr = JSON.parse(JSON.stringify(this.multipleSelection));
      var ids = selectarr.map(function (item) {
        return item.id;
      }).join(',');
      this.onConfirmReconc({
        id: ids
      });
    },
    checkmore: function checkmore() {
      // console.log(this.multipleSelection);
      if (!this.multipleSelection.length) {
        this.$message.error('请选择对账记录');
        return;
      }
      var euid = this.multipleSelection[0].entrust_uid;
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (euid != this.multipleSelection[i].entrust_uid) {
          this.$message.error('请选择同一委托对账记录');
          return;
        }
      }
      var selectarr = JSON.parse(JSON.stringify(this.multipleSelection));
      this.ids = selectarr.map(function (item) {
        return item.id;
      }).join(',');
      this.isNo = true;
      this.checkstatus = 1;
      this.proptype = '1';
      this.propmoney = '0';
      this.propmark = '';
      this.loadPre = 'all';
      this.getPreAction();
      // console.log(this.checkoneJson);
    },
    handData: function handData(list) {
      var obj = {
        succ: 0,
        err: 0
      };
      var configPrice = JSON.parse(JSON.stringify(list[0].price_data));
      configPrice.forEach(function (item) {
        item.forEach(function (ele) {
          ele.deduction_amount = 0;
        });
      });
      var money = 0;
      list.forEach(function (item) {
        money += Number(item.settlement_amount);
        if (item.auction_status <= 4) {
          obj.err += 1;
        } else {
          obj.succ += 1;
        }
        item.price_data.forEach(function (a, i) {
          a.forEach(function (ele, index) {
            if (configPrice[i][index]) configPrice[i][index].deduction_amount += ele.deduction_amount ? Number(ele.deduction_amount) : 0;
          });
        });
      });
      this.checkoneJson.account = money.toFixed(2);
      this.$set(this.checkoneJson, 'configPrice', configPrice);
      this.$set(this.checkoneJson, 'obj', obj);
    },
    onReconc: function onReconc(row) {
      this.ids = row.id;
      this.checkstatus = 1;
      this.proptype = '1';
      this.propmoney = '0';
      this.propmark = '';
      this.checkoneJson.check_auction_data = [];
      this.loadPre = row.id;
      this.isNo = true;
      this.getPreAction();
      // this.checkoneJson.entrustUser = row.entrustUser;
      // this.checkoneJson.check_auction_data = [row];
      // this.checkoneJson.account = Number(row.settlement_amount).toFixed(2);
      // this.checkoneJson.settlement_amount = row.settlement_amount;
      // console.log(this.checkoneJson);
    },
    getPreAction: function getPreAction() {
      var _this11 = this;
      (0, _accounts.preReconciliationApi)({
        ids: this.ids
      }).then(function (res) {
        // res.data.reconciliationData.forEach(m => {
        //   m.settlement_amount = Number(m.settlement_amount) + Number(m.store_order_deduction_amount);
        // })
        _this11.checkoneJson.entrustUser = res.data.entrustUser;
        _this11.checkoneJson.check_auction_data = res.data.reconciliationData;
        _this11.loadPre = false;
        _this11.showcheck = true;
      }).catch(function () {
        _this11.loadPre = false;
      });
    },
    changeAuctionId: function changeAuctionId() {
      var _this12 = this;
      // 查找lastAuction里的id
      this.lastAuction.forEach(function (item) {
        if (item.venue_id == _this12.lastAuctionId) {
          _this12.venueName = item.title;
        }
      });
      localStorage.setItem('lastAuctionId', this.lastAuctionId);
      localStorage.setItem('venueName', this.venueName);
      this.getList(1);
    },
    getcheckOrder: function getcheckOrder(row) {
      var _this13 = this;
      if (!row.entrustCheckOrder) {
        return;
      }
      this.orderid = row.entrustCheckOrder.id;
      this.checkoneJson = {
        auction: [],
        check_auction_data: [],
        entrustUser: {},
        account: '',
        settlement_amount: ''
      }, (0, _contract.getCheckOrderDetailApi)({
        order_id: row.entrustCheckOrder.id
      }).then(function (res) {
        _this13.propmark = res.data.remark;
        _this13.propmoney = Math.abs(Number(res.data.extra_settlement_amount));
        if (Number(res.data.extra_settlement_amount) < 0) {
          _this13.proptype = '2';
        } else {
          _this13.proptype = '1';
        }
        var money = 0;
        res.data.check_auction_data.forEach(function (item) {
          money += Number(item.settlement_amount);
        });
        res.data.account = money.toFixed(2);
        // console.log('1---',res.data);
        _this13.checkoneJson = res.data;
        _this13.checkoneJson.settlement_amount = (money + Number(res.data.extra_settlement_amount)).toFixed(2);
      });
      this.showcheck = true;
      this.isNo = !!(row.entrustCheckOrder && row.entrustCheckOrder.check_order_status == 0);
      this.checkstatus = 2;
    },
    countmoney: function countmoney() {
      var money = Number(this.checkoneJson.account);
      if (this.proptype == '1') {
        money += Number(this.propmoney);
      } else if (this.proptype == '2') {
        money -= Number(this.propmoney);
      }
      this.checkoneJson.settlement_amount = money.toFixed(2);
    },
    cursorCheck: function cursorCheck() {
      var _this14 = this;
      // const str = this.proptype == '1' ? '+' : '-';
      // console.log(this.checkoneJson.check_auction_data);
      // return
      var arr = this.checkoneJson.check_auction_data.map(function (item) {
        return {
          id: item.id,
          price_data: item.price_data,
          store_order_deduction_amount: item.store_order_deduction_amount
        };
      });
      (0, _accounts.reconciliationApi)({
        reconciliationData: arr
      }).then(function (res) {
        _this14.$message.success('操作成功');
        _this14.multipleSelection = [];
        _this14.showcheck = false;
        _this14.getList();
      });
      // if (this.checkstatus == 1) {
      //   createCheckOrderApi({
      //     check_auction_ids: this.ids,
      //     extra_settlement_amount: str + this.propmoney,
      //     remark: this.propmark,
      //     check_auction_price_data: arr
      //   }).then(res => {
      //     this.$message.success('操作成功');
      //     this.showcheck = false;
      //     this.getList();
      //   });
      // } else if (this.checkstatus == 2) {
      //   createCompositeCheckOrderApi({
      //     order_id: this.orderid,
      //     extra_settlement_amount: str + this.propmoney,
      //     remark: this.propmark,
      //     check_auction_price_data: arr
      //   }).then(res => {
      //     this.$message.success('操作成功');
      //     this.showcheck = false;
      //     this.getList();
      //   });
      // }
    },
    checkSubmit: function checkSubmit() {
      var _this15 = this;
      var arr = this.checkoneJson.check_auction_data.map(function (item) {
        return {
          id: item.id,
          price_data: item.price_data,
          store_order_deduction_amount: item.store_order_deduction_amount
        };
      });
      (0, _accounts.updateReconciliationPriceDataApi)({
        reconciliationData: arr
      }).then(function (res) {
        // console.log(res);
        _this15.$message.success('操作成功');
        _this15.multipleSelection = [];
        _this15.showcheck = false;
        _this15.getList();
      });
    }
  }
};