"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _basic = require("@/api/basic");
var _warehouse = require("@/api/warehouse");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "stokcheck",
  data: function data() {
    return {
      showconfig: false,
      id: 0,
      type: "",
      showcreate: false,
      time: "",
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        username: "",
        type: "",
        page: 1,
        limit: 20,
        receipt_sn: "",
        order_type: "",
        keyword: "",
        status: "",
        task_id: this.$route.query.id || ''
      },
      searchQuery: {},
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      selectionList: [],
      loading: false,
      showcert: false,
      checkflag: false,
      certs: [],
      scangoods: {},
      stashno: '',
      searchLabelarr: []
    };
  },
  mounted: function mounted() {
    this.getList(1);
    this.getlabel();
    // this.getadminList();
    // this.getstore();
  },
  methods: {
    getlabel: function getlabel(val) {
      var _this2 = this;
      (0, _basic.labelLstApi)({
        page: 1,
        limit: 50,
        label_name: val || ''
      }).then(function (res) {
        _this2.searchLabelarr = res.data.list.map(function (m) {
          return {
            value: m.label_id,
            label: m.label_name
          };
        });
      }).catch(function (res) {
        // this.$message.error(res.message)
      });
    },
    getnogoods: function getnogoods(no) {
      var _this3 = this;
      if (!no) {
        return;
      }
      if (no.length < 10) {
        return;
      }
      (0, _warehouse.getnogoods)({
        store_no: no
      }).then(function (res) {
        if (res.status == 200) {
          _this3.scangoods = res.data;
          return;
        }
      });
    },
    scheck: function scheck() {
      this.scangoods.task_id = this.tableFrom.task_id;
      this.checkgood(this.scangoods);
    },
    checkgood: function checkgood(row) {
      var _this4 = this;
      (0, _warehouse.editrecordstatus)(row).then(function (res) {
        _this4.$message.success('操作成功');
        _this4.getList();
        _this4.checkflag = false;
      }).catch(function (msg) {
        _this4.$message.error(msg);
        _this4.checkflag = false;
      });
    },
    changecertflag: function changecertflag(row) {
      this.row = row;
      this.showcert = true;
      this.certs = [];
    },
    cursorcert: function cursorcert() {
      var _this5 = this;
      (0, _warehouse.upgoodscert)(this.row.record_id, {
        credentials: this.certs.join(',')
      }).then(function (res) {
        _this5.$message.success('操作成功');
        _this5.showcert = false;
        _this5.getList();
      });
    },
    changeTime: function changeTime(e) {
      console.log(e);
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: "OrderList",
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this6 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      var data = JSON.parse(JSON.stringify(this.tableFrom));
      data = Object.assign({}, data, this.searchQuery);
      (0, _warehouse.getcheckrecordlist)(data).then(function (res) {
        _this6.tableData.data = res.data.list;
        _this6.tableData.total = res.data.count;
        _this6.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this6.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this7 = this;
      console.log(tit, num, i);
      var _this = this;
      this.$modalUpload(function (img) {
        console.log(img);
        _this7.certs = _this7.certs.concat(img);
        _this7.$forceUpdate();
      }, 1);
    },
    searchAction: function searchAction(e) {
      this.searchQuery = JSON.parse(JSON.stringify(e));
      this.tableFrom.page = 1;
      this.getList();
    }
  }
};