"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.replace");
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.array.find");
var _objectSpread3 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _addUser = _interopRequireDefault(require("@/components/addUser.vue"));
var _basic = require("@/api/basic");
var _warehouse = require("@/api/warehouse");
var _product = require("@/api/product");
var _setting = require("@/api/setting");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Createoutorder',
  components: {
    AddUserCom: _addUser.default
  },
  data: function data() {
    return {
      loading: false,
      rules: {
        out_warehouse_id: [{
          required: true,
          message: '请选择仓库ID',
          trigger: 'change'
        }],
        manage_admin_id: [{
          required: false,
          message: '请选择业务负责人',
          trigger: 'change'
        }],
        out_type: [{
          required: true,
          message: '请选择出库类型',
          trigger: 'change'
        }]
      },
      formValidate: {
        out_warehouse_id: '',
        manage_admin_id: '',
        out_type: ''
      },
      timeVal: [],
      goodsData: [],
      selectFlag: false,
      tableFrom: {
        page: 1,
        limit: 20,
        cate_id: '',
        keyword: '',
        id: ''
      },
      tableData: {
        data: [],
        total: 0
      },
      merSelect: [],
      editFlag: false,
      num: 0,
      id: this.$route.query.id || '',
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        }
      },
      disabled: false,
      dialogVisible: false,
      dialogImageUrl: '',
      storeArr: [],
      adminarr: [],
      goodsArr: [],
      goodsIdArr: [],
      BrandList: [],
      attrList: [],
      attr: [],
      orderno: '',
      mannagename: '',
      searchQuery: {},
      userList: [],
      removeList: [],
      usershList: [],
      addrList: [],
      reductjson: {
        erp_v2_out_warehouse: '0',
        is_approval_admin: 0,
        adminData: []
      },
      reviewAduitId: '',
      isQuick: false,
      labelarr: [],
      searchLabelarr: [],
      pushheaderList: [],
      pushHideList: [],
      erpsettingList: [],
      handName: '',
      headObj: {},
      shelfList: [{
        shelf_id: 0,
        shelf_no: '请选择货架'
      }],
      showVisible: false,
      formArr: [],
      formState: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    // if (this.id) {
    //   this.getGoodsList();
    // }
    // this.getDetail();
    // getOrderNo().then(res=>{
    //   this.orderno = res.data.order_no
    // })

    this.getstore();
    this.getadminList();
    (0, _warehouse.getFieldSetAPI)({
      types: 'erp_v2_out_warehouse_create,erp_v2_contract_create_product'
    }).then(function (res) {
      if (res.data.erp_v2_out_warehouse_create) {
        res.data.erp_v2_out_warehouse_create.show_list.forEach(function (item) {
          if (/^\d*$/.test(item.field)) {
            item.newtype = 'attribute';
          }
        });
        _this.pushheaderList = res.data.erp_v2_out_warehouse_create.show_list;
        _this.pushHideList = res.data.erp_v2_out_warehouse_create.hide_list;
        // console.log(this.pushheaderList);
      }
      if (res.data.erp_v2_contract_create_product) {
        res.data.erp_v2_contract_create_product.show_list.forEach(function (item) {
          if (item.attr_type > 0) {
            // if(/^\d*$/.test(item.field)){
            item.newtype = 'attribute';
          }
        });
        _this.erpsettingList = res.data.erp_v2_contract_create_product.show_list;
      }
    });
    // this.getBrandListApi();
    // getBaseInfo()
    //   .then(res => {
    //     this.label = res.data;
    //     this.mannagename = res.data.merchantAdmin.real_name;
    //     if(res.data.isStaff){
    //       this.formValidate.manage_admin_id = res.data.merchantAdmin.merchant_admin_id;
    //     }
    //   })
    //   .catch(({ message }) => {
    //     //
    //   });
  },
  created: function created() {
    if (this.$route.query.quick) {
      this.isQuick = true;
      this.formValidate.out_type = 'out_warehouse_refund';
      var data = sessionStorage.getItem('outProduct');
      if (data) {
        var outProduct = JSON.parse(data);
        var d = [];
        outProduct.forEach(function (m) {
          d.push((0, _objectSpread3.default)((0, _objectSpread3.default)((0, _objectSpread3.default)({}, m.erpProduct), m), {}, {
            id: m.erp_product_id
          }));
        });
        this.goodsArr = d;
        this.goodsIdArr = this.goodsArr.map(function (m) {
          return m.id;
        });
      }
    }
    this.getlabel();
  },
  methods: {
    getlabel: function getlabel() {
      var _this2 = this;
      (0, _basic.labelLstApi)({
        page: 1,
        limit: 50
      }).then(function (res) {
        _this2.labelarr = res.data.list;
        _this2.searchLabelarr = res.data.list.map(function (m) {
          return {
            value: m.label_id,
            label: m.label_name
          };
        });
        var json = {};
        res.data.list.forEach(function (item) {
          json[item.label_id] = item.label_name;
        });
        _this2.labeljson = json;
      }).catch(function (res) {
        // this.$message.error(res.message)
      });
    },
    onSelectFilterChange: function onSelectFilterChange(val) {
      var _this3 = this;
      (0, _basic.labelLstApi)({
        page: 1,
        limit: 50,
        label_name: val
      }).then(function (res) {
        res.data.list.forEach(function (item) {
          item.label = item.label_name;
          item.value = item.label_id + '';
        });
        _this3.searchLabelarr = res.data.list;
      });
    },
    pushshelf: function pushshelf() {
      var _this4 = this;
      (0, _basic.shelfList)({
        warehouse_id: this.formValidate.out_warehouse_id,
        page: 1,
        limit: 200
      }).then(function (res) {
        res.data.list.unshift({
          shelf_id: 0,
          shelf_no: '请选择货架'
        });
        _this4.shelfList = res.data.list;
      });
    },
    onChangeOutType: function onChangeOutType() {
      var _this5 = this;
      (0, _setting.getApprovalApi)({
        type: "erp_v2_".concat(this.formValidate.out_type)
      }).then(function (res) {
        console.log(res);
        _this5.reductjson.erp_v2_out_warehouse = res.data["erp_v2_".concat(_this5.formValidate.out_type)];
        _this5.reductjson.adminData = res.data.adminData;
      });
    },
    onUserChange: function onUserChange() {
      var _this6 = this;
      (0, _user.userAddressListApi)({
        uid: this.formValidate.express_user_name
      }).then(function (res) {
        console.log(res);
        _this6.addrList = res.data.list;
      });
    },
    searchUserAction: function searchUserAction(data) {
      var _this7 = this;
      var params = {
        keyword: data,
        page: 1,
        size: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this7.usershList = res.data.list;
      });
    },
    createUser: function createUser() {
      this.$refs.userREf.adduser();
    },
    onUserAddre: function onUserAddre() {
      var _this8 = this;
      var row = this.addrList.find(function (item) {
        return item.detail == _this8.formValidate.express_user_address;
      });
      this.formValidate.express_user_phone = row.phone;
    },
    getInfoAction: function getInfoAction() {
      var _this9 = this;
      (0, _warehouse.getwarehouseOrderDetailApiV2)(this.id).then(function (res) {
        // console.log('1----0',res);
        _this9.searchUserAction(res.data.express_user_name);
        _this9.formValidate.out_warehouse_id = res.data.outWarehouse.warehouse_id;
        _this9.$set(_this9.formValidate, 'remark', res.data.remark);
        _this9.$set(_this9.formValidate, 'out_type', res.data.out_type);
        _this9.$set(_this9.formValidate, 'out_mode', res.data.out_mode);
        _this9.$set(_this9.formValidate, 'express_user_name', res.data.express_user_name);
        _this9.$set(_this9.formValidate, 'express_user_phone', res.data.express_user_phone);
        _this9.$set(_this9.formValidate, 'express_user_address', res.data.express_user_address);
        _this9.selectActiveGoods();
        res.data.warehouseOrderProduct.forEach(function (m) {
          m = (0, _objectSpread3.default)((0, _objectSpread3.default)((0, _objectSpread3.default)({}, m.erpProduct), m), {}, {
            id: m.erpProduct.id,
            label_ids: m.erpProduct.label_ids ? m.erpProduct.label_ids.split(',').map(function (item) {
              return Number(item);
            }) : []
          });
          m.edit = true;
          _this9.saleGoods(m, 'goods', '');
        });
      });
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    close: function close() {
      this.$router.back();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 品牌筛选；
    getBrandListApi: function getBrandListApi() {
      var _this10 = this;
      (0, _product.brandListApi)().then(function (res) {
        _this10.BrandList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getadminList: function getadminList() {
      var _this11 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this11.adminarr = res.data.list;
        _this11.userList = res.data.list.map(function (item) {
          return {
            label: item.real_name,
            value: item.merchant_admin_id
          };
        });
      });
    },
    getstore: function getstore() {
      var _this12 = this;
      (0, _product.getStoreList)().then(function (res) {
        _this12.storeArr = res.data.list;
        if (_this12.$route.query.id) {
          _this12.formValidate.id = _this12.$route.query.id;
          _this12.getInfoAction();
        } else if (res.data.list[0]) {
          _this12.searchUserAction('');
          // this.formValidate.out_warehouse_id = res.data.list[0].warehouse_id;
          (0, _warehouse.getLastWarehouseIdApi)({
            order_type: 2
          }).then(function (res) {
            _this12.formValidate.out_warehouse_id = res.data.warehouse_id;
            _this12.selectActiveGoods();
          });
        }
      });
    },
    submitForm: function submitForm(formName) {
      var _this13 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // if(!this.goodsIdArr.length){
          //    return this.$message.error('请选择货品')
          // }

          var goodsarr = JSON.parse(JSON.stringify(_this13.goodsArr));
          if (!goodsarr.length) {
            return _this13.$message.error('请选择货品');
          }
          // this.formValidate.productData = goodsarr;
          var json = {};
          json.out_id = _this13.formValidate.out_warehouse_id;
          json.warehouseProduct = goodsarr.map(function (item) {
            return {
              erp_product_id: item.id,
              product_no: item.product_no,
              shelf_id: item.shelf_id,
              warehouse_image: item.warehouse_image.join(','),
              product_name: item.product_name
            };
          });
          json.out_type = _this13.formValidate.out_type;
          json.remark = _this13.formValidate.remark;
          json.out_mode = _this13.formValidate.out_mode;
          json.express_user_name = _this13.formValidate.express_user_name;
          json.express_user_phone = _this13.formValidate.express_user_phone;
          json.express_user_address = _this13.formValidate.express_user_address;
          // json.out_mode = 'express';
          // this.formValidate.out_warehouse_id = this.formValidate.warehouse_id;
          if (_this13.reductjson.erp_v2_out_warehouse == 1) {
            if (!_this13.reviewAduitId) {
              _this13.$message.error('请选择审批人');
              return;
            }
            json.audit_admin_id = _this13.reviewAduitId;
          }
          _this13.loading = true;
          if (_this13.id) {
            (0, _warehouse.updateOutApi)(_this13.id, json).then(function (res) {
              _this13.loading = false;
              _this13.$message.success('保存成功');
              console.log(_this13.refser);
              if (_this13.refser) {
                _this13.$emit('savesuccess', res.message);
              } else {
                _this13.$router.back();
              }
            }).catch(function (res) {
              _this13.loading = false;
              // this.$message.error(res.message);
            });
            return;
          } else {
            (0, _warehouse.createoutOrderApiV2)(json).then(function (res) {
              _this13.loading = false;
              if (res.status == 200) {
                _this13.$message.success('保存成功');
                _this13.$router.back();
              }
            }).catch(function (res) {
              _this13.loading = false;
              // this.$message.error(res.message);
            });
          }
        } else {
          return false;
        }
      });
    },
    getDetail: function getDetail() {
      if (!this.id) {
        return;
      }
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      //   this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.formValidate.stime = e[0];
      this.formValidate.etime = e[1];
    },
    getList: function getList() {
      var _this14 = this;
      this.tableFrom.warehouse_id = this.formValidate.out_warehouse_id;
      this.tableFrom.product_warehouse_status = 2;
      (0, _basic.getgoodsListV2Api)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          item.selected = false;
          if (Array.isArray(item.attribute) && !item.attribute.length) {
            item.attribute = {};
          }
        });
        if (_this14.tableFrom.page == 1 && _this14.removeList.length > 0) {
          _this14.tableData.data = [].concat((0, _toConsumableArray2.default)(_this14.removeList), (0, _toConsumableArray2.default)(res.data.list));
        } else _this14.tableData.data = res.data.list;
        _this14.tableData.total = res.data.count;
        _this14.listLoading = false;
      }).catch(function (res) {
        _this14.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    selectInventGoods: function selectInventGoods() {
      this.selectFlag = true;
      this.getList();
      this.selectInventFlag = true;
    },
    selectActiveGoods: function selectActiveGoods() {
      if (!this.formValidate.out_warehouse_id) {
        this.$message.error('请选择仓库');
        return;
      }
      this.pushshelf();
      this.selectFlag = true;
      this.selectInventFlag = false;
      this.getList();
    },
    saleGoods: function saleGoods(row, index) {
      if (this.goodsIdArr.indexOf(row.id) > -1) {
        return;
      }
      this.row = JSON.parse(JSON.stringify(row));
      this.sindex = index;
      this.row.selected = true;
      this.goodsArr.push(Object.assign(row));
      this.goodsIdArr.push(row.id);
    },
    saleGoodsAll: function saleGoodsAll() {
      var _this15 = this;
      this.tableData.data.forEach(function (m, i) {
        _this15.saleGoods(m, i);
      });
    },
    closeSelect: function closeSelect() {
      this.selectFlag = false;
    },
    delGoods: function delGoods(index, row, cindex) {
      if (row.edit) {
        this.tableData.data.unshift(row);
        this.removeList.push(row);
      }
      var nindex = this.goodsIdArr.indexOf(row.id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
    },
    cancelSelect: function cancelSelect(index, row) {
      row.selected = false;
      var nindex = this.goodsIdArr.indexOf(row.id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
      return;
      row.selected = false;
      this.tableData.data.splice(index, 1);
      this.tableData.data.push(row);
    },
    onHeadChange: function onHeadChange(filed) {
      var _this16 = this;
      this.goodsArr.forEach(function (item, index) {
        item[filed] = _this16.headObj[filed];
        _this16.$set(_this16.goodsArr, index, item);
      });
      this.$forceUpdate();
    },
    headClick: function headClick(val) {
      this.handName = val;
      // this.$refs[val][0].focus();
    },
    focus: function focus(e) {
      e.currentTarget.select();
    },
    onClickSize: function onClickSize(value, index, row, field) {
      var arr = value ? value.split('\n') : [''];
      this.formState = {
        index: index,
        row: row,
        field: field
      };
      var newArr = [];
      arr.forEach(function (item) {
        if (item.indexOf('×') != -1) {
          var valArr = item.split('×');
          var long = valArr[0];
          var vice = 1;
          var showVice = false;
          var width = '';
          if (valArr[2]) {
            var numArr = valArr[2].split(' ');
            vice = Number(numArr[0].replace('幅', ''));
            width = valArr[1].replace('cm', '');
            showVice = true;
          } else {
            var widArr = valArr[1].split(' ');
            width = widArr[0].replace('cm', '');
          }
          newArr.push({
            toNum: 1,
            long: long,
            width: width,
            height: '',
            diam: '',
            vice: vice,
            showVice: showVice,
            sizeStr: item
          });
        } else {
          var _valArr = item.split('  ');
          console.log(_valArr);
          var _vice = 1;
          var _showVice = false;
          var _long = '';
          var _width = '';
          var height = '';
          var diam = '';
          _valArr.forEach(function (ele) {
            if (ele.indexOf('长(L)') != -1) {
              var str = ele.replace('长(L): ', '');
              _long = str.replace('cm', '');
            }
            if (ele.indexOf('宽(W)') != -1) {
              var _str = ele.replace('宽(W): ', '');
              _width = _str.replace('cm', '');
            }
            if (ele.indexOf('高(H)') != -1) {
              var _str2 = ele.replace('高(H): ', '');
              height = _str2.replace('cm', '');
            }
            if (ele.indexOf('直径(D)') != -1) {
              var _str3 = ele.replace('直径(D): ', '');
              diam = _str3.replace('cm', '');
            }
            if (ele.indexOf('数量') != -1) {
              var _str4 = ele.replace('数量: ', '');
              _vice = _str4.replace('件', '');
              _showVice = true;
            }
          });
          newArr.push({
            toNum: 1,
            long: _long,
            width: _width,
            height: height,
            diam: diam,
            vice: _vice,
            showVice: _showVice,
            sizeStr: item
          });
        }
      });
      console.log(newArr);
      this.formArr = newArr;
      this.showVisible = true;
    },
    onSubmit: function onSubmit() {
      var key = this.formState.field;
      var arr = this.formArr.map(function (item) {
        return item.sizeStr;
      });
      this.formState.row.attribute = (0, _objectSpread3.default)((0, _objectSpread3.default)({}, this.formState.row.attribute), {}, (0, _defineProperty2.default)({}, key, arr.join('\n')));
      this.$set(this.goodsArr, this.formState.index, this.formState.row);
      this.showVisible = false;
    },
    onChangeSize: function onChangeSize(row) {
      // console.log(this.formState);
      if (Number(row.width) > 0 && Number(row.long) > 0 && Number(row.vice) > 0 && !row.height && !row.diam) {
        if (row.showVice) {
          var num = (Number(row.width) * Number(row.long) * 0.0009).toFixed(row.toNum);
          row.sizeStr = "".concat(Number(row.long), "\xD7").concat(Number(row.width), "cm\xD7").concat(Number(row.vice), "\u5E45 \u7EA6").concat(num, "\u5E73\u5C3A").concat(Number(row.vice) > 1 ? '/幅' : '');
        } else {
          var _num = (Number(row.width) * Number(row.long) * Number(row.vice) * 0.0009).toFixed(row.toNum);
          row.sizeStr = "".concat(Number(row.long), "\xD7").concat(Number(row.width), "cm \u7EA6").concat(_num, "\u5E73\u5C3A");
        }
      } else {
        var str = '';
        if (row.long) str += "\u957F(L): ".concat(row.long, "cm  ");
        if (row.width) str += "\u5BBD(W): ".concat(row.width, "cm  ");
        if (row.height) str += "\u9AD8(H): ".concat(row.height, "cm  ");
        if (row.diam) str += "\u76F4\u5F84(D): ".concat(row.diam, "cm  ");
        if (row.showVice) str += "\u6570\u91CF: ".concat(row.vice, "\u4EF6");
        row.sizeStr = str;
      }
    },
    onAdd: function onAdd() {
      var row = this.formArr[this.formArr.length - 1];
      if (row.sizeStr) {
        this.formArr.push({
          toNum: 1,
          long: '',
          width: '',
          height: '',
          diam: '',
          vice: 1,
          showVice: false,
          sizeStr: ''
        });
      }
    }
  }
};