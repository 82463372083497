var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "set-width-auto",
      staticStyle: {
        color: "#000",
        "font-weight": "500",
        width: "1076px",
        margin: "0 auto",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            width: "1076px",
            overflow: "hidden",
            margin: "0 auto",
            "margin-top": "32px",
            "margin-left": "0",
            "justify-content": "space-between",
            "align-items": "center",
          },
        },
        [
          _c("div", { staticStyle: { float: "left" } }, [
            _c(
              "span",
              { staticStyle: { "font-size": "36px", color: "#000" } },
              [
                _vm._v("\n        " + _vm._s(_vm.companyName) + "\n        "),
                _c("span", { staticStyle: { "font-size": "16px" } }, [
                  _vm._v("委托拍卖合同"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                float: "right",
                "line-height": "48px",
                "font-size": "16px",
                color: "#000",
                "padding-top": "10px",
              },
            },
            [
              _c("span", [_vm._v("合同编号:")]),
              _vm._v(" "),
              _c("span", { staticStyle: { color: "#000" } }, [
                _vm._v(_vm._s(_vm.contractno || "自动生成")),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        staticStyle: {
          width: "1076px",
          height: "1px",
          "margin-top": "10px",
          background: "#000",
          "-webkit-print-color-adjust": "exact",
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticStyle: {
          width: "1076px",
          "margin-top": "2px",
          height: "1px",
          background: "#000",
          "-webkit-print-color-adjust": "exact",
        },
      }),
      _vm._v(" "),
      _vm.titleDisponsejson.place == 1 && _vm.titleDisponsejson.direc == 1
        ? _c(
            "div",
            {
              staticClass: "set-bhh",
              staticStyle: { width: "1076px", "margin-top": "24px" },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "1076px",
                    display: "flex",
                    "flex-wrap": "wrap",
                  },
                },
                _vm._l(_vm.owndata, function (item, index) {
                  return item.select
                    ? _c(
                        "div",
                        {
                          key: "own_t_" + index,
                          staticClass: "top-content set-flex",
                          staticStyle: {
                            display: "inline-block",
                            "font-size": "16px",
                            "font-weight": "500",
                            "margin-bottom": "24px",
                          },
                          style:
                            _vm.titleDisponsejson.linenum == 2
                              ? "width:50%"
                              : "width:33%",
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(item.value || item.name) + "："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "nr",
                              staticStyle: {
                                flex: "1",
                                color: "#828282",
                                margin: "0 20px 0 10px",
                              },
                            },
                            [_vm._v(_vm._s(item.input || ""))]
                          ),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "1076px",
                    display: "flex",
                    "flex-wrap": "wrap",
                    "padding-top": "20px",
                  },
                },
                _vm._l(_vm.otherdata, function (item, index) {
                  return item.select
                    ? _c(
                        "div",
                        {
                          key: "own_o_" + index,
                          staticClass: "top-content set-flex",
                          staticStyle: {
                            display: "inline-block",
                            "font-size": "16px",
                            "font-weight": "500",
                            "margin-bottom": "24px",
                          },
                          style:
                            item.name == "地址"
                              ? "width: 1076px;margin-right: " +
                                (_vm.titleDisponsejson.linenum == 2
                                  ? "0"
                                  : "1%") +
                                ";"
                              : _vm.titleDisponsejson.linenum == 2
                              ? "width:50%"
                              : "width:33%",
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(item.value || item.name) + "："),
                            item.name == "地址"
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "400",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v("（接收信函通知）")]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "nr",
                              staticStyle: {
                                flex: "1",
                                color: "#828282",
                                margin: "0 20px 0 10px",
                              },
                            },
                            [_vm._v(_vm._s(item.input || ""))]
                          ),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
            ]
          )
        : _vm.titleDisponsejson.place == 1 && _vm.titleDisponsejson.direc == 2
        ? _c(
            "div",
            {
              staticClass: "set-bhh",
              staticStyle: { width: "1076px", "margin-top": "24px" },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "1076px",
                    display: "flex",
                    "flex-wrap": "wrap",
                  },
                },
                _vm._l(_vm.otherdata, function (item, index) {
                  return item.select
                    ? _c(
                        "div",
                        {
                          key: "own_o_" + index,
                          staticClass: "top-content set-flex",
                          staticStyle: {
                            display: "inline-block",
                            "font-size": "16px",
                            "font-weight": "500",
                            "margin-bottom": "24px",
                          },
                          style:
                            item.name == "地址"
                              ? "width: 1076px;margin-right: " +
                                (_vm.titleDisponsejson.linenum == 2
                                  ? "0"
                                  : "1%") +
                                ";"
                              : _vm.titleDisponsejson.linenum == 2
                              ? "width:50%"
                              : "width:33%",
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(item.value || item.name) + "："),
                            item.name == "地址"
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "400",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v("（接收信函通知）")]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "nr",
                              staticStyle: {
                                flex: "1",
                                color: "#828282",
                                margin: "0 20px 0 10px",
                              },
                            },
                            [_vm._v(_vm._s(item.input || ""))]
                          ),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "1076px",
                    display: "flex",
                    "flex-wrap": "wrap",
                    "padding-top": "20px",
                  },
                },
                _vm._l(_vm.owndata, function (item, index) {
                  return item.select
                    ? _c(
                        "div",
                        {
                          key: "own_t_" + index,
                          staticClass: "top-content set-flex",
                          staticStyle: {
                            display: "inline-block",
                            "font-size": "16px",
                            "font-weight": "500",
                            "margin-bottom": "24px",
                          },
                          style:
                            _vm.titleDisponsejson.linenum == 2
                              ? "width:50%"
                              : "width:33%",
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(item.value || item.name) + "："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "nr",
                              staticStyle: {
                                flex: "1",
                                color: "#828282",
                                margin: "0 20px 0 10px",
                              },
                            },
                            [_vm._v(_vm._s(item.input || ""))]
                          ),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
            ]
          )
        : _vm.titleDisponsejson.place == 2 && _vm.titleDisponsejson.direc == 1
        ? _c(
            "div",
            { staticStyle: { width: "1076px", "margin-top": "24px" } },
            [
              _c(
                "div",
                { staticStyle: { width: "48%", display: "inline-block" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "font-size": "16px",
                        "font-weight": "500",
                      },
                    },
                    _vm._l(_vm.owndata, function (item, index) {
                      return item.select
                        ? _c(
                            "div",
                            {
                              key: "own_o_" + index,
                              staticStyle: { "margin-bottom": "24px" },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.value || item.name) + "："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "nr",
                                  staticStyle: {
                                    flex: "1",
                                    color: "#828282",
                                    margin: "0 20px 0 10px",
                                  },
                                },
                                [_vm._v(_vm._s(item.input || ""))]
                              ),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "48%", display: "inline-block" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "font-size": "16px",
                        "font-weight": "500",
                      },
                    },
                    _vm._l(_vm.otherdata, function (item, index) {
                      return item.select
                        ? _c(
                            "div",
                            {
                              key: "own_oh_" + index,
                              staticStyle: { "margin-bottom": "24px" },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.value || item.name) + "："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "nr",
                                  staticStyle: {
                                    flex: "1",
                                    color: "#828282",
                                    margin: "0 20px 0 10px",
                                  },
                                },
                                [_vm._v(_vm._s(item.input || ""))]
                              ),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                ]
              ),
            ]
          )
        : _vm.titleDisponsejson.place == 2 && _vm.titleDisponsejson.direc == 2
        ? _c(
            "div",
            {
              staticStyle: {
                width: "1076px",
                display: "flex",
                "margin-top": "24px",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "48%", display: "inline-block" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        "font-size": "16px",
                        "font-weight": "500",
                      },
                    },
                    _vm._l(_vm.otherdata, function (item, index) {
                      return item.select
                        ? _c(
                            "div",
                            {
                              key: "own_oh_" + index,
                              staticStyle: { "margin-bottom": "24px" },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.value || item.name) + "："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "nr",
                                  staticStyle: {
                                    flex: "1",
                                    color: "#828282",
                                    margin: "0 20px 0 10px",
                                  },
                                },
                                [_vm._v(_vm._s(item.input || ""))]
                              ),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "48%", display: "inline-block" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "1076px",
                        "font-size": "16px",
                        "font-weight": "500",
                      },
                    },
                    _vm._l(_vm.owndata, function (item, index) {
                      return item.select
                        ? _c(
                            "div",
                            {
                              key: "own_o_" + index,
                              staticStyle: { "margin-bottom": "24px" },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.value || item.name) + "："),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "nr",
                                  staticStyle: {
                                    flex: "1",
                                    color: "#828282",
                                    margin: "0 20px 0 10px",
                                  },
                                },
                                [_vm._v(_vm._s(item.input || ""))]
                              ),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticStyle: {
          width: "1076px",
          background: "#000",
          height: "1px",
          "margin-top": "0px",
          "-webkit-print-color-adjust": "exact",
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticStyle: {
          width: "1076px",
          "margin-top": "2px",
          height: "1px",
          background: "#000",
          "-webkit-print-color-adjust": "exact",
        },
      }),
      _vm._v(" "),
      _vm.expiryDateSwitch
        ? _c(
            "div",
            {
              staticStyle: {
                "margin-top": "24px",
                "font-size": "16px",
                "font-weight": "500",
              },
            },
            [
              _c("span", [_vm._v("合同有效期：")]),
              _vm._v(" "),
              _vm.timeVal.length
                ? _c("span", { staticStyle: { color: "#000" } }, [
                    _vm._v(
                      _vm._s(_vm.timeVal[0]) + " 至 " + _vm._s(_vm.timeVal[1])
                    ),
                  ])
                : _c("span", { staticStyle: { color: "#000" } }, [
                    _vm._v("- 年 - 月- 日 至 - 年 - 月- 日"),
                  ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            "margin-top": "24px",
            width: "1076px",
            "white-space": "normal",
            "word-break": "break-all",
          },
        },
        [
          _vm.content
            ? _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.entrustOrderSwitch
        ? _c(
            "div",
            { staticStyle: { "font-size": "16px", "margin-top": "24px" } },
            [_vm._v("委托标的清单：")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.entrustOrderSwitch
        ? _c(
            "div",
            { staticStyle: { width: "1076px" } },
            [
              _vm.catearr.length
                ? [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "24px",
                          border: "1px solid #ddd",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "bdqd-list",
                            staticStyle: {
                              "font-weight": "bold",
                              background: "#F9FCFF",
                              "line-height": "30px",
                              color: "#333",
                              "font-size": "12px",
                              padding: "5px 20px",
                              "-webkit-print-color-adjust": "exact",
                            },
                          },
                          [
                            _vm._l(_vm.erpsettingList, function (m, i) {
                              return [
                                m.field == "image"
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass: "w-long is-none",
                                          staticStyle: {
                                            "text-align": "center",
                                            display: "none",
                                          },
                                          style:
                                            "width: " +
                                            100 / _vm.erpsettingList.length +
                                            "%",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(m.name || m.origin_name)
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _c(
                                      "div",
                                      {
                                        key: i,
                                        class:
                                          m.field == "lot" ||
                                          m.field == "order_num"
                                            ? "w-sort"
                                            : "w-long",
                                        staticStyle: {
                                          "text-align": "center",
                                          display: "inline-block",
                                        },
                                        style:
                                          "width: " +
                                          100 / _vm.erpsettingList.length +
                                          "%",
                                      },
                                      [_vm._v(_vm._s(m.name || m.origin_name))]
                                    ),
                              ]
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.catearr, function (citem, cindex) {
                          return _c(
                            "div",
                            {
                              key: "ct_" + cindex,
                              staticClass: "bdqd-list",
                              staticStyle: {
                                overflow: "hidden",
                                "font-weight": "bold",
                                "-webkit-print-color-adjust": "exact",
                                "line-height": "18px",
                                "min-height": "48px",
                                color: "#333",
                                "font-size": "12px",
                                padding: "15px 20px",
                              },
                              style:
                                (cindex > 0
                                  ? "border-top:1px solid #ddd;"
                                  : "") +
                                (cindex % 2 == 0 ? "" : "background: #FCFBFC;"),
                            },
                            [
                              _vm._l(_vm.erpsettingList, function (m, i) {
                                return [
                                  m.field == "image"
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            key: i,
                                            staticClass: "w-long is-none",
                                            staticStyle: {
                                              "align-items": "center",
                                              display: "none",
                                              float: "left",
                                              "min-height": "18px",
                                            },
                                            style:
                                              "width: " +
                                              100 / _vm.erpsettingList.length +
                                              "%",
                                          },
                                          [
                                            citem.image && citem.image.length
                                              ? _c("img", {
                                                  staticStyle: {
                                                    display: "block",
                                                    height: "38px",
                                                    margin: "0 auto",
                                                  },
                                                  attrs: {
                                                    src:
                                                      citem.image[0] + "!m640",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    : m.attr_type > 0
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            key: i,
                                            staticClass: "w-long",
                                            staticStyle: {
                                              display: "block",
                                              "text-align": "center",
                                              float: "left",
                                              "min-height": "18px",
                                            },
                                            style:
                                              "width: " +
                                              100 / _vm.erpsettingList.length +
                                              "%",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(citem.attribute[m.field])
                                            ),
                                          ]
                                        ),
                                      ]
                                    : m.field == "category"
                                    ? _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass: "w-long",
                                          staticStyle: {
                                            "text-align": "center",
                                            display: "block",
                                            "min-height": "18px",
                                            float: "left",
                                          },
                                          style:
                                            "width: " +
                                            100 / _vm.erpsettingList.length +
                                            "%",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getCategoryName(citem.cate_id)
                                            )
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          key: i,
                                          class:
                                            m.field == "lot" ||
                                            m.field == "order_num"
                                              ? "w-sort"
                                              : "w-long",
                                          staticStyle: {
                                            "text-align": "center",
                                            display: "block",
                                            "min-height": "18px",
                                            float: "left",
                                          },
                                          style:
                                            "width: " +
                                            100 / _vm.erpsettingList.length +
                                            "%",
                                        },
                                        [_vm._v(_vm._s(citem[m.field]))]
                                      ),
                                ]
                              }),
                            ],
                            2
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-left": "20px",
                              color: "#999",
                              "font-size": "12px",
                              "line-height": "48px",
                              height: "48px",
                              "border-top": "1px solid #ddd",
                            },
                          },
                          [
                            _vm._v(
                              "\n          注：保留价如有调整，以调整后的保留价为准\n        "
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.catearr.length ? [_vm._m(0)] : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.entrustOrderSwitch
        ? _c(
            "div",
            { staticStyle: { "font-size": "16px", "margin-top": "40px" } },
            [_vm._v("拍卖标的成交收费标准设置：")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.entrustOrderSwitch
        ? _c(
            "div",
            {
              staticStyle: {
                "margin-top": "24px",
                border: "1px solid #ddd",
                width: "1076px",
              },
            },
            [
              _vm.catearr.length
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "bdqd-list",
                        staticStyle: {
                          width: "1076px",
                          "font-weight": "bold",
                          background: "#F9FCFF",
                          height: "60px",
                          color: "#333",
                          "font-size": "12px",
                          "-webkit-print-color-adjust": "exact",
                          "line-height": "30px",
                        },
                      },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-block",
                              width: "10%",
                              "text-align": "center",
                              "vertical-align": "top",
                            },
                          },
                          [_vm._v("保留价")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-block",
                              width: "68%",
                            },
                          },
                          [
                            _vm.inventoryConfig.switch.sold_price_switch
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: { display: "inline-block" },
                                    style:
                                      "width: " +
                                      (100 / _vm.priceCount - 0.5) +
                                      "%;",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v("成交收费")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "line-height": "17px",
                                        },
                                      },
                                      _vm._l(
                                        _vm.inventoryConfig.price.sold_price,
                                        function (item, index) {
                                          return item.name
                                            ? _c(
                                                "div",
                                                {
                                                  key: "s_" + index,
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    "text-align": "center",
                                                  },
                                                  style:
                                                    "width: " +
                                                    (100 /
                                                      _vm.inventoryConfig.price
                                                        .sold_price.length -
                                                      1) +
                                                    "%",
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.inventoryConfig.switch.unsold_price_switch
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: { display: "inline-block" },
                                    style:
                                      "width: " +
                                      (100 / _vm.priceCount - 0.5) +
                                      "%;",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v("未成交收费")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "line-height": "17px",
                                        },
                                      },
                                      _vm._l(
                                        _vm.inventoryConfig.price.unsold_price,
                                        function (item, index) {
                                          return item.name
                                            ? _c(
                                                "div",
                                                {
                                                  key: "s_" + index,
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    "text-align": "center",
                                                  },
                                                  style:
                                                    "width: " +
                                                    (100 /
                                                      _vm.inventoryConfig.price
                                                        .unsold_price.length -
                                                      1) +
                                                    "%",
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.inventoryConfig.switch.other_price_switch
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: { display: "inline-block" },
                                    style:
                                      "width: " +
                                      (100 / _vm.priceCount - 0.5) +
                                      "%;",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v("其他收费")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "line-height": "17px",
                                        },
                                      },
                                      _vm._l(
                                        _vm.inventoryConfig.price.other_price,
                                        function (item, index) {
                                          return item.name
                                            ? _c(
                                                "div",
                                                {
                                                  key: "s_" + index,
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    "text-align": "center",
                                                  },
                                                  style:
                                                    "width: " +
                                                    (100 /
                                                      _vm.inventoryConfig.price
                                                        .other_price.length -
                                                      1) +
                                                    "%",
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.catearr, function (citem, cindex) {
                      return [
                        _c(
                          "div",
                          {
                            key: "ct_" + cindex,
                            staticStyle: {
                              width: "1076px",
                              color: "#333",
                              "font-size": "12px",
                              "-webkit-print-color-adjust": "exact",
                            },
                            style:
                              (cindex > 0 ? "border-top:1px solid #ddd;" : "") +
                              (cindex % 2 == 0 ? "" : "background: #FCFBFC;"),
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "20%",
                                  "margin-left": "1%",
                                  "line-height": "60px",
                                  "vertical-align": "top",
                                },
                              },
                              [_vm._v(_vm._s(citem.product_name))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "10%",
                                  "text-align": "center",
                                  "line-height": "60px",
                                  "vertical-align": "top",
                                },
                              },
                              [_vm._v(_vm._s(citem.retain_price))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "68%",
                                },
                              },
                              [
                                _vm.inventoryConfig.switch.sold_price_switch
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                        style:
                                          "width: " +
                                          (100 / _vm.priceCount - 0.5) +
                                          "%;",
                                      },
                                      _vm._l(
                                        _vm.inventoryConfig.price.sold_price,
                                        function (item, index) {
                                          return item.name
                                            ? _c(
                                                "div",
                                                {
                                                  key: "s_" + index,
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    "text-align": "center",
                                                    "line-height": "60px",
                                                  },
                                                  style:
                                                    "width: " +
                                                    (100 /
                                                      _vm.inventoryConfig.price
                                                        .sold_price.length -
                                                      1) +
                                                    "%",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        citem.success[index]
                                                          .number
                                                      ) +
                                                      _vm._s(
                                                        citem.success[index]
                                                          .unit == 2
                                                          ? "%"
                                                          : "元"
                                                      ) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.inventoryConfig.switch.unsold_price_switch
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                        style:
                                          "width: " +
                                          (100 / _vm.priceCount - 0.5) +
                                          "%;",
                                      },
                                      _vm._l(
                                        _vm.inventoryConfig.price.unsold_price,
                                        function (item, index) {
                                          return item.name
                                            ? _c(
                                                "div",
                                                {
                                                  key: "s_" + index,
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    "text-align": "center",
                                                    "line-height": "60px",
                                                  },
                                                  style:
                                                    "width: " +
                                                    (100 /
                                                      _vm.inventoryConfig.price
                                                        .unsold_price.length -
                                                      1) +
                                                    "%",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        citem.fail[index].number
                                                      ) +
                                                      _vm._s(
                                                        citem.fail[index]
                                                          .unit == 2
                                                          ? "%"
                                                          : "元"
                                                      ) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.inventoryConfig.switch.other_price_switch
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                        style:
                                          "width: " +
                                          (100 / _vm.priceCount - 0.5) +
                                          "%;",
                                      },
                                      _vm._l(
                                        _vm.inventoryConfig.price.other_price,
                                        function (item, index) {
                                          return item.name
                                            ? _c(
                                                "div",
                                                {
                                                  key: "s_" + index,
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    "text-align": "center",
                                                    "line-height": "60px",
                                                  },
                                                  style:
                                                    "width: " +
                                                    (100 /
                                                      _vm.inventoryConfig.price
                                                        .other_price.length -
                                                      1) +
                                                    "%",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        citem.other[index]
                                                          .number
                                                      ) +
                                                      _vm._s(
                                                        citem.other[index]
                                                          .unit == 2
                                                          ? "%"
                                                          : "元"
                                                      ) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]
                    }),
                  ]
                : [_vm._m(2), _vm._v(" "), _vm._m(3)],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.signDisponsejson.direc == 1
        ? _c(
            "div",
            { staticStyle: { width: "1076px", "margin-top": "38px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    "vertical-align": "top",
                  },
                  style:
                    _vm.signDisponsejson.place == 1
                      ? "width: 1076px;"
                      : "width: 48%",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.ownsigndata[0].value || _vm.ownsigndata[0].name
                          ) +
                          "（签章）："
                      ),
                      _c("span", { staticStyle: { color: "#000" } }, [
                        _vm._v(_vm._s(_vm.ownsigndata[0].input || "")),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.ownsigndata[1].value || _vm.ownsigndata[1].name
                          ) +
                          "："
                      ),
                      _c("span", { staticStyle: { color: "#000" } }, [
                        _vm._v(_vm._s(_vm.ownsigndata[1].input || "")),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.formatDate(_vm.nowdate, "{y}年{m}月{d}日")
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                        "margin-bottom": "24px",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.ownsigndata[2].input || "") +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    "vertical-align": "top",
                  },
                  style:
                    _vm.signDisponsejson.place == 1
                      ? "width: 1076px;"
                      : "width: 48%",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.othersigndata[0].value ||
                              _vm.othersigndata[0].name
                          ) +
                          "（签章）："
                      ),
                      _c("span", { staticStyle: { color: "#000" } }, [
                        _vm._v(_vm._s(_vm.othersigndata[0].input || "")),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.othersigndata[1].select
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "margin-top": "24px",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.othersigndata[1].value ||
                                  _vm.othersigndata[1].name
                              ) +
                              "："
                          ),
                          _c("span", { staticStyle: { color: "#000" } }, [
                            _vm._v(_vm._s(_vm.othersigndata[1].input || "")),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.othersigndata[2].select
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "margin-top": "12px",
                            "padding-right": "24px",
                            "font-weight": "400",
                            "margin-bottom": "24px",
                            "white-space": "pre-wrap",
                          },
                        },
                        [_vm._v(_vm._s(_vm.othersigndata[2].value || ""))]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm.signDisponsejson.direc == 2
        ? _c(
            "div",
            {
              staticStyle: {
                width: "1076px",
                "margin-top": "38px",
                "margin-bottom": "38px",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    "vertical-align": "top",
                  },
                  style:
                    _vm.signDisponsejson.place == 1
                      ? "width: 1076px;"
                      : "width: 48%",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.othersigndata[0].value ||
                              _vm.othersigndata[0].name
                          ) +
                          "（签章）："
                      ),
                      _c("span", { staticStyle: { color: "#000" } }, [
                        _vm._v(_vm._s(_vm.othersigndata[0].input || "")),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.othersigndata[1].select
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "margin-top": "24px",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.othersigndata[1].value ||
                                  _vm.othersigndata[1].name
                              ) +
                              "："
                          ),
                          _c("span", { staticStyle: { color: "#000" } }, [
                            _vm._v(_vm._s(_vm.othersigndata[1].input || "")),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.othersigndata[2].select
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "margin-top": "12px",
                            "padding-right": "24px",
                            "font-weight": "400",
                            "margin-bottom": "24px",
                            "white-space": "pre-wrap",
                          },
                        },
                        [_vm._v(_vm._s(_vm.othersigndata[2].value || ""))]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    "vertical-align": "top",
                  },
                  style:
                    _vm.signDisponsejson.place == 1
                      ? "width: 1076px;"
                      : "width: 48%",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.ownsigndata[0].value || _vm.ownsigndata[0].name
                          ) +
                          "（签章）："
                      ),
                      _c("span", { staticStyle: { color: "#000" } }, [
                        _vm._v(_vm._s(_vm.ownsigndata[0].input || "")),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm.ownsigndata[1].select
                        ? [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.ownsigndata[1].value ||
                                    _vm.ownsigndata[1].name
                                ) +
                                "："
                            ),
                            _c("span", { staticStyle: { color: "#000" } }, [
                              _vm._v(_vm._s(_vm.ownsigndata[1].input || "")),
                            ]),
                          ]
                        : [_vm._v("\n             \n        ")],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.formatDate(_vm.nowdate, "{y}年{m}月{d}日")
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm.ownsigndata[2].select
                        ? [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.ownsigndata[2].value ||
                                    _vm.ownsigndata[2].name
                                ) +
                                "\n        "
                            ),
                          ]
                        : [_vm._v("\n             \n        ")],
                    ],
                    2
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { "page-break-after": "always" } }, [
        _vm.attachmentContent
          ? _c(
              "div",
              { staticStyle: { "font-size": "22px", "font-weight": "600" } },
              [_vm._v("合同附件")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-top": "24px", width: "1076px" } }, [
          _vm.attachmentContent
            ? _c("div", {
                domProps: { innerHTML: _vm._s(_vm.attachmentContent) },
              })
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "margin-top": "24px", border: "1px solid #ddd" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              "font-weight": "bold",
              background: "#F9FCFF",
              height: "40px",
              color: "#333",
              "font-size": "12px",
              padding: "0 20px",
              "-webkit-print-color-adjust": "exact",
              display: "flex",
              "align-items": "center",
            },
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  width: "50px",
                  "text-align": "center",
                  "line-height": "40px",
                },
              },
              [_vm._v("序号")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  "text-align": "center",
                  "line-height": "40px",
                },
              },
              [_vm._v("图录号")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  "text-align": "center",
                  "line-height": "40px",
                },
              },
              [_vm._v("作者/年代")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  "text-align": "left",
                  "margin-left": "20px",
                  "line-height": "40px",
                },
              },
              [_vm._v("作品名称")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  "text-align": "center",
                  "line-height": "40px",
                },
              },
              [_vm._v("质地/形式")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  "text-align": "center",
                  "line-height": "40px",
                },
              },
              [_vm._v("尺寸 (cm)")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  "text-align": "center",
                  "line-height": "40px",
                },
              },
              [_vm._v("保留价 (元)")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "132px", "line-height": "40px" } },
              [_vm._v("备注")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "font-weight": "bold",
              height: "48px",
              color: "#333",
              "font-size": "12px",
              padding: "0 20px",
              "-webkit-print-color-adjust": "exact",
              display: "flex",
              "align-items": "center",
            },
          },
          [
            _c("div", {
              staticStyle: {
                width: "50px",
                "text-align": "center",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "left",
                "margin-left": "20px",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "132px", "line-height": "40px" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "border-top": "1px solid #ddd",
              background: "#FCFBFC",
              "font-weight": "bold",
              height: "48px",
              color: "#333",
              "font-size": "12px",
              padding: "0 20px",
              "-webkit-print-color-adjust": "exact",
              display: "flex",
              "align-items": "center",
            },
          },
          [
            _c("div", {
              staticStyle: {
                width: "50px",
                "text-align": "center",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "left",
                "margin-left": "20px",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "132px", "line-height": "40px" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "padding-left": "20px",
              color: "#999",
              "font-size": "12px",
              display: "flex",
              "align-items": "center",
              height: "48px",
              "border-top": "1px solid #ddd",
            },
          },
          [
            _vm._v(
              "\n          注：保留价如有调整，以调整后的保留价为准\n        "
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "inline-block",
          width: "20%",
          "margin-left": "1%",
          "vertical-align": "top",
        },
      },
      [_c("span", [_vm._v("商品名称")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "font-weight": "bold",
          background: "#F9FCFF",
          height: "60px",
          color: "#333",
          "font-size": "12px",
          padding: "0 20px",
          "-webkit-print-color-adjust": "exact",
          display: "flex",
          "align-items": "center",
        },
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "220px",
              "line-height": "60px",
              "vertical-align": "top",
            },
          },
          [_c("span", [_vm._v("商品名称")])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              width: "86px",
              "text-align": "center",
              "line-height": "60px",
              "vertical-align": "top",
            },
          },
          [_vm._v("保留价")]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "230px", "line-height": "30px" } }, [
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "line-height": "17px",
                "margin-top": "10px",
              },
            },
            [_vm._v("成交收费")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                  },
                },
                [_vm._v("装裱费")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                  },
                },
                [_vm._v("佣金")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                  },
                },
                [_vm._v("图录费")]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "230px", "line-height": "30px" } }, [
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "line-height": "17px",
                "margin-top": "10px",
              },
            },
            [_vm._v("未成交收费")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                  },
                },
                [_vm._v("装裱费")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                  },
                },
                [_vm._v("保留价佣金")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                  },
                },
                [_vm._v("图录费")]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "230px", "line-height": "30px" } }, [
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "line-height": "17px",
                "margin-top": "10px",
              },
            },
            [_vm._v("其他收费")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                  },
                },
                [_vm._v("装裱费")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                  },
                },
                [_vm._v("佣金")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                  },
                },
                [_vm._v("图录费")]
              ),
            ]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "space-between",
          color: "#333",
          "font-size": "12px",
          padding: "0 20px",
          "-webkit-print-color-adjust": "exact",
        },
      },
      [
        _c("div", { staticStyle: { width: "230px" } }),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "120px", "text-align": "center" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              width: "230px",
              "justify-content": "space-between",
            },
          },
          [
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              width: "230px",
              "justify-content": "space-between",
            },
          },
          [
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              width: "230px",
              "justify-content": "space-between",
            },
          },
          [
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }