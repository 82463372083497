"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ruleData: {
        erp_system_reference_price_config: []
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _system.getConfigClassKeys)('auction_rule_set').then(function (res) {
      if (res.data && (res.data.order_is_valuation || res.data.order_is_valuation === 0)) {
        if (res.data.auction_picture_show === '') {
          res.data.auction_picture_show = '1';
        }
        if (res.data.auction_examine) res.data.auction_examine = Number(res.data.auction_examine);
        if (res.data.price_format) res.data.price_format = Number(res.data.price_format);
        if (res.data.compel_anonymous) res.data.compel_anonymous = Number(res.data.compel_anonymous);
        if (res.data.nickname_anonymous_type) res.data.nickname_anonymous_type = Number(res.data.nickname_anonymous_type);
        if (res.data.avatar_anonymous_type) res.data.avatar_anonymous_type = Number(res.data.avatar_anonymous_type);
        if (res.data.bid_confirm) res.data.bid_confirm = Number(res.data.bid_confirm);
        if (res.data.negotiate_purchase_switch) res.data.negotiate_purchase_switch = Number(res.data.negotiate_purchase_switch);
        if (res.data.confirm_network_bidding) res.data.confirm_network_bidding = Number(res.data.confirm_network_bidding);
        if (res.data.finish_confirm_number) res.data.finish_confirm_number = Number(res.data.finish_confirm_number);
        if (res.data.finish_show_transaction_price) res.data.finish_show_transaction_price = Number(res.data.finish_show_transaction_price);
        if (res.data.confirm_start_price) res.data.confirm_start_price = Number(res.data.confirm_start_price);
        if (res.data.order_signing_agreement) res.data.order_signing_agreement = Number(res.data.order_signing_agreement);
        if (res.data.liveEndNotShowAlbumData) res.data.liveEndNotShowAlbumData = Number(res.data.liveEndNotShowAlbumData);
        if (res.data.auction_entrust_self_transaction_is_juhui) res.data.auction_entrust_self_transaction_is_juhui = Number(res.data.auction_entrust_self_transaction_is_juhui);
        if (res.data.printPhotos) res.data.printPhotos = Number(res.data.printPhotos);
        if (res.data.auction_image_show_message) res.data.auction_image_show_message = Number(res.data.auction_image_show_message);
        if (res.data.auction_refund_entrust_deliver_sign) res.data.auction_refund_entrust_deliver_sign = Number(res.data.auction_refund_entrust_deliver_sign);
        if (res.data.settlementRatio) res.data.settlementRatio = Number(res.data.settlementRatio);
        if (res.data.auction_end_retain_time) res.data.auction_end_retain_time = Number(res.data.auction_end_retain_time);
        if (res.data.erp_system_reference_price_config) res.data.erp_system_reference_price_config = res.data.erp_system_reference_price_config.split(',');else res.data.erp_system_reference_price_config = [];
        if (res.data.check_agreement === '') {
          res.data.check_agreement = '0';
        }
        if (res.data.isOpenEntrustDelivery === '') {
          res.data.isOpenEntrustDelivery = 0;
        }
        if (res.data.confirm_network_bidding === '') {
          res.data.confirm_network_bidding = 0;
        }
        if (res.data.auction_image_show_message === '') {
          res.data.auction_image_show_message = 1;
        }
        if (res.data.auction_refund_entrust_deliver_sign === '') {
          res.data.auction_refund_entrust_deliver_sign = 0;
        }
        if (res.data.settlementRatio === '') {
          res.data.settlementRatio = 0;
        }
        if (res.data.auction_end_retain_time === '') {
          res.data.auction_end_retain_time = 1;
        }
        if (!res.data.pm_join_room_nickname_tips) res.data.pm_join_room_nickname_tips = 2;
        if (!res.data.lot_view_type) res.data.lot_view_type = '1';
        if (!res.data.specialNumberCreateOrderSwitch) res.data.specialNumberCreateOrderSwitch = '0';
        _this.ruleData = res.data;
      } else {
        _this.ruleData = {
          auction_examine: '',
          avatar_anonymous_type: 2,
          bid_confirm: 0,
          auction_picture_show: '1',
          dialog_title: '',
          compel_anonymous: 1,
          confirm_network_bidding: 0,
          confirm_start_price: 1,
          finish_show_transaction_price: 0,
          delivery_description: '',
          finish_confirm_number: 1,
          invoice_description: '',
          invoice_reminder: 0,
          is_open_invoice: 1,
          negotiate_purchase_switch: 0,
          nickname_anonymous_type: 3,
          order_auto_confirm_receive_day: 0,
          order_is_valuation: 2,
          order_refund: 0,
          order_signing_agreement: 1,
          order_timeout: 0,
          pay_tail_max_use_point: 0,
          pay_tail_use_point: 0,
          price_format: 1,
          product_order_auto_confirm_receive_day: 0,
          product_order_refund: 0,
          product_order_timeout: 0,
          refund_address_info: '',
          refund_address_name: '',
          refund_address_phone: '',
          reset_time: 0,
          transfer_album_id: '',
          transfer_live_id: '',
          transfer_video_costom_id: '',
          trigger_reset_time: 0,
          auction_image_show_message: 1,
          auction_refund_entrust_deliver_sign: 0,
          settlementRatio: 0,
          auction_end_retain_time: 1,
          erp_system_reference_price_config: [],
          pm_join_room_nickname_tips: 2,
          lot_view_type: '1',
          specialNumberCreateOrderSwitch: '0'
        };
      }
    }).catch(function () {});
  },
  methods: {
    saveAction: function saveAction() {
      var _this2 = this;
      // if (Number(this.ruleData.order_auto_confirm_receive_day)) {
      //   if (
      //     Number(this.ruleData.order_auto_confirm_receive_day) < 7 ||
      //     Number(this.ruleData.order_auto_confirm_receive_day) > 30
      //   ) {
      //     this.$message.error('自动收货时间只能设置7-30天之间');
      //     return false;
      //   }
      // } else {
      //   this.ruleData.order_auto_confirm_receive_day = 0;
      // }
      var d = JSON.parse(JSON.stringify(this.ruleData));
      if (!d.order_auto_confirm_receive_day) d.order_auto_confirm_receive_day = 0;
      if (!d.product_order_refund) d.product_order_refund = 0;
      if (!d.order_refund) d.order_refund = 0;
      if (!d.bid_confirm) d.bid_confirm = 0;
      if (!d.finish_show_transaction_price) d.finish_show_transaction_price = 0;
      if (!d.product_order_timeout) d.product_order_timeout = 0;
      if (!d.lot_view_type) d.lot_view_type = '1';
      if (!d.specialNumberCreateOrderSwitch) d.lot_view_type = '0';
      if (d.erp_system_reference_price_config && d.erp_system_reference_price_config.length) {
        if (!d.erp_system_reference_price_config[0] || isNaN(d.erp_system_reference_price_config[0])) d.erp_system_reference_price_config[0] = 0;
        if (!d.erp_system_reference_price_config[1] || isNaN(d.erp_system_reference_price_config[1])) d.erp_system_reference_price_config[0] = 0;
        d.erp_system_reference_price_config = d.erp_system_reference_price_config.join(',');
      } else {
        d.erp_system_reference_price_config = '';
      }
      (0, _system.configClassKeysSave)('auction_rule_set', d).then(function (res) {
        _this2.$message.success('设置成功');
      }).catch(function () {});
    },
    compelAnonymousAction: function compelAnonymousAction(val) {
      if (val === 1 && this.ruleData.nickname_anonymous_type < 3) {
        this.ruleData.nickname_anonymous_type = 3;
      }
      if (val === 1 && this.ruleData.avatar_anonymous_type !== 2) {
        this.ruleData.avatar_anonymous_type = 2;
      }
    }
  }
};