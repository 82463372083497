var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticClass: "contact-box" },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "150px" },
              attrs: { icon: "el-icon-back" },
              on: { click: _vm.close },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "contact-header-con" }, [
            _c("div", { staticClass: "contact-header-left" }, [
              _vm._v("合同头部：甲乙双方基本信息"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contact-header-right" },
              [
                _c(
                  "el-link",
                  {
                    staticStyle: { "font-size": "16px", color: "#347ff4" },
                    attrs: { icon: "el-icon-edit" },
                    on: { click: _vm.changedraw },
                  },
                  [_vm._v("\n          配置展示字段\n        ")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", {
            staticStyle: {
              width: "1076px",
              height: "3px",
              "margin-top": "32px",
              background: "#000",
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticStyle: {
              width: "1076px",
              "margin-top": "4px",
              height: "2px",
              background: "#000",
            },
          }),
          _vm._v(" "),
          _vm.titleDisponsejson.place == 1 && _vm.titleDisponsejson.direc == 1
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "1076px",
                    "margin-top": "24px",
                    display: "flex",
                    "flex-wrap": "wrap",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "1076px",
                        display: "flex",
                        "flex-wrap": "wrap",
                      },
                    },
                    _vm._l(_vm.owndata, function (item, index) {
                      return item.select
                        ? _c(
                            "div",
                            {
                              key: "own_t_" + index,
                              staticStyle: {
                                "font-size": "16px",
                                "font-weight": "500",
                                "margin-bottom": "24px",
                              },
                              style:
                                _vm.titleDisponsejson.linenum == 2
                                  ? "width:50%"
                                  : "width:33%",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.value || item.name) + "："),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v(
                                  _vm._s(item.value || item.name) + "字段"
                                ),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "1076px",
                        display: "flex",
                        "flex-wrap": "wrap",
                      },
                    },
                    _vm._l(_vm.otherdata, function (item, index) {
                      return item.select
                        ? _c(
                            "div",
                            {
                              key: "own_o_" + index,
                              staticStyle: {
                                "font-size": "16px",
                                "font-weight": "500",
                                "margin-bottom": "24px",
                              },
                              style:
                                _vm.titleDisponsejson.linenum == 2
                                  ? "width:50%"
                                  : "width:33%",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.value || item.name) + "："),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v(
                                  _vm._s(item.value || item.name) + "字段"
                                ),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                ]
              )
            : _vm.titleDisponsejson.place == 1 &&
              _vm.titleDisponsejson.direc == 2
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "1076px",
                    "margin-top": "24px",
                    display: "flex",
                    "flex-wrap": "wrap",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "1076px",
                        display: "flex",
                        "flex-wrap": "wrap",
                      },
                    },
                    _vm._l(_vm.otherdata, function (item, index) {
                      return item.select
                        ? _c(
                            "div",
                            {
                              key: "own_o_" + index,
                              staticStyle: {
                                "font-size": "16px",
                                "font-weight": "500",
                                "margin-bottom": "24px",
                              },
                              style:
                                _vm.titleDisponsejson.linenum == 2
                                  ? "width:50%"
                                  : "width:33%",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.value || item.name) + "："),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v(
                                  _vm._s(item.value || item.name) + "字段"
                                ),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "1076px",
                        display: "flex",
                        "flex-wrap": "wrap",
                      },
                    },
                    _vm._l(_vm.owndata, function (item, index) {
                      return item.select
                        ? _c(
                            "div",
                            {
                              key: "own_t_" + index,
                              staticStyle: {
                                "font-size": "16px",
                                "font-weight": "500",
                                "margin-bottom": "24px",
                              },
                              style:
                                _vm.titleDisponsejson.linenum == 2
                                  ? "width:50%"
                                  : "width:33%",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.value || item.name) + "："),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v(
                                  _vm._s(item.value || item.name) + "字段"
                                ),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                ]
              )
            : _vm.titleDisponsejson.place == 2 &&
              _vm.titleDisponsejson.direc == 1
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "1076px",
                    "margin-top": "24px",
                    display: "flex",
                    "flex-wrap": "wrap",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "1",
                        display: "flex",
                        "flex-wrap": "wrap",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            "font-size": "16px",
                            "font-weight": "500",
                          },
                        },
                        _vm._l(_vm.owndata, function (item, index) {
                          return item.select
                            ? _c(
                                "div",
                                {
                                  key: "own_o_" + index,
                                  staticStyle: { "margin-bottom": "24px" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(item.value || item.name) + "："
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#999" } },
                                    [
                                      _vm._v(
                                        _vm._s(item.value || item.name) + "字段"
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e()
                        }),
                        0
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "1",
                        display: "flex",
                        "flex-wrap": "wrap",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            "font-size": "16px",
                            "font-weight": "500",
                          },
                        },
                        _vm._l(_vm.otherdata, function (item, index) {
                          return item.select
                            ? _c(
                                "div",
                                {
                                  key: "own_oh_" + index,
                                  staticStyle: { "margin-bottom": "24px" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(item.value || item.name) + "："
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#999" } },
                                    [
                                      _vm._v(
                                        _vm._s(item.value || item.name) + "字段"
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e()
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              )
            : _vm.titleDisponsejson.place == 2 &&
              _vm.titleDisponsejson.direc == 2
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "1076px",
                    "margin-top": "24px",
                    display: "flex",
                    "flex-wrap": "wrap",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "1",
                        display: "flex",
                        "flex-wrap": "wrap",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            "font-size": "16px",
                            "font-weight": "500",
                          },
                        },
                        _vm._l(_vm.otherdata, function (item, index) {
                          return item.select
                            ? _c(
                                "div",
                                {
                                  key: "own_oh_" + index,
                                  staticStyle: { "margin-bottom": "24px" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(item.value || item.name) + "："
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#999" } },
                                    [
                                      _vm._v(
                                        _vm._s(item.value || item.name) + "字段"
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e()
                        }),
                        0
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "1",
                        display: "flex",
                        "flex-wrap": "wrap",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            "font-size": "16px",
                            "font-weight": "500",
                          },
                        },
                        _vm._l(_vm.owndata, function (item, index) {
                          return item.select
                            ? _c(
                                "div",
                                {
                                  key: "own_o_" + index,
                                  staticStyle: { "margin-bottom": "24px" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(item.value || item.name) + "："
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#999" } },
                                    [
                                      _vm._v(
                                        _vm._s(item.value || item.name) + "字段"
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e()
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticStyle: {
              width: "1076px",
              background: "#000",
              height: "1px",
              "margin-top": "8px",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "contact-header-con" }, [
            _c("div", { staticClass: "contact-header-left" }, [
              _vm._v("合同有效期"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contact-header-right" },
              [
                _c("el-switch", {
                  staticStyle: { width: "40px" },
                  attrs: { "active-color": "#13ce66" },
                  model: {
                    value: _vm.expiry_date_switch,
                    callback: function ($$v) {
                      _vm.expiry_date_switch = $$v
                    },
                    expression: "expiry_date_switch",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.expiry_date_switch
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "24px",
                    "font-size": "16px",
                    "font-weight": "500",
                  },
                },
                [
                  _c("span", [_vm._v("合同有效期：")]),
                  _vm._v(" "),
                  _c("span", { staticStyle: { color: "#999" } }, [
                    _vm._v("- 年 - 月- 日 至 - 年 - 月- 日"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "24px" } },
            [
              _c("ueditorFrom", {
                staticStyle: { width: "1076px" },
                attrs: { content: _vm.content },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "contact-header-con" }, [
            _c("div", { staticClass: "contact-header-left" }, [
              _vm._v("委托标的清单"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contact-header-right" },
              [
                _c("el-switch", {
                  staticStyle: { width: "40px" },
                  attrs: { "active-color": "#13ce66" },
                  model: {
                    value: _vm.entrust_order_switch,
                    callback: function ($$v) {
                      _vm.entrust_order_switch = $$v
                    },
                    expression: "entrust_order_switch",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.entrust_order_switch
            ? [
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "16px", "margin-top": "24px" },
                  },
                  [_vm._v("委托标的清单：")]
                ),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "16px", "margin-top": "24px" },
                  },
                  [_vm._v("拍卖标的成交收费标准设置：")]
                ),
                _vm._v(" "),
                _vm._m(3),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "contact-header-con" }, [
            _c("div", { staticClass: "contact-header-left" }, [
              _vm._v("签章区"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "contact-header-right",
                on: {
                  click: function ($event) {
                    _vm.signatureFlag = true
                  },
                },
              },
              [
                _c(
                  "el-link",
                  {
                    staticStyle: { "font-size": "16px", color: "#347ff4" },
                    attrs: { icon: "el-icon-edit" },
                  },
                  [_vm._v("\n          配置展示字段\n        ")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.signDisponsejson.direc == 1
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "1076px",
                    display: "flex",
                    "margin-top": "19px",
                    "flex-wrap": "wrap",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      style:
                        _vm.signDisponsejson.place == 1
                          ? "width: 100%;"
                          : "width: 50%",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.ownsigndata[0].value ||
                                  _vm.ownsigndata[0].name
                              ) +
                              "（签章）："
                          ),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.ownsigndata[0].value ||
                                  _vm.ownsigndata[0].name
                              ) + "签章字段"
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "margin-top": "24px",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm.ownsigndata[1].select
                            ? [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.ownsigndata[1].value ||
                                        _vm.ownsigndata[1].name
                                    ) +
                                    "："
                                ),
                                _c("span", { staticStyle: { color: "#999" } }, [
                                  _vm._v("员工设置字段"),
                                ]),
                              ]
                            : [_vm._v("\n             \n          ")],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "margin-top": "24px",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatDate(_vm.nowdate, "{y}年{m}月{d}日")
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "margin-top": "24px",
                            "font-weight": "500",
                            "margin-bottom": "24px",
                          },
                        },
                        [
                          _vm.ownsigndata[2].select
                            ? [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.ownsigndata[2].value ||
                                        _vm.ownsigndata[2].name
                                    ) +
                                    "\n          "
                                ),
                              ]
                            : [_vm._v("\n             \n          ")],
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      style:
                        _vm.signDisponsejson.place == 1
                          ? "width: 100%;"
                          : "width: 50%",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.othersigndata[0].value ||
                                  _vm.othersigndata[0].name
                              ) +
                              "（签章）："
                          ),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.othersigndata[0].value ||
                                  _vm.othersigndata[0].name
                              ) + "签章字段"
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.othersigndata[1].select
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "16px",
                                "margin-top": "24px",
                                "font-weight": "500",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.othersigndata[1].value ||
                                      _vm.othersigndata[1].name
                                  ) +
                                  "："
                              ),
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v("员工设置字段"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.othersigndata[2].select
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "margin-top": "12px",
                                "padding-right": "24px",
                                "font-weight": "400",
                                "margin-bottom": "24px",
                                "white-space": "pre-wrap",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.othersigndata[2].value ||
                                    _vm.othersigndata[2].name
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            : _vm.signDisponsejson.direc == 2
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "1076px",
                    display: "flex",
                    "margin-top": "19px",
                    "flex-wrap": "wrap",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      style:
                        _vm.signDisponsejson.place == 1
                          ? "width: 100%;"
                          : "width: 50%",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.othersigndata[0].value ||
                                  _vm.othersigndata[0].name
                              ) +
                              "（签章）："
                          ),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.othersigndata[0].value ||
                                  _vm.othersigndata[0].name
                              ) + "签章字段"
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.othersigndata[1].select
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "16px",
                                "margin-top": "24px",
                                "font-weight": "500",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.othersigndata[1].value ||
                                      _vm.othersigndata[1].name
                                  ) +
                                  "："
                              ),
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v("员工设置字段"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.othersigndata[2].select
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "margin-top": "12px",
                                "padding-right": "24px",
                                "font-weight": "400",
                                "margin-bottom": "24px",
                                "white-space": "pre-wrap",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.othersigndata[2].value ||
                                    _vm.othersigndata[2].name
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      style:
                        _vm.signDisponsejson.place == 1
                          ? "width: 100%;"
                          : "width: 50%",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.ownsigndata[0].value ||
                                  _vm.ownsigndata[0].name
                              ) +
                              "（签章）："
                          ),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.ownsigndata[0].value ||
                                  _vm.ownsigndata[0].name
                              ) + "签章字段"
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "margin-top": "24px",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm.ownsigndata[1].select
                            ? [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.ownsigndata[1].value ||
                                        _vm.ownsigndata[1].name
                                    ) +
                                    "："
                                ),
                                _c("span", { staticStyle: { color: "#999" } }, [
                                  _vm._v("员工设置字段"),
                                ]),
                              ]
                            : [_vm._v("\n             \n          ")],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "margin-top": "24px",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatDate(_vm.nowdate, "{y}年{m}月{d}日")
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "margin-top": "24px",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm.ownsigndata[2].select
                            ? [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.ownsigndata[2].value ||
                                        _vm.ownsigndata[2].name
                                    ) +
                                    "\n          "
                                ),
                              ]
                            : [_vm._v("\n             \n          ")],
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "120px" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "templ-box" },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "150px" },
              attrs: { type: "success" },
              on: { click: _vm.editDetail },
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "150px" },
              attrs: { type: "success", plain: "" },
              on: {
                click: function ($event) {
                  _vm.showpreview = true
                },
              },
            },
            [_vm._v("预览")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "150px" },
              attrs: { type: "success", plain: "" },
              on: { click: _vm.close },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: { visible: _vm.drawer, direction: "rtl", size: "450px" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "font-18", attrs: { slot: "title" }, slot: "title" },
            [_vm._v("合同头部字段设置")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "draw-title", staticStyle: {} }, [
            _vm._v("展示方式"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "draw-list margin-t-16" },
            [
              _c("div", { staticClass: "name" }, [_vm._v("双方位置")]),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticStyle: { width: "80px", "margin-right": "16px" },
                  attrs: { label: 1 },
                  model: {
                    value: _vm.titleDisponsejson.direc,
                    callback: function ($$v) {
                      _vm.$set(_vm.titleDisponsejson, "direc", $$v)
                    },
                    expression: "titleDisponsejson.direc",
                  },
                },
                [_vm._v("我方在前")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticStyle: { width: "100px" },
                  attrs: { label: 2 },
                  model: {
                    value: _vm.titleDisponsejson.direc,
                    callback: function ($$v) {
                      _vm.$set(_vm.titleDisponsejson, "direc", $$v)
                    },
                    expression: "titleDisponsejson.direc",
                  },
                },
                [_vm._v("他方在前")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "draw-list margin-t-16" },
            [
              _c("div", { staticClass: "name" }, [_vm._v("双列排列")]),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticStyle: { width: "80px", "margin-right": "16px" },
                  attrs: { label: 1 },
                  model: {
                    value: _vm.titleDisponsejson.place,
                    callback: function ($$v) {
                      _vm.$set(_vm.titleDisponsejson, "place", $$v)
                    },
                    expression: "titleDisponsejson.place",
                  },
                },
                [_vm._v("左右排列")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticStyle: { width: "100px" },
                  attrs: { label: 2 },
                  on: { change: _vm.placechange },
                  model: {
                    value: _vm.titleDisponsejson.place,
                    callback: function ($$v) {
                      _vm.$set(_vm.titleDisponsejson, "place", $$v)
                    },
                    expression: "titleDisponsejson.place",
                  },
                },
                [_vm._v("上下排列")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "draw-list margin-t-16" },
            [
              _c("div", { staticClass: "name" }, [_vm._v("展示列数")]),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticStyle: { width: "80px", "margin-right": "16px" },
                  attrs: { label: 2 },
                  model: {
                    value: _vm.titleDisponsejson.linenum,
                    callback: function ($$v) {
                      _vm.$set(_vm.titleDisponsejson, "linenum", $$v)
                    },
                    expression: "titleDisponsejson.linenum",
                  },
                },
                [_vm._v("两列")]
              ),
              _vm._v(" "),
              _vm.titleDisponsejson.place == 1
                ? _c(
                    "el-radio",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { label: 3 },
                      model: {
                        value: _vm.titleDisponsejson.linenum,
                        callback: function ($$v) {
                          _vm.$set(_vm.titleDisponsejson, "linenum", $$v)
                        },
                        expression: "titleDisponsejson.linenum",
                      },
                    },
                    [_vm._v("三列")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "draw-title margin-t-30" }, [
            _vm._v("我方字段设置"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.owndata, function (item, index) {
            return _c(
              "div",
              { key: "own_" + index, staticClass: "draw-list margin-t-16" },
              [
                _c("i", {
                  staticClass: "sign",
                  class: { "el-icon-top": index > 0 },
                  on: {
                    click: function ($event) {
                      return _vm.changesort(index, "own")
                    },
                  },
                }),
                _vm._v(" "),
                _c("el-checkbox", {
                  staticClass: "checkbox",
                  attrs: { disabled: item.type == "diabled" },
                  on: {
                    change: function ($event) {
                      return _vm.changeselect(index, item, "own")
                    },
                  },
                  model: {
                    value: item.select,
                    callback: function ($$v) {
                      _vm.$set(item, "select", $$v)
                    },
                    expression: "item.select",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "input",
                  model: {
                    value: item.value,
                    callback: function ($$v) {
                      _vm.$set(item, "value", $$v)
                    },
                    expression: "item.value",
                  },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "draw-title margin-t-30" }, [
            _vm._v("他方字段设置"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.otherdata, function (item, index) {
            return _c(
              "div",
              { key: "other_" + index, staticClass: "draw-list margin-t-16" },
              [
                _c("i", {
                  staticClass: "sign",
                  class: { "el-icon-top": index > 0 },
                  on: {
                    click: function ($event) {
                      return _vm.changesort(index, "other")
                    },
                  },
                }),
                _vm._v(" "),
                _c("el-checkbox", {
                  staticClass: "checkbox",
                  attrs: { disabled: item.type == "diabled" },
                  on: {
                    change: function ($event) {
                      return _vm.changeselect(index, item, "other")
                    },
                  },
                  model: {
                    value: item.select,
                    callback: function ($$v) {
                      _vm.$set(item, "select", $$v)
                    },
                    expression: "item.select",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "input",
                  model: {
                    value: item.value,
                    callback: function ($$v) {
                      _vm.$set(item, "value", $$v)
                    },
                    expression: "item.value",
                  },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "50px" } }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.signatureFlag,
            direction: "rtl",
            size: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.signatureFlag = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "font-18", attrs: { slot: "title" }, slot: "title" },
            [_vm._v("签章区设置")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "draw-list margin-t-16" },
            [
              _c("div", { staticClass: "name" }, [_vm._v("双方位置")]),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticStyle: { width: "80px", "margin-right": "16px" },
                  attrs: { label: 1 },
                  model: {
                    value: _vm.signDisponsejson.direc,
                    callback: function ($$v) {
                      _vm.$set(_vm.signDisponsejson, "direc", $$v)
                    },
                    expression: "signDisponsejson.direc",
                  },
                },
                [_vm._v("我方在前")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticStyle: { width: "100px" },
                  attrs: { label: 2 },
                  model: {
                    value: _vm.signDisponsejson.direc,
                    callback: function ($$v) {
                      _vm.$set(_vm.signDisponsejson, "direc", $$v)
                    },
                    expression: "signDisponsejson.direc",
                  },
                },
                [_vm._v("他方在前")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "draw-list margin-t-16" },
            [
              _c("div", { staticClass: "name" }, [_vm._v("排列方式")]),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticStyle: { width: "80px", "margin-right": "16px" },
                  attrs: { label: 1 },
                  model: {
                    value: _vm.signDisponsejson.place,
                    callback: function ($$v) {
                      _vm.$set(_vm.signDisponsejson, "place", $$v)
                    },
                    expression: "signDisponsejson.place",
                  },
                },
                [_vm._v("上下排列")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticStyle: { width: "100px" },
                  attrs: { label: 2 },
                  on: { change: _vm.placechange },
                  model: {
                    value: _vm.signDisponsejson.place,
                    callback: function ($$v) {
                      _vm.$set(_vm.signDisponsejson, "place", $$v)
                    },
                    expression: "signDisponsejson.place",
                  },
                },
                [_vm._v("左右排列")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "draw-title margin-t-30" }, [
            _vm._v("我方字段设置"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.ownsigndata, function (item, index) {
            return _c(
              "div",
              { key: "own_" + index, staticClass: "draw-list margin-t-16" },
              [
                _c("el-checkbox", {
                  staticClass: "checkbox",
                  attrs: { disabled: item.type == "diabled" },
                  model: {
                    value: item.select,
                    callback: function ($$v) {
                      _vm.$set(item, "select", $$v)
                    },
                    expression: "item.select",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "input",
                  attrs: {
                    type: item.type == "textarea" ? "textarea" : "text",
                    rows: 3,
                  },
                  model: {
                    value: item.value,
                    callback: function ($$v) {
                      _vm.$set(item, "value", $$v)
                    },
                    expression: "item.value",
                  },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "draw-title margin-t-30",
              staticStyle: { "margin-top": "80px" },
            },
            [_vm._v("他方字段设置")]
          ),
          _vm._v(" "),
          _vm._l(_vm.othersigndata, function (item, index) {
            return _c(
              "div",
              { key: "other_" + index, staticClass: "draw-list margin-t-16" },
              [
                _c("el-checkbox", {
                  staticClass: "checkbox",
                  attrs: { disabled: item.type == "diabled" },
                  model: {
                    value: item.select,
                    callback: function ($$v) {
                      _vm.$set(item, "select", $$v)
                    },
                    expression: "item.select",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "input",
                  attrs: {
                    type: item.type == "textarea" ? "textarea" : "text",
                    rows: 3,
                  },
                  model: {
                    value: item.value,
                    callback: function ($$v) {
                      _vm.$set(item, "value", $$v)
                    },
                    expression: "item.value",
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showpreview, width: "1200px" },
          on: {
            "update:visible": function ($event) {
              _vm.showpreview = $event
            },
          },
        },
        [
          _c("preview", {
            attrs: {
              titleDisponsejson: _vm.titleDisponsejson,
              signDisponsejson: _vm.signDisponsejson,
              expiryDateSwitch: _vm.expiry_date_switch,
              entrustOrderSwitch: _vm.entrust_order_switch,
              owndata: _vm.owndata,
              otherdata: _vm.otherdata,
              ownsigndata: _vm.ownsigndata,
              othersigndata: _vm.othersigndata,
              content: _vm.content,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          width: "1076px",
          display: "flex",
          margin: "0 auto",
          "margin-top": "32px",
          "justify-content": "space-between",
          "align-items": "center",
        },
      },
      [
        _c("div", [
          _c("span", { staticStyle: { "font-size": "36px", color: "#000" } }, [
            _vm._v("\n          合同名称\n        "),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticStyle: { "font-size": "21px", "margin-left": "16px" } },
            [_vm._v("委托拍卖合同")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "font-size": "16px",
              color: "#000",
              "padding-top": "3px",
            },
          },
          [
            _c("span", [_vm._v("合同编号：")]),
            _vm._v(" "),
            _c("span", { staticStyle: { color: "#999" } }, [
              _vm._v("自动生成"),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact-header-con" }, [
      _c("div", { staticClass: "contact-header-left" }, [
        _vm._v("合同主体内容"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "margin-top": "24px", border: "1px solid #ddd" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "font-weight": "bold",
              background: "#F9FCFF",
              height: "40px",
              "justify-content": "space-between",
              color: "#333",
              "font-size": "12px",
              padding: "0 20px",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { width: "50px", "text-align": "center" } },
              [_vm._v("序号")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "132px", "text-align": "center" } },
              [_vm._v("图录号")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "132px", "text-align": "center" } },
              [_vm._v("作者/年代")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  "text-align": "left",
                  "margin-left": "20px",
                },
              },
              [_vm._v("作品名称")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "132px", "text-align": "center" } },
              [_vm._v("质地/形式")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "132px", "text-align": "center" } },
              [_vm._v("尺寸 (cm)")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "132px", "text-align": "center" } },
              [_vm._v("保留价 (元)")]
            ),
            _vm._v(" "),
            _c("div", { staticStyle: { flex: "1" } }, [_vm._v("备注")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "font-weight": "bold",
              height: "48px",
              "justify-content": "space-between",
              color: "#333",
              "font-size": "12px",
              padding: "0 20px",
            },
          },
          [
            _c("div", {
              staticStyle: { width: "50px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "132px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "132px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "left",
                "margin-left": "20px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "132px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "132px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "132px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", { staticStyle: { flex: "1" } }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "border-top": "1px solid #ddd",
              background: "#FCFBFC",
              display: "flex",
              "align-items": "center",
              "font-weight": "bold",
              height: "48px",
              "justify-content": "space-between",
              color: "#333",
              "font-size": "12px",
              padding: "0 20px",
            },
          },
          [
            _c("div", {
              staticStyle: { width: "50px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "132px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "132px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "left",
                "margin-left": "20px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "132px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "132px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "132px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", { staticStyle: { flex: "1" } }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "padding-left": "20px",
              color: "#999",
              "font-size": "12px",
              display: "flex",
              "align-items": "center",
              height: "48px",
              "border-top": "1px solid #ddd",
            },
          },
          [_vm._v("\n        注：保留价如有调整，以调整后的保留价为准\n      ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "margin-top": "24px", border: "1px solid #ddd" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "font-weight": "bold",
              background: "#F9FCFF",
              height: "60px",
              "justify-content": "space-between",
              color: "#333",
              "font-size": "12px",
              padding: "0 20px",
            },
          },
          [
            _c("div", { staticStyle: { width: "230px" } }, [
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v("商品名称"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "120px", "text-align": "center" } },
              [_vm._v("保留价")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  width: "240px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { width: "70px", "text-align": "center" },
                      },
                      [_vm._v("装裱费")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { width: "70px", "text-align": "center" },
                      },
                      [_vm._v("佣金")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { width: "70px", "text-align": "center" },
                      },
                      [_vm._v("图录费")]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  width: "240px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { width: "70px", "text-align": "center" },
                      },
                      [_vm._v("装裱费")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { width: "70px", "text-align": "center" },
                      },
                      [_vm._v("保留价佣金")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { width: "70px", "text-align": "center" },
                      },
                      [_vm._v("图录费")]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  width: "240px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { width: "70px", "text-align": "center" },
                      },
                      [_vm._v("装裱费")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { width: "70px", "text-align": "center" },
                      },
                      [_vm._v("佣金")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { width: "70px", "text-align": "center" },
                      },
                      [_vm._v("图录费")]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "space-between",
              color: "#333",
              "font-size": "12px",
              padding: "15px 20px",
            },
          },
          [
            _c("div", { staticStyle: { width: "230px" } }, [
              _vm._v("徐悲鸿亲笔骏马图"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "120px", "text-align": "center" } },
              [_vm._v("800")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  width: "240px",
                  "justify-content": "space-between",
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("800")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("300")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("12%")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  width: "240px",
                  "justify-content": "space-between",
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("800")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("300")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("13%")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  width: "240px",
                  "justify-content": "space-between",
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("800")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("300")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("13%")]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "border-top": "1px solid #ddd",
              background: "#FCFBFC",
              display: "flex",
              "align-items": "center",
              "justify-content": "space-between",
              color: "#333",
              "font-size": "12px",
              padding: "15px 20px",
            },
          },
          [
            _c("div", { staticStyle: { width: "230px" } }, [
              _vm._v("徐悲鸿亲笔骏马图"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "120px", "text-align": "center" } },
              [_vm._v("800")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  width: "240px",
                  "justify-content": "space-between",
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("800")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("300")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("12%")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  width: "240px",
                  "justify-content": "space-between",
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("800")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("300")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("13%")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  width: "240px",
                  "justify-content": "space-between",
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("800")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("300")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "70px", "text-align": "center" } },
                  [_vm._v("13%")]
                ),
              ]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }