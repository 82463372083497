var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "min-height": "100vh" } },
    [
      _c("header-breadcrumb", {
        attrs: { name: "批量创建委托退回出库单", "has-back": "" },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.list,
                border: "",
                "span-method": _vm.objectSpanMethod,
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c(
                  "div",
                  { staticClass: "tableEmpty" },
                  [
                    !_vm.loading
                      ? [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "tableDiv" }, [
                            _vm._v("暂无数据"),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "委托方", width: "220" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrustUser
                          ? _c(
                              "div",
                              { staticClass: "nowrap" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.entrustUser.real_name) +
                                    "\n            "
                                ),
                                scope.row.entrustUser.phone
                                  ? [
                                      _vm._v(
                                        "\n              (" +
                                          _vm._s(scope.row.entrustUser.phone) +
                                          ")\n            "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "合同号", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.contract
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.contract.contract_no) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "库房", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.erpProduct.warehouse
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.erpProduct.warehouse.name) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "货架", width: "102" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.erpProduct.shelf
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.erpProduct.shelf.shelf_no) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图录号", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.erpProduct
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.erpProduct.lot) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "拍品名称", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.erpProduct
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.erpProduct.product_name) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发货方式", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.delivery_type == 1
                          ? _c("div", [_vm._v("快递发货")])
                          : scope.row.delivery_type == 2
                          ? _c("div", [_vm._v("客户自提")])
                          : scope.row.delivery_type == 4
                          ? _c("div", [_vm._v("员工送货")])
                          : scope.row.delivery_type
                          ? _c("div", [
                              _vm._v(_vm._s(scope.row.deliveryTypeText)),
                            ])
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "快递公司", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.delivery_name) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "收件地址", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.delivery_type &&
                        scope.row.delivery_type != 2 &&
                        scope.row.entrustUser &&
                        scope.row.entrustUser.entrust &&
                        scope.row.entrustUser.entrust.contact_address
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.entrustUser.entrust.contact_address
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c goodslist-page-box" }, [
            _c(
              "div",
              { staticClass: "flex-one flex" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.createPushOrder },
                  },
                  [_vm._v("确认创建出库单")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }