var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "r-title" }, [_vm._v("送拍记录")]),
      _vm._v(" "),
      _c("div", { staticClass: "count-box" }, [
        _c("div", { staticClass: "count-list" }, [
          _c("div", { staticClass: "count-num" }, [
            _vm._v(_vm._s(_vm.formatPriceNumber(_vm.countjson.productCount))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "count-word" }, [_vm._v("送拍(件)")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-list" }, [
          _c("div", { staticClass: "count-num" }, [
            _vm._v(
              _vm._s(_vm.formatPriceNumber(_vm.countjson.dealProductCount))
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "count-word" }, [_vm._v("成交(件)")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-list" }, [
          _c("div", { staticClass: "count-num" }, [
            _vm._v(
              _vm._s(_vm.formatPriceNumber(_vm.countjson.dealOrderTotalPrice))
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "count-word" }, [_vm._v("成交额")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-list" }, [
          _c("div", { staticClass: "count-num" }, [
            _vm._v(
              _vm._s(_vm.formatPriceNumber(_vm.countjson.dealAveragePrice))
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "count-word" }, [_vm._v("平均单价")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-list" }, [
          _c("div", { staticClass: "count-num" }, [
            _vm._v(
              _vm._s(_vm.formatPriceNumber(_vm.countjson.refundProductCount))
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "count-word" }, [_vm._v("退回(件)")]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex",
          staticStyle: {
            margin: "20px 20px 0 0",
            "justify-content": "flex-end",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.exportOrder },
            },
            [_vm._v("导出")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.getExportFileList },
            },
            [_vm._v("导出记录")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { padding: "20px" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.order_id
                },
                data: _vm.tableData.data,
                size: "mini",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "交易时间",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "合同号", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.contract
                                ? scope.row.contract.contract_no
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "上拍专场", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.pmAuction
                                ? scope.row.pmAuction.album.album_name
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auction_image &&
                        scope.row.auction_image.length > 0
                          ? _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: scope.row.auction_image[0],
                                    "preview-src-list": scope.row.auction_image,
                                  },
                                }),
                              ],
                              1
                            )
                          : scope.row.warehouse_image &&
                            scope.row.warehouse_image.length > 0
                          ? _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: scope.row.warehouse_image[0],
                                    "preview-src-list":
                                      scope.row.warehouse_image,
                                  },
                                }),
                              ],
                              1
                            )
                          : scope.row.contract_image &&
                            scope.row.contract_image.length > 0
                          ? _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: scope.row.contract_image[0],
                                    "preview-src-list":
                                      scope.row.contract_image,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "LOT", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.pmAuction
                                ? scope.row.pmAuction.lot
                                : scope.row.lot
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "拍品名称", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.pmAuction
                                ? scope.row.pmAuction.product.store_name
                                : scope.row.product_name
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "拍品状态", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pmAuction
                          ? [
                              scope.row.pmAuction.auction_status == 1
                                ? [_vm._v("进行中")]
                                : scope.row.pmAuction.auction_status == -1
                                ? [_vm._v("违约")]
                                : scope.row.pmAuction.auction_status == 2
                                ? [_vm._v("预展中")]
                                : scope.row.pmAuction.auction_status == 3
                                ? [_vm._v("保留价待确认")]
                                : scope.row.pmAuction.auction_status == 4
                                ? [_vm._v("流拍")]
                                : scope.row.pmAuction.auction_status == 5
                                ? [_vm._v("已中拍 未付款")]
                                : scope.row.pmAuction.auction_status == 6
                                ? [_vm._v("已完成")]
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "成交价", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.pmAuction
                                ? _vm.formatPriceNumber(
                                    scope.row.pmAuction.now_price + "",
                                    2
                                  )
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "结算金额", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.entrustReconciliation
                                ? _vm.formatPriceNumber(
                                    scope.row.entrustReconciliation
                                      .reconciliation_amount + "",
                                    2
                                  )
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "库存状态", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.warehouse_id > 0 ? "在库" : "") +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block mt10" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [6, 20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }