var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-full w-full flex flex-col items-center 123 min-w-1100px pr-6",
      staticStyle: { "user-select": "none", "touch-action": "none" },
    },
    [
      _c(
        "div",
        {
          staticClass: "fixed right-0 top-0 z-10 flex justify-center",
          staticStyle: { left: "332px" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex items-center border bg-ffffff border-E6E7EB h-80px rounded-b-8px",
              staticStyle: {
                "box-shadow": "0px 0px 20px 0px rgba(0,0,0,0.05)",
              },
              style: _vm.isMobile ? "" : "border-top: none;",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex rounded-8px text-000000 bg-F4F5F9 relative w-128px h-40px text-14px ml-10px",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded-l-lg  flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 w-40px h-40px",
                      on: { click: _vm.reducePercentageAction },
                    },
                    [
                      _c("Icon", {
                        staticStyle: { width: "16px", height: "16px" },
                        attrs: { icon: "material-symbols:remove" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-1 h-full flex items-center justify-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 ",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.showChangePercentage = true
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "relative bottom-[-1px]" }, [
                        _vm._v(_vm._s(_vm.percentage) + "%"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded-r-lg flex justify-center items-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7 w-40px h-40px",
                      on: { click: _vm.increasePercentageAction },
                    },
                    [
                      _c("Icon", {
                        staticStyle: { width: "16px", height: "16px" },
                        attrs: { icon: "material-symbols:add" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showChangePercentage,
                          expression: "showChangePercentage",
                        },
                      ],
                      staticClass:
                        "absolute left-0 right-0 top-44px border h-160px bg-#ffffff border-#E6E7EB rounded-8px z-50",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-40px leading-[40px] flex items-center justify-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7",
                          on: {
                            click: function ($event) {
                              _vm.percentage = 50
                            },
                          },
                        },
                        [_vm._v("\n            50%\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-40px leading-[40px] flex items-center justify-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7",
                          on: {
                            click: function ($event) {
                              _vm.percentage = 100
                            },
                          },
                        },
                        [_vm._v("\n            100%\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-40px leading-[40px] flex items-center justify-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7",
                          on: {
                            click: function ($event) {
                              _vm.percentage = 150
                            },
                          },
                        },
                        [_vm._v("\n            150%\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-40px leading-[40px] flex items-center justify-center cursor-pointer hover-bg-F0EAFF hover-text-6432E7",
                          on: {
                            click: function ($event) {
                              _vm.percentage = 200
                            },
                          },
                        },
                        [_vm._v("\n            200%\n          ")]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.handleType == "imageOutward"
                ? _c(
                    "div",
                    {
                      staticClass: "text-14px text-000000 font-medium ml-10px",
                    },
                    [
                      _vm._v("\n        扩图比例\n        "),
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "ml-10px",
                          staticStyle: { height: "40px" },
                          model: {
                            value: _vm.ratio,
                            callback: function ($$v) {
                              _vm.ratio = $$v
                            },
                            expression: "ratio",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "1:1" } }),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "4:3" } }),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "3:4" } }),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "16:9" } }),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "9:16" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.handleType == "imageReplace"
                ? _c("el-input", {
                    staticClass: "ml-10px",
                    staticStyle: { width: "500px", height: "40px" },
                    attrs: {
                      resize: "none",
                      placeholder: "请描述你要替换的内容",
                    },
                    model: {
                      value: _vm.prompt,
                      callback: function ($$v) {
                        _vm.prompt = $$v
                      },
                      expression: "prompt",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "w-120px h-40px ml-10px mr-6" }, [
                _c(
                  "div",
                  { staticClass: "flex-1" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          background: "#6c49e6",
                          color: "#fff",
                          height: "40px",
                        },
                        attrs: { loading: _vm.imgStatus === 0 },
                        on: { click: _vm.drawTwoImage },
                      },
                      [_vm._v(_vm._s(_vm.handText))]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "containerEL",
          staticClass: "flex-1 overflow-hidden flex mb-0 pt-42px",
          staticStyle: { "z-index": "1", width: "100%" },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex w-full h-full justify-center items-center",
              on: {
                mousemove: _vm.containerMove,
                touchmove: _vm.containerMove,
              },
            },
            [
              _vm.imgUrl
                ? _c(
                    "div",
                    {
                      staticClass: "max-w-full relative",
                      staticStyle: {
                        "user-select": "none",
                        "touch-action": "none",
                      },
                      style: [
                        {
                          transform:
                            "scale(" +
                            _vm.percentage / 100 +
                            ") translate(" +
                            _vm.moveX +
                            "px, " +
                            _vm.moveY +
                            "px)",
                        },
                        {
                          "aspect-ratio": _vm.imgWidth + " / " + _vm.imgHeight,
                        },
                        { cursor: _vm.lineManipulateType === 3 ? "move" : "" },
                      ],
                      on: {
                        mousedown: _vm.containerDown,
                        mouseup: _vm.containerUp,
                        touchstart: _vm.containerDown,
                        touchend: _vm.containerUp,
                      },
                    },
                    [
                      _c("img", {
                        ref: "orgImgEL",
                        staticClass: "max-w-full max-h-full object-cover",
                        style:
                          "width: " +
                          _vm.imgWidth +
                          "px;height: " +
                          _vm.imgHeight +
                          "px;",
                        attrs: { src: _vm.imgUrl },
                        on: { load: _vm.imgLoadedAction },
                      }),
                      _vm._v(" "),
                      _vm.imgStatus !== -10 && _vm.imgStatus !== 1
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "absolute left-0 right-0 bottom-0 top-0 z-50 bg-00000080 text-FDBA46 flex items-center justify-center flex-col",
                            },
                            [
                              _vm.imgStatus === -1 || _vm.imgStatus === -4
                                ? _c("img", {
                                    staticClass: "w-48px h-48px text-#ffffff",
                                    attrs: { src: _vm.worriedFace },
                                  })
                                : _c("Icon", {
                                    staticStyle: {
                                      width: "48px",
                                      height: "48px",
                                    },
                                    attrs: { icon: "eos-icons:bubble-loading" },
                                  }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-18px mt-3" },
                                [
                                  _vm.imgStatus === -2
                                    ? [
                                        _vm._v(
                                          "\n              等待处理中...\n            "
                                        ),
                                      ]
                                    : _vm.imgStatus === -3
                                    ? [
                                        _vm._v(
                                          "\n              资源加载中...\n            "
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n              图片处理中...\n            "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }