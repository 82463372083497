"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.sort");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
var _authenticate = require("@/api/authenticate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var selectType = {
  '1': 'Expert',
  '3': 'PraisalReport',
  '4': 'PraisalCategory',
  '5': 'PraisalSetting',
  '2': 'Appraisal',
  '6': 'AppraisalComplaint'
};
var _default = exports.default = {
  name: 'AppraisalExpertList',
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      activeMenu: '2',
      showIntro: false,
      introMes: '',
      total: 0,
      // 页数
      username: '',
      category: '',
      queryParams: {
        limit: 10,
        status: '0',
        page: Number(this.$route.query.page || 1)
      },
      date: '',
      stime: '',
      etime: '',
      startDateOption: {
        disabledDate: this.setStartDate
      },
      endDateOption: {
        disabledDate: this.setEndDate
      },
      columns: [{
        title: '满意度',
        minWidth: 120,
        render: function render(h, params) {
          return h('div', [h('div', "\u63D0\u95EE\u8005\uFF1A".concat(params.row.satisfaction_ratio, "%")), h('div', "\u8D85\u65F6\u7387\uFF1A".concat(params.row.timeout_ratio, "%")), params.row.within_twelve_hours_count ? h('div', {
            style: {
              color: 'red'
            }
          }, "\u5373\u5C06\u8D85\u65F6\uFF1A") : '', params.row.within_six_hours_count ? h('div', {
            style: {
              color: 'red'
            }
          }, "<6\u5C0F\u65F6\uFF1A".concat(params.row.within_six_hours_count, "\u5355")) : '', params.row.within_twelve_hours_count ? h('div', {
            style: {
              color: 'red'
            }
          }, "<12\u5C0F\u65F6\uFF1A".concat(params.row.within_twelve_hours_count, "\u5355")) : '']);
        }
      }],
      resultData: [],
      categoryList: [],
      // 分类列表
      reason: '',
      sortNum: '',
      // 排序
      loading: false,
      row: '',
      userUid: '',
      showUserdetail: false
    };
  },
  created: function created() {
    this.getCartgoryList();
    this.load();
  },
  methods: {
    getExpertList: function getExpertList(query) {
      var _this = this;
      this.loading = true;
      (0, _authenticate._getExpertList)(query).then(function (res) {
        if (res.status === 200) {
          _this.resultData = res.data.list.map(function (item) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              isInput: false
            });
          });
          _this.total = res.data.count;
          _this.loading = false;
        }
      }).catch(function (err) {
        console.log(err);
        _this.loading = false;
      });
    },
    getCartgoryList: function getCartgoryList() {
      var _this2 = this;
      (0, _authenticate._getCartgorySetList)().then(function (res) {
        _this2.categoryList = res.data.list;
      }).catch(function (err) {
        console.log(err);
      });
    },
    sortExpert: function sortExpert(row) {
      var _this3 = this;
      this.$set(row, 'isInput', false);
      if (row.sort == this.sortNum) return;
      if (isNaN(+this.sortNum)) return this.$message.error('只能输入数字');
      var data = {
        uid: row.uid,
        sort: this.sortNum
      };
      (0, _authenticate._sortExpert)(data).then(function (res) {
        _this3.$message.success('操作成功');
        _this3.load();
        _this3.sortNum = '';
      }).catch(function (err) {
        console.log(err);
      });
    },
    onSort: function onSort(row) {
      var _this4 = this;
      this.resultData.forEach(function (item) {
        if (item.id != row.id) _this4.$set(item, 'isInput', false);else _this4.$set(item, 'isInput', true);
      });
      this.sortNum = row.sort;
      this.$nextTick(function () {
        _this4.$refs['inputRef' + row.id].focus();
      });
    },
    onShenfen: function onShenfen(row, status) {
      var _this5 = this;
      var state = {
        uid: row.uid,
        status: status
      };
      (0, _authenticate._shenfenExpert)(state).then(function (res) {
        _this5.$message.success('操作成功');
        _this5.load();
      }).catch(function (err) {
        console.log(err);
      });
    },
    onZhiding: function onZhiding(row, status) {
      var _this6 = this;
      var state = {
        uid: row.uid,
        status: status
      };
      (0, _authenticate._zhidingExpert)(state).then(function (res) {
        _this6.$message.success('操作成功');
        _this6.load();
      }).catch(function (err) {
        console.log(err);
      });
    },
    onDelete: function onDelete(row) {
      var _this7 = this;
      (0, _authenticate._deleteExpert)(row.id).then(function (res) {
        _this7.$message.success('操作成功');
        _this7.load();
      }).catch(function (err) {
        console.log(err);
      });
    },
    onInfo: function onInfo(row) {
      this.introMes = row.profile;
      this.showIntro = true;
    },
    onSelectTit: function onSelectTit(index) {
      var _this8 = this;
      this.activeMenu = index;
      this.$router.push({
        name: selectType[index]
      });
      setTimeout(function () {
        _this8.activeMenu = '2';
      });
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.limit = size;
      this.queryParams.page = 1;
      this.load();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      var newPage = page;
      this.$router.push({
        path: this.$route.path,
        query: {
          page: newPage
        }
      });
      this.load();
    },
    onChangeDate: function onChangeDate(e) {
      this.date = e;
    },
    load: function load() {
      var params = this.queryParams;
      if (this.date && this.date.length) {
        params.date = this.date.join('-');
      } else {
        params.date = '';
      }
      this.getExpertList(params);
    },
    searchAction: function searchAction() {
      this.queryParams.page = 1;
      this.queryParams.uname = this.username;
      this.queryParams.category = this.category;
      this.load();
    },
    changeStateEvent: function changeStateEvent(name) {
      this.queryParams.status = name;
      this.queryParams.page = 1;
      this.queryParams.uname = '';
      this.queryParams.category = '';
      this.username = '';
      this.category = '';
      this.load();
    },
    addAction: function addAction(id) {
      this.$router.push({
        name: 'AddExpert',
        query: {
          id: id
        }
      });
    },
    confirmShenhe: function confirmShenhe(row) {
      var _this9 = this;
      var state = {
        uid: row.uid,
        status: 1
      };
      console.log(state);
      (0, _authenticate._reviewExpert)(state).then(function (res) {
        _this9.$message.success('操作成功');
        _this9.load();
      }).catch(function (err) {
        console.log(err);
      });
    },
    cancelShenhe: function cancelShenhe(row) {
      var _this10 = this;
      this.$prompt('请输入拒绝理由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (_ref) {
        var value = _ref.value;
        _this10.reason = value;
        _this10.reasonAction(row);
      }).catch(function () {});
    },
    reasonAction: function reasonAction(row) {
      var _this11 = this;
      // 拒绝身份申请
      if (!this.reason) {
        this.$message.error('请输入拒绝理由！');
        return;
      }
      var data = {
        uid: row.uid,
        failed_reason: this.reason,
        status: 2
      };
      (0, _authenticate._reviewExpert)(data).then(function (res) {
        _this11.$message.success('操作完成');
        _this11.load();
      });
    },
    // 详情
    onDetails: function onDetails(row) {
      this.row = row;
      this.userUid = row.uid;
      // this.visibleDetail = true
      this.showUserdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showUserdetail = false;
    }
  }
};