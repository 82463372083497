"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _user = require("@/api/user.js");
var _index = require("@/utils/index");
var _system = require("@/api/system.js");
var _contract = require("@/api/contract");
var _accounts = require("@/api/accounts");
var _order = require("@/api/order");
var _printJs = _interopRequireDefault(require("print-js"));
var _zhTW = _interopRequireDefault(require("@/utils/zh-TW.json"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ids: '',
      source: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      total_rmb: '',
      result: [],
      isNo: true,
      merData: {},
      no: ''
    };
  },
  computed: {
    feeTypeObj: function feeTypeObj() {
      return this.$store.getters.feeTypeInfo;
    },
    getForeWidth: function getForeWidth() {
      var w = 0;
      if (this.detail.tableColumn[2] && this.detail.tableColumn[2].open && this.detail.tableColumn[2].w) {
        w += this.detail.tableColumn[2].w;
      }
      if (this.detail.tableColumn[3] && this.detail.tableColumn[3].open && this.detail.tableColumn[3].w) {
        w += this.detail.tableColumn[3].w;
      }
      if (this.detail.tableColumn[4] && this.detail.tableColumn[4].open && this.detail.tableColumn[4].w) {
        w += this.detail.tableColumn[4].w;
      }
      if (this.detail.tableColumn[5] && this.detail.tableColumn[5].open && this.detail.tableColumn[5].w) {
        w += this.detail.tableColumn[5].w;
      }
      return "width: ".concat(w, "px;");
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
    (0, _system.getConfigClassKeys)('print_config').then(function (res) {
      if (res.data.print_erp_contract_payment) {
        var d = JSON.parse(res.data.print_erp_contract_payment);
        if (!d.signColumnTab) d.signColumnTab = 'system';
        _this.detail = d;
      } else {
        _this.detail = {
          title: {
            china: '委托方付款单',
            english: 'Collection settlement bill'
          },
          buyerCompany: {
            china: _this.switchLang('BuyerCompany'),
            english: _this.switchLang('BuyerCompany_en')
          },
          tableColumn: [{
            open: true,
            name: '图录号',
            showName: _this.switchLang('BuyerTabletu'),
            english: 'LOT NO.',
            w: 86
          }, {
            open: true,
            name: '拍品名称',
            showName: _this.switchLang('BuyerTablepp'),
            english: 'DESCRIPTION'
          }, {
            open: true,
            name: '成交状态',
            showName: _this.switchLang('SALESSTATUS'),
            english: 'SALES STATUS',
            w: 120
          }, {
            open: true,
            name: '落槌价',
            showName: _this.switchLang('BuyerTablelc'),
            english: 'HAMMER PRICE',
            w: 110
          }, {
            open: true,
            name: '佣金',
            showName: _this.switchLang('BuyerTableyj'),
            english: 'COMMISSION',
            w: 240
          }, {
            open: true,
            name: '金额',
            showName: _this.switchLang('Amount'),
            english: 'SUBTOTAL',
            w: 110
          }],
          settleText: {
            china: "".concat(_this.switchLang('AccountText'), "\u3002"),
            english: 'Dear client, thank you very much for your support and assistance to our company. We are now submitting the transaction results of the works you entrusted our company for auction. Please check and verify them. Please continue to support our company as always. Thank you.'
          },
          signColumnTab: 'system',
          signColumn: {
            accountName: {
              open: false,
              name: '开户名称',
              showName: '',
              w: 'auto'
            },
            accountBank: {
              open: false,
              name: '开户行',
              showName: '',
              w: 'auto'
            },
            accountNumber: {
              open: false,
              name: '帐号',
              showName: '',
              w: 'auto'
            },
            accountAddress: {
              open: false,
              name: '公司地址',
              showName: '',
              w: '100'
            },
            creator: {
              open: true,
              name: '制单人',
              showName: '',
              w: '25'
            },
            companySeal: {
              open: true,
              name: '单位盖章',
              showName: '',
              w: '25'
            },
            recipient: {
              open: true,
              name: '收款人',
              showName: '',
              w: '25'
            },
            documentNumber: {
              open: true,
              name: '单据编号',
              showName: '',
              w: '25'
            }
          }
        };
      }
    });
  },
  methods: {
    switchLang: function switchLang(filed) {
      if (this.feeTypeObj.mer_fee_type == 'HKD') {
        return _zhTW.default.TW[filed];
      } else return _zhTW.default.CN[filed];
    },
    bindPrintorder: function bindPrintorder() {
      (0, _system.updateConfigApi)({
        key: 'erp_entrust_order_print_no',
        value: this.no
      });
      // console.log("是不是修改的这里啊");
      var that = this;
      (0, _printJs.default)({
        printable: 'printorder',
        type: 'html',
        header: null,
        targetStyles: ['*'],
        scanStyles: true,
        maxWidth: 1000,
        font_size: 'auto',
        style: "@page {margin:5mm 10mm 12mm}",
        onPrintDialogClose: function onPrintDialogClose() {
          console.log('关闭');
        },
        onPdfOpen: function onPdfOpen() {
          console.log('pdf1');
        }
      });
      (0, _order.batchCreate)({
        order_ids: that.collectMoney.order_ids,
        order_type: 'erp_v2_entrust_check_auction'
      });
      // that.collectMoney.show = false;
      that.$emit('load');
    },
    loadData: function loadData(ids, uid, source) {
      var _this2 = this;
      this.isNo = true;
      this.source = source;
      console.log(ids);
      (0, _accounts.printPaymentOrderApi)({
        payment_order_id: ids
      }).then(function (res) {
        // console.log(res.data.reconciliationData);
        // m.pmAuction.now_price
        var total = 0;
        var obj = {
          succ: 0,
          err: 0
        };
        // const lll = res.data.reconciliationData.map(item => item.price_data.length);
        // const maxNum = Math.max(lll);
        // let configPrice = [];
        // res.data.reconciliationData.forEach(item => {
        //   if (item.price_data.length == maxNum) configPrice = item.price_data;
        // })
        // console.log('1----',maxNum,lll,configPrice);
        var configPrice = JSON.parse(JSON.stringify(res.data.reconciliationData[0].price_data));
        configPrice.forEach(function (item) {
          item.forEach(function (ele) {
            ele.price = 0;
          });
        });
        res.data.reconciliationData.forEach(function (item) {
          if (item.auction_status == 5 || item.auction_status == 6) {
            if (Number(item.auction_now_price)) {
              total += Number(item.auction_now_price);
            } else if (item.pmAuction.now_price) {
              total += Number(item.pmAuction.now_price);
            }
            // total += item.pmAuction.now_price?Number(item.pmAuction.now_price):0;
          }
          if (item.auction_status <= 4) {
            obj.err += 1;
          } else {
            obj.succ += 1;
          }
          item.price_data.forEach(function (a, i) {
            a.forEach(function (ele, index) {
              if (configPrice[i][index]) configPrice[i][index].price += Number(ele.deduction_amount ? ele.deduction_amount : 0);
            });
          });
        });
        // res.data.reconciliationData = [...res.data.reconciliationData,...res.data.reconciliationData];
        res.data.configPrice = configPrice;
        res.data.obj = obj;
        res.data.nowTotal = total;
        var lastNo = res.data.merConfig.erp_entrust_order_print_no || '';
        var nowNo = "".concat((0, _index.parseTime)(new Date(), '{y}{m}{d}'), "0000");
        if (lastNo && lastNo.length == 12) {
          var dayNum = "".concat(lastNo.slice(0, 8), "0000");
          if (dayNum === nowNo) {
            var no = Number(lastNo.slice(8, 12));
            nowNo = Number(nowNo) + no + 1;
          } else {
            nowNo = Number(nowNo) + 1;
          }
        } else {
          nowNo = Number(nowNo) + 1;
        }
        _this2.no = "".concat(nowNo);
        _this2.collectMoney = {
          order_ids: ids,
          data: res.data,
          show: true
        };
        _this2.$nextTick(function () {
          // 赋值后马上更新
          _this2.compendHeight();
        });
      }).catch(function (e) {
        console.log(e);
      });
    },
    compendHeight: function compendHeight() {
      var num = 0;
      var heightAll = 0;
      var arr = [];
      var dom = document.getElementsByClassName('calcul');
      var printTopDom = document.getElementsByClassName('all-print-main-el');
      var h = 1350 - printTopDom[0].offsetTop;
      for (var i = 0; i < dom.length; i++) {
        heightAll += Number(window.getComputedStyle(dom[i]).height.replace('px', ''));
        if (num == 0 && heightAll > h) {
          arr.push(i);
          num += 1;
        } else if (num > 0 && heightAll > 1200 * num + h) {
          arr.push(i);
          num += 1;
        }
        // else if (num == 0 && heightAll+){

        // }
      }
      // this.collectMoney.data.reconciliationData
      this.result = this.sliceArrayIntoChunks(this.collectMoney.data.reconciliationData, arr);
      this.isNo = false;
    },
    sliceArrayIntoChunks: function sliceArrayIntoChunks(array, indexes) {
      var arr = [];
      var len = array.length;
      var ind = 0;
      indexes.forEach(function (item, index) {
        arr.push(array.slice(ind, item));
        ind = item;
      });
      arr.push(array.slice(indexes[indexes.length - 1], len));
      return arr;
    },
    delAction: function delAction(i) {
      this.collectMoney.data.auctionData.splice(i, 1);
      this.getCNPrice();
    },
    getCNPrice: function getCNPrice() {
      var _this3 = this;
      var mer_id = localStorage.getItem('mer_id');
      var total_price = 0;
      this.collectMoney.data.auctionData.forEach(function (m) {
        total_price += m.should_pay_price;
      });
      this.total_price = total_price;
      (0, _contract.numberToRmbApi)({
        number: this.total_price,
        mer_id: mer_id
      }).then(function (res) {
        _this3.total_rmb = res.data.rmb;
      });
    }
  }
};