"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var userRouter = {
  path: "".concat(_settings.roterPre, "/user"),
  name: 'user',
  meta: {
    icon: 'dashboard',
    title: '用户管理'
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: 'noRedirect',
  children: [{
    path: 'maticlabel',
    name: 'UserAutoLabel',
    meta: {
      title: '自动标签',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/label'));
      });
    }
  }, {
    path: 'feedback',
    name: 'UserFeedback',
    meta: {
      title: '用户留言',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/feedback'));
      });
    }
  }, {
    path: 'list',
    name: 'UserList',
    meta: {
      title: '全部用户'
    },
    redirect: "".concat(_settings.roterPre, "/user/list/allList"),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/list/allUser'));
      });
    },
    children: [{
      path: 'allList',
      name: 'AllList',
      meta: {
        title: '用户列表',
        activeMenu: "".concat(_settings.roterPre, "/user/list"),
        permissionKey: '/user/list/allList'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/user/list'));
        });
      }
    }, {
      path: 'wtList',
      name: 'wtList',
      meta: {
        title: '委托方',
        activeMenu: "".concat(_settings.roterPre, "/user/list"),
        permissionKey: '/user/list/wtList'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/user/list'));
        });
      }
    }, {
      path: 'verify',
      name: 'UserVerify',
      meta: {
        title: '实名认证',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/user/list"),
        permissionKey: '/user/list/verify'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/user/list/userVerify'));
        });
      }
    }]
  }, {
    path: 'userConfig',
    name: 'userConfig',
    meta: {
      title: '用户配置'
    },
    redirect: "".concat(_settings.roterPre, "/user/userConfig/group"),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/list/userConfig'));
      });
    },
    children: [{
      path: 'group',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/user/group'));
        });
      },
      name: 'UserGroup',
      meta: {
        title: '用户分组',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/user/list")
      }
    }, {
      path: 'label',
      name: 'UserLabel',
      meta: {
        title: '用户标签',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/user/list"),
        permissionKey: '/user/_label'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/user/group'));
        });
      }
    }, {
      path: 'cancellation',
      name: 'cancellation',
      meta: {
        title: '注销申请',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/user/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/user/list/cancellation'));
        });
      }
    }]
  }, {
    path: 'regiest_set',
    name: 'Userregiest_set',
    meta: {
      title: '注册配置',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/user/list")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/list/regiest_set'));
      });
    }
  }]
};
var _default = exports.default = userRouter;