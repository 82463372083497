var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading || _vm.lock,
          expression: "pageLoading || lock",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "header-breadcrumb",
        { attrs: { name: "图录号设置", "has-back": true, "is-fixed": true } },
        [
          _c("template", { slot: "leftHander" }, [
            _c("span", { staticClass: "header-txt" }),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("el-card", [
        _c(
          "div",
          {
            ref: "titleWidthEL",
            staticClass: "album-change flex align-items-c",
          },
          [
            _c("div", { staticClass: "flex-one flex align-items-c" }, [
              _c(
                "div",
                { staticClass: "start-lot" },
                [
                  _c("span", [_vm._v("起始图录号：")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticStyle: {
                      width: "120px",
                      "font-size": "14px",
                      "font-weight": "500",
                    },
                    attrs: { disabled: _vm.lockSet, controls: false },
                    model: {
                      value: _vm.startLot,
                      callback: function ($$v) {
                        _vm.startLot = $$v
                      },
                      expression: "startLot",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("span", { staticClass: "ml20" }, [_vm._v("每行展示：")]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w100",
                    model: {
                      value: _vm.lineNum,
                      callback: function ($$v) {
                        _vm.lineNum = $$v
                      },
                      expression: "lineNum",
                    },
                  },
                  [
                    _c("el-option", { attrs: { value: 5, label: "5" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: 10, label: "10" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: 15, label: "15" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: 20, label: "20" } }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "ml5" }, [_vm._v("条")]),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        !_vm.pageLoading
          ? _c("div", { staticClass: "auction-con" }, [
              _vm.albumList
                ? _c(
                    "div",
                    [
                      _c(
                        "draggable",
                        _vm._b(
                          {
                            staticClass: "auction-list",
                            attrs: {
                              list: _vm.albumList,
                              filter: ".add",
                              "set-data": _vm.setData,
                              disabled: _vm.lockSet,
                            },
                          },
                          "draggable",
                          {
                            forceFallback: true,
                            fallbackClass: "draggable-has",
                            scroll: true,
                          },
                          false
                        ),
                        [
                          _vm._l(_vm.albumList, function (m, i) {
                            return _c(
                              "div",
                              {
                                key: "auction" + i,
                                staticClass: "item",
                                style:
                                  "width: " +
                                  _vm.viewW +
                                  "px;cursor: " +
                                  (_vm.lockSet ? "default" : "move") +
                                  ";",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "img",
                                    style:
                                      "width: " +
                                      _vm.viewW +
                                      "px;height:" +
                                      _vm.viewW +
                                      "px;",
                                  },
                                  [
                                    m.warehouse_image[0]
                                      ? [
                                          _c("el-image", {
                                            directives: [
                                              {
                                                name: "lazy",
                                                rawName: "v-lazy",
                                              },
                                            ],
                                            style:
                                              "width: " +
                                              _vm.viewW +
                                              "px;display: block;",
                                            attrs: {
                                              src:
                                                m.warehouse_image[0] +
                                                "?x-oss-process=image/auto-orient,1/resize,m_pad,w_300,h_300/quality,q_90/format,webp",
                                              fit: "contain",
                                            },
                                          }),
                                        ]
                                      : m.contract_image[0]
                                      ? [
                                          _c("el-image", {
                                            directives: [
                                              {
                                                name: "lazy",
                                                rawName: "v-lazy",
                                              },
                                            ],
                                            style:
                                              "width: " +
                                              _vm.viewW +
                                              "px;display: block;",
                                            attrs: {
                                              src:
                                                m.contract_image[0] +
                                                "?x-oss-process=image/auto-orient,1/resize,m_pad,w_300,h_300/quality,q_90/format,webp",
                                              fit: "contain",
                                            },
                                          }),
                                        ]
                                      : m.isZW
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "img-show item-flex-center",
                                              style:
                                                "width: " +
                                                _vm.viewW +
                                                "px;height:" +
                                                _vm.viewW +
                                                "px;background: #eee;user-select: none;",
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "icon",
                                                staticStyle: {
                                                  cursor: "pointer",
                                                  width: "30px",
                                                  height: "30px",
                                                },
                                                attrs: {
                                                  src: "https://saas.cdn.yunzongbu.cn/merchant/333425/2025116/image/1737037713116454013.png",
                                                  alt: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.delImgAction(i)
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "name",
                                    class: m.isZW ? "item-flex-center" : "",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.startSort + i + _vm.startLot)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    m.product_no
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "ml5",
                                            staticStyle: {
                                              "word-wrap": "break-word",
                                            },
                                          },
                                          [_vm._v(_vm._s(m.product_no))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    m.product_name
                                      ? _c("span", { staticClass: "ml5" }, [
                                          _vm._v(_vm._s(m.product_name)),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                m.retain_price || m.retain_price === 0
                                  ? _c("div", { staticClass: "desc" }, [
                                      _c("span", [
                                        _vm._v(
                                          "保留价 " +
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                m.retain_price,
                                                2
                                              )
                                            )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                Number(m.take_home_price)
                                  ? _c("div", { staticClass: "desc" }, [
                                      _c("span", [
                                        _vm._v(
                                          "实得价 " +
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                m.take_home_price,
                                                2
                                              )
                                            )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                m.entrustUser || m.storeCategory
                                  ? _c("div", { staticClass: "desc" }, [
                                      m.entrustUser
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(m.entrustUser.real_name)
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      m.entrustUser && m.storeCategory
                                        ? _c("span", [_vm._v("|")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      m.storeCategory
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(m.storeCategory.cate_name)
                                            ),
                                          ])
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "item add unDrag",
                              on: { click: _vm.addEmptyAction },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "img item-flex-center",
                                  style:
                                    "width: " +
                                    _vm.viewW +
                                    "px;height:" +
                                    _vm.viewW +
                                    "px;",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "img-show item-flex-center",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "icon",
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/merchant/333425/2025117/image/1737113501517518263.png",
                                          alt: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", [_vm._v("增加预留号码")]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "formFixedBox" },
          [
            _c(
              "el-button",
              {
                staticClass: "btn",
                attrs: { type: "primary" },
                on: { click: _vm.saveAction },
              },
              [_vm._v("保存")]
            ),
            _vm._v(" "),
            _c("el-button", { on: { click: _vm.printAction } }, [
              _vm._v("打印"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "print-view" }, [
        _c("div", { attrs: { id: "printEL" } }, [
          !_vm.pageLoading
            ? _c("div", { staticClass: "auction-con" }, [
                _vm.albumList
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "auction-list" },
                        _vm._l(_vm.albumList, function (m, i) {
                          return _c(
                            "div",
                            {
                              key: "auction" + i,
                              staticClass: "item",
                              style:
                                "width: " +
                                _vm.printW +
                                "px;cursor: " +
                                (_vm.lockSet ? "default" : "move") +
                                ";",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "img",
                                  style:
                                    "width: " +
                                    _vm.printW +
                                    "px;height:" +
                                    _vm.printW +
                                    "px;",
                                },
                                [
                                  m.warehouse_image[0]
                                    ? [
                                        _c("el-image", {
                                          directives: [
                                            { name: "lazy", rawName: "v-lazy" },
                                          ],
                                          style:
                                            "width: " +
                                            _vm.printW +
                                            "px;display: block;",
                                          attrs: {
                                            src:
                                              m.warehouse_image[0] +
                                              "?x-oss-process=image/auto-orient,1/resize,m_pad,w_300,h_300/quality,q_90/format,webp",
                                            fit: "contain",
                                          },
                                        }),
                                      ]
                                    : m.contract_image[0]
                                    ? [
                                        _c("el-image", {
                                          directives: [
                                            { name: "lazy", rawName: "v-lazy" },
                                          ],
                                          style:
                                            "width: " +
                                            _vm.printW +
                                            "px;display: block;",
                                          attrs: {
                                            src:
                                              m.contract_image[0] +
                                              "?x-oss-process=image/auto-orient,1/resize,m_pad,w_300,h_300/quality,q_90/format,webp",
                                            fit: "contain",
                                          },
                                        }),
                                      ]
                                    : m.isZW
                                    ? [
                                        _c("div", {
                                          staticClass:
                                            "img-show item-flex-center",
                                          style:
                                            "width: " +
                                            _vm.viewW +
                                            "px;height:" +
                                            _vm.viewW +
                                            "px;background: #eee;user-select: none;",
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "name",
                                  class: m.isZW ? "item-flex-center" : "",
                                  staticStyle: { "word-wrap": "break-word" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.startSort + i + _vm.startLot)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  m.product_no
                                    ? _c("span", { staticClass: "ml5" }, [
                                        _vm._v(_vm._s(m.product_no)),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  m.product_name
                                    ? _c("span", { staticClass: "ml5" }, [
                                        _vm._v(_vm._s(m.product_name)),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              m.retain_price
                                ? _c("div", { staticClass: "desc" }, [
                                    _c("span", [
                                      _vm._v(
                                        "保留价 " +
                                          _vm._s(
                                            _vm.formatPriceNumber(
                                              m.retain_price,
                                              2
                                            )
                                          )
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              Number(m.take_home_price)
                                ? _c("div", { staticClass: "desc" }, [
                                    _c("span", [
                                      _vm._v(
                                        "实得价 " +
                                          _vm._s(
                                            _vm.formatPriceNumber(
                                              m.take_home_price,
                                              2
                                            )
                                          )
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              m.entrustUser || m.storeCategory
                                ? _c("div", { staticClass: "desc" }, [
                                    m.entrustUser
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(m.entrustUser.real_name)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    m.entrustUser && m.storeCategory
                                      ? _c("span", [_vm._v("|")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    m.storeCategory
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(m.storeCategory.cate_name)
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }