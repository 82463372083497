"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      routerUrl: ''
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  mounted: function mounted() {
    this.routerUrl = this.$route.path;
  },
  methods: {
    onGOUrl: function onGOUrl(url) {
      this.$router.push({
        path: url
      });
    },
    changeTab: function changeTab(data) {
      var _this = this;
      if (data !== this.routerUrl) {
        this.$router.replace({
          path: data
        });
        this.routerUrl = data;
        setTimeout(function () {
          _this.routerUrl = _this.$route.path;
        });
      }
    }
  }
};