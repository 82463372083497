"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("@iconify/vue2");
// import { useBasicLayout } from '@/hooks/useBasicLayout'
var _default = exports.default = {
  name: 'ImageHeader',
  components: {
    Icon: _vue.Icon
  },
  data: function data() {
    return {
      isOpen: false,
      activeIndex: 0,
      menu: [{
        icon: 'https://saas.cdn.yunzongbu.cn/merchant/559719/2025324/image/1742802477262909083.png',
        name: '背景移除',
        type: 'imageCut',
        change: true,
        notHas: false
      }, {
        icon: 'https://saas.cdn.yunzongbu.cn/merchant/559719/2025324/image/174280247726130383.png',
        name: '变清晰',
        type: 'imageClear',
        change: false,
        notHas: false
      }, {
        icon: 'https://saas.cdn.yunzongbu.cn/merchant/559719/2025324/image/1742802477262483407.png',
        name: '涂抹消除',
        type: 'imageScratch',
        change: false,
        notHas: false
      }, {
        icon: 'https://saas.cdn.yunzongbu.cn/merchant/559719/2025324/image/1742802477263943209.png',
        name: '提取线稿',
        type: 'tqxg',
        change: false,
        notHas: false
      }, {
        icon: 'https://saas.cdn.yunzongbu.cn/merchant/559719/2025324/image/1742802477262830891.png',
        name: '裁剪尺寸',
        type: 'cjcc',
        change: false,
        notHas: false
      }, {
        icon: 'https://saas.cdn.yunzongbu.cn/merchant/559719/2025324/image/1742803560530898925.png',
        name: '画面扶正',
        type: 'hmfz',
        change: false,
        notHas: false
      }, {
        icon: 'https://saas.cdn.yunzongbu.cn/merchant/559719/2025324/image/1742802477262794650.png',
        name: '图片加框',
        type: 'tpjk',
        change: false,
        notHas: false
      }, {
        icon: 'https://saas.cdn.yunzongbu.cn/merchant/559719/2025324/image/1742802477263515954.png',
        name: '去除水印',
        type: 'qcsy',
        change: false,
        notHas: false
      }, {
        icon: 'https://saas.cdn.yunzongbu.cn/merchant/559719/2025324/image/1742802477262626964.png',
        name: '尺寸外扩',
        type: 'imageOutward',
        change: false,
        notHas: false
      }, {
        icon: 'https://saas.cdn.yunzongbu.cn/merchant/559719/2025324/image/1742802477263827307.png',
        name: '色彩校正',
        type: 'imageColorEnhance',
        change: false,
        notHas: false
      }, {
        icon: 'https://saas.cdn.yunzongbu.cn/merchant/559719/2025324/image/1742802477263221152.png',
        name: '背景替换',
        type: 'imageReplace',
        change: false,
        notHas: false
      }, {
        icon: 'https://saas.cdn.yunzongbu.cn/merchant/559719/2025324/image/174280247726278769.png',
        name: '画笔标记',
        type: 'hbbd',
        change: false,
        notHas: false
      }]
    };
  },
  computed: {
    isMobile: function isMobile() {
      return false;
    }
  },
  methods: {
    onClickItem: function onClickItem(item, index) {
      if (!item.type) return false;
      this.activeIndex = index;
      this.$emit('onClickItem', item);
      this.closePop();
    },
    closePop: function closePop() {
      this.isOpen = false;
    },
    clickOpenAction: function clickOpenAction() {
      if (!this.isMobile) return false;
      this.isOpen = !this.isOpen;
    },
    mouseenter: function mouseenter() {
      if (this.isMobile) return false;
      this.isOpen = true;
    },
    mouseleave: function mouseleave() {
      if (this.isMobile) return false;
      this.isOpen = false;
    }
  }
};