var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.formValidate.id ? "修改作者" : "新增作者",
            visible: _vm.showEdit,
            size: "700px",
            "wrapper-closable": false,
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEdit = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                padding: "0 20px",
                "max-height": "80vh",
                "overflow-y": "auto",
              },
            },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "100px", "label-position": "left" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "头像", prop: "avatar" } },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _vm.formValidate.avatar
                          ? _c(
                              "div",
                              {
                                staticClass: "pictrue",
                                attrs: { draggable: "false" },
                              },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: {
                                    src: _vm.formValidate.avatar,
                                    fit: "contain",
                                    "preview-src-list": [
                                      _vm.formValidate.avatar,
                                    ],
                                  },
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-error btndel",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove("avatar")
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "uploadCont",
                            attrs: { title: "750*750px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox",
                                on: {
                                  click: function ($event) {
                                    return _vm.modalPicTap("1")
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "w200",
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formValidate.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "name", $$v)
                          },
                          expression: "formValidate.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别", prop: "sex" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formValidate.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "sex", $$v)
                            },
                            expression: "formValidate.sex",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "男" } }, [
                            _vm._v("男"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "女" } }, [
                            _vm._v("女"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "生卒年", prop: "birth_year" } },
                    [
                      _c("el-input", {
                        staticClass: "w200",
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formValidate.birth_year,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "birth_year", $$v)
                          },
                          expression: "formValidate.birth_year",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "籍贯", prop: "native" } },
                    [
                      _c("el-input", {
                        staticClass: "w200",
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formValidate.native,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "native", $$v)
                          },
                          expression: "formValidate.native",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "中文介绍", prop: "intro" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "textarea",
                          rows: "6",
                          placeholder: "请输入",
                        },
                        model: {
                          value: _vm.formValidate.intro,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "intro", $$v)
                          },
                          expression: "formValidate.intro",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "英文介绍", prop: "english_descipt" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "textarea",
                          rows: "6",
                          placeholder: "请输入",
                        },
                        model: {
                          value: _vm.formValidate.english_descipt,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "english_descipt", $$v)
                          },
                          expression: "formValidate.english_descipt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { margin: "20px 0 20px 120px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.saveLoading },
                  on: { click: _vm.saveAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.saveLoading },
                  on: {
                    click: function ($event) {
                      _vm.showEdit = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }