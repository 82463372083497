var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 170px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                height: "100%",
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.id
                },
                data: _vm.tableData.data,
                size: "mini",
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "30",
                },
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托方",
                  width: "160",
                  prop: "",
                  "search-key": "entrust_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "max-width": "100%" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(scope.row.entrustUser)
                              },
                            },
                          },
                          [
                            scope.row.entrustUser
                              ? _c(
                                  "div",
                                  { staticClass: "nowrap" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row.entrustUser.nickname) +
                                        "\n              "
                                    ),
                                    scope.row.entrustUser.real_name
                                      ? [
                                          _vm._v(
                                            "\n                (" +
                                              _vm._s(
                                                scope.row.entrustUser.real_name
                                              ) +
                                              ")\n              "
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "库房编号",
                  width: "100",
                  prop: "",
                  "search-key": "erp_product_no",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.erpProduct
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.erpProduct.product_no || ""
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "图录号",
                  width: "80",
                  prop: "",
                  "search-key": "lot",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.erpProduct
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.erpProduct.lot) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "初始名称",
                  width: "160",
                  prop: "",
                  "search-key": "erp_product_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.erpProduct
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.erpProduct.product_name) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "合同号",
                  width: "130",
                  prop: "",
                  "show-overflow-tooltip": "",
                  "search-key": "contract_no",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "max-width": "100%" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.getdetail(scope.row.contract_id)
                              },
                            },
                          },
                          [
                            scope.row.contract
                              ? _c("div", { staticClass: "nowrap" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.contract.contract_no) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "退回原因",
                  width: "90",
                  prop: "",
                  "search-key": "auction_status",
                  "search-type": "radio",
                  options: [
                    { label: "交割失败", value: "-1" },
                    { label: "流拍", value: "4" },
                    { label: "成交", value: "5" },
                    { label: "私洽成交", value: "7" },
                  ],
                  "show-overflow-tooltip": "",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.auctionStatusText) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "业务员",
                  width: "140",
                  prop: "",
                  "show-overflow-tooltip": "",
                  "search-key": "salesman_admin_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.salesmanAdminData &&
                        scope.row.salesmanAdminData.length
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.salesmanAdminData
                                      .map(function (m) {
                                        return m.real_name
                                      })
                                      .join(",")
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发货方式",
                  width: "100",
                  prop: "",
                  "search-key": "delivery_type",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.delivery_type == 1
                          ? _c("div", [_vm._v("快递发货")])
                          : scope.row.delivery_type == 2
                          ? _c("div", [_vm._v("客户自提")])
                          : scope.row.delivery_type == 4
                          ? _c("div", [_vm._v("员工送货")])
                          : scope.row.delivery_type
                          ? _c("div", [
                              _vm._v(_vm._s(scope.row.deliveryTypeText)),
                            ])
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "快递公司",
                  width: "100",
                  prop: "",
                  "search-key": "delivery_name",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.delivery_name || "-") +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "收件地址",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.delivery_type &&
                        scope.row.delivery_type != 2 &&
                        scope.row.entrustUser &&
                        scope.row.entrustUser.entrust &&
                        scope.row.entrustUser.entrust.contact_address
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.entrustUser.entrust.contact_address
                                )
                              ),
                            ])
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "操作",
                  width: "70",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.editstatic(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n            修改物流\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c goodslist-page-box" },
        [
          _c(
            "div",
            {
              staticClass: "flex-one flex",
              staticStyle: { "margin-left": "4px" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.createPushOrder },
                },
                [_vm._v("创建出库单")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.logisticsOrder },
                },
                [_vm._v("确认物流")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: { "margin-right": "10px" },
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes ",
              total: _vm.tableData.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "",
            visible: _vm.showconfig,
            direction: "rtl",
            size: "450px",
            "destroy-on-close": true,
            "show-close": true,
            "wrapper-closable": true,
            "custom-class": "overfloyy",
            "before-close": _vm.saveconfig,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showconfig = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticStyle: { "font-weight": "bold" },
              attrs: { "label-width": "40px", "label-position": "left" },
            },
            [
              _c("el-form-item", [_c("span", [_vm._v("委托标的清单配置")])]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    {
                      staticStyle: { width: "100px", display: "inline-block" },
                    },
                    [_vm._v("售出后收费")]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    staticStyle: { width: "40px" },
                    model: {
                      value: _vm.inventoryConfig.switch.sold_price_switch,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inventoryConfig.switch,
                          "sold_price_switch",
                          $$v
                        )
                      },
                      expression: "inventoryConfig.switch.sold_price_switch",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    {
                      staticStyle: { width: "100px", display: "inline-block" },
                    },
                    [_vm._v("未售出收费")]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    staticStyle: { width: "40px" },
                    model: {
                      value: _vm.inventoryConfig.switch.unsold_price_switch,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inventoryConfig.switch,
                          "unsold_price_switch",
                          $$v
                        )
                      },
                      expression: "inventoryConfig.switch.unsold_price_switch",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    {
                      staticStyle: { width: "100px", display: "inline-block" },
                    },
                    [_vm._v("其他收费项目")]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    staticStyle: { width: "40px" },
                    model: {
                      value: _vm.inventoryConfig.switch.other_price_switch,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inventoryConfig.switch,
                          "other_price_switch",
                          $$v
                        )
                      },
                      expression: "inventoryConfig.switch.other_price_switch",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.inventoryConfig.switch.sold_price_switch
                ? [
                    _c("el-form-item", [
                      _c("span", [_vm._v("售出后收费项目")]),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.inventoryConfig.price.sold_price,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          { key: "C_" + index },
                          [
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: { placeholder: "自定义名称" },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                model: {
                                  value: item.unit,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unit", $$v)
                                  },
                                  expression: "item.unit",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: 1, label: "元/件" } },
                                  [_vm._v("元/件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-option",
                                  { attrs: { value: 2, label: "%" } },
                                  [_vm._v("%落槌价")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.inventoryConfig.switch.unsold_price_switch
                ? [
                    _c("el-form-item", [_c("span", [_vm._v("未售出收费")])]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.inventoryConfig.price.unsold_price,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          { key: "U_" + index },
                          [
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: { placeholder: "自定义名称" },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                model: {
                                  value: item.unit,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unit", $$v)
                                  },
                                  expression: "item.unit",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: 1, label: "元/件" } },
                                  [_vm._v("元/件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-option",
                                  { attrs: { value: 2, label: "%" } },
                                  [_vm._v("%保留价")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.inventoryConfig.switch.other_price_switch
                ? [
                    _c("el-form-item", [_c("span", [_vm._v("其他收费项目")])]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.inventoryConfig.price.other_price,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          { key: "o_" + index },
                          [
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: { placeholder: "自定义名称" },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                model: {
                                  value: item.unit,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unit", $$v)
                                  },
                                  expression: "item.unit",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: 1, label: "元/件" } },
                                  [_vm._v("元/件")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "确认物流", visible: _vm.showflag, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.showflag = $event
            },
            close: _vm.closeAction,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formvisible,
                "label-width": "80px",
                inline: false,
                size: "normal",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      on: {
                        change: function ($event) {
                          _vm.formvisible.delivery_name = "顺丰速运"
                        },
                      },
                      model: {
                        value: _vm.formvisible.delivery_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formvisible, "delivery_type", $$v)
                        },
                        expression: "formvisible.delivery_type",
                      },
                    },
                    [_vm._v("快递发货")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      on: { change: _vm.getDeliveryAction },
                      model: {
                        value: _vm.formvisible.delivery_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formvisible, "delivery_type", $$v)
                        },
                        expression: "formvisible.delivery_type",
                      },
                    },
                    [_vm._v("客户自提")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "4" },
                      on: {
                        change: function ($event) {
                          _vm.formvisible.delivery_name = ""
                        },
                      },
                      model: {
                        value: _vm.formvisible.delivery_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formvisible, "delivery_type", $$v)
                        },
                        expression: "formvisible.delivery_type",
                      },
                    },
                    [_vm._v("员工送货")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formvisible.delivery_type == "1" ||
              _vm.formvisible.delivery_type == "4"
                ? [
                    _vm.formvisible.delivery_type == "1"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "选择物流" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                model: {
                                  value: _vm.formvisible.delivery_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formvisible,
                                      "delivery_name",
                                      $$v
                                    )
                                  },
                                  expression: "formvisible.delivery_name",
                                },
                              },
                              _vm._l(_vm.expressList, function (m, i) {
                                return _c("el-option", {
                                  key: i,
                                  attrs: { value: m.label },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm.formvisible.delivery_type == "4"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "选择员工" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                model: {
                                  value: _vm.formvisible.delivery_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formvisible,
                                      "delivery_name",
                                      $$v
                                    )
                                  },
                                  expression: "formvisible.delivery_name",
                                },
                              },
                              _vm._l(_vm.adminarr, function (item) {
                                return _c("el-option", {
                                  key: item.merchant_admin_id,
                                  attrs: {
                                    label: item.real_name,
                                    value: item.real_name,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "姓名" } },
                      [
                        _c("el-input", {
                          staticStyle: { "margin-right": "40px" },
                          model: {
                            value: _vm.formvisible.entrust_contact_name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formvisible,
                                "entrust_contact_name",
                                $$v
                              )
                            },
                            expression: "formvisible.entrust_contact_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "地址" } },
                      [
                        _c("el-input", {
                          staticStyle: { "margin-right": "40px" },
                          model: {
                            value: _vm.formvisible.contact_address,
                            callback: function ($$v) {
                              _vm.$set(_vm.formvisible, "contact_address", $$v)
                            },
                            expression: "formvisible.contact_address",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "电话" } },
                      [
                        _c("el-input", {
                          staticStyle: { "margin-right": "40px" },
                          model: {
                            value: _vm.formvisible.entrust_contact_phone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formvisible,
                                "entrust_contact_phone",
                                $$v
                              )
                            },
                            expression: "formvisible.entrust_contact_phone",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm.formvisible.delivery_type == 2
                ? [
                    _c("el-form-item", { attrs: { label: "所在库房" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.formvisible.erpProduct.warehouse
                              ? _vm.formvisible.erpProduct.warehouse.name
                              : ""
                          ) +
                          " " +
                          _vm._s(
                            _vm.formvisible.erpProduct.shelf
                              ? _vm.formvisible.erpProduct.shelf.shelf_no +
                                  " 货架"
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "库房地址" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.formvisible.erpProduct.warehouse
                              ? _vm.formvisible.erpProduct.warehouse
                                  .address_detail
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "库管姓名" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.formvisible.erpProduct.warehouse
                              ? _vm.formvisible.erpProduct.warehouse
                                  .contact_name
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "库管电话" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.formvisible.erpProduct.warehouse
                              ? _vm.formvisible.erpProduct.warehouse
                                  .contact_phone
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showflag = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showpreview, width: "840px" },
          on: {
            "update:visible": function ($event) {
              _vm.showpreview = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "max-height": "70vh", "overflow-y": "auto" } },
            [
              _vm.contactImg && _vm.contactImg.length
                ? _vm._l(_vm.contactImg, function (m, i) {
                    return _c("div", { key: i }, [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { src: m, alt: "" },
                      }),
                    ])
                  })
                : _c("div", { attrs: { id: "printContent" } }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.html_content) },
                    }),
                  ]),
            ],
            2
          ),
          _vm._v(" "),
          _vm.contactUploadId
            ? _c(
                "div",
                { staticClass: "mt20 pb20 item-flex-center" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.bindPrint },
                    },
                    [_vm._v("打印合同")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }