var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.updLoading,
          expression: "updLoading",
        },
      ],
      staticClass: "flex",
      staticStyle: { height: "100%" },
      attrs: {
        "element-loading-text":
          "图片上传中" + _vm.updSussNum + "/" + _vm.imgLength,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "pictureLeftBox",
          staticStyle: {
            width: "200px",
            "padding-right": "20px",
            "flex-shrink": "0",
          },
        },
        [
          _c(
            "div",
            { staticClass: "treeBox" },
            [
              _c("div", { staticClass: "systemTit" }, [_vm._v("系统分类")]),
              _vm._v(" "),
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                _vm._l(_vm.systemCategoryList, function (item) {
                  return _c(
                    "el-collapse-item",
                    {
                      key: item.attachment_category_id,
                      attrs: {
                        title: item.attachment_category_name,
                        name: item.attachment_category_id,
                      },
                    },
                    _vm._l(item.children, function (ele) {
                      return _c(
                        "div",
                        {
                          key: ele.attachment_category_id,
                          staticClass: "customItem",
                          class:
                            _vm.tableData.attachment_category_id ==
                            ele.attachment_category_id
                              ? "activeColor"
                              : "",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleNodeClick(ele)
                            },
                          },
                        },
                        [_vm._v(_vm._s(ele.attachment_category_name))]
                      )
                    }),
                    0
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    {
                      staticClass: "weightClass",
                      attrs: { title: "自定义分类", name: "custom" },
                    },
                    _vm._l(_vm.merCategoryList, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.attachment_category_id,
                          staticClass: "customItem",
                          class:
                            _vm.tableData.attachment_category_id ==
                            item.attachment_category_id
                              ? "activeColor"
                              : "",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleNodeClick(item)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "customText" }, [
                            _vm._v(_vm._s(item.attachment_category_name)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            { attrs: { placement: "top", effect: "light" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-edit",
                                    staticStyle: {
                                      "font-size": "16px",
                                      cursor: "pointer",
                                    },
                                    attrs: { title: "修改" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.onEdit(
                                          item.attachment_category_id
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-delete",
                                    staticStyle: {
                                      "font-size": "16px",
                                      cursor: "pointer",
                                    },
                                    attrs: { title: "删除分类" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return (function () {
                                          return _vm.handleDelete(
                                            item.attachment_category_id
                                          )
                                        })($event)
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "customHandIconBox" }, [
                                _c("i", {
                                  staticClass: "el-icon-more customHandIcon",
                                }),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "systemTit",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleRecycleClick($event)
                    },
                  },
                },
                [_vm._v("回收站")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pictureLeftHand" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onAdd(0)
                    },
                  },
                },
                [_vm._v("创建分类")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "conter",
        },
        [
          _c(
            "div",
            {
              staticClass: "flex justify-b bnt",
              staticStyle: { height: "36px" },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: "拍卖会" },
                      model: {
                        value: _vm.tableData.venue_keyword,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableData, "venue_keyword", $$v)
                        },
                        expression: "tableData.venue_keyword",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getFileList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: "专场名称" },
                      model: {
                        value: _vm.tableData.album_keyword,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableData, "album_keyword", $$v)
                        },
                        expression: "tableData.album_keyword",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getFileList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: "拍品名称/库号/LOT号" },
                      model: {
                        value: _vm.tableData.auction_keyword,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableData, "auction_keyword", $$v)
                        },
                        expression: "tableData.auction_keyword",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getFileList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: "图片标签" },
                      model: {
                        value: _vm.tableData.custom_label_keyword,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableData, "custom_label_keyword", $$v)
                        },
                        expression: "tableData.custom_label_keyword",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getFileList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _vm.params !== "/merchant/config/picture" &&
                  !_vm.changeCategory &&
                  !_vm.ssif &&
                  ![30010, 30006].includes(_vm.tableData.attachment_category_id)
                    ? _c(
                        "el-button",
                        {
                          staticClass: "mr10",
                          attrs: {
                            size: "small",
                            type: "primary",
                            icon: "el-icon-circle-check",
                          },
                          on: { click: _vm.checkPics },
                        },
                        [_vm._v("使用选中图片")]
                      )
                    : _vm.params !== "/merchant/config/picture" &&
                      _vm.changeCategory &&
                      !_vm.ssif &&
                      ![30010, 30006].includes(
                        _vm.tableData.attachment_category_id
                      )
                    ? _c(
                        "el-button",
                        {
                          staticClass: "mr10",
                          attrs: {
                            size: "small",
                            type: "primary",
                            icon: "el-icon-circle-check",
                          },
                          on: { click: _vm.checkCategory },
                        },
                        [_vm._v("使用选中分类")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.params == "/merchant/config/picture" ||
                  _vm.params == "/merchant/systemForm/picture"
                    ? [
                        _c(
                          "div",
                          { staticStyle: { width: "100px" } },
                          [
                            _c(
                              "ImageHeader",
                              {
                                ref: "viewImageHeaderEL",
                                on: { onClickItem: _vm.onAiChange },
                              },
                              [
                                _c("div", { staticClass: "menu-title" }, [
                                  _c("div", { staticClass: "menu-text" }, [
                                    _vm._v(_vm._s(_vm.headTit)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "menu-icon rotate-right" },
                                    [
                                      _c("Icon", {
                                        staticStyle: {
                                          width: "20px",
                                          height: "20px",
                                        },
                                        attrs: {
                                          icon: "ic:round-chevron-right",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  height: "36px",
                                  "margin-right": "60px",
                                },
                                attrs: { size: "small", type: "primary" },
                                on: { click: _vm.onViewExamples },
                              },
                              [_vm._v("查看示例")]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.uploadShow
                    ? _c(
                        "el-upload",
                        {
                          ref: "uploadImg",
                          staticClass: "upload-demo mr10 mb15",
                          attrs: {
                            action: _vm.fileUrl,
                            "on-success": _vm.handleSuccess,
                            headers: _vm.myHeaders,
                            "show-file-list": false,
                            multiple: "",
                            "before-upload": _vm.beforeAvatarUpload,
                            "on-change": _vm.onChange,
                            "http-request": _vm.modeUpload,
                            accept: "image/*,video/*,audio/*",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { height: "36px" },
                              attrs: {
                                size: "small",
                                type: "primary",
                                icon: "el-icon-upload",
                              },
                            },
                            [_vm._v("上传")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: [30010, 30006].includes(
                    _vm.tableData.attachment_category_id
                  ),
                  expression:
                    "[30010,30006].includes(tableData.attachment_category_id)",
                },
              ],
              staticClass: "infinite-list",
            },
            [
              _c(
                "VirtualScroll",
                {
                  ref: "virtualRef",
                  staticStyle: { height: "100%", width: "100%" },
                  attrs: {
                    data: _vm.pictrueList.list,
                    height: 20,
                    "key-prop": "attachment_id",
                  },
                  on: {
                    change: function (renderData) {
                      return (_vm.virtualList = renderData)
                    },
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "load-more",
                          rawName: "v-load-more.expand",
                          value: {
                            func: _vm.loadNextPage,
                            target: ".el-table__body-wrapper",
                            delay: 300,
                            distance: 500,
                          },
                          expression:
                            "{ func: loadNextPage, target: '.el-table__body-wrapper', delay: 300, distance: 500 }",
                          modifiers: { expand: true },
                        },
                      ],
                      ref: "userRef",
                      staticClass: "noHover",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.virtualList,
                        "row-key": "attachment_id",
                        height: "100%",
                        size: "mini",
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "36",
                          resizable: false,
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  attrs: {
                                    slot: "reference",
                                    value:
                                      _vm.checkPicList.length !== 0 &&
                                      _vm.checkPicList.length ===
                                        _vm.pictrueList.list.length,
                                  },
                                  on: {
                                    change: function (e) {
                                      return _vm.docuTableChange(e)
                                    },
                                  },
                                  slot: "reference",
                                }),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  attrs: { value: scope.row.isSelect },
                                  on: {
                                    change: function (v) {
                                      return _vm.docuTableLineChange(
                                        v,
                                        scope.row
                                      )
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: {
                          label: "类型",
                          width: "100",
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("getFileTypeByExtension")(
                                        row.attachment_src
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: {
                          label: "格式",
                          width: "100",
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("getFileExtension")(
                                        row.attachment_src
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: {
                          label: "文档大小",
                          width: "100",
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("formatFileSize")(
                                        row.attachment_size
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: {
                          label: "文档名称",
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(row.attachment_name) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: {
                          label: "创建时间",
                          width: "200",
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(row.create_time) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.loading &&
          ![30010, 30006].includes(_vm.tableData.attachment_category_id)
            ? _c(
                "ul",
                {
                  directives: [
                    {
                      name: "infinite-scroll",
                      rawName: "v-infinite-scroll",
                      value: _vm.pictrueLoad,
                      expression: "pictrueLoad",
                    },
                  ],
                  staticClass: "infinite-list",
                  staticStyle: { overflow: "auto" },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowPic,
                          expression: "isShowPic",
                        },
                      ],
                      staticClass: "imagesNo",
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-picture",
                        staticStyle: {
                          "font-size": "60px",
                          color: "rgb(219, 219, 219)",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "imagesNo_sp" }, [
                        _vm._v("图片库为空"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "pictrueList acea-row" }, [
                    _c(
                      "div",
                      { staticClass: "conters" },
                      _vm._l(_vm.pictrueList.list, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "gridPic" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "imgConBox",
                                on: {
                                  click: function ($event) {
                                    return _vm.changImage(
                                      item,
                                      index,
                                      _vm.pictrueList.list
                                    )
                                  },
                                },
                              },
                              [
                                item.num > 0
                                  ? _c(
                                      "p",
                                      { staticClass: "number" },
                                      [
                                        _c(
                                          "el-badge",
                                          {
                                            staticClass: "item",
                                            attrs: { value: item.num },
                                          },
                                          [
                                            _c("a", {
                                              staticClass: "demo-badge",
                                              attrs: { href: "#" },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.attachment_type === 1
                                  ? _c("img", {
                                      directives: [
                                        {
                                          name: "lazy",
                                          rawName: "v-lazy",
                                          value:
                                            item.attachment_src +
                                            (item.attachment_src.indexOf(
                                              ".ico"
                                            ) == -1
                                              ? "!m640"
                                              : ""),
                                          expression:
                                            "item.attachment_src + (item.attachment_src.indexOf('.ico') == -1 ? '!m640' : '')",
                                        },
                                      ],
                                      class: item.isSelect ? "on" : "",
                                      staticStyle: { "object-fit": "contain" },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.attachment_type === 2
                                  ? _c("img", {
                                      directives: [
                                        {
                                          name: "lazy",
                                          rawName: "v-lazy",
                                          value:
                                            item.attachment_src +
                                            "?x-oss-process=video/snapshot,t_0,m_fast",
                                          expression:
                                            "item.attachment_src + '?x-oss-process=video/snapshot,t_0,m_fast'",
                                        },
                                      ],
                                      class: item.isSelect ? "on" : "",
                                      staticStyle: { "object-fit": "contain" },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.attachment_type === 2
                                  ? _c("div", { staticClass: "bofang" })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.attachment_type === 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "viewBox",
                                        on: {
                                          click: function ($event) {
                                            return _vm.prieviewimg(
                                              item.attachment_src
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("预览")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "space-between",
                                },
                              },
                              [
                                _vm.editId === item.attachment_id
                                  ? _c("el-input", {
                                      model: {
                                        value: item.attachment_name,
                                        callback: function ($$v) {
                                          _vm.$set(item, "attachment_name", $$v)
                                        },
                                        expression: "item.attachment_name",
                                      },
                                    })
                                  : _c(
                                      "p",
                                      {
                                        staticClass: "name",
                                        staticStyle: { width: "80%" },
                                      },
                                      [_vm._v(_vm._s(item.attachment_name))]
                                    ),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-edit imgIcon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(
                                        item.attachment_id,
                                        item.attachment_name
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isShowPic,
                          expression: "!isShowPic",
                        },
                      ],
                      staticClass: "exactlyText",
                    },
                    [_vm._v("已经到底了～")]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "flex justify-b list-footer" }, [
            _c(
              "div",
              { staticClass: "flex-one flex" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.selectAll($event)
                      },
                    },
                  },
                  [_vm._v("全选")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      disabled: _vm.checkPicList.length === 0,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.editPicList("图片")
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
                _vm._v(" "),
                _vm.checkPicList.length > 0
                  ? _c(
                      "el-select",
                      {
                        staticStyle: { "margin-left": "10px", width: "100px" },
                        attrs: { placeholder: "移动至" },
                        on: { change: _vm.getMove },
                        model: {
                          value: _vm.selectOptionId,
                          callback: function ($$v) {
                            _vm.selectOptionId = $$v
                          },
                          expression: "selectOptionId",
                        },
                      },
                      _vm._l(_vm.moveList, function (group) {
                        return _c(
                          "el-option-group",
                          {
                            key: group.attachment_category_id,
                            attrs: { label: group.attachment_category_name },
                          },
                          _vm._l(group.children, function (item) {
                            return _c("el-option", {
                              key: item.attachment_category_id,
                              attrs: {
                                label: item.attachment_category_name,
                                value: item.attachment_category_id,
                              },
                            })
                          }),
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.checkPicList.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "selectText flex",
                        staticStyle: { "align-items": "center" },
                      },
                      [_vm._v("选中" + _vm._s(_vm.checkPicList.length) + "条")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "totalText" }, [
              _vm._v("共 " + _vm._s(_vm.pictrueList.total) + " 条"),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            title: "",
            visible: _vm.moduleVisible,
            "close-on-click-modal": true,
            top: "5vh",
            "show-close": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.moduleVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "20px 0" } }, [
            _c("div", { staticClass: "flex justify-c mb20" }, [
              _c("i", {
                staticClass: "el-icon-warning-outline",
                staticStyle: { color: "red", "font-size": "40px" },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex justify-c mb20",
                staticStyle: {
                  color: "red",
                  "font-size": "18px",
                  "text-align": "center",
                },
              },
              [
                _vm._v("删除该图片后，所有使用该图的位置(例：专场，拍品) "),
                _c("br"),
                _vm._v(" 将同步删除！"),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex justify-c mb20",
                staticStyle: { color: "red", "font-size": "20px" },
              },
              [_vm._v("请谨慎操作！")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex justify-c" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.moduleVisible = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.onNext } },
                  [_vm._v("确认删除")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            title: "",
            visible: _vm.flVisible,
            "close-on-click-modal": true,
            top: "5vh",
            "show-close": false,
            width: "530px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.flVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "20px 0" } }, [
            _c("div", { staticClass: "flex justify-c mb20" }, [
              _c("i", {
                staticClass: "el-icon-warning-outline",
                staticStyle: { color: "red", "font-size": "40px" },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex justify-c mb20",
                staticStyle: {
                  color: "red",
                  "font-size": "18px",
                  "text-align": "center",
                },
              },
              [
                _vm._v(
                  "删除该分类后，所有使用该分类图片的位置(例：专场，拍品) "
                ),
                _c("br"),
                _vm._v(" 将同步删除！"),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex justify-c mb20",
                staticStyle: { color: "red", "font-size": "20px" },
              },
              [_vm._v("请谨慎操作！")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex justify-c" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.flVisible = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.onflNext } },
                  [_vm._v("确认删除")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.urlshow
        ? _c("el-image-viewer", {
            staticStyle: { "z-index": "99999999" },
            attrs: {
              "url-list": _vm.urlshowList,
              "on-close": function () {
                _vm.urlshow = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "90%",
            visible: _vm.drawerVisible,
            "with-header": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerVisible = $event
            },
          },
        },
        [
          _c("fixPicture", {
            ref: "fixPictureRef",
            on: { close: _vm.onClose, confirmImg: _vm.onConfirm },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }