var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 230px)", width: "900px" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                data: _vm.list,
                height: "100%",
                size: "mini",
                border: "",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: { label: "专场名称", prop: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.album_name)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: { label: "专场类型", prop: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            scope.row.album_type == 1
                              ? [_vm._v("限时拍")]
                              : scope.row.album_type == 2
                              ? [_vm._v("即时拍")]
                              : scope.row.album_type == 3
                              ? [_vm._v("同步拍")]
                              : scope.row.album_type == 4
                              ? [_vm._v("即刻拍")]
                              : scope.row.album_type == 5
                              ? [_vm._v("抖音拍")]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              padding: "0 20px",
                            },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.goAuctionPage(
                                  scope.row.album_id,
                                  scope.row.album_type,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.auction_num) + "件")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: { label: "专场状态", prop: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.album_status === 1
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "danger" } },
                              [_vm._v("预展中")]
                            )
                          : scope.row.album_status === 2
                          ? _c("el-tag", { attrs: { size: "small" } }, [
                              _vm._v("进行中"),
                            ])
                          : scope.row.album_status === 3
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "info" } },
                              [_vm._v("已结束")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.is_show === 0
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "info" } },
                              [_vm._v("已隐藏")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: { label: "成交确认书", prop: "", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "default",
                              loading: _vm.btnLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downloadPDF(scope.row)
                              },
                            },
                          },
                          [_vm._v("打印")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-size": _vm.queryParams.limit,
              "current-page": _vm.queryParams.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showDownloadList,
            "close-on-click-modal": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDownloadList = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex align-items-c",
              staticStyle: { transform: "translateY(-4px)" },
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", { staticStyle: { "font-size": "20px" } }, [
                _vm._v("查看文件"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadPDF()
                    },
                  },
                },
                [_vm._v("重新生成")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.downloadList, height: "400px" },
            },
            [
              _c("ytx-table-column", {
                attrs: { label: "文件名", prop: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.split("/").pop())),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "操作",
                  prop: "",
                  align: "center",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "default" },
                            on: {
                              click: function ($event) {
                                return _vm.downloadPDFUrl(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量打印成交确认书",
            visible: _vm.orderConfirmData.show,
            "close-on-click-modal": false,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.orderConfirmData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "max-height": "700px", "overflow-y": "auto" } },
            [
              _vm.printData.length > 0
                ? _c("div", { staticClass: "h400" }, [
                    _vm.albumPriceData.mer_id == 559615
                      ? _c(
                          "div",
                          {
                            ref: "print1Div",
                            staticClass: "print-div1",
                            attrs: { id: "printConfirm" },
                          },
                          _vm._l(_vm.printData, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                style:
                                  "width: 555px;height: 800px;overflow: hidden;",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-family": "黑体",
                                      color: "#000",
                                      overflow: "hidden",
                                      "padding-right": "14px",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticStyle: { height: "150px" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex align-items-c mt20",
                                        staticStyle: {
                                          "font-size": "14px",
                                          height: "60px",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticStyle: { width: "220px" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "flex-one",
                                            staticStyle: {
                                              "font-weight": "bold",
                                              overflow: "hidden",
                                              "white-space": "nowrap",
                                              "text-overflow": "ellipsis",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.tuluhao))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex align-items-c",
                                        staticStyle: {
                                          "font-size": "14px",
                                          height: "60px",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticStyle: { width: "220px" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "220px",
                                              "font-weight": "bold",
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(item.title))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex align-items-c",
                                        staticStyle: {
                                          "font-size": "14px",
                                          height: "60px",
                                          "margin-top": "120px",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticStyle: { width: "220px" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "flex-one",
                                            staticStyle: {
                                              "font-weight": "bold",
                                              overflow: "hidden",
                                              "white-space": "nowrap",
                                              "text-overflow": "ellipsis",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.albumPriceData.commission
                                                ) +
                                                "%"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "page-break-after": "always" },
                                }),
                              ]
                            )
                          }),
                          0
                        )
                      : _c(
                          "div",
                          {
                            ref: "print1Div",
                            staticClass: "print-div1",
                            attrs: { id: "printConfirm" },
                          },
                          _vm._l(_vm.printData, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                style:
                                  "height: 524px;padding-top: 40px;overflow: hidden;",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-family": "黑体",
                                      color: "#000",
                                      overflow: "hidden",
                                      "padding-right": "14px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pt20 item-flex-center",
                                        staticStyle: { position: "relative" },
                                      },
                                      [
                                        _vm.albumPriceData.mLogo
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "img",
                                                staticStyle: {
                                                  position: "absolute",
                                                  left: "0",
                                                  top: "0px",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    "max-height": "40px",
                                                    "max-width": "200px",
                                                  },
                                                  attrs: {
                                                    src: _vm.albumPriceData
                                                      .mLogo,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "c-name",
                                            staticStyle: {
                                              "font-size": "26px",
                                              "font-weight": "bold",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                  "text-align": "center",
                                                },
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.albumPriceData.mName
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "24px",
                                                    },
                                                  },
                                                  [_vm._v("成交确认书")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex align-items-c mt20",
                                        staticStyle: {
                                          "border-bottom": "1px solid #000",
                                          "font-size": "14px",
                                          height: "40px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { width: "200px" } },
                                          [_vm._v("拍卖会名称")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "flex-one",
                                            staticStyle: {
                                              "font-weight": "bold",
                                              overflow: "hidden",
                                              "white-space": "nowrap",
                                              "text-overflow": "ellipsis",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.albumPriceData.name)
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            "日期：" +
                                              _vm._s(_vm.albumPriceData.d)
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex align-items-c",
                                        staticStyle: {
                                          "border-bottom": "1px solid #000",
                                          "font-size": "14px",
                                          height: "40px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { width: "200px" } },
                                          [_vm._v("图录号、名称")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "flex-one",
                                            staticStyle: {
                                              "font-weight": "bold",
                                              overflow: "hidden",
                                              "white-space": "nowrap",
                                              "text-overflow": "ellipsis",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.tuluhao) +
                                                " " +
                                                _vm._s(item.title)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex align-items-c",
                                        staticStyle: {
                                          "border-bottom": "1px solid #000",
                                          "font-size": "14px",
                                          height: "40px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { width: "200px" } },
                                          [_vm._v("落槌价")]
                                        ),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "flex-one",
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex align-items-c",
                                        staticStyle: {
                                          "border-bottom": "1px solid #000",
                                          "font-size": "14px",
                                          height: "40px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { width: "200px" } },
                                          [_vm._v("佣金")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "flex-one",
                                            staticStyle: {
                                              "font-weight": "bold",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.albumPriceData.commission
                                              ) + "%"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex align-items-c",
                                        staticStyle: {
                                          "border-bottom": "1px solid #000",
                                          "font-size": "14px",
                                          height: "80px",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "flex-one" }, [
                                          _c(
                                            "div",
                                            { staticStyle: { width: "200px" } },
                                            [_vm._v("竞投号牌")]
                                          ),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass: "flex-one",
                                            staticStyle: {
                                              "font-weight": "bold",
                                            },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "flex-one" }, [
                                          _c(
                                            "div",
                                            { staticStyle: { width: "200px" } },
                                            [_vm._v("买受人签字")]
                                          ),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass: "flex-one",
                                            staticStyle: {
                                              "font-weight": "bold",
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex align-items-c mt20",
                                      },
                                      [
                                        _c("div", { staticClass: "txt" }, [
                                          _vm._v(
                                            "·买受人已认真阅读" +
                                              _vm._s(_vm.albumPriceData.mName) +
                                              "的拍卖规则，同意在拍卖交易中遵守拍卖规则中的一切条款，并按规定向" +
                                              _vm._s(_vm.albumPriceData.mName) +
                                              "支付佣金及其他各项费用。"
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex align-items-c mt40",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-one flex align-items-c",
                                          },
                                          [_vm._v("记录员签字：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-one flex align-items-c",
                                          },
                                          [_vm._v("拍卖师签字：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-one flex align-items-c",
                                            staticStyle: { flex: "1.5" },
                                          },
                                          [
                                            _c("div", { staticClass: "bj" }, [
                                              _vm._v("拍卖人公章："),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "flex-one",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "page-break-after": "always" },
                                }),
                              ]
                            )
                          }),
                          0
                        ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-c mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.orderConfirmData.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "print",
                      rawName: "v-print",
                      value: _vm.printObj2,
                      expression: "printObj2",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [_vm._v("确认打印")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }