"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
var _authenticate = require("@/api/authenticate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var selectType = {
  '1': 'Expert',
  '3': 'PraisalReport',
  '4': 'PraisalCategory',
  '5': 'PraisalSetting',
  '2': 'Appraisal',
  '6': 'praisalResult'
};
var _default = exports.default = {
  name: 'PraisalCategory',
  data: function data() {
    return {
      activeMenu: '6',
      showDetail: false,
      loading: false,
      resultId: '',
      // 分类id
      aa: '',
      resultName: '',
      // 结果名称
      resultSort: '',
      // 结果排序

      resultData: [],
      showChild: [],
      // 子类
      level: 1,
      isEdit: false,
      // 编辑
      editParentCategoryList: [],
      pid: null,
      visible: false,
      // 查看图片
      categoryLevel: [{
        id: 0,
        level_name: ''
      }],
      showEditLevelDetail: false
    };
  },
  created: function created() {
    this.load();
  },
  methods: {
    onSelectTit: function onSelectTit(index) {
      var _this = this;
      this.activeMenu = index;
      this.$router.replace({
        name: selectType[index]
      });
      setTimeout(function () {
        _this.activeMenu = '4';
      });
    },
    onUpload: function onUpload() {
      var _this2 = this;
      this.$modalUpload(function (img) {
        if (img.length > 1) return _this2.$message.error('只能选择一张');
        _this2.uploadImg = img[0];
      });
    },
    handleView: function handleView() {
      this.visible = true;
    },
    handleRemoveNew: function handleRemoveNew() {
      this.uploadImg = '';
    },
    getCartgorySetList: function getCartgorySetList(state) {
      var _this3 = this;
      this.loading = true;
      (0, _authenticate._getResultListApi)(state).then(function (res) {
        _this3.resultData = res.data.list;
        _this3.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this3.loading = false;
      });
    },
    load: function load() {
      var params = {
        page: 1,
        limit: 1000
      };
      this.getCartgorySetList(params);
    },
    createdAction: function createdAction(e) {
      this.showDetail = true;
      this.pid = null;
      this.level = 1;
      e.preventDefault();
    },
    removeAction: function removeAction(e, index) {
      var _this4 = this;
      var result = this.resultData[index];
      (0, _authenticate._deleteResultApi)(result.id).then(function (res) {
        if (res.status == 200) {
          _this4.$message.success('操作成功');
          _this4.load();
        }
      }).catch(function (err) {
        console.log(err);
      });
      return false;
    },
    linkUrl: function linkUrl(file) {
      window.open(file.response.url);
    },
    editAction: function editAction(e, index) {
      var result = this.resultData[index];
      this.resultId = result.id;
      this.resultName = result.name;
      this.resultSort = result.sort;
      this.isEdit = true;
      this.showDetail = true;
      e.preventDefault();
    },
    saveDetail: function saveDetail() {
      var _this5 = this;
      var sort = this.resultSort;
      var name = this.resultName;
      if (!name) {
        this.$message.error('请输入分类名称');
        return false;
      }
      var data = {
        name: name,
        sort: sort
      };
      if (this.isEdit) {
        if (this.resultId) {
          data.id = this.resultId;
          (0, _authenticate._editResultApi)(data).then(function (res) {
            if (res.status == 200) {
              _this5.$message.success('保存成功');
              _this5.load();
              _this5.showDetail = false;
              _this5.resultName = '';
              _this5.resultSort = '';
              _this5.isEdit = false;
            }
          }).catch(function (err) {
            console.log(err);
          });
        }
      } else {
        (0, _authenticate._createResultApi)(data).then(function (res) {
          if (res.status === 200) {
            _this5.$message.success('保存成功');
            _this5.load();
            _this5.showDetail = false;
            _this5.resultName = '';
            _this5.resultSort = '';
          }
        });
      }
      return false;
    },
    closeDetail: function closeDetail() {
      this.showDetail = false;
      this.resultId = '';
      this.resultName = '';
      this.isEdit = false;
      this.resultSort = '';
    },
    closeEditLevelDetail: function closeEditLevelDetail() {
      this.showEditLevelDetail = false;
    },
    goUpAction: function goUpAction(index) {
      var parent = JSON.parse(JSON.stringify(this.categoryLevel));
      parent.splice(index - 1, 0, parent[index]);
      parent.splice(index + 1, 1);
      this.categoryLevel = parent;
    }
  }
};