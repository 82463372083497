var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "section",
      {
        style: {
          background: _vm.facade.item_background,
          margin: "0 " + _vm.facade.page_margin / 2 + "px",
          borderRadius: _vm.facade.radius / 2 + "px",
          boxShadow: _vm.facade.item_shadow
            ? "0 0 " +
              (_vm.facade.item_shadow_size || 0) / 2 +
              "px " +
              _vm.facade.item_shadow
            : "",
          overflow: "hidden",
        },
      },
      [
        _c(
          "section",
          {
            style: {
              padding:
                "0 " +
                _vm.facade.item_padding / 2 +
                "px " +
                _vm.facade.item_padding / 2 +
                "px " +
                _vm.facade.item_padding / 2 +
                "px",
            },
          },
          [
            _vm.content.title
              ? _c(
                  "section",
                  {
                    style: {
                      paddingTop: _vm.facade.title_margin / 2 + "px",
                      color: _vm.facade.title_color,
                      fontWeight: _vm.facade.title_weight,
                      fontSize: _vm.facade.title_size / 2 + "px",
                      textAlign: _vm.facade.title_text_align,
                    },
                  },
                  [_vm._v(_vm._s(_vm.content.title))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.content.subtitle
              ? _c(
                  "section",
                  {
                    style: {
                      marginTop: _vm.facade.desc_margin / 2 + "px",
                      color: _vm.facade.desc_color,
                      fontWeight: _vm.facade.desc_weight,
                      fontSize: _vm.facade.desc_size / 2 + "px",
                      textAlign: _vm.facade.desc_text_align,
                      lineHeight: _vm.facade.desc_line_height / 2 + "px",
                      whiteSpace: "pre-wrap",
                    },
                  },
                  [_vm._v(_vm._s(_vm.content.subtitle))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.content.contentData
              ? _c("section", {
                  staticClass: "html-content",
                  style: {
                    marginTop: _vm.facade.desc_margin / 2 + "px",
                    color: _vm.facade.desc_color,
                    fontWeight: _vm.facade.desc_weight,
                    fontSize: _vm.facade.desc_size / 2 + "px",
                    textAlign: _vm.facade.desc_text_align,
                    lineHeight: _vm.facade.desc_line_height / 2 + "px",
                    transform: "scale(" + (_vm.isPoster ? 0.5 : 1) + ")",
                    transformOrigin: "0 0",
                  },
                  domProps: { innerHTML: _vm._s(_vm.content.contentData) },
                })
              : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }