"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _warehouse = require("@/api/warehouse");
var _system = require("@/api/system");
var _settings = require("@/settings");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "stokcheck",
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      showconfig: false,
      id: 0,
      type: "",
      showcreate: false,
      time: "",
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        username: "",
        type: "",
        page: 1,
        limit: 20,
        receipt_sn: "",
        order_type: "",
        keyword: "",
        status: "",
        task_id: this.$route.query.id || ''
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      selectionList: [],
      loading: false
    };
  },
  mounted: function mounted() {
    this.getList(1);
    // this.getadminList();
    // this.getstore();
  },
  methods: {
    changeTime: function changeTime(e) {
      console.log(e);
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: "OrderList",
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _warehouse.getcheckrecordlist)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this2 = this;
      var data = JSON.parse(JSON.stringify(this.tableFrom));
      data.export_type = "warehouseStockTakeRecord";
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this2.$createElement;
        _this2.$msgbox({
          title: "提示",
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this2.$msgbox.close();
                _this2.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList("warehouseStockTakeRecord");
    }
  }
};