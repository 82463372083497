"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AETListApi = AETListApi;
exports.attrListApi = attrListApi;
exports.getAccountListApi = getAccountListApi;
exports.getAssociationJoinApi = getAssociationJoinApi;
exports.getEffectsImageListApi = getEffectsImageListApi;
exports.getFontListApi = getFontListApi;
exports.getOrderStatusByIdApi = getOrderStatusByIdApi;
exports.getResourceBundleApi = getResourceBundleApi;
exports.getScoreInfoApi = getScoreInfoApi;
exports.getScoreInfoListApi = getScoreInfoListApi;
exports.getTokenApi = getTokenApi;
exports.getWxLoginStatusApi = getWxLoginStatusApi;
exports.getWxScanCodeApi = getWxScanCodeApi;
exports.iceInfoApi = iceInfoApi;
exports.iceSaveApi = iceSaveApi;
exports.initiateCompanyPayApi = initiateCompanyPayApi;
exports.orderSubmitApi = orderSubmitApi;
exports.saasLoginApi = saasLoginApi;
exports.sourceImageHandleApi = sourceImageHandleApi;
exports.standTemplateListApi = standTemplateListApi;
exports.submitRemittanceInformationApi = submitRemittanceInformationApi;
exports.tagListApi = tagListApi;
var _requestOpen3d = _interopRequireDefault(require("./requestOpen3d"));
/**
 * @description 
 */
function getTokenApi(data) {
  return _requestOpen3d.default.get('/member/app/merchant/saas/getToken', data);
}

/**
 * @description 
 */
function iceInfoApi(id, data) {
  return _requestOpen3d.default.get("/ice/app/ve/get/template/info/".concat(id), data);
}

/**
 * @description 
 */
function iceSaveApi(data) {
  return _requestOpen3d.default.post('/ice/app/ve/save/template/info', data);
}

/**
 * @description 
 */
function AETListApi(data) {
  return _requestOpen3d.default.get("/ice/app/ve/template/list", data);
}

/**
 * @description 
 */
function standTemplateListApi(data) {
  return _requestOpen3d.default.get("/ice/app/ve/standard/template/list", data);
}

/**
 * @description 属性 -- 列表
 */
function attrListApi(data) {
  return _requestOpen3d.default.get('/system/sys/attr/list', data);
}

/**
 * @description 
 */
function getFontListApi(data) {
  return _requestOpen3d.default.get('/ice/app/ve/font/list', {});
}

/**
 * @description 
 */
function getScoreInfoApi() {
  return _requestOpen3d.default.get('/saas/app/score/get/info');
}

/**
 * @description 
 */
function getScoreInfoListApi(type, data) {
  return _requestOpen3d.default.get("/saas/app/score/get/info/".concat(type), data);
}

/**
 * @description 
 */
function getResourceBundleApi(data) {
  return _requestOpen3d.default.get("/resource/bundle", data);
}

/**
 * @description 
 */
function orderSubmitApi(data) {
  return _requestOpen3d.default.post("/order/app/merchant/order/submit", data);
}

/**
 * @description 
 */
function initiateCompanyPayApi(data) {
  return _requestOpen3d.default.post("/pay/initiateCompanyPay", data);
}
function getOrderStatusByIdApi(data) {
  return _requestOpen3d.default.get("/order/app/order/getOrderStatusById", data);
}

/**
 * @description 
 */
function submitRemittanceInformationApi(data) {
  return _requestOpen3d.default.post("/order/app/merchant/order/submitRemittanceInformation", data);
}

/**
 * @description 文章标签列表
 * https://api-test.open3d.net/open3d/product/sys/tag/list?pageNum=1&pageSize=20&type=4
 */
function tagListApi(data) {
  return _requestOpen3d.default.get("/product/sys/tag/list", data);
}

/**
 * @description 登录二维码
 * https://api-test.open3d.net/open3d/product/sys/tag/list?pageNum=1&pageSize=20&type=4
 */
function getWxScanCodeApi(data) {
  return _requestOpen3d.default.get("/thirdparty/wechat/code", data);
}

/**
 * @description 获取登录状态
 * https://api-test.open3d.net/open3d/product/sys/tag/list?pageNum=1&pageSize=20&type=4
 */
function getWxLoginStatusApi(data) {
  return _requestOpen3d.default.get("/thirdparty/wechat/status", data);
}

/**
 * @description 获取账号信息
 * https://api-test.open3d.net/open3d/product/sys/tag/list?pageNum=1&pageSize=20&type=4
 */
function getAccountListApi(data) {
  return _requestOpen3d.default.get("/member/app/merchant/association/getAccountList", data);
}

/**
 * @description 获取SAAS账号信息
 * https://api-test.open3d.net/open3d/product/sys/tag/list?pageNum=1&pageSize=20&type=4
 */
function getAssociationJoinApi(data) {
  return _requestOpen3d.default.post("/member/app/merchant/association/join", data);
}

/**
 * @description saas登录
 * https://api-test.open3d.net/open3d/product/sys/tag/list?pageNum=1&pageSize=20&type=4
 */
function saasLoginApi(data) {
  return _requestOpen3d.default.post("/member/app/merchant/saas/login", data);
}

/**
 * @description 素材库图片处理
 * https://api-test.open3d.net/open3d/product/sys/tag/list?pageNum=1&pageSize=20&type=4
 */
function sourceImageHandleApi(data) {
  return _requestOpen3d.default.post("/special/effects/app/image/handle", data);
}

/**
 * @description 查询当前的修图记录
 * https://api-test.open3d.net/open3d/product/sys/tag/list?pageNum=1&pageSize=20&type=4
 */
function getEffectsImageListApi(data) {
  return _requestOpen3d.default.post("/special/effects/app/image/list", data);
}