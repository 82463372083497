"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find");
var _warehouse = require("@/api/warehouse");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tab: this.$route.query.tab || "1",
      proofType: "1",
      listLoading: true,
      all: [{
        tab: "1",
        proofType: "1",
        type: "erp_v2_contract_create_product",
        list: []
      }],
      setPrintColumns: {},
      hasPermission: false
    };
  },
  computed: {
    typeName: function typeName() {
      var txt = "";
      switch (this.tab) {
        case "1":
          txt = "入库单";
          break;
        case "2":
          txt = "出库单";
          break;
        case "3":
          txt = "调拨单";
          break;
      }
      return txt;
    },
    currType: function currType() {
      var _this = this;
      return this.all.find(function (m) {
        return m.tab === _this.tab && m.proofType === _this.proofType;
      }).type;
    },
    list: function list() {
      var _this2 = this;
      return this.all.find(function (m) {
        return m.tab === _this2.tab && m.proofType === _this2.proofType;
      }).list;
    }
  },
  created: function created() {
    var _this3 = this;
    this.getList("all");
    this.$store.dispatch('user/getEnterInfo').then(function (data) {
      _this3.hasPermission = data.merchantAdmin && data.merchantAdmin.level === 0;
    });
  },
  methods: {
    getList: function getList(type) {
      var _this4 = this;
      var data = {
        types: type
      };
      if (type === "all") {
        data.types = this.all.map(function (m) {
          return m.type;
        }).join(",");
      }
      (0, _warehouse.getFieldSetAPI)(data).then(function (res) {
        var types = _this4.all.map(function (m) {
          return m.type;
        });
        for (var key in res.data) {
          var index = types.indexOf(key);
          if (index !== -1) {
            _this4.$set(_this4.all[index], "list", res.data[key].show_list.map(function (m) {
              return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, m), {}, {
                checked: true
              });
            }).concat(res.data[key].hide_list.map(function (m) {
              if (m.field == "product_name") {
                return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, m), {}, {
                  checked: true
                });
              } else {
                return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, m), {}, {
                  checked: false
                });
              }
            })));
          }
        }
        _this4.listLoading = false;
      });
    },
    changeTab: function changeTab(val) {
      if (val == 4) {
        this.$router.replace({
          path: "/merchant/warehouse/warehouseList"
        });
        return;
      } else {
        this.proofType = "1";
      }
      this.tab = val;
    },
    saveAction: function saveAction() {
      var _this5 = this;
      var types = this.currType;
      var show_data = [];
      var hide_data = [];
      this.list.forEach(function (m) {
        if (m.checked) {
          show_data.push(m);
        } else {
          hide_data.push(m);
        }
      });
      (0, _system.setCustomHeader)({
        types: types,
        show_data: show_data,
        hide_data: hide_data
      }).then(function () {
        _this5.$message.success("设置成功");
      });
      if (this.tab == "2" && this.proofType == "3") {
        (0, _system.configClassKeysSave)("setPrintColumns", this.setPrintColumns).then(function (res) {}).catch(function () {});
      }
    },
    focus: function focus(e) {
      e.currentTarget.select();
    }
  }
};