var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.merData.config
    ? _c(
        "div",
        { class: { "no-previre": !_vm.preview } },
        [
          _c(
            "div",
            {
              staticClass: "detail-box",
              style: _vm.preview ? "margin: 20px 0;" : "",
            },
            [
              _c("div", { staticClass: "flex" }, [
                _vm.merData.config && _vm.merData.config.mer_print_logo
                  ? _c("img", {
                      staticStyle: { height: "70px", "margin-right": "20px" },
                      attrs: {
                        src: _vm.merData.config.mer_print_logo + "!120a",
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left edit-view",
                    on: {
                      click: function ($event) {
                        return _vm.goEditAction("title")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "detail-left-title" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.merData.company_name) +
                          " | " +
                          _vm._s(_vm.detail.title.china) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-desc",
                        staticStyle: {
                          "font-size": "14px",
                          "font-family": "'printEN'",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.merData.company_english_name) +
                              " " +
                              _vm._s(_vm.detail.title.english)
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "detail-list",
              staticStyle: { "margin-top": "25px" },
            },
            [
              _c("div", { staticStyle: { "white-space": "nowrap" } }, [
                _vm._v(_vm._s(_vm.switchLang("BuyerName")) + " BUYER'S NAME "),
                _c(
                  "span",
                  {
                    staticStyle: { "font-weight": "500", "font-size": "16px" },
                  },
                  [_vm._v("马老三")]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ptd-box" }, [
            _c(
              "div",
              {
                staticClass: "ptd edit-view",
                staticStyle: { "font-weight": "bold" },
                on: {
                  click: function ($event) {
                    return _vm.goEditAction("tableColumn")
                  },
                },
              },
              [
                _vm._l(_vm.detail.tableColumn, function (m, i) {
                  return [
                    m.open
                      ? [
                          _c(
                            "div",
                            {
                              key: "col" + i,
                              staticClass: "ptd-title",
                              style: m.w ? "width: " + m.w + "px;" : "flex: 1",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(m.showName || m.name)),
                              ]),
                              m.english
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                        "white-space": "nowrap",
                                      },
                                    },
                                    [_vm._v(_vm._s(m.english))]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ptd", staticStyle: { "font-weight": "600" } },
              [
                _vm.detail.tableColumn[0].open
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style: "width: " + _vm.detail.tableColumn[0].w + "px;",
                      },
                      [_vm._v("103")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.detail.tableColumn[1].open
                  ? _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "1" } },
                      [_vm._v("清 乾隆玉枕")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.detail.tableColumn[2].open
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style: "width: " + _vm.detail.tableColumn[2].w + "px;",
                      },
                      [_vm._v("已成交")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.detail.tableColumn[3].open
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style: "width: " + _vm.detail.tableColumn[3].w + "px;",
                      },
                      [_vm._v("300,000")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.detail.tableColumn[4].open
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style:
                          "width: " +
                          _vm.detail.tableColumn[4].w +
                          "px;text-align: center;",
                      },
                      [_vm._v("30,000")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.detail.tableColumn[5].open
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style: "width: " + _vm.detail.tableColumn[5].w + "px;",
                      },
                      [_vm._v("330,000")]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ptd", staticStyle: { "font-weight": "600" } },
              [
                _vm.detail.tableColumn[0].open
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style: "width: " + _vm.detail.tableColumn[0].w + "px;",
                      },
                      [_vm._v("103")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.detail.tableColumn[1].open
                  ? _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "1" } },
                      [_vm._v("清 黄花梨圆桌")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.detail.tableColumn[2].open
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style: "width: " + _vm.detail.tableColumn[2].w + "px;",
                      },
                      [_vm._v("流拍")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.detail.tableColumn[3].open
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style: "width: " + _vm.detail.tableColumn[3].w + "px;",
                      },
                      [_vm._v("1,300,000")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.detail.tableColumn[4].open
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style:
                          "width: " +
                          _vm.detail.tableColumn[4].w +
                          "px;text-align: center;",
                      },
                      [_vm._v("130,000")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.detail.tableColumn[5].open
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style: "width: " + _vm.detail.tableColumn[5].w + "px;",
                      },
                      [_vm._v("1,430,000")]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "ptd-box",
              staticStyle: { "margin-top": "0", "border-top": "none" },
            },
            [
              _c("div", { staticClass: "ptd" }, [
                _c(
                  "div",
                  {
                    staticClass: "ptd-body",
                    style:
                      "width: " +
                      _vm.detail.tableColumn[0].w +
                      "px;flex-direction: column;",
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.switchLang("Total")))]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'printEN'" } },
                      [_vm._v("TOTAL")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm.detail.tableColumn[2].open
                  ? _c("div", {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableColumn[2].w + "px;",
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.detail.tableColumn[3].open
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style: "width: " + _vm.detail.tableColumn[3].w + "px;",
                      },
                      [_vm._v("1,600,000")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.detail.tableColumn[4].open
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style: "width: " + _vm.detail.tableColumn[4].w + "px;",
                      },
                      [_vm._v("160,000")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.detail.tableColumn[5].open
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style: "width: " + _vm.detail.tableColumn[5].w + "px;",
                      },
                      [_vm._v("1,760,000")]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ptd" }, [
                _c(
                  "div",
                  {
                    staticClass: "ptd-body",
                    staticStyle: { flex: "1", "flex-direction": "column" },
                  },
                  [
                    _c("span", { staticStyle: { "text-align": "left" } }, [
                      _vm._v(_vm._s(_vm.switchLang("AdditionalAmount"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "'printEN'",
                          "text-align": "left",
                        },
                      },
                      [_vm._v("ADDITIONAL AMOUNT")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "ptd-body",
                    style: _vm.getTwoWidth + ";display:flex;",
                  },
                  [_vm._v("1,760,000")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "ptd-body",
                    style:
                      "width:" +
                      _vm.detail.tableColumn[4].w +
                      "px;flex-direction: column;",
                  },
                  [
                    _c("span", { staticStyle: { "text-align": "left" } }, [
                      _vm._v(_vm._s(_vm.switchLang("PaidAmount"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "'printEN'",
                          "text-align": "left",
                        },
                      },
                      [_vm._v("ACTUAL PAYMENT AMOUNT")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "ptd-body",
                    style:
                      "width:" +
                      _vm.detail.tableColumn[5].w +
                      "px;display:flex;",
                  },
                  [_vm._v("1,760,000")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ptd" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "ptd-body",
                    style: _vm.getForeWidth + ";font-weight: bold;",
                  },
                  [_vm._v("壹仟柒佰陆拾万圆")]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("div", {
            staticClass: "print-line",
            staticStyle: { "margin-top": "25px" },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "edit-view",
              on: {
                click: function ($event) {
                  return _vm.goEditAction("signColumn")
                },
              },
            },
            [
              _vm.detail.signColumnTab == "system"
                ? _c(
                    "div",
                    {
                      staticClass: "detail-list flex-wrap",
                      staticStyle: { "margin-top": "30px" },
                    },
                    [
                      _vm._l(_vm.detail.signColumn, function (m, i) {
                        return [
                          m.open
                            ? [
                                _c(
                                  "div",
                                  {
                                    key: i,
                                    style:
                                      "width: " +
                                      (m.w == "auto" ? "auto" : m.w + "%") +
                                      ";margin-bottom: 10px;white-space: nowrap;padding-right: 5px;" +
                                      (m.name == "打印时间"
                                        ? "text-align: right;"
                                        : ""),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(m.showName || m.name) + ":"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    i == "accountName"
                                      ? [
                                          _c("span", { staticClass: "ml5" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.merData.config.bank_name
                                              )
                                            ),
                                          ]),
                                        ]
                                      : i == "accountBank"
                                      ? [
                                          _c("span", { staticClass: "ml5" }, [
                                            _vm._v(
                                              _vm._s(_vm.merData.config.bank)
                                            ),
                                          ]),
                                        ]
                                      : i == "accountNumber"
                                      ? [
                                          _c("span", { staticClass: "ml5" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.merData.config.bank_number
                                              )
                                            ),
                                          ]),
                                        ]
                                      : i == "accountAddress"
                                      ? [
                                          _c("span", { staticClass: "ml5" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.merData.config
                                                  .company_address
                                              )
                                            ),
                                          ]),
                                        ]
                                      : i == "documentNumber"
                                      ? [
                                          _c("span", { staticClass: "ml5" }, [
                                            _vm._v("202412120001"),
                                          ]),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm.detail.signColumnTab == "custome"
                ? _c(
                    "div",
                    {
                      staticClass: "custome-view",
                      staticStyle: { "margin-top": "30px" },
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.detail.signColumnCustome),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          !_vm.preview
            ? [
                _c(
                  "el-drawer",
                  {
                    attrs: {
                      visible: _vm.showSettings,
                      size: "900px",
                      direction: "rtl",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.showSettings = $event
                      },
                    },
                  },
                  [
                    _vm.settingKey == "title"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "edit-title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v("标题文字修改")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "edit-content" }, [
                            _c(
                              "div",
                              { staticClass: "edit-input" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    row: 3,
                                    placeholder: "输入中文自定义内容",
                                  },
                                  model: {
                                    value: _vm.detail.title.china,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.detail.title, "china", $$v)
                                    },
                                    expression: "detail.title.china",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "edit-input" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    row: 3,
                                    placeholder: "输入英文自定义内容",
                                  },
                                  model: {
                                    value: _vm.detail.title.english,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.detail.title, "english", $$v)
                                    },
                                    expression: "detail.title.english",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.settingKey == "buyerCompany"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "edit-title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v("顶部文字修改")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "edit-content" }, [
                            _c(
                              "div",
                              { staticClass: "edit-input" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    row: 3,
                                    placeholder: "输入中文自定义内容",
                                  },
                                  model: {
                                    value: _vm.detail.buyerCompany.china,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detail.buyerCompany,
                                        "china",
                                        $$v
                                      )
                                    },
                                    expression: "detail.buyerCompany.china",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "edit-input" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    row: 3,
                                    placeholder: "输入英文自定义内容",
                                  },
                                  model: {
                                    value: _vm.detail.buyerCompany.english,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detail.buyerCompany,
                                        "english",
                                        $$v
                                      )
                                    },
                                    expression: "detail.buyerCompany.english",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.settingKey == "tableColumn"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "edit-title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v("表格展示列设置")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "edit-content" }, [
                            _c("div", { staticClass: "flex align-items-s" }, [
                              _c("div", { staticClass: "proof-table" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "proof-title item-flex-center",
                                  },
                                  [
                                    _c("div", { staticClass: "w100 td" }, [
                                      _vm._v("是否启用"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "flex-one td" }, [
                                      _vm._v("字段名称"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "flex-one td" }, [
                                      _vm._v("中文自定义"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "flex-one td" }, [
                                      _vm._v("英文自定义"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "w140 td" }, [
                                      _vm._v("列宽"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "proof-content" },
                                  _vm._l(
                                    _vm.detail.tableColumn,
                                    function (m, i) {
                                      return _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass: "item-flex-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w100 td" },
                                            [
                                              _c("el-checkbox", {
                                                model: {
                                                  value: m.open,
                                                  callback: function ($$v) {
                                                    _vm.$set(m, "open", $$v)
                                                  },
                                                  expression: "m.open",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-one td" },
                                            [_vm._v(_vm._s(m.name))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-one td" },
                                            [
                                              _c("el-input", {
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                                on: {
                                                  focus: function ($event) {
                                                    return _vm.focus($event)
                                                  },
                                                },
                                                model: {
                                                  value: m.showName,
                                                  callback: function ($$v) {
                                                    _vm.$set(m, "showName", $$v)
                                                  },
                                                  expression: "m.showName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-one td" },
                                            [
                                              _c("el-input", {
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                                on: {
                                                  focus: function ($event) {
                                                    return _vm.focus($event)
                                                  },
                                                },
                                                model: {
                                                  value: m.english,
                                                  callback: function ($$v) {
                                                    _vm.$set(m, "english", $$v)
                                                  },
                                                  expression: "m.english",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "w140 td" },
                                            [
                                              i == 1
                                                ? [_vm._v("剩余全部宽度")]
                                                : [
                                                    _c("el-input-number", {
                                                      attrs: {
                                                        controls: false,
                                                      },
                                                      on: {
                                                        focus: function (
                                                          $event
                                                        ) {
                                                          return _vm.focus(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: m.w,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(m, "w", $$v)
                                                        },
                                                        expression: "m.w",
                                                      },
                                                    }),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.settingKey == "settleText"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "edit-title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v("签收确认文字修改")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "edit-content" }, [
                            _c(
                              "div",
                              { staticClass: "edit-input" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    row: 3,
                                    placeholder: "输入中文自定义内容",
                                  },
                                  model: {
                                    value: _vm.detail.settleText.china,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detail.settleText,
                                        "china",
                                        $$v
                                      )
                                    },
                                    expression: "detail.settleText.china",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "edit-input" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    row: 3,
                                    placeholder: "输入英文自定义内容",
                                  },
                                  model: {
                                    value: _vm.detail.settleText.english,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detail.settleText,
                                        "english",
                                        $$v
                                      )
                                    },
                                    expression: "detail.settleText.english",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.settingKey == "signColumn"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "edit-title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v("签字区域修改")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "edit-content" },
                            [
                              _c(
                                "el-tabs",
                                {
                                  model: {
                                    value: _vm.detail.signColumnTab,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.detail, "signColumnTab", $$v)
                                    },
                                    expression: "detail.signColumnTab",
                                  },
                                },
                                [
                                  _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "系统内设",
                                        name: "system",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex align-items-s" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "proof-table",
                                              staticStyle: {
                                                "margin-top": "0",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "proof-title item-flex-center",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "w50 td" },
                                                    [_vm._v("启用")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "w140 td" },
                                                    [_vm._v("字段名称")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "w140 td" },
                                                    [_vm._v("中文自定义")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-one td",
                                                    },
                                                    [_vm._v("列宽")]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "proof-content",
                                                },
                                                _vm._l(
                                                  _vm.detail.signColumn,
                                                  function (m, i) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "item-flex-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "w50 td",
                                                          },
                                                          [
                                                            _c("el-checkbox", {
                                                              model: {
                                                                value: m.open,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      m,
                                                                      "open",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "m.open",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "w140 td",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(m.name)
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "w140 td",
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                "text-align":
                                                                  "center",
                                                              },
                                                              on: {
                                                                focus:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.focus(
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  m.showName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      m,
                                                                      "showName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "m.showName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex-one td",
                                                          },
                                                          [
                                                            _c(
                                                              "el-radio-group",
                                                              {
                                                                model: {
                                                                  value: m.w,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        m,
                                                                        "w",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "m.w",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-radio",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "100",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "整行"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-radio",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "50",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "1/2行"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-radio",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "33.33",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "1/3行"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-radio",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "25",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "1/4行"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-radio",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "auto",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "自适应"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "自定义",
                                        name: "custome",
                                      },
                                    },
                                    [
                                      _c("Tinymce", {
                                        ref: "editor",
                                        attrs: { height: 600 },
                                        model: {
                                          value: _vm.detail.signColumnCustome,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detail,
                                              "signColumnCustome",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detail.signColumnCustome",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "formFixedBox",
                    staticStyle: { left: "140px", "z-index": "10" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.saveAction },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ptd-body", staticStyle: { flex: "1" } }, [
      _c("span", [_vm._v("2件")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "ptd-body",
        staticStyle: { flex: "1", "flex-direction": "column" },
      },
      [
        _c("span", { staticStyle: { "text-align": "left" } }, [
          _vm._v("人民币（大写）"),
        ]),
        _vm._v(" "),
        _c(
          "span",
          { staticStyle: { "font-family": "'printEN'", "text-align": "left" } },
          [_vm._v("CAPITAL IN RMB")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "detail-list",
        staticStyle: {
          "margin-top": "25px",
          "font-size": "16px",
          "justify-content": "flex-start",
        },
      },
      [
        _c("div", { staticStyle: { "margin-right": "40px" } }, [
          _vm._v("对账人：张老三"),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-right": "40px" } }, [
          _vm._v("付款人：张老二"),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-right": "40px" } }, [
          _vm._v("付款时间：2025-02-30"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("单据编号：88888888")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }