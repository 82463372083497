"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _basic = require("@/api/basic");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserGroup',
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      cateTableFrom: {
        page: 1,
        limit: 20
      },
      cateTableData: {
        data: [],
        total: 0
      },
      tabValue: '0',
      listLoading: true
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getCateList();
  },
  methods: {
    changeValue: function changeValue() {
      console.log(this.tabValue);
    },
    // 添加标签分类
    onAddCate: function onAddCate() {
      var _this = this;
      this.$modalForm((0, _basic.labelCateCreateFormApi)()).then(function () {
        return _this.getCateList();
      });
    },
    // 编辑标签分类
    onEditCate: function onEditCate(id) {
      var _this2 = this;
      this.$modalForm((0, _basic.labelCateEditFormApi)(id)).then(function () {
        return _this2.getCateList();
      });
    },
    // 删除
    handleDeleteCate: function handleDeleteCate(id) {
      var _this3 = this;
      this.$modalSure().then(function () {
        (0, _basic.labelCateDelApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this3.$message.success(message);
          _this3.getCateList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      });
    },
    // 列表
    getCateList: function getCateList() {
      var _this4 = this;
      this.listLoading = true;
      (0, _basic.labelCateListApi)(this.cateTableFrom).then(function (res) {
        _this4.cateTableData.data = res.data.list;
        _this4.cateTableData.total = res.data.count;
        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageCateChange: function pageCateChange(page) {
      this.tableFrom.page = page;
      this.getCateList();
    },
    handleCateSizeChange: function handleCateSizeChange(val) {
      this.tableFrom.limit = val;
      this.getCateList();
    },
    // 列表
    getList: function getList() {
      var _this5 = this;
      this.listLoading = true;
      (0, _basic.labelLstApi)(this.tableFrom).then(function (res) {
        _this5.tableData.data = res.data.list;
        _this5.tableData.total = res.data.count;
        _this5.listLoading = false;
      }).catch(function (res) {
        _this5.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      var _this6 = this;
      this.$modalForm((0, _basic.labelFormApi)()).then(function () {
        return _this6.getList();
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      var _this7 = this;
      this.$modalForm((0, _basic.labelEditApi)(id)).then(function () {
        return _this7.getList();
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this8 = this;
      this.$modalSure().then(function () {
        (0, _basic.labelDeleteApi)(id).then(function (_ref3) {
          var message = _ref3.message;
          _this8.$message.success(message);
          _this8.tableData.data.splice(idx, 1);
        }).catch(function (_ref4) {
          var message = _ref4.message;
          _this8.$message.error(message);
        });
      });
    }
  }
};