"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _user = require("@/api/user.js");
var _system = require("@/api/system.js");
var _index = require("@/utils/index");
var _order = require("@/api/order");
var _printJs = _interopRequireDefault(require("print-js"));
var _zhTW = _interopRequireDefault(require("@/utils/zh-TW.json"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  filters: {
    filterPercent: function filterPercent(val) {
      var remainder = val % 10;
      if (remainder > 0) val;else return val / 10;
    }
  },
  data: function data() {
    return {
      parseTime: _index.parseTime,
      ids: '',
      uid: '',
      source: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      fee_type: '',
      feeTypeInfo: {},
      isOutbound: false,
      qrcodeImg: '',
      merData: {},
      no: '',
      isNo: true,
      result: [],
      printBotArr: [''],
      detail: {
        buyerCompany: {},
        tableColumn: [],
        settleText: {},
        signColumn: {}
      }
    };
  },
  computed: {
    feeTypeObj: function feeTypeObj() {
      return this.$store.getters.feeTypeInfo;
    },
    getWidth: function getWidth() {
      return this.fee_type != this.feeTypeInfo.mer_fee_type ? 'width: 130px;' : 'width: 160px;';
    },
    getForeWidth: function getForeWidth() {
      var w = 0;
      if (this.detail.tableColumn[2] && this.detail.tableColumn[2].open && this.detail.tableColumn[2].w) {
        w += this.detail.tableColumn[2].w;
      }
      if (this.detail.tableColumn[3] && this.detail.tableColumn[3].open && this.detail.tableColumn[3].w) {
        w += this.detail.tableColumn[3].w;
      }
      if (this.detail.tableColumn[4] && this.detail.tableColumn[4].open && this.detail.tableColumn[4].w) {
        w += this.detail.tableColumn[4].w;
      }
      if (this.detail.tableColumn[5] && this.detail.tableColumn[5].open && this.detail.tableColumn[5].w) {
        w += this.detail.tableColumn[5].w;
      }
      if (this.detail.tableColumn[5] && this.detail.tableColumn[5].open && this.detail.tableColumn[5].w && this.fee_type != this.feeTypeInfo.mer_fee_type) {
        w += this.detail.tableColumn[5].w;
      }
      return "width: ".concat(w, "px;");
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
    (0, _system.getConfigClassKeys)('print_config').then(function (res) {
      if (res.data.print_auction_order_bill_info) {
        var d = JSON.parse(res.data.print_auction_order_bill_info);
        if (!d.title) {
          d.title = {
            china: _this.source == 'send' ? _this.switchLang('Delivery') : _this.switchLang('CollectionBill'),
            english: _this.source == 'send' ? 'Bill of Lading' : 'Collection settlement bill'
          };
        }
        if (!d.buyerColumn || !d.buyerColumn.address_user_phone) {
          d.buyerColumn = {
            buyerName: {
              open: true,
              name: '买受人姓名',
              showName: "\u4E70\u53D7\u4EBA\u59D3\u540D BUYER'S NAME",
              w: 'auto'
            },
            number: {
              open: true,
              name: '号牌',
              showName: '号牌 PADDLE NO.',
              w: 'auto'
            },
            date: {
              open: true,
              name: '交易日期',
              showName: '交易日期 DATE OF SALE',
              w: 'auto'
            },
            address: {
              open: true,
              name: '收货人地址',
              showName: '',
              w: 'auto'
            },
            address_user_phone: {
              open: true,
              name: '收货人手机号',
              showName: '',
              w: 'auto'
            },
            address_real_name: {
              open: true,
              name: '收货人姓名',
              showName: '',
              w: 'auto'
            }
          };
        }
        if (!d.signColumnTab) d.signColumnTab = 'system';
        _this.detail = d;
      } else {
        _this.detail = {
          title: {
            china: _this.source == 'send' ? _this.switchLang('Delivery') : _this.switchLang('CollectionBill'),
            english: _this.source == 'send' ? 'Bill of Lading' : 'Collection settlement bill'
          },
          buyerCompany: {
            china: _this.switchLang('BuyerCompany'),
            english: "Thank you for your support. We're pleased to inform you that the following items have been delivered. We appreciate your kindness and understanding, and we look forward to your continued partnership!"
          },
          buyerColumn: {
            buyerName: {
              open: true,
              name: '买受人姓名',
              showName: "\u4E70\u53D7\u4EBA\u59D3\u540D BUYER'S NAME",
              w: 'auto'
            },
            number: {
              open: true,
              name: '号牌',
              showName: '号牌 PADDLE NO.',
              w: 'auto'
            },
            date: {
              open: true,
              name: '交易日期',
              showName: '交易日期 DATE OF SALE',
              w: 'auto'
            },
            address: {
              open: true,
              name: '收货人地址',
              showName: '',
              w: 'auto'
            },
            address_user_phone: {
              open: true,
              name: '收货人手机号',
              showName: '',
              w: 'auto'
            },
            address_real_name: {
              open: true,
              name: '收货人姓名',
              showName: '',
              w: 'auto'
            }
          },
          tableColumn: [{
            open: true,
            name: '图录号',
            showName: _this.switchLang("BuyerTabletu"),
            english: 'LOT NO.',
            w: 86
          }, {
            open: true,
            name: '拍品名称',
            showName: _this.switchLang("BuyerTablepp"),
            english: 'DESCRIPTION'
          }, {
            open: true,
            name: '落槌价',
            showName: _this.switchLang("BuyerTablelc"),
            english: 'HAMMER PRICE',
            w: 130
          }, {
            open: true,
            name: '佣金',
            showName: _this.switchLang("BuyerTableyj"),
            english: 'COMMISSION',
            w: 130
          }, {
            open: true,
            name: '其他',
            showName: _this.switchLang("BuyerTableqt"),
            english: 'OTHER',
            w: 130
          }, {
            open: true,
            name: '金额',
            showName: _this.switchLang("Amount"),
            english: 'SUBTOTAL',
            w: 130
          }],
          settleText: {
            china: "* ".concat(_this.switchLang('SettleText'), "\u3002"),
            english: 'I confirm that all the items listed above are in good condition after inspection, and I hereby accept delivery of the items.'
          },
          signColumnTab: 'system',
          signColumn: {
            accountName: {
              open: true,
              name: '开户名称',
              showName: '',
              w: 'auto'
            },
            accountBank: {
              open: true,
              name: '开户行',
              showName: '',
              w: 'auto'
            },
            accountNumber: {
              open: true,
              name: '帐号',
              showName: '',
              w: 'auto'
            },
            accountAddress: {
              open: true,
              name: '公司地址',
              showName: '',
              w: '100'
            },
            documentNumber: {
              open: true,
              name: '单据编号',
              showName: '',
              w: '25'
            },
            creator: {
              open: true,
              name: '制单人',
              showName: '',
              w: '25'
            },
            recipient: {
              open: true,
              name: '收款人',
              showName: '',
              w: '25'
            },
            reviewer: {
              open: false,
              name: '复核人',
              showName: '',
              w: '25'
            },
            companySeal: {
              open: false,
              name: '单位盖章',
              showName: '',
              w: '25'
            },
            printTime: {
              open: true,
              name: '打印时间',
              showName: '',
              w: '25'
            }
          }
        };
      }
    });
  },
  methods: {
    switchLang: function switchLang(filed) {
      if (this.feeTypeObj.mer_fee_type == 'HKD') {
        return _zhTW.default.TW[filed];
      } else return _zhTW.default.CN[filed];
    },
    onPrintOut: function onPrintOut() {
      var _this2 = this;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _order.orderProductCreateOut)({
        order_ids: this.ids,
        uid: this.uid
      }).then(function (res) {
        console.log(res);
        _this2.qrcodeImg = res.data.bar_code_image;
        setTimeout(function () {
          _this2.bindPrintorder();
          loading.close();
        }, 500);
      }).catch(function (err) {
        return loading.close();
      });
    },
    bindPrintordOne: function bindPrintordOne() {
      this.printBotArr = ['财务留存', '库房留存', '客户留存'];
      (0, _system.updateConfigApi)({
        key: 'order_bill_no',
        value: this.no
      });
      var that = this;
      setTimeout(function () {
        (0, _printJs.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
          printable: 'printorder',
          type: 'html',
          header: null,
          targetStyles: ['*'],
          scanStyles: true,
          maxWidth: 1000,
          font_size: 'auto',
          style: "@page { margin: 5mm 7mm 0;size: auto; } .paging{page-break-after: always;}",
          onPrintDialogClose: function onPrintDialogClose() {
            console.log('关闭');
          }
        }, "onPrintDialogClose", function onPrintDialogClose() {
          console.log('pdf');
        }), "onPdfOpen", function onPdfOpen() {
          console.log('pdf1');
        }));
        (0, _order.batchCreate)({
          order_ids: that.collectMoney.order_ids,
          order_type: 'order'
        });
        // that.collectMoney.show = false;
        that.$emit('load');
      }, 500);
    },
    bindPrintorder: function bindPrintorder() {
      this.printBotArr = [''];
      // console.log("是不是修改的这里啊");
      (0, _system.updateConfigApi)({
        key: 'order_bill_no',
        value: this.no
      });
      var that = this;
      setTimeout(function () {
        (0, _printJs.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
          printable: 'printorder',
          type: 'html',
          header: null,
          targetStyles: ['*'],
          scanStyles: true,
          maxWidth: 1000,
          font_size: 'auto',
          style: "@page { margin: 5mm 7mm 12mm;size: auto; } .paging{page-break-after: always;}",
          onPrintDialogClose: function onPrintDialogClose() {
            console.log('关闭');
          }
        }, "onPrintDialogClose", function onPrintDialogClose() {
          console.log('pdf');
        }), "onPdfOpen", function onPdfOpen() {
          console.log('pdf1');
        }));
        (0, _order.batchCreate)({
          order_ids: that.collectMoney.order_ids,
          order_type: 'order'
        });
        // that.collectMoney.show = false;
        that.$emit('load');
      }, 500);
    },
    getNewArray: function getNewArray(array) {
      var newArray = [];
      var num = 0;
      var heightAll = 0;
      var dom = document.getElementsByClassName('calcul');
      var printTopDom = document.getElementsByClassName('all-print-main-el');
      var h = 1350 - printTopDom[0].offsetTop;
      var arr = [];
      for (var i = 0; i < dom.length; i++) {
        heightAll += Number(window.getComputedStyle(dom[i]).height.replace('px', ''));
        if (num === 0 && heightAll > h) {
          arr.push(i);
          num += 1;
        } else if (num > 0 && heightAll > 1200 * num + h) {
          arr.push(i);
          num += 1;
        }
      }
      // if (array.length > 20) {
      //   newArray.push(array.slice(0, 20));
      //   const ffarr = array.slice(20, array.length);
      //   const aaa = this.getSplArray(ffarr, 26);
      //   // console.log(aaa);
      //   aaa.forEach(item => {
      //     newArray.push(item);
      //   });
      // } else newArray.push(array);

      // collectMoney.data.orderList
      // console.log(this.sliceArrayIntoChunks(this.collectMoney.data.orderList, arr));
      this.result = this.sliceArrayIntoChunks(this.collectMoney.data.orderList, arr);
      this.isNo = false;
    },
    sliceArrayIntoChunks: function sliceArrayIntoChunks(array, indexes) {
      var arr = [];
      var len = array.length;
      var ind = 0;
      indexes.forEach(function (item, index) {
        arr.push(array.slice(ind, item));
        ind = item;
      });
      arr.push(array.slice(indexes[indexes.length - 1], len));
      return arr;
    },
    getSplArray: function getSplArray(array, subGroupLength) {
      // array 代表一个数组
      // subGroupLength 代表数组里面的数组的length值
      var index = 0;
      var newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    },
    loadData: function loadData(ids, uid, source) {
      var _this3 = this;
      this.source = source;
      this.isNo = true;
      this.ids = ids;
      this.uid = uid;
      (0, _order.getAuctionOrderBillInfo)({
        order_ids: ids,
        uid: uid
      }).then(function (res) {
        // console.log(res.data);
        var total_price = 0;
        res.data.orderList.forEach(function (m) {
          _this3.fee_type = m.fee_type;
          _this3.feeTypeInfo = m.feeTypeInfo;
          total_price += m.product_price;
          console.log(m.erpProduct);
          if (m.erpProduct && m.erpProduct.warehouse_id > 0 && m.erpProduct.product_warehouse_status == 2) {
            _this3.isOutbound = true;
          }
        });
        var lastNo = res.data.merConfig.order_bill_no || '';
        var nowNo = "".concat((0, _index.parseTime)(new Date(), '{y}{m}{d}'), "0000");
        if (lastNo && lastNo.length == 12) {
          var dayNum = "".concat(lastNo.slice(0, 8), "0000");
          if (dayNum === nowNo) {
            var no = Number(lastNo.slice(8, 12));
            nowNo = Number(nowNo) + no + 1;
          } else {
            nowNo = Number(nowNo) + 1;
          }
        } else {
          nowNo = Number(nowNo) + 1;
        }
        _this3.no = "".concat(nowNo);
        var orderLength = res.data.orderList.length;
        // res.data.orderList = this.getNewArray(res.data.orderList);

        _this3.total_price = total_price;
        _this3.collectMoney = {
          order_ids: ids,
          data: res.data,
          show: true,
          orderLength: orderLength
        };
        _this3.$nextTick(function () {
          // 赋值后马上更新
          _this3.getNewArray(res.data.orderList);
        });
      }).catch(function () {});
    }
  }
};