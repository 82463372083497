"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _setting = require("@/api/setting");
var _settings = require("@/settings");
var _basic = require("@/api/basic");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formValidate: {
        erpAuditAdmin: [],
        financeAuditAdmin: []
      },
      adminarr: [],
      formValidateJson: {
        "entrust_check_order_audit_admin": "",
        "entrust_check_order_audit_admin_two": "",
        "entrust_check_order_people_number": "1"
      }
    };
  },
  mounted: function mounted() {
    this.getadminList();
  },
  methods: {
    getadminList: function getadminList() {
      var _this = this;
      (0, _setting.adminListApi)().then(function (result) {
        _this.adminarr = result.data.list;
        (0, _system.getConfigClassKeys)('entrust_check_order_config').then(function (res) {
          _this.formValidateJson = {
            entrust_check_order_people_number: res.data.entrust_check_order_people_number ? res.data.entrust_check_order_people_number : '1',
            entrust_check_order_audit_admin: res.data.entrust_check_order_audit_admin ? res.data.entrust_check_order_audit_admin.split(',').map(function (item) {
              return Number(item);
            }) : '',
            entrust_check_order_audit_admin_two: res.data.entrust_check_order_audit_admin_two ? res.data.entrust_check_order_audit_admin_two.split(',').map(function (item) {
              return Number(item);
            }) : '',
            entrust_check_order_user_look: res.data.entrust_check_order_user_look || 'false'
          };
        });
      });
    },
    save: function save() {
      var _this2 = this;
      var model = {
        entrust_check_order_people_number: this.formValidateJson.entrust_check_order_people_number,
        entrust_check_order_audit_admin: this.formValidateJson.entrust_check_order_audit_admin ? this.formValidateJson.entrust_check_order_audit_admin.join(',') : '',
        entrust_check_order_audit_admin_two: this.formValidateJson.entrust_check_order_audit_admin_two ? this.formValidateJson.entrust_check_order_audit_admin_two.join(',') : '',
        entrust_check_order_user_look: this.formValidateJson.entrust_check_order_user_look || 'false'
      };
      (0, _system.configClassKeysSave)('entrust_check_order_config', model).then(function (res) {
        _this2.$message.success('保存成功');
      });
    }
  }
};