var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _c(
              "div",
              {
                staticClass: "banner-img",
                style: {
                  marginTop: _vm.facade.marginTop / 2 + "px",
                  background: _vm.facade.background,
                  width: _vm.facade.view_width / 2 + "px",
                  height: _vm.facade.view_height / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "img overflow-hidden",
                    style: {
                      width: _vm.facade.img_width / 2 + "px",
                      height: _vm.facade.img_height / 2 + "px",
                      borderRadius:
                        _vm.facade.imgTopLeftRadius / 2 +
                        "px " +
                        _vm.facade.imgTopRightRadius / 2 +
                        "px " +
                        _vm.facade.imgBottomRightRadius / 2 +
                        "px " +
                        _vm.facade.imgBottomLeftRadius / 2 +
                        "px",
                    },
                  },
                  [
                    _c("el-image", {
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        display: "block",
                      },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                        fit: _vm.facade.img_style === 2 ? "contain" : "cover",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pointer", {
                  attrs: {
                    color: _vm.facade.indicator_color,
                    uncolor: _vm.facade.indicator_uncolor,
                    type: _vm.facade.indicator_style,
                    align: _vm.facade.indicator_align,
                    "v-align": _vm.facade.indicator_vertical_align,
                    margin: 0,
                  },
                }),
              ],
              1
            ),
          ]
        : _vm.content.type === "status"
        ? [
            _c(
              "div",
              {
                staticClass: "flex align-items-c justify-b",
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "time1 flex align-items-c",
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      "font-size": _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [
                    _c("div", { staticClass: "tag-item flex align-items-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "status",
                          style: {
                            color: _vm.facade.ingColor,
                            fontWeight: _vm.facade.ingFontWeight,
                            "font-size": _vm.facade.ingFontSize / 2 + "px",
                          },
                        },
                        [_vm._v("拍卖中")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "text" }, [_vm._v("出价25次")]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    {
                      staticClass: "r-btn flex align-items-c ml10",
                      style: {
                        color: _vm.facade.color,
                        fontWeight: _vm.facade.fontWeight,
                        "font-size": _vm.facade.fontSize / 2 + "px",
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.facade.followIcon ||
                            "https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727159036163748090.png",
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        : _vm.content.type === "name"
        ? [
            _c(
              "div",
              {
                staticClass: "flex align-items-c",
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "l",
                    style: {
                      color: _vm.facade.nowColor,
                      fontWeight: _vm.facade.nowFontWeight,
                      "font-size": _vm.facade.nowFontSize / 2 + "px",
                      marginRight: "21px",
                    },
                  },
                  [_vm._v("拍品 7")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "r flex align-items-c",
                    style: {
                      color: _vm.facade.lotColor,
                      fontWeight: _vm.facade.lotFontWeight,
                      "font-size": _vm.facade.lotFontSize / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "r-item",
                        staticStyle: { "margin-right": "21px" },
                      },
                      [_vm._v("拍品 8")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "r-item",
                        staticStyle: { "margin-right": "21px" },
                      },
                      [_vm._v("拍品 9")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "r-item",
                        staticStyle: { "margin-right": "21px" },
                      },
                      [_vm._v("拍品 10")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "r-item",
                        staticStyle: { "margin-right": "21px" },
                      },
                      [_vm._v("拍品 11")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "r-item" }, [_vm._v("拍品 12")]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.nameMarginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c(
                  "span",
                  {
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      fontSize: _vm.facade.fontSize / 2 + "px",
                      lineHeight:
                        (_vm.facade.nameLineHeight || _vm.facade.fontSize + 6) /
                          2 +
                        "px",
                    },
                  },
                  [
                    _vm._v(
                      "\n        紫泥菁英2024春拍——紫砂古器与近现代臻品\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "desc"
        ? [
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c(
                  "div",
                  {
                    class: { line1: _vm.facade.fold },
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      fontSize: _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [
                    _vm._v(
                      "\n        国内众多的博物馆（院）和实力雄厚的财团，通过拍卖国内众多的博物馆（院）和实力雄厚的财团，通过拍卖\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "price"
        ? [
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px " +
                    _vm.facade.marginBottom / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c("div", { staticClass: "flex align-items-c justify-b" }, [
                  _c(
                    "div",
                    {
                      staticClass: "flex-one",
                      style: {
                        fontSize: _vm.facade.fontSize / 2 + "px",
                        color: _vm.facade.color,
                        fontWeight: _vm.facade.fontWeight,
                      },
                    },
                    [
                      _c("span", [_vm._v("当前价")]),
                      _c("span", { staticClass: "ml5" }, [_vm._v("RMB")]),
                      _c(
                        "span",
                        {
                          staticClass: "price-family ml5 mr5",
                          style: {
                            fontSize: _vm.facade.priceFontSize / 2 + "px",
                            color: _vm.facade.priceColor,
                            fontWeight: _vm.facade.priceFontWeight,
                          },
                        },
                        [_vm._v("90,000")]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex align-items-c justify-s",
                    style: {
                      "margin-top":
                        _vm.facade.referencePriceMarginTop / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        style: {
                          fontSize: _vm.facade.referenceFontSize / 2 + "px",
                          color: _vm.facade.referenceColor,
                          fontWeight: _vm.facade.referenceFontWeight,
                        },
                      },
                      [
                        _c("span", [_vm._v("估价：")]),
                        _c(
                          "span",
                          {
                            staticClass: "price-family",
                            style: {
                              fontSize:
                                _vm.facade.referencePriceFontSize / 2 + "px",
                              color: _vm.facade.referencePriceColor,
                              fontWeight: _vm.facade.referencePriceFontWeight,
                            },
                          },
                          [_vm._v("RMB 120,000")]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex align-items-c justify-s",
                    style: {
                      "margin-top": _vm.facade.proxyBidMarginTop / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        style: {
                          fontSize: _vm.facade.proxyBidFontSize / 2 + "px",
                          color: _vm.facade.proxyBidColor,
                          fontWeight: _vm.facade.proxyBidFontWeight,
                        },
                      },
                      [
                        _c("span", [_vm._v("我的委托")]),
                        _c(
                          "span",
                          {
                            staticClass: "price-family ml5 mr5",
                            style: {
                              fontSize:
                                _vm.facade.proxyBidPriceFontSize / 2 + "px",
                              color: _vm.facade.proxyBidPriceColor,
                              fontWeight: _vm.facade.proxyBidPriceFontWeight,
                            },
                          },
                          [_vm._v("120,000")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.facade.proxyBidIcon
                      ? _c("div", [
                          _c("img", {
                            style: {
                              width: _vm.facade.proxyBidFontSize / 2 + "px",
                              height: _vm.facade.proxyBidFontSize / 2 + "px",
                            },
                            attrs: { src: _vm.facade.proxyBidIcon + "!120a" },
                          }),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "message"
        ? [
            _c(
              "div",
              {
                style: {
                  padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                  background: _vm.facade.background,
                  marginTop: _vm.facade.marginTop / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex align-items-c",
                    style: {
                      height: _vm.facade.titleHeight / 2 + "px",
                      fontSize: _vm.facade.titleFontSize / 2 + "px",
                      color: _vm.facade.titleColor,
                      fontWeight: _vm.facade.titleFontWeight,
                    },
                  },
                  [_c("div", { staticClass: "flex-one" }, [_vm._v("拍品详情")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content",
                    staticStyle: { "margin-bottom": "17px" },
                    style: {
                      fontSize: _vm.facade.attrFontSize / 2 + "px",
                      color: _vm.facade.attrColor,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("拍卖会")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("北京银座第90期线上拍卖会之清朝家具")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r flex align-items-c",
                            style: {
                              color: _vm.facade.attrShowColor,
                              fontWeight: _vm.facade.attrShowFontWeight,
                            },
                          },
                          [
                            _c("div", [_vm._v("8个专场")]),
                            _vm._v(" "),
                            _vm.facade.showDetailIcon
                              ? _c("img", {
                                  style: {
                                    width: _vm.facade.attrFontSize / 2 + "px",
                                    height: _vm.facade.attrFontSize / 2 + "px",
                                  },
                                  attrs: {
                                    src: _vm.facade.showDetailIcon + "!120a",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("拍卖场次")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("紫泥菁英2024春拍")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r flex align-items-c",
                            style: {
                              color: _vm.facade.attrShowColor,
                              fontWeight: _vm.facade.attrShowFontWeight,
                            },
                          },
                          [
                            _c("div", [_vm._v("30件拍品")]),
                            _vm._v(" "),
                            _vm.facade.showDetailIcon
                              ? _c("img", {
                                  style: {
                                    width: _vm.facade.attrFontSize / 2 + "px",
                                    height: _vm.facade.attrFontSize / 2 + "px",
                                  },
                                  attrs: {
                                    src: _vm.facade.showDetailIcon + "!120a",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("预展时间")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("2024年5月9日至5月12日")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("预展地点")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [
                            _vm._v(
                              "北京银座在线微信小程序、抖音小程序、苹果APP、安卓APP"
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("竞价阶梯")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("查看详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r flex align-items-c",
                            style: {
                              color: _vm.facade.attrShowColor,
                              fontWeight: _vm.facade.attrShowFontWeight,
                              "min-height": "21px",
                            },
                          },
                          [
                            _vm.facade.showDetailIcon
                              ? _c("img", {
                                  style: {
                                    width: _vm.facade.attrFontSize / 2 + "px",
                                    height: _vm.facade.attrFontSize / 2 + "px",
                                  },
                                  attrs: {
                                    src: _vm.facade.showDetailIcon + "!120a",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("延时周期")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("最后30秒出价延时5分钟")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("佣金")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("10%-15%")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r flex align-items-c",
                            style: {
                              color: _vm.facade.attrShowColor,
                              fontWeight: _vm.facade.attrShowFontWeight,
                              "min-height": "21px",
                            },
                          },
                          [
                            _vm.facade.showDetailIcon
                              ? _c("img", {
                                  style: {
                                    width: _vm.facade.attrFontSize / 2 + "px",
                                    height: _vm.facade.attrFontSize / 2 + "px",
                                  },
                                  attrs: {
                                    src: _vm.facade.showDetailIcon + "!120a",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("保证金")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("RMB 1000 或 1:5")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r flex align-items-c",
                            style: {
                              color: _vm.facade.attrShowColor,
                              fontWeight: _vm.facade.attrShowFontWeight,
                              "min-height": "21px",
                            },
                          },
                          [
                            _vm.facade.showDetailIcon
                              ? _c("img", {
                                  style: {
                                    width: _vm.facade.attrFontSize / 2 + "px",
                                    height: _vm.facade.attrFontSize / 2 + "px",
                                  },
                                  attrs: {
                                    src: _vm.facade.showDetailIcon + "!120a",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("运费")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("包邮")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("3D展览")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("沉浸式看展")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r flex align-items-c",
                            style: {
                              color: _vm.facade.attrShowColor,
                              fontWeight: _vm.facade.attrShowFontWeight,
                              "min-height": "21px",
                            },
                          },
                          [
                            _vm.facade.showDetailIcon
                              ? _c("img", {
                                  style: {
                                    width: _vm.facade.attrFontSize / 2 + "px",
                                    height: _vm.facade.attrFontSize / 2 + "px",
                                  },
                                  attrs: {
                                    src: _vm.facade.showDetailIcon + "!120a",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("拍卖时间")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("2024年5月9日 10:00")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("款识")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("花好月圆，二零零二年，成喜作于京华")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("钦印")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("王氏翰墨、成喜")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("说明")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("附此件作品与作者合影照片")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("附赠")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("附银座拍卖证书")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("尺寸")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("68.5*68.2cm")]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content",
                    style: {
                      fontSize: _vm.facade.fontSize / 2 + "px",
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      lineHeight: _vm.facade.lineHeight / 2 + "px",
                    },
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "徐秀棠（b.1937）：中国工艺美术大师，中国美术家协会会员，中国陶瓷美术协会理事。作品在全国评比中获奖，被国内外博物馆收藏。著有《紫砂陶刻艺术初论》、《中国紫砂》等书。宜兴长乐弘陶艺有限公司是1993年成立的公认紫砂名牌企业，中国工艺美术大师徐秀棠任长乐弘陶艺艺术总监，培养了一批有创作实力的设计人才及传统工艺制作人员。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v(" ")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex align-items-c",
                    style: {
                      paddingBottom:
                        (_vm.facade.hotMarginBottom || 10) / 2 + "px",
                      justifyContent: _vm.facade.hotAlign,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        style: {
                          fontSize: _vm.facade.hotFontSize / 2 + "px",
                          color: _vm.facade.hotColor,
                          fontWeight: _vm.facade.hotFontWeight,
                        },
                      },
                      [
                        _vm.facade.hotIcon
                          ? _c("img", {
                              style: {
                                width: (_vm.facade.hotFontSize + 4) / 2 + "px",
                                height: (_vm.facade.hotFontSize + 4) / 2 + "px",
                              },
                              attrs: { src: _vm.facade.hotIcon + "!120a" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "margin-left": "1px" } }, [
                          _vm._v("6521"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "bid_record"
        ? [
            _c(
              "div",
              {
                staticClass: "bid",
                style: {
                  padding:
                    "0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px " +
                    (_vm.facade.paddingBottom || 48) / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                  marginTop: _vm.facade.marginTop / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex align-items-c",
                    style: {
                      height: _vm.facade.titleHeight / 2 + "px",
                      fontSize: _vm.facade.titleFontSize / 2 + "px",
                      color: _vm.facade.titleColor,
                      fontWeight: _vm.facade.titleFontWeight,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-one",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _vm._v("竞拍记录 "),
                        _c(
                          "span",
                          {
                            staticClass: "flex align-items-c",
                            style: {
                              position: "absolute",
                              right: 0,
                              top: 0,
                              bottom: 0,
                              fontSize: _vm.facade.countFontSize / 2 + "px",
                              color: _vm.facade.countColor,
                              fontWeight: _vm.facade.countFontWeight,
                            },
                          },
                          [_vm._v("25次")]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "bid-view" },
                  _vm._l(2, function (item, index) {
                    return _c("div", { key: index, staticClass: "item-view" }, [
                      _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "top",
                              style:
                                index == 0
                                  ? "background: " +
                                    (_vm.facade.nameBgColor || "#FF3D3D") +
                                    ";color: " +
                                    (_vm.facade.nameColor || "#fff") +
                                    ";font-size: " +
                                    (_vm.facade.nameFontSize || 24) / 2 +
                                    "px;height: " +
                                    ((_vm.facade.nameFontSize || 24) + 32) / 2 +
                                    "px;font-weight: " +
                                    _vm.facade.nameFontWeight
                                  : "background: " +
                                    _vm.facade.outNameBgColor +
                                    ";color: " +
                                    _vm.facade.outNameColor +
                                    ";font-size: " +
                                    (_vm.facade.outNameFontSize || 24) / 2 +
                                    "px;height: " +
                                    ((_vm.facade.outNameFontSize || 24) + 32) /
                                      2 +
                                    "px;font-weight: " +
                                    _vm.facade.outNameFontWeight,
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "line1" },
                                [
                                  _vm._v(
                                    _vm._s(index == 0 ? "领先" : "出局") +
                                      "\n                "
                                  ),
                                  index == 0
                                    ? [_vm._v("我")]
                                    : [_vm._v("现场买家")],
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          index == 0
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass: "content",
                                    style:
                                      index == 0
                                        ? "color: " +
                                          (_vm.facade.contentColor || "#000") +
                                          ";box-shadow: 0px 2px 10px 0px " +
                                          (_vm.facade.shadowColor ||
                                            "rgba(0, 0, 0, 0.05)")
                                        : "",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "unit",
                                        style:
                                          "font-size:" +
                                          (_vm.facade.contentFontSize || 20) /
                                            2 +
                                          "px;line-height:" +
                                          ((_vm.facade.contentFontSize || 20) +
                                            4) /
                                            2 +
                                          "px;",
                                      },
                                      [_vm._v("RMB")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "price",
                                        style:
                                          "font-size:" +
                                          (_vm.facade.contentPriceFontSize ||
                                            32) /
                                            2 +
                                          "px;line-height:" +
                                          (_vm.facade.contentPriceFontSize ||
                                            32) /
                                            2 +
                                          "px;font-weight: " +
                                          _vm.facade.contentFontWeight +
                                          ";",
                                      },
                                      [_vm._v("10,000")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "status",
                                        style:
                                          "font-size:" +
                                          ((_vm.facade.contentFontSize || 20) -
                                            2) /
                                            2 +
                                          "px;line-height:" +
                                          ((_vm.facade.contentFontSize || 20) +
                                            2) /
                                            2 +
                                          "px;",
                                      },
                                      [
                                        index == 1
                                          ? _c("span", [_vm._v("失效")])
                                          : _c("span", [_vm._v("委托")]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            : [
                                _c(
                                  "div",
                                  {
                                    staticClass: "content",
                                    style:
                                      "color: " +
                                      _vm.facade.outContentColor +
                                      ";box-shadow: 0px 2px 10px 0px " +
                                      (_vm.facade.shadowColor ||
                                        "rgba(0, 0, 0, 0.05)"),
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "unit",
                                        style:
                                          "font-size:" +
                                          (_vm.facade.outContentFontSize ||
                                            20) /
                                            2 +
                                          "px;line-height:" +
                                          ((_vm.facade.outContentFontSize ||
                                            20) +
                                            4) /
                                            2 +
                                          "px;",
                                      },
                                      [_vm._v("RMB")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "price",
                                        style:
                                          "font-size:" +
                                          (_vm.facade.outContentPriceFontSize ||
                                            32) /
                                            2 +
                                          "px;line-height:" +
                                          (_vm.facade.outContentPriceFontSize ||
                                            32) /
                                            2 +
                                          "px;font-weight: " +
                                          _vm.facade.outContentFontWeight +
                                          ";",
                                      },
                                      [_vm._v("10,000")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "status",
                                        style:
                                          "font-size:" +
                                          ((_vm.facade.outContentFontSize ||
                                            20) -
                                            2) /
                                            2 +
                                          "px;line-height:" +
                                          ((_vm.facade.outContentFontSize ||
                                            20) +
                                            2) /
                                            2 +
                                          "px;",
                                      },
                                      [
                                        index == 1
                                          ? _c("span", [_vm._v("失效")])
                                          : _c("span", [_vm._v("委托")]),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                        ],
                        2
                      ),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ]
        : _vm.content.type === "list"
        ? [
            _c(
              "div",
              {
                style: {
                  background: _vm.facade.background,
                  marginTop: _vm.facade.marginTop / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex align-items-c",
                    style: {
                      padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                      height: _vm.facade.titleHeight / 2 + "px",
                      fontSize: _vm.facade.titleFontSize / 2 + "px",
                      color: _vm.facade.titleColor,
                      fontWeight: _vm.facade.titleFontWeight,
                    },
                  },
                  [_vm._v("相关拍品")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "list",
                    style: {
                      margin: "0 -" + _vm.facade.left_margin / 2 + "px",
                      "padding-bottom": "20px",
                    },
                  },
                  [
                    _vm.listFacade
                      ? _c("auctionList", {
                          attrs: {
                            facade: Object.assign({}, _vm.listFacade, {
                              pageMargin: _vm.pageInfo.page_margin,
                            }),
                            content: { type: "list", style: 2 },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { width: "100%" },
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }