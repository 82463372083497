var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "配置", hasBack: true, isFixed: true },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": _vm.activeMenu,
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "5" } }, [
                _vm._v("规则配置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "4" } }, [
                _vm._v("分类设置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "6" } }, [
                _vm._v("鉴定结果"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  staticClass: "color-green create-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.createdAction },
                },
                [_vm._v("创建鉴定结果")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "mt20 button-no-padding",
              attrs: { border: "", data: _vm.resultData, size: "small" },
            },
            [
              _c("template", { slot: "empty" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.loading,
                        expression: "!loading",
                      },
                    ],
                    staticClass: "tableEmpty",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tableDiv" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "结果名称", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "结果排序", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.sort))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "r" },
                          [
                            scope.$index !== 0
                              ? _c("span", [_vm._v("|")])
                              : _vm._e(),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.editAction($event, scope.$index)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c("span", [_vm._v("|")]),
                            _c("el-popconfirm", {
                              attrs: { title: "确定删除吗？" },
                              on: {
                                onConfirm: function ($event) {
                                  return _vm.removeAction($event, scope.$index)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "reference",
                                    fn: function () {
                                      return [_c("a", [_vm._v("删除")])]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "auction-modal",
              attrs: {
                visible: _vm.showDetail,
                title: _vm.resultId ? "编辑鉴定结果" : "新增鉴定结果",
                "close-on-click-modal": false,
                width: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showDetail = $event
                },
                close: _vm.closeDetail,
              },
            },
            [
              _c(
                "div",
                { staticClass: "item-align-center mt20" },
                [
                  _c("span", { staticStyle: { width: "100px" } }, [
                    _vm._v("鉴定结果名称："),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "w200",
                    model: {
                      value: _vm.resultName,
                      callback: function ($$v) {
                        _vm.resultName = $$v
                      },
                      expression: "resultName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-align-center mt20" },
                [
                  _c("span", { staticStyle: { width: "100px" } }, [
                    _vm._v("鉴定结果排序："),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "w200",
                    model: {
                      value: _vm.resultSort,
                      callback: function ($$v) {
                        _vm.resultSort = $$v
                      },
                      expression: "resultSort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20 mb20" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "100px", width: "100px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.saveDetail },
                    },
                    [_vm._v(_vm._s(_vm.resultId ? "保存" : "确认添加"))]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }