"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _auction = require("@/api/auction");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    },
    hasMoreData: {
      type: String,
      default: ''
    },
    isOne: {
      type: Boolean,
      default: false
    },
    query: {
      type: [String, Object],
      default: ''
    }
  },
  data: function data() {
    return {
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        limit: 10,
        order: 'time'
      },
      total: 0,
      auctionName: '',
      venueList: [],
      venueLoading: false,
      venue_id: '',
      isAll: false
    };
  },
  watch: {
    flag: function flag(val) {
      this.showSelectAuction = val;
    }
  },
  mounted: function mounted() {
    this.showSelectAuction = this.flag;
    if (this.query) {
      this.queryParams = Object.assign({}, this.queryParams, this.query);
    }
  },
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.resultData.length === 0) {
        this.loadAuction();
      }
      this.showSelectAuction = true;
    },
    searchAAction: function searchAAction() {
      this.queryParams.venue_id = this.venue_id;
      this.queryParams.keyword = this.auctionName;
      this.queryParams.page = 1;
      this.loadAuction();
    },
    loadAuction: function loadAuction(fun) {
      var _this = this;
      (0, _auction.albumlist)(this.queryParams).then(function (response) {
        _this.total = response.data.count;
        _this.resultData = response.data.list;
        _this.setAllStatus();
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      this.setAllStatus();
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var m = this.resultData[index];
      var data = {
        image: m.album_image + '!300',
        name: m.album_name,
        id: m.album_id
      };
      if (this.hasMoreData) {
        data.image = m.album_image + '!m640';
        data.address_name = m.venue && m.venue.address_name ? m.venue.address_name : '';
        data.scheduled_show_time = m.scheduled_show_time_format[3];
        data.scheduled_show_time_format = m.scheduled_show_time_format[0];
        data.stime = m.stime_format[3];
        data.stime_format = m.stime_format[0];
        data.etime = m.album_end_time ? m.etime_format[3] : '';
        data.etime_format = m.album_end_time ? m.etime_format[0] : '';
        data.message = m.message;
        data.image_show_style = m.album_config.image_show_style;
        data.desc = '';
        data.venue = m.venue;
        data.live_stime_format = m.live_id && m.live && m.live.start_time ? (0, _utils.parseTime)(m.live.start_time, '{m}月{d}日 {h}:{i}') : '';
      }
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      this.setAllStatus();
      if (this.isOne) {
        this.cursorSelect();
      }
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'auctionAlbum',
        data: this.selectedAuction
      });
      this.showSelectAuction = false;
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.loadAuction();
    },
    searchVenueAction: function searchVenueAction(data) {
      var _this2 = this;
      var params = {
        keyword: data,
        venue_type: '',
        page: 1,
        limit: 10
      };
      (0, _auction.meetingList)(params).then(function (res) {
        _this2.venueList = res.data.list;
      });
    },
    setAllStatus: function setAllStatus() {
      var isAll = true;
      for (var i = 0; i < this.resultData.length; i += 1) {
        var m = this.resultData[i];
        var index = this.selectedAuctionIdList.indexOf(m.album_id);
        if (index === -1) {
          isAll = false;
          break;
        }
      }
      this.isAll = isAll;
    },
    auctionSelectedAllAction: function auctionSelectedAllAction() {
      var _this3 = this;
      if (this.isAll) {
        var allData = JSON.parse(JSON.stringify(this.selectedAuction));
        this.resultData.forEach(function (m) {
          var ids = allData.map(function (m) {
            return m.id;
          });
          var i = ids.indexOf(m.album_id);
          if (i !== -1) {
            allData.splice(i, 1);
          }
        });
        this.selectedAuction = allData;
      } else {
        var data = [];
        this.resultData.forEach(function (m) {
          var i = _this3.selectedAuctionIdList.indexOf(m.album_id);
          if (i === -1) {
            var d = {
              image: m.album_image + '!300',
              name: m.album_name,
              id: m.album_id
            };
            if (_this3.hasMoreData) {
              d.image = m.album_image + '!m640';
              d.address_name = m.venue && m.venue.address_name ? m.venue.address_name : '';
              d.scheduled_show_time = m.scheduled_show_time_format[3];
              d.scheduled_show_time_format = m.scheduled_show_time_format[0];
              d.stime = m.stime_format[3];
              d.stime_format = m.stime_format[0];
              d.etime = m.album_end_time ? m.etime_format[3] : '';
              d.etime_format = m.album_end_time ? m.etime_format[0] : '';
              d.message = m.message;
              d.image_show_style = m.album_config.image_show_style;
              d.desc = '';
              d.venue = m.venue;
              data.live_stime_format = m.live_id && m.live && m.live.start_time ? (0, _utils.parseTime)(m.live.start_time, '{m}月{d}日 {h}:{i}') : '';
            }
            data.push(d);
          }
        });
        this.selectedAuction = this.selectedAuction.concat(data);
      }
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      this.setAllStatus();
    }
  }
};