var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "pt10" },
            [
              _c(
                "el-form",
                { attrs: { size: "small", "label-width": "80px" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "直播状态：" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "mr20",
                                  staticStyle: { display: "block" },
                                  attrs: { type: "button", size: "small" },
                                  on: { change: _vm.selectChange },
                                  model: {
                                    value: _vm.tableFrom.live_status,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tableFrom,
                                        "live_status",
                                        $$v
                                      )
                                    },
                                    expression: "tableFrom.live_status",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: 1 } },
                                    [
                                      _vm._v(
                                        "预告中 " +
                                          _vm._s(_vm.statueNum.pre_num)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: 2 } },
                                    [
                                      _vm._v(
                                        "直播中 " +
                                          _vm._s(_vm.statueNum.start_num)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: 3 } },
                                    [
                                      _vm._v(
                                        "已结束 " +
                                          _vm._s(_vm.statueNum.end_num)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: -1 } },
                                    [_vm._v("已取消")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "直播类型：" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "mr20",
                                  staticStyle: { display: "block" },
                                  attrs: { type: "button", size: "small" },
                                  on: { change: _vm.selectChange },
                                  model: {
                                    value: _vm.tableFrom.live_type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tableFrom, "live_type", $$v)
                                    },
                                    expression: "tableFrom.live_type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "" } },
                                    [_vm._v("全部")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "news" } },
                                    [_vm._v("新闻")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "auction_album_3" } },
                                    [_vm._v("同步拍")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "auction_album_1" } },
                                    [_vm._v("限时拍")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "auction_album_2" } },
                                    [_vm._v("即时拍")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "时间选择：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "mr20",
                          attrs: { type: "button", size: "small" },
                          on: {
                            change: function ($event) {
                              return _vm.selectChangeData(_vm.tableFrom.date)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "date", $$v)
                            },
                            expression: "tableFrom.date",
                          },
                        },
                        _vm._l(_vm.fromList.fromTxt, function (item, i) {
                          return _c(
                            "el-radio-button",
                            { key: i, attrs: { label: item.val } },
                            [_vm._v(_vm._s(item.text))]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: {
                          width: "250px",
                          position: "relative",
                          top: "2px",
                        },
                        attrs: {
                          "value-format": "yyyy/MM/dd",
                          format: "yyyy/MM/dd",
                          size: "small",
                          type: "daterange",
                          placement: "bottom-end",
                          placeholder: "自定义时间",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.timeVal,
                          callback: function ($$v) {
                            _vm.timeVal = $$v
                          },
                          expression: "timeVal",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt20" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.createdAction },
                        },
                        [_vm._v("创建直播间")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "no-height",
          staticStyle: { width: "100%", color: "#000" },
          attrs: { data: _vm.tableData.data, "highlight-current-row": "" },
        },
        [
          _c("el-table-column", {
            key: "1",
            attrs: { label: "封面图", width: "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "num" }, [
                      _c("img", {
                        staticStyle: { width: "37px", height: "37px" },
                        attrs: {
                          src: scope.row.cover_image + "!120a",
                          alt: "",
                        },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "2",
            attrs: { label: "ID", "min-width": "60", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "num" }, [
                      _vm._v(_vm._s(scope.row.live_id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "3",
            attrs: { label: "直播标题", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "num" }, [
                      _vm._v(_vm._s(scope.row.live_title)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "4",
            attrs: { label: "直播时间", "min-width": "170", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.parseTime(scope.row.start_time)) + " "),
                      scope.row.end_time
                        ? _c("span", [
                            _vm._v(
                              "至 " +
                                _vm._s(
                                  _vm.parseTime(
                                    scope.row.end_time,
                                    "{d} {h}:{i}:{s}"
                                  )
                                )
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.tableFrom.live_status == 3
            ? [
                _c("el-table-column", {
                  key: "5",
                  attrs: {
                    label: "直播时长",
                    "min-width": "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "num" }, [
                              _vm._v(
                                _vm._s(
                                  parseInt(scope.row.time_duration / 3600, 10)
                                ) +
                                  ":" +
                                  _vm._s(
                                    parseInt(
                                      (scope.row.time_duration / 60) % 60,
                                      10
                                    )
                                  ) +
                                  ":" +
                                  _vm._s(scope.row.time_duration % 60)
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1553900988
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  key: "6",
                  attrs: {
                    label: "回放视频",
                    "min-width": "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "right",
                                  width: "400",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          scope.row.videoRecord &&
                                          scope.row.videoRecord.length,
                                        expression:
                                          "scope.row.videoRecord && scope.row.videoRecord.length",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          color: "#000",
                                        },
                                        attrs: {
                                          data: scope.row.videoRecord,
                                          "highlight-current-row": "",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "查看回放视频",
                                            "min-width": "70",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (params) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      [
                                                        params.row.video &&
                                                        params.row.video
                                                          .MediaUrl
                                                          ? _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                  size: "mini",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.showVideoAction(
                                                                        params
                                                                          .row
                                                                          .video
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "查看视频"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "操作",
                                            "min-width": "40",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (params) {
                                                  return [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "text",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.delLiveVideoRecordAction(
                                                              {
                                                                live_id:
                                                                  scope.row
                                                                    .live_id,
                                                                video_record_id:
                                                                  params.row
                                                                    .video_record_id,
                                                              },
                                                              scope.$index,
                                                              params.$index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "text",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.copyUrlAction(
                                                              params.row.video
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("文件地址")]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.videoRecord.length,
                                        expression:
                                          "scope.row.videoRecord.length",
                                      },
                                    ],
                                    attrs: { slot: "reference", type: "text" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.videoRecord.length) +
                                        "个回放"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1820931485
                  ),
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.tableFrom.live_status == 2
            ? [
                _c("el-table-column", {
                  key: "7",
                  attrs: {
                    label: "在线人数",
                    "min-width": "80",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showPeopleAction(scope.row, 1)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.online_num) + "人")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.allBannedAction(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.extend_data.is_open_chat
                                          ? "全体禁言"
                                          : "解除禁言"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3265293980
                  ),
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.tableFrom.live_status == 2 || _vm.tableFrom.live_status == 3
            ? [
                _c("el-table-column", {
                  key: "8",
                  attrs: {
                    label: "直播数据",
                    "min-width": "140",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "flex item-flex-center" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showPeopleAction(
                                          scope.row,
                                          2
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.click_num_uv) +
                                        "人共观看 " +
                                        _vm._s(scope.row.click_num) +
                                        "次"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex item-flex-center" },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.comment_num) + "条评论"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "ml5" }, [
                                  _vm._v(_vm._s(scope.row.like_num) + "点赞"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex item-flex-center" },
                              [
                                _vm._v(
                                  "最高同时在线 " +
                                    _vm._s(scope.row.maximum_online_num) +
                                    " 人"
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4046574221
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "虚拟人数",
                    "min-width": "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input-number", {
                              staticStyle: {
                                width: "80px",
                                "text-align": "center",
                              },
                              attrs: { min: 0, controls: false },
                              on: {
                                change: function (val) {
                                  return _vm.editVirtualAction(
                                    val,
                                    scope.$index
                                  )
                                },
                              },
                              model: {
                                value: scope.row.extend_data.virtual_num,
                                callback: function ($$v) {
                                  _vm.$set(
                                    scope.row.extend_data,
                                    "virtual_num",
                                    $$v
                                  )
                                },
                                expression: "scope.row.extend_data.virtual_num",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2219008763
                  ),
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            key: "10",
            attrs: { label: "订阅", "min-width": "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showPeopleAction(scope.row, 3)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.follow_num) + "人")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "11",
            attrs: { label: "直播类型", "min-width": "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.live_type == "news"
                      ? [_c("div", [_vm._v("新闻直播")])]
                      : scope.row.live_type == "auction_album"
                      ? [_c("div", [_vm._v("直播拍卖")])]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "12",
            attrs: {
              label: "关联拍品/商品",
              "min-width": "80",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.live_type == "news"
                      ? [
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showAboutProductAction(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(scope.row.product_num || 0) + "件"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : [_c("div", [_vm._v("-")])],
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "13",
            attrs: { label: "直播流", "min-width": "110", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        scope.row.live_stream_type == "LVB"
                          ? [_vm._v("\n            标准直播\n          ")]
                          : scope.row.live_stream_type == "LEB"
                          ? [_vm._v("\n            快直播\n          ")]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    scope.row.live_status != 3
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.getLivePushUrlAction(
                                  scope.row,
                                  scope.row.live_stream_type
                                )
                              },
                            },
                          },
                          [_vm._v("推流地址")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.live_status != 3
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.getLivePlayUrlAction(
                                  scope.row,
                                  scope.row.live_stream_type
                                )
                              },
                            },
                          },
                          [_vm._v("拉流地址")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "14",
            attrs: { label: "直播状态", "min-width": "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.live_status == 1
                      ? _c("div", [_vm._v("待开播")])
                      : scope.row.live_status == 2
                      ? _c("div", [_vm._v("直播中")])
                      : scope.row.live_status == 3
                      ? _c("div", [_vm._v("已结束")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.live_status == 1 || scope.row.live_status == 2
                      ? [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "left",
                                width: "100",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ft12",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyXcxLink(
                                              scope.row,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  小程序链接\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ft12",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyH5Link(
                                              scope.row,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  H5链接\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ft12",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showImgAction(
                                              1,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  小程序码\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ft12",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showImgAction(
                                              2,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  二维码\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ft12",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showImgAction(
                                              3,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  小程序海报\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ft12",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showImgAction(
                                              4,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  二维码海报\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(
                                    "\n              推广链接\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.live_type == "news"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editAction(scope.row.live_id)
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "commonedit" },
                            }),
                            _vm._v("编辑"),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.delAction(scope.row.live_id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                    _vm._v(" "),
                    scope.row.live_status == 1
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.clearAction(scope.row.live_id)
                                },
                              },
                            },
                            [_vm._v("取消直播")]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.live_status == 2
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.endLiveAction(scope.row.live_id)
                                },
                              },
                            },
                            [_vm._v("结束直播")]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.goObsConsole(scope.row)
                          },
                        },
                      },
                      [_vm._v("obs控制页")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block mt20 flex" },
        [
          _c("div", { staticClass: "flex-one" }),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.pushData.show,
            "close-on-click-modal": false,
            center: "",
            title: "查看推流地址",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.pushData, "show", $event)
            },
          },
        },
        [
          _c("div", [
            _vm._v("普通推流地址：" + _vm._s(_vm.pushData.data.rtmp)),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("快直播推流地址：" + _vm._s(_vm.pushData.data.webrtc)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.drawerData.show,
            direction: "rtl",
            size: "50%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawerData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "record-t",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _vm.drawerData.key == 1
                ? _c("span", [_vm._v("在线用户列表")])
                : _vm.drawerData.key == 2
                ? _c("span", [_vm._v("观看用户列表")])
                : _vm.drawerData.key == 3
                ? _c("span", [_vm._v("订阅用户列表")])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.drawerData.key == 2
            ? _c(
                "div",
                { staticClass: "search" },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "w300",
                      attrs: { placeholder: "客户昵称/姓名/手机号" },
                      on: { change: _vm.goSearchAction },
                      model: {
                        value: _vm.keywords,
                        callback: function ($$v) {
                          _vm.keywords = $$v
                        },
                        expression: "keywords",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: { click: _vm.goSearchAction },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "record-content" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.drawerData.listLoading,
                      expression: "drawerData.listLoading",
                    },
                  ],
                  staticClass: "order-table",
                  staticStyle: {
                    width: "100%",
                    color: "#000",
                    height: "60%",
                    "overflow-y": "auto",
                  },
                  attrs: {
                    data: _vm.drawerData.data,
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    key: "1",
                    attrs: { label: "头像", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              staticStyle: {
                                width: "40px",
                                height: "40px",
                                display: "block",
                              },
                              attrs: {
                                src:
                                  scope.row.user.avatar +
                                  (scope.row.user.avatar.indexOf(
                                    "https://saas.cdn.yunzongbu.cn"
                                  ) === -1
                                    ? ""
                                    : "!120a"),
                                alt: "",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    key: "2",
                    attrs: { label: "客户", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "color-lan",
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetails(scope.row.user.uid)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.user.real_name ||
                                      scope.row.user.nickname
                                  )
                                ),
                                scope.row.user.real_name
                                  ? _c("span", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(scope.row.user.nickname) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            scope.row.user.phone
                              ? _c("div", [
                                  _vm._v(_vm._s(scope.row.user.phone)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.user.mark
                              ? _c("div", [_vm._v(_vm._s(scope.row.user.mark))])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    key: "3",
                    attrs: { label: "号牌", "min-width": "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("div", [_vm._v(_vm._s(scope.row.number))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.drawerData.key == 2
                    ? [
                        _c("el-table-column", {
                          key: "4",
                          attrs: { label: "渠道", "min-width": "70" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row.source_type_text)
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            208297225
                          ),
                        }),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.drawerData.key != 1
                    ? [
                        _c("el-table-column", {
                          key: "5",
                          attrs: {
                            label:
                              _vm.drawerData.key == 2 ? "观看时间" : "订阅时间",
                            "min-width": "120",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(_vm._s(scope.row.create_time)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3407778047
                          ),
                        }),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.drawerData.key == 2
                    ? [
                        _c("el-table-column", {
                          key: "6",
                          attrs: { label: "操作", width: "100" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.bannelAction(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.isBanned ? "解禁" : "禁言"
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1948267110
                          ),
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block mt20" },
                [
                  _c("el-pagination", {
                    staticStyle: { "text-align": "right" },
                    attrs: {
                      "page-size": _vm.drawerData.limit,
                      "current-page": _vm.drawerData.page,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.drawerData.total,
                    },
                    on: { "current-change": _vm.pageChangeDrawer },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showVideo.show,
            "close-on-click-modal": false,
            "before-close": _vm.closeBeforeAction,
            center: "",
            title: "查看视频",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showVideo, "show", $event)
            },
          },
        },
        [
          _vm.showVideo.show
            ? _c("video", { attrs: { id: "id_test_video" } })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImg,
            "before-close": _vm.beforeCloseImg,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imgUrl },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.aboutProduct.show,
            direction: "rtl",
            size: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.aboutProduct, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "record-t",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("关联拍品/商品")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "record-content" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.showChangeProduct("auctionEL")
                    },
                  },
                },
                [_vm._v("添加关联拍品")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.showChangeProduct("goodsEL")
                    },
                  },
                },
                [_vm._v("添加关联商品")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.aboutProduct.listLoading,
                      expression: "aboutProduct.listLoading",
                    },
                  ],
                  staticClass: "order-table mt20",
                  staticStyle: {
                    width: "100%",
                    color: "#000",
                    height: "80%",
                    "overflow-y": "auto",
                  },
                  attrs: {
                    data: _vm.aboutProduct.data,
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "信息", "min-width": "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "display-flex item-align-center" },
                              [
                                scope.row.product_item &&
                                scope.row.product_item.image
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "25px",
                                        height: "25px",
                                        display: "block",
                                      },
                                      attrs: {
                                        src:
                                          scope.row.product_item.image +
                                          "!120a",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "ml10 flex-one ft12" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(scope.row.product_id) +
                                        " | " +
                                        _vm._s(
                                          scope.row.product_type === 0
                                            ? ""
                                            : "LOT" +
                                                scope.row.product_item.lot +
                                                " | "
                                        ) +
                                        _vm._s(
                                          scope.row.product_item.store_name
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "类型", "min-width": "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.product_type == 7
                              ? [
                                  scope.row.product_item.auction_type == 1
                                    ? _c("div", [_vm._v("限时拍拍品")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.product_item.auction_type == 2
                                    ? _c("div", [_vm._v("即时拍拍品")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.product_item.auction_type == 3
                                    ? _c("div", [_vm._v("同步拍拍品")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.product_item.auction_type == 4
                                    ? _c("div", [_vm._v("即刻拍拍品")])
                                    : _vm._e(),
                                ]
                              : scope.row.product_type === 0
                              ? _c("div", [_vm._v("商品")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "状态", "min-width": "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.product_type == 7
                              ? _c(
                                  "div",
                                  [
                                    scope.row.product_item.auction_status == 1
                                      ? [_vm._v("进行中")]
                                      : scope.row.product_item.auction_status ==
                                        -1
                                      ? [_vm._v("违约")]
                                      : scope.row.product_item.auction_status ==
                                        2
                                      ? [_vm._v("预展中")]
                                      : scope.row.product_item.auction_status ==
                                        3
                                      ? [_vm._v("保留价待确认")]
                                      : scope.row.product_item.auction_status ==
                                        4
                                      ? [_vm._v("流拍")]
                                      : scope.row.product_item.auction_status ==
                                        5
                                      ? [_vm._v("已中拍 未付款")]
                                      : scope.row.product_item.auction_status ==
                                        6
                                      ? [_vm._v("已完成")]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : scope.row.product_type === 0
                              ? _c(
                                  "div",
                                  [
                                    scope.row.product_item.stock
                                      ? [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.product_item.is_show
                                                ? "上架"
                                                : "下架"
                                            )
                                          ),
                                        ]
                                      : [_vm._v("已售罄")],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "价格", "min-width": "90" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.product_type == 7
                              ? _c(
                                  "div",
                                  [
                                    scope.row.product_item.auction_status == 2
                                      ? [
                                          _vm._v(
                                            "起拍价：" +
                                              _vm._s(
                                                scope.row.product_item
                                                  .start_price
                                              )
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            "当前价：" +
                                              _vm._s(
                                                scope.row.product_item.now_price
                                              )
                                          ),
                                        ],
                                    _vm._v(" "),
                                    scope.row.product_item.bid_range_id
                                      ? _c("div", [
                                          _vm._v(
                                            "竞价阶梯：" +
                                              _vm._s(
                                                scope.row.product_item.bidRange
                                                  .title
                                              )
                                          ),
                                        ])
                                      : _c("div", [
                                          _vm._v(
                                            "加价幅度：" +
                                              _vm._s(
                                                scope.row.product_item
                                                  .plus_price
                                              )
                                          ),
                                        ]),
                                  ],
                                  2
                                )
                              : scope.row.product_type === 0
                              ? _c("div", [
                                  _vm._v(
                                    "售价：" +
                                      _vm._s(scope.row.product_item.price)
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "其他信息", "min-width": "140" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.product_type == 7
                              ? _c("div", [
                                  scope.row.product_item.start_time
                                    ? _c("div", [
                                        _vm._v(
                                          "开拍：" +
                                            _vm._s(
                                              _vm.parseTime(
                                                scope.row.product_item
                                                  .start_time
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("div", [_vm._v("未开拍")]),
                                  _vm._v(" "),
                                  scope.row.product_item.end_time
                                    ? _c("div", [
                                        _vm._v(
                                          "结拍：" +
                                            _vm._s(
                                              _vm.parseTime(
                                                scope.row.product_item.end_time
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.product_item.start_time
                                              ? "进行中"
                                              : "未开拍"
                                          )
                                        ),
                                      ]),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "添加时间", "min-width": "110" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.create_time))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "排序", width: "90" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input-number", {
                              staticStyle: { width: "60px" },
                              attrs: { min: 0, controls: false },
                              on: {
                                change: function (val) {
                                  return _vm.editSortAction(val, scope.$index)
                                },
                              },
                              model: {
                                value: scope.row.sort,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "sort", $$v)
                                },
                                expression: "scope.row.sort",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    key: "6",
                    attrs: { label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.is_top
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.LiveProductTopAction(
                                          scope.row.product_id,
                                          1
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("取消置顶")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.LiveProductTopAction(
                                          scope.row.product_id,
                                          0
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("置顶推荐")]
                                ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delLiveProductAction(
                                      scope.row.product_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block flex mt20" },
                [
                  _c("div", { staticClass: "flex-one" }),
                  _vm._v(" "),
                  _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.aboutProduct.limit,
                      "current-page": _vm.aboutProduct.page,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.aboutProduct.total,
                    },
                    on: { "current-change": _vm.pageChangeAboutProduct },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("auctionComponent", {
        ref: "auctionEL",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("goodsComponent", {
        ref: "goodsEL",
        attrs: { size: "1", "current-data": [] },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }