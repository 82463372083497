"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _system = require("@/api/system");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      tab: '5',
      stateForm: {
        erp_product_no_prefix_rule: '0',
        erp_product_no_entry_rule: '0',
        erp_product_no_entry_system_date: []
      },
      userList: []
    };
  },
  computed: {
    entry_date: function entry_date() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();
      var returnd = '';
      if (this.stateForm.erp_product_no_entry_system_date.indexOf('y') !== -1) {
        returnd = "".concat(returnd).concat((0, _utils.formatDateNumber)(year));
      }
      if (this.stateForm.erp_product_no_entry_system_date.indexOf('m') !== -1) {
        returnd = "".concat(returnd).concat((0, _utils.formatDateNumber)(month));
      }
      if (this.stateForm.erp_product_no_entry_system_date.indexOf('d') !== -1) {
        returnd = "".concat(returnd).concat((0, _utils.formatDateNumber)(day));
      }
      if (this.stateForm.erp_product_no_entry_system_date.indexOf('H') !== -1) {
        returnd = "".concat(returnd).concat((0, _utils.formatDateNumber)(hour));
      }
      if (this.stateForm.erp_product_no_entry_system_date.indexOf('i') !== -1) {
        returnd = "".concat(returnd).concat((0, _utils.formatDateNumber)(minute));
      }
      if (this.stateForm.erp_product_no_entry_system_date.indexOf('s') !== -1) {
        returnd = "".concat(returnd).concat((0, _utils.formatDateNumber)(second));
      }
      return returnd;
    }
  },
  created: function created() {
    var _this = this;
    (0, _system.getlistNames)().then(function (res) {
      // console.log(res);
      _this.userList = res.data.list;
      _this.init();
    });
  },
  methods: {
    init: function init() {
      var _this2 = this;
      (0, _system.getConfigClassKeys)('erp_config').then(function (res) {
        // console.log(res);
        _this2.userList.forEach(function (item, index) {
          // res.data.erp_product_no_prefix_admin.forEach(m => {
          if (res.data.erp_product_no_prefix_admin[item.merchant_admin_id]) {
            // item.value = m[item.merchant_admin_id]
            var obj = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              value: res.data.erp_product_no_prefix_admin[item.merchant_admin_id]
            });
            _this2.$set(_this2.userList, index, obj);
          }
          // })
        });
        console.log(_this2.userList);
        if (!res.data.erp_product_no_entry_system_date) {
          res.data.erp_product_no_entry_system_date = ['y', 'm', 'd', 'H', 'i', 's'];
        } else {
          res.data.erp_product_no_entry_system_date = res.data.erp_product_no_entry_system_date.split('');
        }
        console.log(res.data);
        _this2.stateForm = res.data;
      });
    },
    onSubmit: function onSubmit() {
      var _this3 = this;
      var obj = {};
      this.userList.forEach(function (item) {
        if (item.value) obj[item.merchant_admin_id] = item.value;
      });
      var data = JSON.parse(JSON.stringify(this.stateForm));
      data.erp_product_no_prefix_admin = obj;
      var d = [];
      if (this.stateForm.erp_product_no_entry_system_date.indexOf('y') !== -1) {
        d.push('y');
      }
      if (this.stateForm.erp_product_no_entry_system_date.indexOf('m') !== -1) {
        d.push('m');
      }
      if (this.stateForm.erp_product_no_entry_system_date.indexOf('d') !== -1) {
        d.push('d');
      }
      if (this.stateForm.erp_product_no_entry_system_date.indexOf('H') !== -1) {
        d.push('H');
      }
      if (this.stateForm.erp_product_no_entry_system_date.indexOf('i') !== -1) {
        d.push('i');
      }
      if (this.stateForm.erp_product_no_entry_system_date.indexOf('s') !== -1) {
        d.push('s');
      }
      data.erp_product_no_entry_system_date = d.join('');
      (0, _system.configClassKeysSave)('erp_config', data).then(function (res) {
        _this3.$message.success('设置成功');
      });
    },
    changeTab: function changeTab(val) {
      var _this4 = this;
      if (val != 4) {
        this.$router.replace({
          path: '/merchant/warehouse/setColumns',
          query: {
            tab: val
          }
        });
        this.tab = val;
        setTimeout(function () {
          _this4.tab = val;
        });
      } else {
        this.$router.replace({
          path: '/merchant/warehouse/warehouseList'
        });
      }
    }
  }
};