var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 175px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                height: "100%",
                size: "mini",
                "highlight-current-row": "",
                "row-key": "refund_order_id",
                "expand-row-keys": _vm.rowkeys,
              },
              on: {
                "cell-click": _vm.cellClick,
                "expand-change": _vm.onExpand,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand", width: "30" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand demo-table-expands",
                            staticStyle: { "margin-left": "10px" },
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "订单号：" } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.row.order.order_sn)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "退款商品总价：" } },
                              [
                                props.row.order.activity === 2
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getPresellTotal(
                                            props.row.refundProduct
                                          )
                                        )
                                      ),
                                    ])
                                  : props.row.order.activity === 3
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAssistTotal(
                                            props.row.refundProduct
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getTotal(props.row.refundProduct)
                                        )
                                      ),
                                    ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "退款商品总数：" } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.row.refund_num)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "申请退款时间：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.create_time
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "用户备注：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.mark)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "商家备注：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.mer_mark)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            props.row.status === 2
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "快递公司：" } },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("filterEmpty")(
                                            props.row.delivery_name
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            props.row.status === 2
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "快递单号：" } },
                                  [
                                    _c("span", { staticClass: "mr5" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("filterEmpty")(
                                            props.row.delivery_no
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openLogistics(props.row)
                                          },
                                        },
                                      },
                                      [_vm._v("物流查询")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "退款类型：" } },
                              [
                                props.row.refund_type === 1
                                  ? _c("span", [_vm._v("仅退款")])
                                  : _vm._e(),
                                _vm._v(" "),
                                props.row.refund_type === 2
                                  ? _c("span", [_vm._v("退货退款")])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "退款原因：" } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.row.refund_message)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "状态变更时间：" } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(props.row.status_time)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "退款单号",
                  "min-width": "170",
                  "search-key": "refund_order_sn",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: { display: "block" },
                          domProps: {
                            textContent: _vm._s(scope.row.refund_order_sn),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "用户信息",
                  "min-width": "130",
                  "search-key": "user_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.user
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetails(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.user.real_name ||
                                      scope.row.user.nickname
                                  )
                                ),
                                scope.row.user.real_name
                                  ? _c("span", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(scope.row.user.nickname) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  prop: "refund_price",
                  label: "退款金额",
                  "min-width": "130",
                  "search-type": "inputPacker",
                  "search-key": "start_refund_price",
                  "search-other-key": "end_refund_price",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.refund_price
                          ? [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      scope.row.refund_price || 0,
                                      2
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  prop: "=",
                  label: "专场",
                  "min-width": "130",
                  "search-key": "album_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.order.album
                          ? [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.order.album.album_name) +
                                  "\n        "
                              ),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  prop: "nickname",
                  label: "商品信息",
                  "min-width": "360",
                  "search-key": "product_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.refundProduct, function (val, i) {
                        return _c("div", { key: "order" + scope.$index + i }, [
                          val.product
                            ? _c(
                                "div",
                                { staticClass: "tabBox acea-row row-middle" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "demo-image__preview" },
                                    [
                                      _c("el-image", {
                                        attrs: {
                                          src: val.product.cart_info.product
                                            .image,
                                          "preview-src-list": [
                                            val.product.cart_info.product.image,
                                          ],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "tabBox_tit" }, [
                                    val.product.cart_info.product.lot
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                              "font-size": "12px !important",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  val.product.cart_info.product
                                                    .lot
                                                ) +
                                                " | "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          val.product.cart_info.product
                                            .store_name + " | "
                                        ) +
                                        _vm._s(
                                          val.product.cart_info.productAttr.sku
                                        )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  scope.row.order.activity_type === 2 &&
                                  val.product.cart_info.productPresellAttr
                                    ? _c(
                                        "span",
                                        { staticClass: "tabBox_pice" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              "￥" +
                                                val.product.cart_info
                                                  .productPresellAttr
                                                  .presell_price +
                                                " x " +
                                                val.refund_num
                                            )
                                          ),
                                        ]
                                      )
                                    : scope.row.order.activity_type === 3 &&
                                      val.product.cart_info.productAssistAttr
                                        .assist_price
                                    ? _c(
                                        "span",
                                        { staticClass: "tabBox_pice" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              "￥" +
                                                val.product.cart_info
                                                  .productAssistAttr
                                                  .assist_price +
                                                " x " +
                                                val.refund_num
                                            )
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "tabBox_pice" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              "￥" +
                                                val.product.cart_info
                                                  .productAttr.price +
                                                " x " +
                                                val.refund_num
                                            )
                                          ),
                                        ]
                                      ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  prop: "serviceScore",
                  label: "订单状态",
                  "search-key": "status",
                  "search-type": "radio",
                  "search-query": _vm.searchQuery,
                  options: [
                    { label: "待审核", value: "0" },
                    { label: "待退货", value: "1" },
                    { label: "待收货", value: "2" },
                    { label: "已退款", value: "3" },
                    { label: "审核未通过", value: "-1" },
                  ],
                  "min-width": "100",
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          { staticStyle: { display: "block" } },
                          [
                            scope.row.status == 2
                              ? _c("el-tag", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orderRefundFilter")(
                                        scope.row.status
                                      )
                                    )
                                  ),
                                ])
                              : scope.row.status == 1 ||
                                scope.row.status == -1 ||
                                scope.row.status == 0
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orderRefundFilter")(
                                        scope.row.status
                                      )
                                    )
                                  ),
                                ])
                              : scope.row.status != -2
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orderRefundFilter")(
                                        scope.row.status
                                      )
                                    )
                                  ),
                                ])
                              : scope.row.status == -2
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("用户已取消"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "TableRightBtn",
                          [
                            scope.row.status === 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onOrderStatus(
                                          scope.row.refund_order_id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "order-return",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("售后"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.status === 2
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmReceipt(
                                          scope.row.refund_order_id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "order-querensouhuo",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("确认收货"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作记录",
            visible: _vm.dialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.LogLoading,
                  expression: "LogLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableDataLog.data },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "refund_order_id",
                  align: "center",
                  label: "退款单ID",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "change_message",
                  label: "操作记录",
                  align: "center",
                  "min-width": "280",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "change_time",
                  label: "操作时间",
                  align: "center",
                  "min-width": "280",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFromLog.limit,
                  "current-page": _vm.tableFromLog.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableDataLog.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeLog,
                  "current-change": _vm.pageChangeLog,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("details-from", {
        ref: "orderDetail",
        attrs: { "order-datalist": _vm.orderDatalist },
      }),
      _vm._v(" "),
      _vm.dialogLogistics
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "物流查询",
                visible: _vm.dialogLogistics,
                width: "350px",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogLogistics = $event
                },
              },
            },
            [
              _vm.orderDetails
                ? _c("logistics-from", {
                    attrs: {
                      "order-datalist": _vm.orderDetails,
                      result: _vm.result,
                      "logistics-name": _vm.logisticsName,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }