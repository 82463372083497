var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "all-h-print" }, [
    _c(
      "div",
      { attrs: { id: "printorder" } },
      [
        _c("div", { staticStyle: { "border-top": "1px solid" } }, [
          _c("div", { staticClass: "detail-box" }, [
            _c("div", { staticClass: "flex" }, [
              _vm.merData.config && _vm.merData.config.mer_print_logo
                ? _c("img", {
                    staticStyle: { height: "70px", "margin-right": "20px" },
                    attrs: {
                      src: _vm.merData.config.mer_print_logo + "!120a",
                      alt: "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "detail-left" }, [
                _c("div", { staticClass: "detail-left-title" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.merData.company_name) +
                      " | " +
                      _vm._s(_vm.switchLang("ppckqd")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left-desc",
                    staticStyle: {
                      "font-size": "13px",
                      "font-family": "'printEN'",
                    },
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.merData.company_english_name) +
                        "  AUCTION OUTBOUND ORDER\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "detail-right" }, [
              _c("img", {
                staticStyle: { width: "200px" },
                attrs: {
                  src: "data:image/png;base64," + _vm.detailjson.bar_code_image,
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "detail-code" }, [
                _vm._v(_vm._s(_vm.detailjson.order_no)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.detailjson.out_type == "out_warehouse_refund" &&
          _vm.isShow &&
          !_vm.detailjson.type
            ? _c("div", [
                _c("div", { staticClass: "secTit" }, [
                  _vm._v(_vm._s(_vm.strConfig.returnOutboundZH)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left-desc",
                    staticStyle: {
                      "font-size": "13px",
                      "font-family": "'printEN'",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v(_vm._s(_vm.strConfig.returnOutboundEN))]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          (_vm.detailjson.out_type == "out_warehouse_order" ||
            _vm.detailjson.out_type == "out_warehouse_sales") &&
          _vm.isShow &&
          !_vm.detailjson.type
            ? _c("div", [
                _c("div", { staticClass: "secTit" }, [
                  _vm._v(_vm._s(_vm.strConfig.salesOutboundZH)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left-desc",
                    staticStyle: {
                      "font-size": "13px",
                      "font-family": "'printEN'",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v(_vm._s(_vm.strConfig.salesOutboundEN))]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.detailjson.out_type == "out_warehouse_refund" &&
          _vm.detailjson.entrust_uid > 0
            ? _c("div", [
                _c("div", { staticStyle: { height: "20px" } }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-list",
                    staticStyle: {
                      "justify-content": "start",
                      "margin-top": "5px",
                    },
                  },
                  [
                    _c("div", [
                      _vm._v("姓名 NAME "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "500",
                            "font-size": "16px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.detailjson.express_user_name))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "margin-left": "30px" } }, [
                      _vm._v(_vm._s(_vm.switchLang("dh")) + " PHONE "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "500",
                            "font-size": "16px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.detailjson.express_user_phone))]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.detailjson.out_mode == "express" &&
                _vm.detailjson.express_user_address
                  ? _c(
                      "div",
                      {
                        staticClass: "detail-list",
                        staticStyle: { "margin-top": "5px" },
                      },
                      [
                        _c("div", [
                          _vm._v("地址 ADDRESS "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                "font-size": "16px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.detailjson.express_user_address)
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.detailjson.userRemark
                  ? _c(
                      "div",
                      {
                        staticClass: "detail-list",
                        staticStyle: { "margin-top": "5px" },
                      },
                      [
                        _c("div", [
                          _vm._v(_vm._s(_vm.switchLang("bz")) + " NOTES "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                "font-size": "16px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.detailjson.userRemark))]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.isNo
          ? _c(
              "div",
              { staticClass: "ptd-box" },
              [
                _c(
                  "div",
                  {
                    staticClass: "ptd",
                    staticStyle: { "font-weight": "bold" },
                  },
                  _vm._l(_vm.showFiled, function (item, index) {
                    return _c("div", { key: index, staticClass: "ptd-title" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("nameFirter")(item.name))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'printEN'" } },
                        [_vm._v(_vm._s(_vm._f("englis")(item.name)))]
                      ),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _vm._l(
                  _vm.detailjson.warehouseOrderProduct,
                  function (item, index) {
                    return _c(
                      "div",
                      { key: "order" + index, staticClass: "ptd calcul" },
                      _vm._l(_vm.showFiled, function (ele, ind) {
                        return _c(
                          "div",
                          { key: ind, staticClass: "ptd-body" },
                          [
                            ele.field == "image"
                              ? _c("div", { staticClass: "item-flex-center" }, [
                                  item.erpProduct.warehouse_image &&
                                  item.erpProduct.warehouse_image[0]
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          "max-width": "50px",
                                          display: "block",
                                        },
                                        attrs: {
                                          src:
                                            item.erpProduct.warehouse_image[0] +
                                            "!120a",
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              : ele.attr_type == 1 &&
                                item.erpProduct.attribute &&
                                item.erpProduct.attribute[ele.field]
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.erpProduct.attribute[ele.field]
                                      )
                                  ),
                                ])
                              : ele.field == "label"
                              ? _c("span", [
                                  item.erpProduct[ele.field]
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.erpProduct[ele.field].join(",")
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : ele.field == "category"
                              ? _c("span", [
                                  item.erpProduct.storeCategoryParent
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.erpProduct.storeCategoryParent
                                              .map(function (m) {
                                                return m.cate_name
                                              })
                                              .join(">")
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : ele.field == "contract.contract_no"
                              ? _c("span", [
                                  item.erpProduct.contract
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.erpProduct.contract.contract_no
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(item.erpProduct[ele.field])),
                                ]),
                          ]
                        )
                      }),
                      0
                    )
                  }
                ),
              ],
              2
            )
          : [
              _vm._l(_vm.result, function (abc, ind) {
                return _c(
                  "div",
                  {
                    key: ind,
                    staticClass: "ptd-box",
                    style:
                      ind < _vm.result.length - 1
                        ? "page-break-after: always;"
                        : "",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ptd",
                        staticStyle: { "font-weight": "bold" },
                      },
                      _vm._l(_vm.showFiled, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "ptd-title" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm._f("nameFirter")(item.name))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v(_vm._s(_vm._f("englis")(item.name)))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm._l(abc, function (item, index) {
                      return _c(
                        "div",
                        { key: "order" + index, staticClass: "ptd" },
                        _vm._l(_vm.showFiled, function (ele, ind) {
                          return _c(
                            "div",
                            { key: ind, staticClass: "ptd-body" },
                            [
                              ele.field == "image"
                                ? _c(
                                    "div",
                                    { staticClass: "item-flex-center" },
                                    [
                                      item.erpProduct.warehouse_image &&
                                      item.erpProduct.warehouse_image[0]
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "100%",
                                              "max-width": "50px",
                                              display: "block",
                                            },
                                            attrs: {
                                              src:
                                                item.erpProduct
                                                  .warehouse_image[0] + "!120a",
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : ele.attr_type == 1 &&
                                  item.erpProduct.attribute &&
                                  item.erpProduct.attribute[ele.field]
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.erpProduct.attribute[ele.field]
                                        ) +
                                        " "
                                    ),
                                  ])
                                : ele.field == "label"
                                ? _c("span", [
                                    item.erpProduct[ele.field]
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.erpProduct[ele.field].join(
                                                ","
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : ele.field == "category"
                                ? _c("span", [
                                    item.erpProduct.storeCategoryParent
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.erpProduct.storeCategoryParent
                                                .map(function (m) {
                                                  return m.cate_name
                                                })
                                                .join(">")
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : ele.field == "contract.contract_no"
                                ? _c("span", [
                                    item.erpProduct.contract
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.erpProduct.contract
                                                .contract_no
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _c("span", [
                                    _vm._v(_vm._s(item.erpProduct[ele.field])),
                                  ]),
                            ]
                          )
                        }),
                        0
                      )
                    }),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ptd-box",
                  staticStyle: { "margin-top": "0", "border-top": "none" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ptd" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: { "line-height": "1" },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.switchLang("Total")))]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "font-family": "'printEN'" } },
                            [_vm._v("TOTAL")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.detailjson.warehouseOrderProduct.length
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.showFiled, function (ele, ind) {
                        return [
                          ind < _vm.showFiled.length - 2
                            ? _c("div", { key: ind, staticClass: "ptd-body" })
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ],
        _vm._v(" "),
        _vm.detailjson.type
          ? [
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "20px 0 10px",
                    "font-size": "16px",
                    "font-weight": "bold",
                    color: "#000",
                  },
                },
                [_vm._v(_vm._s(_vm.switchLang("ckpz")))]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex", staticStyle: { "flex-wrap": "wrap" } },
                [
                  _vm._l(_vm.detailjson.outImageList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "upLoadPicBox flex align-items-c flex-wrap",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "pictrue1 mr10 mb10" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: item + "!m640",
                                "preview-src-list": [item],
                                "initial-index": index,
                                fit: "contain",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox flex align-items-c flex-wrap",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1")
                        },
                      },
                    },
                    [_vm._m(0)]
                  ),
                ],
                2
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.detailjson.remark &&
        !_vm.detailjson.type &&
        _vm.strConfig.salesOutShowRemark == 1
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass: "secTit",
                  staticStyle: { "margin-top": "30px" },
                },
                [_vm._v(_vm._s(_vm.detailjson.remark))]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.detailjson.out_type == "out_warehouse_refund" &&
        _vm.isShow &&
        !_vm.detailjson.type
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass: "secTit",
                  staticStyle: { "margin-top": "30px" },
                },
                [_vm._v(_vm._s(_vm.strConfig.returnOutboundStatementZH))]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-desc",
                  staticStyle: {
                    "font-size": "13px",
                    "font-family": "'printEN'",
                    "font-weight": "500",
                  },
                },
                [_vm._v(_vm._s(_vm.strConfig.returnOutboundStatementEN))]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        (_vm.detailjson.out_type == "out_warehouse_order" ||
          _vm.detailjson.out_type == "out_warehouse_sales") &&
        _vm.isShow &&
        !_vm.detailjson.type
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass: "secTit",
                  staticStyle: { "margin-top": "30px" },
                },
                [_vm._v(_vm._s(_vm.strConfig.salesOutboundStatementZH))]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-desc",
                  staticStyle: {
                    "font-size": "13px",
                    "font-family": "'printEN'",
                    "font-weight": "500",
                  },
                },
                [_vm._v(_vm._s(_vm.strConfig.salesOutboundStatementEN))]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isShow && !_vm.detailjson.type
          ? _c("div", {
              staticClass: "print-line",
              staticStyle: { "margin-top": "30px" },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isShow && !_vm.detailjson.type
          ? _c("div", { staticClass: "detail-sign" }, [
              _vm._v(_vm._s(_vm.switchLang("shrqz"))),
              _c("span", { staticStyle: { "font-family": "'printEN'" } }, [
                _vm._v("（Name in Print）"),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isShow && !_vm.detailjson.type
          ? _c("div", { staticClass: "print-line" })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "detail-list",
            staticStyle: {
              "margin-top": "38px",
              "font-weight": "bold",
              "font-size": "16px",
            },
          },
          [
            _c(
              "div",
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.switchLang("OrderMak")) + "：\n      "
                ),
                _vm.detailjson.createAdmin
                  ? [_vm._v(_vm._s(_vm.detailjson.createAdmin.real_name))]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.switchLang("spr")) + "：\n      "
                ),
                _vm.detailjson.auditAdmin
                  ? [_vm._v(_vm._s(_vm.detailjson.auditAdmin.real_name))]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.switchLang("jsr")) + "：\n      "
                ),
                _vm.detailjson.outAdmin
                  ? [_vm._v(_vm._s(_vm.detailjson.outAdmin.real_name))]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.switchLang("ckkf")) + "：\n      "
                ),
                _vm.detailjson.outWarehouse
                  ? [_vm._v(_vm._s(_vm.detailjson.outWarehouse.name))]
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "detail-list" }, [
          _c("div", [
            _vm._v(
              _vm._s(_vm.switchLang("DocumentNumber")) +
                "：" +
                _vm._s(_vm.detailjson.order_no)
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              _vm._s(_vm.switchLang("zdsj")) +
                "：" +
                _vm._s(_vm.detailjson.create_time)
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._v(_vm._s(_vm.switchLang("cklx")) + "："),
              _vm.detailjson.out_type == "out_warehouse_order"
                ? [_vm._v(_vm._s(_vm.switchLang("xsck")))]
                : _vm.detailjson.out_type == "out_warehouse_refund"
                ? [_vm._v(_vm._s(_vm.switchLang("wtth")))]
                : _vm.detailjson.out_type == "out_warehouse_borrow"
                ? [_vm._v(_vm._s(_vm.switchLang("jcck")))]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", [
            _vm._v(_vm._s(_vm.switchLang("ysfs")) + "：\n      "),
            _vm.detailjson.out_mode == "express"
              ? _c("span", [_vm._v(_vm._s(_vm.switchLang("kdfh")))])
              : _vm._e(),
            _vm._v(" "),
            _vm.detailjson.out_mode == "self_take"
              ? _c("span", [_vm._v(_vm._s(_vm.switchLang("at")))])
              : _vm._e(),
            _vm._v(" "),
            _vm.detailjson.out_mode == "entrust_self_take"
              ? _c("span", [_vm._v(_vm._s(_vm.switchLang("wttrzt")))])
              : _vm._e(),
          ]),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upLoad mb10" }, [
      _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }