"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'Previewcontract',
  props: {
    title: {
      type: String,
      default: '合同名称'
    },
    contractno: {
      type: [String, Number],
      default: ''
    },
    merCateList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    titleDisponsejson: {
      type: Object,
      default: function _default() {
        return {
          direc: 1,
          place: 1,
          linenum: 2
        };
      }
    },
    signDisponsejson: {
      type: Object,
      default: function _default() {
        return {
          direc: 1,
          place: 1
        };
      }
    },
    content: {
      type: String,
      default: ''
    },
    attachmentContent: {
      type: String,
      default: ''
    },
    expiryDateSwitch: {
      type: Boolean,
      default: false
    },
    timeVal: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    catearr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    erpsettingList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    inventoryConfig: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    entrustOrderSwitch: {
      type: Boolean,
      default: false
    },
    ownsigndata: {
      type: [Object, Array],
      default: function _default() {
        return [{
          name: '公司名称',
          value: '',
          select: true,
          type: 'diabled'
        }, {
          name: '联系人',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '声明文字',
          value: '',
          select: true,
          type: 'textarea'
        }];
      }
    },
    othersigndata: {
      type: [Object, Array],
      default: function _default() {
        return [{
          name: '公司名称',
          value: '',
          select: true,
          type: 'diabled'
        }, {
          name: '联系人',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '声明文字',
          value: '',
          select: true,
          type: 'textarea'
        }];
      }
    },
    owndata: {
      type: [Object, Array],
      default: function _default() {
        return [{
          name: '公司名称',
          value: '',
          select: true,
          type: 'diabled'
        }, {
          name: '座机',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '传真',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '邮箱',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '网址',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '地址',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '联系人',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '手机号',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '开户名称',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '开户行',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '账号',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '国籍',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '证件类型',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '证件号码',
          value: '',
          select: true,
          type: 'nomal'
        }];
      }
    },
    otherdata: {
      type: [Object, Array],
      default: function _default() {
        return [{
          name: '公司名称',
          value: '',
          select: true,
          type: 'diabled'
        }, {
          name: '座机',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '传真',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '邮箱',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '网址',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '地址',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '联系人',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '手机号',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '开户名称',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '开户行',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '账号',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '国籍',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '证件类型',
          value: '',
          select: true,
          type: 'nomal'
        }, {
          name: '证件号码',
          value: '',
          select: true,
          type: 'nomal'
        }];
      }
    },
    companyName: {
      type: String,
      default: ''
    }
  },
  computed: {
    priceCount: function priceCount() {
      var i = 0;
      if (this.inventoryConfig.switch) {
        if (this.inventoryConfig.switch.sold_price_switch) {
          i += 1;
        }
        if (this.inventoryConfig.switch.unsold_price_switch) {
          i += 1;
        }
        if (this.inventoryConfig.switch.other_price_switch) {
          i += 1;
        }
      }
      return i || 1;
    }
  },
  data: function data() {
    return {
      formatDate: _index.parseTime,
      nowdate: +new Date()
    };
  },
  methods: {
    getCategoryName: function getCategoryName(id) {
      try {
        this.merCateList.forEach(function (m) {
          if (m.store_category_id == id) {
            throw m.cate_name;
          }
          if (m.children && m.children.length) {
            m.children.forEach(function (child) {
              if (child.store_category_id == id) {
                throw child.cate_name;
              }
              if (child.children && child.children.length) {
                child.children.forEach(function (childV) {
                  if (childV.store_category_id == id) {
                    throw childV.cate_name;
                  }
                });
              }
            });
          }
        });
      } catch (e) {
        return e;
      }
    }
  }
};