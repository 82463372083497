var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "拍品" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "banner-control",
              attrs: {
                model: _vm.content,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("风格模板选择"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-align-center flex-wrap" },
                _vm._l(_vm.styleData, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "change-type",
                      staticStyle: {
                        "margin-right": "20px",
                        "margin-top": "15px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "img",
                          class: { curr: _vm.is_active(item) },
                          on: {
                            click: function ($event) {
                              return _vm.setStyle(item.value)
                            },
                          },
                        },
                        [_c("label", [_c("img", { attrs: { src: item.src } })])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "name color-gray align-c ft12" },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.content.style !== 1
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("图片展示方式"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style !== 1
                ? _c(
                    "div",
                    { staticClass: "item-align-center flex-wrap" },
                    _vm._l(_vm.imgStyleData, function (item, index) {
                      return (_vm.content.style !== 2 && item.value !== 1) ||
                        _vm.content.style === 2
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "change-type",
                              staticStyle: {
                                "margin-right": "20px",
                                "margin-top": "15px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "img",
                                  class: {
                                    curr: item.value === _vm.facade.img_style,
                                  },
                                },
                                [
                                  _c("label", [
                                    _c("img", { attrs: { src: item.src } }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.facade.img_style,
                                          expression: "facade.img_style",
                                        },
                                      ],
                                      staticClass: "choose-input",
                                      attrs: { type: "radio", name: "choose" },
                                      domProps: {
                                        value: item.value,
                                        checked: _vm._q(
                                          _vm.facade.img_style,
                                          item.value
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.facade,
                                            "img_style",
                                            item.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "name color-gray align-c ft12" },
                                [_vm._v(_vm._s(item.name))]
                              ),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.cData.dataType !== "category"
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "item-align-center mt20" },
                      [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("数据选择")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "le-radio-group",
                            model: {
                              value: _vm.content.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.content, "type", $$v)
                              },
                              expression: "content.type",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("系统自动展示"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("手动选择展示"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.content.type === 1
                      ? _c(
                          "div",
                          { staticClass: "item-align-center mt20" },
                          [
                            _c(
                              "div",
                              { staticClass: "w80 fontW400 ft14 color-black" },
                              [_vm._v("拍品类型")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-checkbox-group",
                              {
                                staticClass: "le-radio-group",
                                model: {
                                  value: _vm.content.album_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.content, "album_type", $$v)
                                  },
                                  expression: "content.album_type",
                                },
                              },
                              [
                                _c("el-checkbox", { attrs: { label: 1 } }, [
                                  _vm._v("限时拍"),
                                ]),
                                _vm._v(" "),
                                _c("el-checkbox", { attrs: { label: 3 } }, [
                                  _vm._v("同步拍"),
                                ]),
                                _vm._v(" "),
                                _c("el-checkbox", { attrs: { label: 2 } }, [
                                  _vm._v("即时拍"),
                                ]),
                                _vm._v(" "),
                                _c("el-checkbox", { attrs: { label: 4 } }, [
                                  _vm._v("即刻拍"),
                                ]),
                                _vm._v(" "),
                                _c("el-checkbox", { attrs: { label: 5 } }, [
                                  _vm._v("抖音拍"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 1
                      ? _c("div", { staticClass: "item-align-center mt20" }, [
                          _c(
                            "div",
                            { staticClass: "fontW400 ft14 color-black" },
                            [_vm._v("系统自动展示条件")]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 1
                      ? _c(
                          "div",
                          { staticClass: "item-align-center mt10" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "le-radio-group",
                                model: {
                                  value: _vm.content.system_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.content, "system_type", $$v)
                                  },
                                  expression: "content.system_type",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("展示预展+进行中+已结束拍品"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("展示预展+进行中的拍品"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("展示预展中的拍品"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("el-radio", { attrs: { label: 4 } }, [
                                      _vm._v("展示进行中的拍品"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("el-radio", { attrs: { label: 5 } }, [
                                      _vm._v("展示已结束的拍品"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 1 && !_vm.loadMore
                      ? _c(
                          "div",
                          { staticClass: "item-align-center mt20" },
                          [
                            _c(
                              "div",
                              { staticClass: "w80 fontW400 ft14 color-black" },
                              [_vm._v("展示数据")]
                            ),
                            _vm._v(" "),
                            _c("el-input-number", {
                              attrs: { min: 1, max: 50, label: "数量" },
                              model: {
                                value: _vm.content.system_count,
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, "system_count", $$v)
                                },
                                expression: "content.system_count",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 2
                      ? _c("div", { staticClass: "item-align-center mt20" }, [
                          _c(
                            "div",
                            { staticClass: "fontW400 ft14 color-black" },
                            [_vm._v("手动选择拍品")]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.data &&
                    _vm.content.data.length > 0 &&
                    _vm.content.type === 2
                      ? _c(
                          "div",
                          { staticClass: "pt10" },
                          [
                            _c(
                              "draggable",
                              _vm._b(
                                {
                                  staticClass:
                                    "board-column-content flex flex-wrap",
                                  attrs: { list: _vm.content.data },
                                },
                                "draggable",
                                _vm.$attrs,
                                false
                              ),
                              _vm._l(_vm.content.data, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "kuang1 item-align-center mt10",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "item-align-center flex-one",
                                      },
                                      [
                                        _c("div", { staticClass: "ft14" }, [
                                          _vm._v(_vm._s(item.name)),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    index === 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "go-down-img ml10",
                                            on: {
                                              click: function ($event) {
                                                return _vm.goDown(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    index > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "go-up-img ml10",
                                            on: {
                                              click: function ($event) {
                                                return _vm.goUp(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "del-img",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelete(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 2
                      ? _c("div", { staticClass: "item-align-center mt20" }, [
                          _c("img", {
                            staticStyle: {
                              width: "12px",
                              height: "12px",
                              "margin-right": "5px",
                              "margin-top": "2px",
                            },
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "fontW500 ft16",
                              on: { click: _vm.handleAdditem },
                            },
                            [_vm._v("选择拍品")]
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "页面边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.page_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_margin", $$v)
                      },
                      expression: "facade.page_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.background_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "background_color", $$v)
                      },
                      expression: "facade.background_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "上圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.top_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "top_radius", $$v)
                      },
                      expression: "facade.top_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "下圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.bottom_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "bottom_radius", $$v)
                      },
                      expression: "facade.bottom_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.title_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_color", $$v)
                      },
                      expression: "facade.title_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "副标题颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.sub_title_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "sub_title_color", $$v)
                      },
                      expression: "facade.sub_title_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "价格颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.price_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "price_color", $$v)
                      },
                      expression: "facade.price_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "出价数颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.bid_num_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "bid_num_color", $$v)
                      },
                      expression: "facade.bid_num_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "状态样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "item-align-center",
                      staticStyle: { height: "30px" },
                      model: {
                        value: _vm.facade.status_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "status_type", $$v)
                        },
                        expression: "facade.status_type",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: 1 },
                        },
                        [
                          _c("div", { staticClass: "status-type1" }, [
                            _vm._v("预展中"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: 2 },
                        },
                        [
                          _c("div", { staticClass: "status-type2" }, [
                            _vm._v("预展中"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: 3 },
                        },
                        [
                          _c("div", { staticClass: "status-type3" }, [
                            _vm._v("预展中"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "按钮背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.btn_bg_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "btn_bg_color", $$v)
                      },
                      expression: "facade.btn_bg_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "按钮文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.btn_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "btn_color", $$v)
                      },
                      expression: "facade.btn_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "按钮圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.btn_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "btn_radius", $$v)
                      },
                      expression: "facade.btn_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.style === 5 || _vm.content.style === 4
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c(
                      "div",
                      { staticClass: "w80 fontW400 ft14 color-black" },
                      [_vm._v("图片尺寸")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "item-align-center" },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "80px" },
                              attrs: { type: "text", placeholder: "宽" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                },
                              },
                              model: {
                                value: _vm.facade.img_width,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "img_width", $$v)
                                },
                                expression: "facade.img_width",
                              },
                            },
                            [
                              _c("template", { slot: "suffix" }, [
                                _vm._v("px"),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { margin: "0 6px" },
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "80px" },
                              attrs: { type: "text", placeholder: "宽" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                },
                              },
                              model: {
                                value: _vm.facade.img_height,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "img_height", $$v)
                                },
                                expression: "facade.img_height",
                              },
                            },
                            [
                              _c("template", { slot: "suffix" }, [
                                _vm._v("px"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _vm.content.style != 5
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "内容上间距" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.top_margin,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "top_margin", $$v)
                          },
                          expression: "facade.top_margin",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style == 2 ||
              _vm.content.style == 3 ||
              _vm.content.style == 5
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "内容左间距" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.left_margin,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "left_margin", $$v)
                          },
                          expression: "facade.left_margin",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.text_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "text_margin", $$v)
                      },
                      expression: "facade.text_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "拍品底色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.item_background,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_background", $$v)
                      },
                      expression: "facade.item_background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.item_shadow,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_shadow", $$v)
                      },
                      expression: "facade.item_shadow",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影大小" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.item_shadow_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_shadow_size", $$v)
                      },
                      expression: "facade.item_shadow_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.title_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_size", $$v)
                      },
                      expression: "facade.title_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.title_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title_weight", $$v)
                        },
                        expression: "facade.title_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "价格字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.price_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "price_size", $$v)
                      },
                      expression: "facade.price_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "价格样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.price_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "price_weight", $$v)
                        },
                        expression: "facade.price_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "出价数字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.bid_num_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "bid_num_size", $$v)
                      },
                      expression: "facade.bid_num_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "出价数样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.bid_num_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "bid_num_weight", $$v)
                        },
                        expression: "facade.bid_num_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: { "current-data": _vm.content.data },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }