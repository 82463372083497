var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "委托方对账配置", "has-back": "" },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticStyle: { height: "auto" } },
        [
          _c("div", [
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("填加有权限对账的人员（可设置多人）"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {},
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selwidth",
                      staticStyle: { width: "600px" },
                      attrs: {
                        placeholder: "超级管理员",
                        clearable: "",
                        filterable: "",
                        multiple: "",
                      },
                      model: {
                        value:
                          _vm.formValidateJson.entrust_check_order_audit_admin,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formValidateJson,
                            "entrust_check_order_audit_admin",
                            $$v
                          )
                        },
                        expression:
                          "formValidateJson.entrust_check_order_audit_admin",
                      },
                    },
                    _vm._l(_vm.adminarr, function (item) {
                      return _c("el-option", {
                        key: item.merchant_admin_id,
                        attrs: {
                          label: item.real_name,
                          value: item.merchant_admin_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex align-items-c h50" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("业务员确认"),
                _c("span", [
                  _vm._v(
                    '开启时，财务人员确认对账之后，系统会短信通知“签约业务员"，业务员可查看对账单PDF，确认委托方无异议之后，点击“确认完成”。'
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-switch", {
                    attrs: { "active-value": "2", "inactive-value": "1" },
                    model: {
                      value:
                        _vm.formValidateJson.entrust_check_order_people_number,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formValidateJson,
                          "entrust_check_order_people_number",
                          $$v
                        )
                      },
                      expression:
                        "formValidateJson.entrust_check_order_people_number",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }