"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkorder = checkorder;
exports.confirmPmApi = confirmPmApi;
exports.confirmoutOrderApiV2 = confirmoutOrderApiV2;
exports.confirmpushOrderApiV2 = confirmpushOrderApiV2;
exports.createTransferOrder = createTransferOrder;
exports.createTransferOrderApiV2 = createTransferOrderApiV2;
exports.createoutOrder = createoutOrder;
exports.createoutOrderApiV2 = createoutOrderApiV2;
exports.createpushOrder = createpushOrder;
exports.createpushOrderApiV2 = createpushOrderApiV2;
exports.delcheckorder = delcheckorder;
exports.delwarehouse = delwarehouse;
exports.editrecordstatus = editrecordstatus;
exports.failwarehouse = failwarehouse;
exports.finishcheckorder = finishcheckorder;
exports.getFieldSetAPI = getFieldSetAPI;
exports.getLastWarehouseIdApi = getLastWarehouseIdApi;
exports.getOrderNo = getOrderNo;
exports.getOrderbarcodeV2 = getOrderbarcodeV2;
exports.getPmDataApi = getPmDataApi;
exports.getbarcode = getbarcode;
exports.getbarcodeV2 = getbarcodeV2;
exports.getcheckorderlist = getcheckorderlist;
exports.getcheckrecordlist = getcheckrecordlist;
exports.getinventorygoods = getinventorygoods;
exports.getnogoods = getnogoods;
exports.getwarehouseDetail = getwarehouseDetail;
exports.getwarehouseOrderDetailApiV2 = getwarehouseOrderDetailApiV2;
exports.getwarehouseOrderGoodsListApiV2 = getwarehouseOrderGoodsListApiV2;
exports.getwarehouseOrderListApiV2 = getwarehouseOrderListApiV2;
exports.giveshelf = giveshelf;
exports.intowarehouse = intowarehouse;
exports.invalidOutApi = invalidOutApi;
exports.invalidTransfersApi = invalidTransfersApi;
exports.invalidpushOrderApiV2 = invalidpushOrderApiV2;
exports.outwarehouse = outwarehouse;
exports.startcheck = startcheck;
exports.supplementEnterImageApi = supplementEnterImageApi;
exports.supplementOutImageApi = supplementOutImageApi;
exports.updateOutApi = updateOutApi;
exports.updateRemark = updateRemark;
exports.updateShelfApi = updateShelfApi;
exports.updateTransfersApi = updateTransfersApi;
exports.upgoodscert = upgoodscert;
exports.uptaskcert = uptaskcert;
exports.warehouseDetailUpdateApi = warehouseDetailUpdateApi;
exports.warehouselist = warehouselist;
var _request = _interopRequireDefault(require("./request"));
/**
 * 库存单列表
 */
function warehouselist(data) {
  return _request.default.get('erp/warehouse/order/lst', data);
}
/**
 * 入位
 */
function giveshelf(id, data) {
  return _request.default.post("erp/warehouse/order/product/putIn/".concat(id), data);
}
/**
 * 仓库订单详情
 */
function getwarehouseDetail(id) {
  return _request.default.get("erp/warehouse/order/detail/".concat(id));
}
/**
 * 仓库订单修改
 */
function warehouseDetailUpdateApi(id, data) {
  return _request.default.post("erp/v2/warehouse/order/updateEnter/".concat(id), data);
}

/**
 * 出库修改
 */
function updateOutApi(id, data) {
  return _request.default.post("erp/v2/warehouse/order/updateOut/".concat(id), data);
}

/**
 * 修改
 */
function updateTransfersApi(id, data) {
  return _request.default.post("erp/v2/warehouse/order/updateTransfers/".concat(id), data);
}
/**
 * 入库
 */
function intowarehouse(id, data) {
  return _request.default.post("erp/warehouse/order/putIn/".concat(id), data);
}
/**
 * 出库
 */
function outwarehouse(id, data) {
  return _request.default.post("erp/warehouse/order/takeOut/".concat(id), data);
}
/**
 * 失效invalid
 */
function failwarehouse(id, data) {
  return _request.default.post("erp/warehouse/order/invalid/".concat(id), data);
}
/**
 * 失效invalid
 */
function invalidOutApi(data) {
  return _request.default.post("erp/v2/warehouse/order/invalidOut", data);
}
/**
 * 失效invalid
 */
function invalidTransfersApi(data) {
  return _request.default.post("erp/v2/warehouse/order/invalidTransfers", data);
}
/**
 * 删除invalid
 */
function delwarehouse(id) {
  return _request.default.post("erp/warehouse/order/delete/".concat(id));
}
/**
 * 创建调拨单
 */
function createTransferOrder(data) {
  return _request.default.post("erp/warehouse/order/createTransfers", data);
}
/**
 * 获取货品标签码
 */
function getbarcode(data) {
  return _request.default.get("erp/product/barcode", data);
}
/**
 * 盘点任务列表
 */
function getcheckorderlist(data) {
  return _request.default.get("erp/stockTake/task/lst", data);
}
/**
 * 创建盘点任务
 */
function checkorder(data) {
  return _request.default.post("erp/stockTake/task/create", data);
}
/**
 * 完成盘点任务
 */
function finishcheckorder(id) {
  return _request.default.post("erp/stockTake/task/end/".concat(id));
}
/**
 * 删除盘点任务
 */
function delcheckorder(id) {
  return _request.default.post("erp/stockTake/task/delete/".concat(id));
}
/**
 * 盘点记录列表
 */
function getcheckrecordlist(data) {
  return _request.default.get("erp/stockTake/record/lst", data);
}
/**
 * 修改盘点状态
 */
function editrecordstatus(data) {
  return _request.default.post("erp/stockTake/record/switchStatus", data);
}
/**
 * 创建入库单
 */
function createpushOrder(data) {
  return _request.default.post("erp/warehouse/order/createPush", data);
}
/**
 * 创建出库单
 */
function createoutOrder(data) {
  return _request.default.post("erp/warehouse/order/createOut", data);
}
/**
 * 获取委托清单待入库商品
 */
function getinventorygoods(data) {
  return _request.default.get("erp/entrust/order/product/getWaitPushWarehouseLst", data);
}
/**
 * 获取入库编号
 */
function getOrderNo(data) {
  return _request.default.get('getOrderNo', data);
}
/**
 * 上传盘点凭证
 */
function upgoodscert(id, data) {
  return _request.default.post("erp/stockTake/record/uploadCredentials/".concat(id), data);
}
// 开始盘点
function startcheck(id) {
  return _request.default.post("erp/stockTake/task/start/".concat(id));
}
/**
 * 上传任务凭证
 */
function uptaskcert(id, data) {
  return _request.default.post("erp/stockTake/task/uploadCredentials/".concat(id), data);
}
// 获取编号商品
function getnogoods(data) {
  return _request.default.get("erp/product/storeNoByProduct", data);
}

// 仓库订单v2
function getwarehouseOrderListApiV2(data) {
  return _request.default.get('erp/v2/warehouse/order/list', data);
}
// 仓库订单详情v2
function getwarehouseOrderDetailApiV2(id) {
  return _request.default.get("erp/v2/warehouse/order/detail/".concat(id));
}
// 仓库订单货品列表
function getwarehouseOrderGoodsListApiV2(data) {
  return _request.default.get("erp/v2/warehouse/order/product/list", data);
}
// 创建入库单
function createpushOrderApiV2(data) {
  return _request.default.post("erp/v2/warehouse/order/createEnter", data);
}
// 确认入库
function confirmpushOrderApiV2(data) {
  return _request.default.post("erp/v2/warehouse/order/confirmEnter", data);
}
// 补充入库图
function supplementEnterImageApi(data) {
  return _request.default.post("erp/v2/warehouse/order/supplementEnterImage", data);
}
// 作废入库单
function invalidpushOrderApiV2(data) {
  return _request.default.post("erp/v2/warehouse/order/invalidEnter", data);
}
// 创建出库单
function createoutOrderApiV2(data) {
  return _request.default.post("erp/v2/warehouse/order/createOut", data);
}
// 确认出库单
function confirmoutOrderApiV2(data) {
  return _request.default.post("erp/v2/warehouse/order/confirmOut", data);
}
// 出库单补充图片
function supplementOutImageApi(data) {
  return _request.default.post("erp/v2/warehouse/order/supplementOutImage", data);
}
// 创建调拨单
function createTransferOrderApiV2(data) {
  return _request.default.post("erp/v2/warehouse/order/createTransfers", data);
}
// 查看库房字段配置
function getFieldSetAPI(data) {
  return _request.default.get("field/batchConfigIndex", data);
}
/**
 * 获取货品标签码
 */
function getbarcodeV2(data) {
  return _request.default.get("erp/v2/product/barCode", data);
}

// 获取入库单的条码
function getOrderbarcodeV2(data) {
  return _request.default.get("erp/v2/warehouse/order/product/getProductNoImage", data);
}
// 修改货架
function updateShelfApi(data) {
  return _request.default.post("erp/v2/warehouse/order/product/updateShelf", data);
}

// 获取上拍货品列表
function getPmDataApi(data) {
  return _request.default.get("erp/v2/product/getPmData", data);
}

// 确认上架
function confirmPmApi(data) {
  return _request.default.post("erp/v2/product/confirmPm", data);
}

// 获取拍卖会专场详情列表
function updateRemark(id, data) {
  return _request.default.post("erp/v2/warehouse/order/updateRemark/".concat(id), data);
}

// 获取上拍货品列表
function getLastWarehouseIdApi(data) {
  return _request.default.get("erp/v2/warehouse/order/getLastWarehouseId", data);
}