var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.showconfig,
            direction: "rtl",
            size: "800px",
            title: "字段配置",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showconfig = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex align-items-c",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex-one",
                  staticStyle: {
                    "font-size": "20px",
                    color: "#000",
                    "font-weight": "500",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.types == "getExportFields"
                        ? "导出表格字段设置"
                        : "列表项配置"
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mr20" },
                [
                  _vm.types == "erp_v2_product_list"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "medium" },
                          on: { click: _vm.saveTemplate },
                        },
                        [_vm._v("另存模板")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "medium" },
                      on: { click: _vm.saveheadervonfig },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "all-view" }, [
            _vm.types == "erp_v2_product_list"
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "template-list flex ft16",
                      staticStyle: { color: "#000" },
                    },
                    [
                      _c("div", { staticClass: "t fWeight500" }, [
                        _vm._v("导航模板："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-one flex flex-wrap" },
                        _vm._l(_vm.tempList, function (m, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "item",
                              on: {
                                click: function ($event) {
                                  return _vm.changeTemplateAction(m.template_id)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "txt" }, [
                                _vm._v(_vm._s(m.template_name)),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/merchant/333425/2025116/image/1737037713116454013.png",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delTemplateAction(m.template_id)
                                  },
                                },
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "flex edit-config" }, [
              _c("div", { staticClass: "flex-one item" }, [
                _c("div", { staticClass: "t ft14 fWeight500 flex" }, [
                  _c(
                    "div",
                    { staticStyle: { width: "50px", "text-align": "center" } },
                    [_vm._v("排序")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v("业务场景"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one" }, [_vm._v("字段名称")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mr20" }, [_vm._v("字段类型")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "c" },
                  [
                    _c(
                      "draggable",
                      _vm._b(
                        {
                          attrs: {
                            list: _vm.showList,
                            handle: ".move-view",
                            "set-data": _vm.setData,
                          },
                        },
                        "draggable",
                        _vm.$attrs,
                        false
                      ),
                      _vm._l(_vm.showList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: "show_" + index,
                            staticClass: "draw-list",
                            attrs: { draggable: "false" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "move-view",
                                staticStyle: {
                                  width: "50px",
                                  "text-align": "center",
                                },
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "mb2",
                                  attrs: { "icon-class": "drag_new" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "name flex remove-view",
                                staticStyle: {
                                  "justify-content": "space-between",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeselected(index, "show")
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      width: "80px",
                                      "font-size": "13px",
                                      color: "#666",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.business_scene_name))]
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "flex-one" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "13px",
                                      color: "#666",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.field_type_text))]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one ml40 item" }, [
                _c(
                  "div",
                  {
                    staticClass: "search mr20",
                    staticStyle: { "margin-bottom": "5px" },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        "suffix-icon": "el-icon-search",
                        placeholder: "输入关键词搜索",
                        clearable: "",
                      },
                      on: { change: _vm.searchAction },
                      model: {
                        value: _vm.keyword,
                        callback: function ($$v) {
                          _vm.keyword = $$v
                        },
                        expression: "keyword",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "t ft14 fWeight500 flex" }, [
                  _c("div", { staticStyle: { width: "80px" } }, [
                    _vm._v("业务场景"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one" }, [_vm._v("字段名称")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mr20" }, [_vm._v("字段类型")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "c" },
                  _vm._l(_vm.showHideList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "hide_" + index,
                        staticClass: "draw-list",
                        attrs: { draggable: "false" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "name flex add-view",
                            staticStyle: { "justify-content": "space-between" },
                            on: {
                              click: function ($event) {
                                return _vm.changeselected(index, "hide")
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  width: "80px",
                                  "font-size": "13px",
                                  color: "#666",
                                },
                              },
                              [_vm._v(_vm._s(item.business_scene_name))]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "flex-one" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "13px",
                                  color: "#666",
                                },
                              },
                              [_vm._v(_vm._s(item.field_type_text))]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showSetName,
            direction: "rtl",
            width: "400px",
            title: "设置模版名称",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSetName = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { placeholder: "输入模板名称" },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }