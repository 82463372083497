var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.visible,
            "append-to-body": true,
            width: "1100px",
            "before-close": _vm.handleClose,
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "70vh" } },
            [
              _vm.visible
                ? _c("upload-index", {
                    attrs: {
                      isMore: _vm.isMore,
                      changeType: _vm.changeType,
                      changeCategory: _vm.changeCategory,
                      attacCategoryId: _vm.attacCategoryId,
                    },
                    on: { getImage: _vm.getImage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }