var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "w-full overflow-hidden" }, [
      _c("div", { staticClass: "flex align-items-s" }, [
        _c(
          "div",
          {
            staticClass: "proof-table",
            style: { width: _vm.hasPermission ? "740px" : "640px" },
          },
          [
            _c("div", { staticClass: "proof-title item-flex-center" }, [
              _c("div", { staticClass: "w100 td" }, [_vm._v("是否启用")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one td" }, [_vm._v("字段名称")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one td" }, [_vm._v("自定义名称")]),
              _vm._v(" "),
              _vm.hasPermission
                ? _c("div", { staticClass: "flex-one td" }, [
                    _vm._v("纸质标的名称"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "w100 td" }),
              _vm._v(" "),
              _c("div", { staticClass: "w140 td" }, [_vm._v("排序")]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "proof-content" },
              _vm._l(_vm.list, function (m, i) {
                return _c("div", { key: i, staticClass: "item-flex-center" }, [
                  _c(
                    "div",
                    { staticClass: "w100 td" },
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: m.field == "product_name" },
                        model: {
                          value: m.checked,
                          callback: function ($$v) {
                            _vm.$set(m, "checked", $$v)
                          },
                          expression: "m.checked",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one td" }, [
                    _vm._v(_vm._s(m.origin_name)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-one td" },
                    [
                      _c("el-input", {
                        staticStyle: { "text-align": "center" },
                        on: {
                          focus: function ($event) {
                            return _vm.focus($event)
                          },
                        },
                        model: {
                          value: m.name,
                          callback: function ($$v) {
                            _vm.$set(m, "name", $$v)
                          },
                          expression: "m.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasPermission
                    ? _c(
                        "div",
                        { staticClass: "flex-one td" },
                        [
                          _c("el-input", {
                            staticStyle: { "text-align": "center" },
                            on: {
                              focus: function ($event) {
                                return _vm.focus($event)
                              },
                            },
                            model: {
                              value: m.contract_name,
                              callback: function ($$v) {
                                _vm.$set(m, "contract_name", $$v)
                              },
                              expression: "m.contract_name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "w100 td" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(m.attr_type > 0 ? "属性" : "系统字段") +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "w140 td" },
                    [
                      _c("el-input-number", {
                        attrs: { controls: false },
                        on: {
                          focus: function ($event) {
                            return _vm.focus($event)
                          },
                        },
                        model: {
                          value: m.sort,
                          callback: function ($$v) {
                            _vm.$set(m, "sort", $$v)
                          },
                          expression: "m.sort",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _vm.proofType == 3 && _vm.tab == 2
          ? _c("div", { staticClass: "set-print-text" }, [
              _c("div", { staticClass: "t" }, [_vm._v("销售出库文案：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inp" },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autosize: "" },
                    model: {
                      value: _vm.setPrintColumns.salesOutboundZH,
                      callback: function ($$v) {
                        _vm.$set(_vm.setPrintColumns, "salesOutboundZH", $$v)
                      },
                      expression: "setPrintColumns.salesOutboundZH",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inp" },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autosize: "" },
                    model: {
                      value: _vm.setPrintColumns.salesOutboundEN,
                      callback: function ($$v) {
                        _vm.$set(_vm.setPrintColumns, "salesOutboundEN", $$v)
                      },
                      expression: "setPrintColumns.salesOutboundEN",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inp" },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autosize: "" },
                    model: {
                      value: _vm.setPrintColumns.salesOutboundStatementZH,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.setPrintColumns,
                          "salesOutboundStatementZH",
                          $$v
                        )
                      },
                      expression: "setPrintColumns.salesOutboundStatementZH",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inp" },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autosize: "" },
                    model: {
                      value: _vm.setPrintColumns.salesOutboundStatementEN,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.setPrintColumns,
                          "salesOutboundStatementEN",
                          $$v
                        )
                      },
                      expression: "setPrintColumns.salesOutboundStatementEN",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "t" }, [_vm._v("退还出库文案：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inp" },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autosize: "" },
                    model: {
                      value: _vm.setPrintColumns.returnOutboundZH,
                      callback: function ($$v) {
                        _vm.$set(_vm.setPrintColumns, "returnOutboundZH", $$v)
                      },
                      expression: "setPrintColumns.returnOutboundZH",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inp" },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autosize: "" },
                    model: {
                      value: _vm.setPrintColumns.returnOutboundEN,
                      callback: function ($$v) {
                        _vm.$set(_vm.setPrintColumns, "returnOutboundEN", $$v)
                      },
                      expression: "setPrintColumns.returnOutboundEN",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inp" },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autosize: "" },
                    model: {
                      value: _vm.setPrintColumns.returnOutboundStatementZH,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.setPrintColumns,
                          "returnOutboundStatementZH",
                          $$v
                        )
                      },
                      expression: "setPrintColumns.returnOutboundStatementZH",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "inp" },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autosize: "" },
                    model: {
                      value: _vm.setPrintColumns.returnOutboundStatementEN,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.setPrintColumns,
                          "returnOutboundStatementEN",
                          $$v
                        )
                      },
                      expression: "setPrintColumns.returnOutboundStatementEN",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "orderFoot flex align-items-c" }, [
      _c(
        "div",
        { staticClass: "flex-one" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.saveAction },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }