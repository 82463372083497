var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "all-h-print" }, [
    _c(
      "div",
      { attrs: { id: "printorder" } },
      [
        _c("div", { staticClass: "detail-box" }, [
          _c("div", { staticClass: "detail-left" }, [
            _c("div", { staticClass: "detail-left-title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.merData.company_name) +
                  " | 拍品调拨清单\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail-left-desc",
                staticStyle: {
                  "font-size": "14px",
                  "font-family": "'printEN'",
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.merData.company_english_name) +
                    "  AUCTION ALLOCATION ORDER\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "detail-right" }, [
            _c("img", {
              staticStyle: { width: "200px" },
              attrs: {
                src: "data:image/png;base64," + _vm.detailjson.bar_code_image,
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "detail-code" }, [
              _vm._v(_vm._s(_vm.detailjson.order_no)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.isNo
          ? [
              _c(
                "div",
                { staticClass: "ptd-box" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ptd",
                      staticStyle: { "font-weight": "bold" },
                    },
                    _vm._l(_vm.showFiled, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "ptd-title" },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("nameFirter")(item.name))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "font-family": "'printEN'" } },
                            [_vm._v(_vm._s(_vm._f("englis")(item.name)))]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm._l(
                    _vm.detailjson.warehouseOrderProduct,
                    function (item, index) {
                      return _c(
                        "div",
                        { key: "order" + index, staticClass: "ptd calcul" },
                        _vm._l(_vm.showFiled, function (ele, ind) {
                          return _c(
                            "div",
                            { key: ind, staticClass: "ptd-body" },
                            [
                              ele.field == "image"
                                ? _c(
                                    "span",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      item.erpProduct.warehouse_image &&
                                      item.erpProduct.warehouse_image[0]
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                item.erpProduct
                                                  .warehouse_image[0] + "!m640",
                                              alt: "",
                                            },
                                          })
                                        : item.erpProduct.contract_image &&
                                          item.erpProduct.contract_image[0]
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                item.erpProduct
                                                  .contract_image[0] + "!m640",
                                              alt: "",
                                            },
                                          })
                                        : item.erpProduct.auction_image &&
                                          item.erpProduct.auction_image[0]
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                item.erpProduct
                                                  .auction_image[0] + "!m640",
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : ele.attr_type > 0
                                ? _c("span", [
                                    item.erpProduct.attribute
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.erpProduct.attribute[
                                                ele.field
                                              ]
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : ele.field == "label"
                                ? _c("span", [
                                    item.erpProduct[ele.field]
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.erpProduct[ele.field].join(
                                                ","
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _c("span", [
                                    _vm._v(_vm._s(item.erpProduct[ele.field])),
                                  ]),
                            ]
                          )
                        }),
                        0
                      )
                    }
                  ),
                ],
                2
              ),
            ]
          : _vm._l(_vm.result, function (abc, ind) {
              return _c(
                "div",
                {
                  key: ind,
                  staticClass: "ptd-box",
                  style:
                    ind < _vm.result.length - 1
                      ? "page-break-after: always;"
                      : "",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ptd",
                      staticStyle: { "font-weight": "bold" },
                    },
                    _vm._l(_vm.showFiled, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "ptd-title" },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("nameFirter")(item.name))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "font-family": "'printEN'" } },
                            [_vm._v(_vm._s(_vm._f("englis")(item.name)))]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm._l(abc, function (item, index) {
                    return _c(
                      "div",
                      { key: "order" + index, staticClass: "ptd" },
                      _vm._l(_vm.showFiled, function (ele, ind) {
                        return _c(
                          "div",
                          { key: ind, staticClass: "ptd-body" },
                          [
                            _c("div", { staticClass: "ptd-body-nr" }, [
                              ele.field == "image"
                                ? _c(
                                    "span",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      item.erpProduct.warehouse_image &&
                                      item.erpProduct.warehouse_image[0]
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                item.erpProduct
                                                  .warehouse_image[0] + "!m640",
                                              alt: "",
                                            },
                                          })
                                        : item.erpProduct.contract_image &&
                                          item.erpProduct.contract_image[0]
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                item.erpProduct
                                                  .contract_image[0] + "!m640",
                                              alt: "",
                                            },
                                          })
                                        : item.erpProduct.auction_image &&
                                          item.erpProduct.auction_image[0]
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                item.erpProduct
                                                  .auction_image[0] + "!m640",
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : ele.attr_type > 0
                                ? _c("span", [
                                    item.erpProduct.attribute
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.erpProduct.attribute[
                                                ele.field
                                              ]
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : ele.field == "label"
                                ? _c("span", [
                                    item.erpProduct[ele.field]
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.erpProduct[ele.field].join(
                                                ","
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _c("span", [
                                    _vm._v(_vm._s(item.erpProduct[ele.field])),
                                  ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  }),
                ],
                2
              )
            }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "detail-list",
            staticStyle: {
              "margin-top": "38px",
              "font-weight": "bold",
              "font-size": "16px",
            },
          },
          [
            _c(
              "div",
              [
                _vm._v("\n      出库人：\n      "),
                _vm.detailjson.outAdmin
                  ? [_vm._v(_vm._s(_vm.detailjson.outAdmin.real_name))]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v("\n      调出库房：\n      "),
                _vm.detailjson.outWarehouse
                  ? [_vm._v(_vm._s(_vm.detailjson.outWarehouse.name))]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v("\n      入库人：\n      "),
                _vm.detailjson.enterAdmin
                  ? [_vm._v(_vm._s(_vm.detailjson.enterAdmin.real_name))]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v("\n      调入库房：\n      "),
                _vm.detailjson.enterWarehouse
                  ? [_vm._v(_vm._s(_vm.detailjson.enterWarehouse.name))]
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "detail-list" }, [
          _c("div", [_vm._v("单据编号：" + _vm._s(_vm.detailjson.order_no))]),
          _vm._v(" "),
          _c("div", [
            _vm._v("制单时间：" + _vm._s(_vm.detailjson.create_time)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._v("制单人："),
              _vm.detailjson.createAdmin
                ? [_vm._v(_vm._s(_vm.detailjson.createAdmin.real_name))]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", [_vm._v("审批人：")]),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }