"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.split");
var _user = require("@/api/user.js");
var _warehouse = require("@/api/warehouse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    detailjson: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      merData: {},
      showFiled: [],
      attrItem: ['190', '198', '203', '204', '209', '210', '211', '212', '213', '214', '215', '232', '233', '234', '235', '236', '237', '238', '239', '240', '241'],
      isNo: true,
      result: []
    };
  },
  filters: {
    nameFirter: function nameFirter(str) {
      var arr = str.split('|');
      if (arr.length > 1) return arr[0];else return str;
    },
    englis: function englis(str) {
      var arr = str.split('|');
      if (arr.length > 1) return arr[1];else return ' ';
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
    (0, _warehouse.getFieldSetAPI)({
      types: 'erp_v2_transfers_warehouse_print'
    }).then(function (res) {
      _this.showFiled = res.data.erp_v2_transfers_warehouse_print.show_list;
    });
  },
  watch: {
    detailjson: {
      handler: function handler(val) {
        var _this2 = this;
        this.isNo = true;
        setTimeout(function () {
          _this2.compendHeight();
        }, 1000);
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    compendHeight: function compendHeight() {
      var _this3 = this;
      var num = 0;
      var dom = document.getElementsByClassName('calcul');
      var elements = Array.from(dom);

      // 基于960宽度计算A4纸张尺寸
      var A4_WIDTH_BASE = 960; // 基准宽度
      var A4_RATIO = 297 / 210; // A4纸高宽比
      var A4_HEIGHT_PX = Math.round(A4_WIDTH_BASE * A4_RATIO); // 约1360px

      // 页面边距
      var MARGIN_TOP = 80; // 页眉空间
      var MARGIN_BOTTOM = 80; // 页脚空间

      // 实际可用内容区域高度
      var CONTENT_HEIGHT = A4_HEIGHT_PX - MARGIN_TOP - MARGIN_BOTTOM;

      // 计算每个元素的高度百分比
      var elementHeights = elements.map(function (el) {
        var height = Number(window.getComputedStyle(el).height.replace('px', ''));
        return height / CONTENT_HEIGHT;
      });

      // 按页面分组
      var pages = [];
      var currentPage = [];
      var currentHeight = 0;
      elementHeights.forEach(function (height, index) {
        if (currentHeight + height > 1) {
          // 当前页已满,创建新页
          pages.push(currentPage);
          currentPage = [index];
          currentHeight = height;
        } else {
          currentPage.push(index);
          currentHeight += height;
        }
      });

      // 添加最后一页
      if (currentPage.length > 0) {
        pages.push(currentPage);
      }

      // 根据页面分组重组数据
      this.result = pages.map(function (pageIndexes) {
        return pageIndexes.map(function (index) {
          return _this3.detailjson.warehouseOrderProduct[index];
        });
      });
      this.isNo = false;
      console.log('分页结果:', {
        totalPages: pages.length,
        itemsPerPage: pages.map(function (p) {
          return p.length;
        })
      });
    }
  }
};