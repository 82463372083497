var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "960px",
        visible: _vm.visible,
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c(
          "div",
          { staticClass: "poster-dialog" },
          [
            _vm.tempId
              ? [
                  _vm._v(
                    _vm._s(_vm.templateData ? _vm.templateData.title : "") +
                      " 做同款"
                  ),
                ]
              : [_vm._v("生成需求")],
            _vm._v(" "),
            _c("img", {
              staticClass: "posterImg",
              attrs: {
                src: require("@/assets/images/poster/close.png"),
                alt: "",
              },
              on: { click: _vm.onClose },
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.saveLoading,
              expression: "saveLoading",
            },
          ],
          staticStyle: { "padding-bottom": "20px" },
        },
        [
          _c(
            "div",
            { staticClass: "poster-content" },
            [
              _c("div", { staticClass: "poster-formtit" }, [
                _vm._v("公众号文章类型"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "poster-type-container" },
                _vm._l(_vm.temTypeList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "poster-type-item",
                      class:
                        _vm.formValidate.template_tag == index
                          ? "type-active"
                          : "",
                      style: _vm.tempId ? "color: #ccc;" : "",
                      on: {
                        click: function ($event) {
                          return _vm.onChangeType(index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "poster-formtit" }, [
                _vm._v("选择数据"),
              ]),
              _vm._v(" "),
              _vm._l(_vm.formValidate.demand_data, function (ele, ind) {
                return _c(
                  "div",
                  { key: ind, staticClass: "poster-flex" },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { flex: "1" },
                        attrs: {
                          placeholder: "请选择拍卖会",
                          filterable: "",
                          remote: "",
                          "remote-method": function (e) {
                            return _vm.venueInit(e, ind)
                          },
                        },
                        on: {
                          change: function (e) {
                            return _vm.onVenueChange(e, ind)
                          },
                        },
                        model: {
                          value: ele.venue_id,
                          callback: function ($$v) {
                            _vm.$set(ele, "venue_id", $$v)
                          },
                          expression: "ele.venue_id",
                        },
                      },
                      _vm._l(ele.venueList, function (item, index) {
                        return _c("el-option", {
                          key: item.venue_id,
                          attrs: { label: item.title, value: item.venue_id },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticStyle: { flex: "1", "margin-left": "16px" },
                        attrs: {
                          placeholder: "请选择专场",
                          filterable: "",
                          remote: "",
                          "remote-method": function (e) {
                            return _vm.albumInit(ele.venue_id, e, ind)
                          },
                        },
                        on: {
                          change: function (e) {
                            return _vm.onAlbumChange(ele.venue_id, e, ind)
                          },
                        },
                        model: {
                          value: ele.album_id,
                          callback: function ($$v) {
                            _vm.$set(ele, "album_id", $$v)
                          },
                          expression: "ele.album_id",
                        },
                      },
                      _vm._l(ele.albumList, function (item, index) {
                        return _c("el-option", {
                          key: item.album_id,
                          attrs: {
                            label: item.album_name,
                            value: item.album_id,
                          },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "change-auction",
                        on: {
                          click: function ($event) {
                            return _vm.handleAdditem(ele, ind)
                          },
                        },
                      },
                      [
                        ele.auction_ids && ele.auction_ids.length
                          ? _c("span", [
                              _vm._v(
                                "已选择" + _vm._s(ele.auction_ids.length) + "件"
                              ),
                            ])
                          : _c("span", { staticStyle: { color: "#c0c4cc" } }, [
                              _vm._v("点击选择拍品"),
                            ]),
                      ]
                    ),
                    _vm._v(" "),
                    ind == 0
                      ? _c("img", {
                          staticClass: "posterFlexImg",
                          attrs: {
                            src: require("@/assets/images/poster/jia.png"),
                            alt: "",
                          },
                          on: { click: _vm.onAdd },
                        })
                      : _c("img", {
                          staticClass: "posterFlexImg",
                          attrs: {
                            src: require("@/assets/images/poster/jian.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onRedu(ind)
                            },
                          },
                        }),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass: "flex-one",
                    staticStyle: { "margin-right": "16px" },
                  },
                  [
                    _c("div", { staticClass: "poster-formtit" }, [
                      _vm._v("文章风格"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "poster-type-container" },
                      _vm._l(_vm.templateStyle, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "poster-type-item",
                            class:
                              _vm.formValidate.template_style == index
                                ? "type-active"
                                : "",
                            style: _vm.tempId ? "color: #ccc;" : "",
                            on: {
                              click: function ($event) {
                                return _vm.onChangeStyle(index)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item))]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "poster-formtit" }, [
                _vm._v("模版类型"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "poster-type-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "poster-type-item",
                    class:
                      _vm.formValidate.template_level == 1 ? "type-active" : "",
                    style: _vm.tempId ? "color: #ccc;" : "",
                    on: {
                      click: function ($event) {
                        return _vm.onChangeQuality(1)
                      },
                    },
                  },
                  [_vm._v("普通")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "poster-type-item",
                    class:
                      _vm.formValidate.template_level == 2 ? "type-active" : "",
                    style: _vm.tempId ? "color: #ccc;" : "",
                    on: {
                      click: function ($event) {
                        return _vm.onChangeQuality(2)
                      },
                    },
                  },
                  [_vm._v("高级")]
                ),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "poster-foot" }, [
            _c("div", { staticClass: "poster-foot-text" }, [
              _vm._v("本次消耗 "),
              _c("img", {
                staticClass: "footImg",
                attrs: {
                  src: require("@/assets/images/poster/jifen.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", { staticStyle: { color: "#C8A259" } }, [
                _vm._v("100积分"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "poster-btn", on: { click: _vm.onNext } },
              [_vm._v("开始生成")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: {
          "has-more-data": "1",
          "all-image": "true",
          "current-data": [],
        },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }