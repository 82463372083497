"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _system = require("@/api/system");
var _basic = require("@/api/basic");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    draggable: _vuedraggable.default
  },
  props: {
    types: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  computed: {
    showHideList: function showHideList() {
      var _this = this;
      return this.hideList.filter(function (m) {
        return m.name.indexOf(_this.searchKeyword) != -1;
      });
    }
  },
  data: function data() {
    return {
      showconfig: false,
      showList: [],
      hideList: [],
      tempList: [],
      keyword: '',
      searchKeyword: '',
      showSetName: false,
      name: ''
    };
  },
  methods: {
    setData: function setData(dataTransfer) {
      dataTransfer.setData('Text', '');
    },
    changeheaderconfig: function changeheaderconfig() {
      var _this2 = this;
      this.showconfig = true;
      console.log(this.types);
      if (this.types == 'getExportFields') {
        (0, _basic.getExportFieldsApi)().then(function (res) {
          _this2.showList = [];
          _this2.hideList = res.data;
        });
        return false;
      }
      if (this.types == 'erp_v2_product_list') {
        this.loadTemplateList();
      }
      (0, _system.getCustomHeadder)({
        types: this.types
      }).then(function (res) {
        res.data.show_list.forEach(function (item) {
          item.select = true;
        });
        res.data.hide_list.forEach(function (item) {
          item.select = false;
        });
        _this2.showList = res.data.show_list;
        _this2.hideList = res.data.hide_list;
      });
    },
    loadTemplateList: function loadTemplateList() {
      var _this3 = this;
      (0, _system.fieldTemplateListApi)().then(function (res) {
        _this3.tempList = res.data.list;
      });
    },
    saveTemplate: function saveTemplate() {
      this.name = '';
      this.showSetName = true;
    },
    changeTemplateAction: function changeTemplateAction(template_id) {
      var _this4 = this;
      (0, _system.getCustomHeadder)({
        types: this.types,
        template_id: template_id
      }).then(function (res) {
        res.data.show_list.forEach(function (item) {
          item.select = true;
        });
        res.data.hide_list.forEach(function (item) {
          item.select = false;
        });
        _this4.showList = res.data.show_list;
        _this4.hideList = res.data.hide_list;
      });
    },
    delTemplateAction: function delTemplateAction(id) {
      var _this5 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u6B64\u6A21\u7248?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _system.fieldTemplateDelApi)({
          template_id: id
        }).then(function () {
          _this5.$message.success('删除成功');
          _this5.loadTemplateList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    saveAction: function saveAction() {
      var _this6 = this;
      if (!this.name) {
        this.$message.error('请输入模版名称');
        return false;
      }
      if (this.setLock) return false;
      this.setLock = true;
      (0, _system.fieldTemplateCreateApi)({
        template_name: this.name,
        types: 'erp_v2_product_list',
        show_data: this.showList,
        hide_data: this.hideList
      }).then(function (res) {
        _this6.$message.success('操作成功');
        _this6.showSetName = false;
        _this6.setLock = false;
        _this6.loadTemplateList();
      }).catch(function () {
        _this6.setLock = false;
      });
    },
    saveheadervonfig: function saveheadervonfig() {
      if (this.types == 'getExportFields') {
        this.$emit('column', this.showList);
        this.showconfig = false;
        return false;
      }
      (0, _system.setCustomHeader)({
        types: this.types,
        show_data: this.showList,
        hide_data: this.hideList
      }).then(function (res) {
        window.location.reload();
      });
    },
    changeselected: function changeselected(index, type) {
      var showList = JSON.parse(JSON.stringify(this.showList));
      var hideList = JSON.parse(JSON.stringify(this.hideList));
      var row = null;
      if (type == 'show') {
        row = showList[index];
        row.select = false;
        showList.splice(index, 1);
        hideList.push(row);
      } else {
        row = hideList[index];
        row.select = true;
        hideList.splice(index, 1);
        showList.push(row);
      }
      this.showList = showList;
      this.hideList = hideList;
    },
    searchAction: function searchAction() {
      this.searchKeyword = this.keyword;
    }
  }
};