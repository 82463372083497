"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancelRefundApi = cancelRefundApi;
exports.cancelsigncontract = cancelsigncontract;
exports.contactV2ProductListApi = contactV2ProductListApi;
exports.contractAuctionAuditApi = contractAuctionAuditApi;
exports.contractAuctionCreateApi = contractAuctionCreateApi;
exports.contractEntrustRefundApi = contractEntrustRefundApi;
exports.contractInvild = contractInvild;
exports.contractNoExistsApi = contractNoExistsApi;
exports.contractNoList = contractNoList;
exports.contractNoStatusGet = contractNoStatusGet;
exports.contractNodelete = contractNodelete;
exports.contractNoexport = contractNoexport;
exports.contractNoset = contractNoset;
exports.contractV2CancelAuditApi = contractV2CancelAuditApi;
exports.contractV2CreateApi = contractV2CreateApi;
exports.contractV2DetailApi = contractV2DetailApi;
exports.contractV2EditApi = contractV2EditApi;
exports.contractV2EditStatusApi = contractV2EditStatusApi;
exports.contractV2ListApi = contractV2ListApi;
exports.contractV2deleteApi = contractV2deleteApi;
exports.createCheckOrderApi = createCheckOrderApi;
exports.createCompositeCheckOrderApi = createCompositeCheckOrderApi;
exports.createEntrustCheckOrderPreReturnApi = createEntrustCheckOrderPreReturnApi;
exports.createEntrustCheckOrderReturnApi = createEntrustCheckOrderReturnApi;
exports.createExtractOrderApi = createExtractOrderApi;
exports.createcontract = createcontract;
exports.createinvforder = createinvforder;
exports.createmodal = createmodal;
exports.createreturnorder = createreturnorder;
exports.delcontract = delcontract;
exports.delmodal = delmodal;
exports.editContractInterNoApi = editContractInterNoApi;
exports.editcontract = editcontract;
exports.editcontractstatus = editcontractstatus;
exports.editmodal = editmodal;
exports.editmodalDetail = editmodalDetail;
exports.entrustRefundApi = entrustRefundApi;
exports.erpCheckUpdateApi = erpCheckUpdateApi;
exports.getCheckOrderDetailApi = getCheckOrderDetailApi;
exports.getCheckOrderListApi = getCheckOrderListApi;
exports.getDownloadContractApi = getDownloadContractApi;
exports.getExportTempplateApi = getExportTempplateApi;
exports.getLastAuctionApi = getLastAuctionApi;
exports.getNextProductNo = getNextProductNo;
exports.getcontractDetail = getcontractDetail;
exports.getcontractDownload = getcontractDownload;
exports.getcontractchart = getcontractchart;
exports.getcontractlist = getcontractlist;
exports.getfinnanceorder = getfinnanceorder;
exports.getinvfinnanceList = getinvfinnanceList;
exports.getmodalDetail = getmodalDetail;
exports.getreturnorderlist = getreturnorderlist;
exports.getreviewdetail = getreviewdetail;
exports.getrevivieworder = getrevivieworder;
exports.modalList = modalList;
exports.numberToRmbApi = numberToRmbApi;
exports.payinvforder = payinvforder;
exports.preEntrustRefundApi = preEntrustRefundApi;
exports.printProductOrderApi = printProductOrderApi;
exports.receiveinvforder = receiveinvforder;
exports.savePriceData = savePriceData;
exports.setappendix = setappendix;
exports.switchAuditRecordStatusApi = switchAuditRecordStatusApi;
exports.updateAuctionNowPriceApi = updateAuctionNowPriceApi;
exports.updateDeliveryApi = updateDeliveryApi;
exports.updateLogisticsApi = updateLogisticsApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * 合同模板列表
 */
function modalList(data) {
  return _request.default.get('erp/contract/template/lst', data);
}
/**
 * 创建合同模板
 */
function createmodal(data) {
  return _request.default.post("erp/contract/template/create", data);
}
/**
 * 修改合同模板
 */
function editmodal(id, data) {
  return _request.default.post("erp/contract/template/update/".concat(id), data);
}
/**
 * 保存价格
 */
function savePriceData(data) {
  return _request.default.post("erp/v2/contract/entrustCheckAuction/savePriceData", data);
}
/**
 * 修改落槌价
 */
function updateAuctionNowPriceApi(data) {
  return _request.default.post("erp/v2/contract/entrustCheckAuction/updateAuctionNowPrice", data);
}
/**
 * 删除合同模板
 */
function delmodal(id) {
  return _request.default.post("erp/contract/template/delete/".concat(id));
}
/**
 * 修改合同模板详情
 */
function editmodalDetail(id, data) {
  return _request.default.post("erp/contract/template/updateDetail/".concat(id), data);
}
/**
 * 获取合同模板详情
 */
function getmodalDetail(id) {
  return _request.default.get("erp/contract/template/detail/".concat(id));
}
/**
 * 获取合同列表
 */
function getcontractlist(data) {
  return _request.default.get('erp/contract/lst', data);
}
/**
 * 创建合同
 */
function createcontract(data) {
  return _request.default.post("erp/contract/create", data);
}
/**
 * 修改合同
 */
function editcontract(id, data) {
  return _request.default.post("erp/contract/update/".concat(id), data);
}
/**
 * 修改合同状态
 */
function editcontractstatus(id, data) {
  return _request.default.post("erp/contract/switchStatus/".concat(id), data);
}
/**
 * 删除合同
 */
function delcontract(id, data) {
  return _request.default.post("erp/contract/delete/".concat(id), data);
}
/**
 * 获取合同头部统计
 */
function getcontractchart(data) {
  return _request.default.get('erp/contract/chart', data);
}
/**
 * 获取合同详情
 */
function getcontractDetail(id) {
  return _request.default.get("erp/contract/detail/".concat(id));
}
/**
 * 获取合同下载地址
 */
function getcontractDownload(id) {
  return _request.default.get("erp/contract/getDownloadUrl/".concat(id));
}
/**
 * 取消签约合同
 */
function cancelsigncontract(id, data) {
  return _request.default.post("erp/contract/revokeSign/".concat(id), data);
}
/**
 * 修改附件模板
 */
function setappendix(id, data) {
  return _request.default.post("erp/contract/template/editAttachmentContent/".concat(id), data);
}
/**
 * 合同编号列表
 */
function contractNoList(data) {
  return _request.default.get('erp/contract/no/lst', data);
}
/**
 * 合同编号分配
 */
function contractNoset(data) {
  return _request.default.post('erp/contract/no/give', data);
}
/**
 * 合同编号作废
 */
function contractInvild(data) {
  return _request.default.post('erp/contract/no/invalid', data);
}
/**
 * 合同编号删除
 */
function contractNodelete(id) {
  return _request.default.post('erp/contract/no/delete/' + id);
}
/**
 * 合同编号头部列表
 */
function contractNoStatusGet(data) {
  return _request.default.get('erp/contract/no/chart', data);
}
/**
 * 导出合同编号列表
 */
function contractNoexport(data) {
  return _request.default.post('erp/contract/no/export', data);
}
/**
 * 获取对账单列表
 */
function getinvfinnanceList(data) {
  return _request.default.get('erp/entrust/order/check/lst', data);
}
/**
 * 创建委托单对账单
 */
function createinvforder(data) {
  return _request.default.post('erp/entrust/order/check/create', data);
}
/**
 * 委托单对账单付款
 */
function payinvforder(id, data) {
  return _request.default.post("erp/entrust/order/check/confirmPaymeny/".concat(id), data);
}
// 获取已对账列表
function getrevivieworder(data) {
  return _request.default.get("erp/entrust/order/check/list", data);
}
/**
 * 委托单对账单付款
 */
function receiveinvforder(id, data) {
  return _request.default.post("erp/entrust/order/check/confirmReceipt/".concat(id), data);
}
// 获取对账单详情
function getfinnanceorder(id, data) {
  return _request.default.get("erp/entrust/order/check/detail/".concat(id), data);
}
// 创建退回单
function createreturnorder(data) {
  return _request.default.post('erp/entrust/order/refund/create', data);
}
// 获取退单列表
function getreturnorderlist(data) {
  return _request.default.get('erp/entrust/order/refund/lst', data);
}
// 获取对账单详情
function getreviewdetail(id) {
  return _request.default.get("erp/entrust/order/check/getDetail/".concat(id));
}
// 获取对账单详情
function printProductOrderApi(data) {
  return _request.default.get("erp/v2/contract/entrustCheckAuction/printInfo", data);
  // return request.get(`erp/v2/contract/entrustCheckOrder/detail`, data);
}
// 人民币大写
function numberToRmbApi(data) {
  return _request.default.get("/numberToRmb", data);
}
// 修改erp
function erpCheckUpdateApi(data) {
  return _request.default.post("/erp/entrust/order/check/updateOne", data);
}

// 合同列表v2
function contractV2ListApi(data) {
  return _request.default.get("/erp/v2/contract/list", data);
}

// 合同货品列表
function contactV2ProductListApi(data) {
  return _request.default.get("/erp/v2/contract/product/list", data);
}

// 创建合同
function contractV2CreateApi(data) {
  return _request.default.post("/erp/v2/contract/create", data);
}

// 修改合同
function contractV2EditApi(id, data) {
  return _request.default.post("/erp/v2/contract/update/".concat(id), data);
}

// 删除合同
function contractV2deleteApi(id, data) {
  return _request.default.post("/erp/v2/contract/delete/".concat(id), data);
}

// 修改合同状态
function contractV2EditStatusApi(id, data) {
  return _request.default.post("/erp/v2/contract/switchStatus/".concat(id), data);
}
// 撤销审核
function contractV2CancelAuditApi(id, data) {
  return _request.default.post("/erp/v2/contract/revokeApproval/".concat(id), data);
}

// 获取合同详情
function contractV2DetailApi(id) {
  return _request.default.get("/erp/v2/contract/detail/".concat(id));
}

// 获取导入模板
function getExportTempplateApi() {
  return _request.default.get("/erp/v2/contract/product/downloadImportTemplate");
}
// 下载合同
function getDownloadContractApi(id) {
  return _request.default.get("/erp/v2/contract/getDownloadUrl/".concat(id));
}
// 获取对账单
function getCheckOrderListApi(data) {
  return _request.default.get("erp/v2/contract/entrustCheckAuction/list", data);
}
// 获取对账单详情
function getCheckOrderDetailApi(data) {
  return _request.default.get("erp/v2/contract/entrustCheckOrder/detail", data);
}
// 获取最近拍卖会列表
function getLastAuctionApi(data) {
  return _request.default.get("auction/venue/searchList", data);
}

// 提交委托对账单
function createCheckOrderApi(data) {
  return _request.default.post("erp/v2/contract/entrustCheckOrder/submit", data);
}
// 复合对账单
function createCompositeCheckOrderApi(data) {
  return _request.default.post("erp/v2/contract/entrustCheckOrder/twoSubmit", data);
}
// 修改物流
function updateLogisticsApi(data) {
  return _request.default.post("erp/v2/contract/entrustCheckAuction/updateLogistics", data);
}

// 委托对账单预退回
function createEntrustCheckOrderPreReturnApi(data) {
  return _request.default.post("erp/v2/contract/entrustCheckAuction/preEntrustRefund", data);
}

// 委托标的退回
function createEntrustCheckOrderReturnApi(data) {
  return _request.default.post("erp/v2/contract/entrustCheckAuction/entrustRefund", data);
}

// 合同对账-创建付款单
function createExtractOrderApi(data) {
  return _request.default.post("erp/v2/contract/entrustCheckAuction/createExtractOrder", data);
}

// 
function contractNoExistsApi(data) {
  return _request.default.post("erp/v2/contract/contractNoExists", data);
}
function getNextProductNo(data) {
  return _request.default.get("erp/v2/product/getNextProductNo", data);
}
function cancelRefundApi(data) {
  return _request.default.post("erp/v2/contract/entrustCheckAuction/cancelRefund", data);
}
function editContractInterNoApi(data) {
  return _request.default.post("erp/v2/contract/editContractInterNo", data);
}
function contractAuctionAuditApi(data) {
  return _request.default.get("erp/v2/contract/auctionAudit", data);
}
function contractAuctionCreateApi(data) {
  return _request.default.post("erp/v2/contract/auctionAudit/create", data);
}

// 拍后退货
function contractEntrustRefundApi(data) {
  return _request.default.get("erp/v2/contract/entrustRefund/list", data);
}

// 拍后退货 - 修改物流
function updateDeliveryApi(data) {
  return _request.default.post("erp/v2/contract/entrustRefund/updateDelivery", data);
}

// 拍后退货 - 预创建出库单
function preEntrustRefundApi(data) {
  return _request.default.post("erp/v2/contract/entrustRefund/preEntrustRefund", data);
}

// 拍后退货 - 创建出库单
function entrustRefundApi(data) {
  return _request.default.post("erp/v2/contract/entrustRefund/entrustRefund", data);
}

// 合同 切换审核状态
function switchAuditRecordStatusApi(data) {
  return _request.default.post("erp/v2/contract/switchAuditRecordStatus", data);
}