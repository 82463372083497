"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _basic = require("@/api/basic");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      showEdit: false,
      formValidate: {
        avatar: '',
        name: '',
        sex: '男',
        birth_year: '',
        native: '',
        intro: '',
        english_descipt: ''
      },
      saveLoading: false
    };
  },
  methods: {
    changeaddgoods: function changeaddgoods() {
      this.formValidate = {
        avatar: '',
        name: '',
        sex: '男',
        birth_year: '',
        native: '',
        intro: '',
        english_descipt: ''
      };
      this.showEdit = true;
    },
    editgoods: function editgoods(row) {
      this.formValidate = {
        id: row.id,
        avatar: row.avatar,
        name: row.name,
        sex: row.sex,
        birth_year: row.birth_year,
        native: row.native,
        intro: row.intro,
        english_descipt: row.extend_data ? row.extend_data.english_descipt : ''
      };
      this.showEdit = true;
    },
    saveAction: function saveAction() {
      var _this2 = this;
      if (!this.formValidate.name) {
        return this.$message.error('请输入作者名称');
      }
      if (this.saveLoading) return;
      this.saveLoading = true;
      var action = _basic.artistCreateApi;
      if (this.formValidate.id) {
        action = _basic.artistUpdateApi;
      }
      action(this.formValidate).then(function (res) {
        _this2.$message.success('操作成功');
        _this2.showEdit = false;
        _this2.saveLoading = false;
        _this2.$emit('saveAction');
      }).catch(function () {
        _this2.saveLoading = false;
      });
    },
    handleRemove: function handleRemove(key) {
      this.formValidate[key] = '';
    },
    // 点击上传头像图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit === '1' && !num) {
          _this.formValidate.avatar = img[0];
        }
      }, '1');
    }
  }
};