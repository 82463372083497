var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "财务管理" },
        scopedSlots: _vm._u([
          {
            key: "btn",
            fn: function () {
              return [
                _c(
                  "el-dropdown",
                  { attrs: { type: "primary", placement: "bottom" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        staticStyle: { color: "#3480F4", cursor: "pointer" },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "16px",
                            height: "16px",
                            "margin-right": "5px",
                          },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/202464/image/1717490887632187719.png",
                            alt: "",
                          },
                        }),
                        _vm._v("\n          配置\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOUrl(
                                    "/merchant/systemForm/systemBank/list"
                                  )
                                },
                              },
                            },
                            [_vm._v("收款账号")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOUrl(
                                    "/merchant/accounts/reconcCon"
                                  )
                                },
                              },
                            },
                            [_vm._v("委托方对账配置")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticStyle: { height: "auto" } },
        [
          _c(
            "div",
            { staticClass: "top-view", staticStyle: { padding: "0 20px" } },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo default-tabs mt10",
                  attrs: {
                    "default-active": _vm.routerUrl,
                    mode: "horizontal",
                  },
                  on: { select: _vm.changeTab },
                },
                [
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/accounts/wallet/finance" } },
                    [_vm._v("买家账户")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/accounts/wallet/receipt" } },
                    [_vm._v("收款单")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/accounts/wallet/payment" } },
                    [_vm._v("买家退款")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/accounts/wallet/invoice" } },
                    [_vm._v("发票管理")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index: "/merchant/accounts/wallet/clientAwaiting",
                      },
                    },
                    [_vm._v("委托方待对账")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/accounts/wallet/clientEnd" } },
                    [_vm._v("委托方已对账")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index: "/merchant/accounts/wallet/clientSettlement",
                      },
                    },
                    [_vm._v("委托方付款单")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key, ref: "userChild" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }