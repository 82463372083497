var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: _vm.collectMoney.data.type == 1 ? "填写快递单" : "创建出库单",
        width: "70%",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _vm.collectMoney.data.type == 2
        ? [
            _c("div", { staticClass: "flex align-items-c mt20" }, [
              _c("div", { staticClass: "flex-one" }, [
                _vm._v(
                  "仓库：" +
                    _vm._s(
                      _vm.collectMoney.data.warehouse_info
                        ? _vm.collectMoney.data.warehouse_info.name
                        : ""
                    )
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _vm._v(
                  "货架：" +
                    _vm._s(
                      _vm.collectMoney.data.warehouse_info
                        ? _vm.collectMoney.data.warehouse_info.warehouse_no
                        : ""
                    )
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("出库时间：" + _vm._s(_vm.nowDate)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("单据编号：" + _vm._s(_vm.collectMoney.data.bill_no)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex align-items-c mt20" }, [
              _c(
                "div",
                { staticClass: "flex-one" },
                [
                  _vm.collectMoney.data.user_info
                    ? [
                        _vm._v(
                          "客户：" +
                            _vm._s(
                              _vm.collectMoney.data.user_info.real_name ||
                                _vm.collectMoney.data.user_info.nickname
                            )
                        ),
                        _vm.collectMoney.data.user_info.real_name
                          ? _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm.collectMoney.data.user_info.nickname
                                  ) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("出库类型：拍卖成交出库"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }),
            ]),
          ]
        : _vm.collectMoney.data.type == 1
        ? [
            _c("div", { staticClass: "flex align-items-c mt20" }, [
              _c(
                "div",
                { staticClass: "flex-one" },
                [
                  _vm.collectMoney.data.user_info
                    ? [
                        _vm._v(
                          "买家：" +
                            _vm._s(
                              _vm.collectMoney.data.user_info.real_name ||
                                _vm.collectMoney.data.user_info.nickname
                            )
                        ),
                        _vm.collectMoney.data.user_info.real_name
                          ? _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm.collectMoney.data.user_info.nickname
                                  ) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _vm._v("操作人：" + _vm._s(_vm.collectMoney.data.admin_name)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.collectMoney.data.type == 3
        ? _vm._l(_vm.collectMoney.data.orderList, function (m, i) {
            return _c(
              "div",
              { key: i },
              [
                _c("div", { staticClass: "flex align-items-c mt20" }, [
                  _c("div", { staticClass: "flex-one" }, [
                    _vm._v(
                      "仓库：" +
                        _vm._s(m.warehouseInfo ? m.warehouseInfo.name : "")
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one" }, [
                    _vm._v(
                      "货架：" +
                        _vm._s(
                          m.warehouseInfo ? m.warehouseInfo.warehouse_no : ""
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one" }, [
                    _vm._v(
                      "制单人：" +
                        _vm._s(m.adminInfo ? m.adminInfo.real_name : "")
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one" }, [
                    _vm._v("出库时间：" + _vm._s(_vm.nowDate)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticClass: "collect-money mt20",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: m.productData,
                      size: "mini",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("template", { slot: "empty" }, [
                      _c("div", { staticClass: "tableEmpty" }, [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "tableDiv" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "图片",
                        "min-width": "50",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "item-flex-center" }, [
                                  scope.row.cart_info &&
                                  scope.row.cart_info.product
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "40px",
                                          height: "40px",
                                          display: "block",
                                        },
                                        attrs: {
                                          src:
                                            scope.row.cart_info.product.image +
                                            "!120a",
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "库房编号", "min-width": "140" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.erpProduct.product_no)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "LOT", "min-width": "60" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.erpProduct
                                  ? _c("div", [
                                      _vm._v(_vm._s(scope.row.erpProduct.lot)),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "货品名称", "min-width": "140" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.erpProduct
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.erpProduct.auction_name
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "货架", "min-width": "80", prop: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      scope.row.erpProduct.shelf
                                        ? scope.row.erpProduct.shelf.shelf_no
                                        : ""
                                    ) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _vm.delivery_type == 1
                      ? _c("el-table-column", {
                          attrs: {
                            label: "保价金额",
                            "min-width": "80",
                            prop: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          scope.row.delivery_insured_price
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "用户备注", "min-width": "80", prop: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.user_remark) +
                                    "\n          "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          })
        : _vm.collectMoney.show &&
          _vm.collectMoney.data &&
          _vm.collectMoney.data.orderList
        ? [
            _c(
              "el-table",
              {
                staticClass: "collect-money mt20",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.collectMoney.data.orderList,
                  size: "mini",
                  "highlight-current-row": "",
                },
              },
              [
                _c("template", { slot: "empty" }, [
                  _c("div", { staticClass: "tableEmpty" }, [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tableDiv" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "图片", "min-width": "50", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "item-flex-center" }, [
                            _c("img", {
                              staticStyle: {
                                width: "40px",
                                height: "40px",
                                display: "block",
                              },
                              attrs: {
                                src: scope.row.auction_info.image + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "LOT", "min-width": "60" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(scope.row.auction_info.lot)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "货品名称", "min-width": "140" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(scope.row.auction_info.store_name)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "拍卖会-专场", "min-width": "140" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(scope.row.auction_info.venue_name) +
                                " - " +
                                _vm._s(scope.row.auction_info.album_name)
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "作者", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                scope.row.auction_info.brand
                                  ? scope.row.auction_info.brand.brand_name
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "运费", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.formatPriceNumber(
                                  scope.row.total_postage,
                                  2
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "属性", "min-width": "140" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(scope.row.auction_info.attr_name)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "deduct mt20" }, [
        _c("div", { staticClass: "item item-align-center" }, [
          _c(
            "div",
            { staticClass: "td", staticStyle: { width: "300px" } },
            [
              _vm._v("收件人：\n        "),
              _c("el-input", {
                staticClass: "w200",
                model: {
                  value: _vm.address.real_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.address, "real_name", $$v)
                  },
                  expression: "address.real_name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "td", staticStyle: { width: "300px" } },
            [
              _vm._v("收件人电话：\n        "),
              _c("el-input", {
                staticClass: "w200",
                model: {
                  value: _vm.address.user_phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.address, "user_phone", $$v)
                  },
                  expression: "address.user_phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "td1", staticStyle: { "padding-right": "0" } },
            [
              _vm._v("收件人地址：\n        "),
              _c("el-input", {
                staticClass: "flex-one",
                model: {
                  value: _vm.address.user_address,
                  callback: function ($$v) {
                    _vm.$set(_vm.address, "user_address", $$v)
                  },
                  expression: "address.user_address",
                },
              }),
              _vm._v(" "),
              _vm.link_a
                ? _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "text", size: "mini" },
                      on: { click: _vm.getAddress },
                    },
                    [_vm._v("获取地址")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item item-align-center" },
          [
            _c(
              "div",
              { staticClass: "td", staticStyle: { width: "300px" } },
              [
                _vm._v("运输方式：\n        "),
                _c(
                  "el-select",
                  {
                    model: {
                      value: _vm.delivery_type,
                      callback: function ($$v) {
                        _vm.delivery_type = $$v
                      },
                      expression: "delivery_type",
                    },
                  },
                  [
                    _c(
                      "el-option",
                      { attrs: { value: 1, label: "快递配送" } },
                      [_vm._v("快递配送")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-option",
                      { attrs: { value: 2, label: "买家自提" } },
                      [_vm._v("买家自提")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-option",
                      { attrs: { value: 3, label: "他人提货" } },
                      [_vm._v("他人提货")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-option",
                      { attrs: { value: 4, label: "员工送货" } },
                      [_vm._v("员工送货")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.delivery_type == 1 &&
            (_vm.collectMoney.data.type === 1 ||
              _vm.collectMoney.data.type === 3)
              ? [
                  _c(
                    "div",
                    { staticClass: "td", staticStyle: { width: "300px" } },
                    [
                      _vm._v("\n          物流公司：\n          "),
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.delivery_name,
                            callback: function ($$v) {
                              _vm.delivery_name = $$v
                            },
                            expression: "delivery_name",
                          },
                        },
                        _vm._l(_vm.expressList, function (m, i) {
                          return _c("el-option", {
                            key: i,
                            attrs: { value: m.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "td1", staticStyle: { padding: "5px 0" } },
                    [
                      _vm._v("运输单号：\n          "),
                      _c("el-input", {
                        staticClass: "w200",
                        model: {
                          value: _vm.delivery_id,
                          callback: function ($$v) {
                            _vm.delivery_id = $$v
                          },
                          expression: "delivery_id",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm.delivery_type == 4
              ? [
                  _c(
                    "div",
                    { staticClass: "td", staticStyle: { width: "300px" } },
                    [
                      _vm._v("\n          员工姓名：\n          "),
                      _c(
                        "el-select",
                        {
                          staticClass: "w200",
                          attrs: { placeholder: "请选择员工", filterable: "" },
                          model: {
                            value: _vm.delivery_name,
                            callback: function ($$v) {
                              _vm.delivery_name = $$v
                            },
                            expression: "delivery_name",
                          },
                        },
                        _vm._l(_vm.adminarr, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.real_name,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _vm.collectMoney.data.type === 3 &&
        _vm.reductjson &&
        _vm.reductjson.erp_v2_out_warehouse == 1
          ? _c("div", { staticClass: "item item-align-center" }, [
              _c(
                "div",
                { staticClass: "td", staticStyle: { width: "300px" } },
                [
                  _vm._v("审批人：\n        "),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择审批人",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.reviewAduitId,
                        callback: function ($$v) {
                          _vm.reviewAduitId = $$v
                        },
                        expression: "reviewAduitId",
                      },
                    },
                    _vm._l(_vm.reductjson.adminData, function (item) {
                      return _c("el-option", {
                        key: item.merchant_admin_id,
                        attrs: {
                          label: item.real_name,
                          value: item.merchant_admin_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "mt20",
          attrs: { type: "primary" },
          on: { click: _vm.payAction },
        },
        [
          _vm.collectMoney.data.type === 2 || _vm.collectMoney.data.type === 3
            ? [_vm._v("确认创建出库单")]
            : _vm.delivery_type === 1
            ? [_vm._v("确认发货")]
            : _vm.delivery_type === 2
            ? [_vm._v("确认自提")]
            : _vm.delivery_type === 3
            ? [_vm._v("确认自提")]
            : [_vm._v("确认操作")],
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "40px" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }