"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.replace");
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _objectSpread3 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _basic = require("@/api/basic");
var _warehouse = require("@/api/warehouse");
var _product = require("@/api/product");
var _setting = require("@/api/setting");
var _user = require("@/api/user");
var _system = require("@/api/system");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Createpushorder',
  data: function data() {
    return {
      loading: false,
      listLoading: false,
      rules: {
        warehouse_id: [{
          required: true,
          message: '请选择仓库ID',
          trigger: 'change'
        }],
        manage_admin_id: [{
          required: false,
          message: '请选择业务负责人',
          trigger: 'change'
        }],
        push_type: [{
          required: false,
          message: '请选择入库类型',
          trigger: 'change'
        }]
      },
      formValidate: {
        warehouse_id: '',
        manage_admin_id: '',
        push_type: ''
      },
      goodsData: [],
      tableFrom: {
        page: 1,
        limit: 10,
        contract_status: 4,
        product_warehouse_status: 0
      },
      tableData: {
        data: [],
        total: 0
      },
      id: this.$route.query.id || '',
      storeArr: [],
      adminarr: [],
      BrandList: [],
      attrList: [],
      attr: [],
      lockid: '',
      locktype: '',
      shelfList: [{
        shelf_id: 0,
        shelf_no: '请选择货架'
      }
      // {
      //   shelf_id: "0",
      //   shelf_no: "请选择货架",
      // },
      ],
      orderno: '',
      mannagename: '',
      goodsIdArr: [],
      enterGoodsList: [],
      labelarr: [],
      searchLabelarr: [],
      attributeList: [],
      pushheaderList: [],
      pushHideList: [],
      searchQuery: {},
      userList: [],
      reductjson: {
        erp_v2_enter_warehouse: '0',
        is_approval_admin: 0,
        adminData: []
      },
      reviewAduitId: '',
      removeList: [],
      categoryList: [],
      salesmanAdminId: '',
      erpsettingList: [],
      showVisible: false,
      formArr: [],
      formState: {},
      addNum: 1,
      handName: '',
      headObj: {},
      categoryLabelProp: {
        multiple: true,
        emitPath: false,
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'labelData'
      },
      categoryLabel: []
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    (0, _setting.getApprovalApi)({
      type: 'erp_v2_enter_warehouse'
    }).then(function (res) {
      console.log(res);
      _this2.reductjson = res.data;
    });
    (0, _setting.adminListApi)({
      page: 1,
      limit: 50
    }).then(function (res) {
      // console.log(res);
      _this2.userList = res.data.list.map(function (item) {
        return {
          label: item.real_name,
          value: item.merchant_admin_id
        };
      });
    });
    this.getCategoryLabelSelect();
    (0, _warehouse.getFieldSetAPI)({
      types: 'erp_v2_enter_warehouse_create,erp_v2_contract_create_product'
    }).then(function (res) {
      if (res.data.erp_v2_enter_warehouse_create) {
        res.data.erp_v2_enter_warehouse_create.show_list.forEach(function (item) {
          if (/^\d*$/.test(item.field)) {
            item.newtype = 'attribute';
          }
        });
        _this2.pushheaderList = res.data.erp_v2_enter_warehouse_create.show_list;
        _this2.pushHideList = res.data.erp_v2_enter_warehouse_create.hide_list;
        // console.log(this.pushheaderList);
      }
      if (res.data.erp_v2_contract_create_product) {
        res.data.erp_v2_contract_create_product.show_list.forEach(function (item) {
          if (item.attr_type > 0) {
            // if(/^\d*$/.test(item.field)){
            item.newtype = 'attribute';
          }
        });
        _this2.erpsettingList = res.data.erp_v2_contract_create_product.show_list;
      }
    });
    // getOrderNo().then(res=>{
    //   this.orderno = res.data.order_no
    // })
    // this.getstore();
    // this.getadminList();
    // this.getBrandListApi();
    (0, _product.getStoreList)().then(function (r) {
      _this2.storeArr = r.data.list;
      if (_this2.$route.query.id) {
        _this2.id = _this2.$route.query.id;
        _this2.tab = 'goods';
        _this2.getInfoAction();
      } else {
        (0, _warehouse.getLastWarehouseIdApi)({
          order_type: 1
        }).then(function (res) {
          _this2.formValidate.warehouse_id = res.data.warehouse_id;
          _this2.pushshelf();
        });
        // getBaseInfo().then(res => {
        //   getStoreList({ manage_admin_id: res.data.merchantAdmin.merchant_admin_id, page: 1, limit: 999999 }).then(result => {
        //     if (result.data.list.length>0) {
        //       this.formValidate.warehouse_id = result.data.list[0].warehouse_id
        //     }else{
        //       this.formValidate.warehouse_id = r.data.list[0].warehouse_id
        //     }
        //     this.pushshelf();
        //   })
        // })
      }
    });

    // .catch(({ message }) => {
    //   //
    // });
    this.getList();
    this.getlabel();
    (0, _system.gettableheader)({
      types: 'store_attribute'
    }).then(function (res) {
      _this2.attributeList = res.data;
    }).catch(function (res) {
      // this.$message.error(res.message);
    });
    // this.getinvent();
  },
  created: function created() {
    var _this3 = this;
    (0, _product.categorySelectApi)().then(function (res) {
      _this3.categoryList = res.data;
      console.log(res);
    });
  },
  methods: {
    delImgAction: function delImgAction(i, index) {
      this.enterGoodsList[i].slider_image.splice(index, 1);
    },
    changeCateAction: function changeCateAction(val, index) {
      if (this.enterGoodsList[index] && val && val.length) {
        this.enterGoodsList[index].cate_id = val[val.length - 1];
      }
    },
    focus: function focus(e) {
      e.currentTarget.select();
    },
    changeColumnWidth: function changeColumnWidth(newWidth, oldWidth, column) {
      // console.log(column);
      var index = column.index;
      this.pushheaderList[index].width = newWidth;
      (0, _system.setCustomHeader)({
        types: 'erp_v2_enter_warehouse_create',
        show_data: this.pushheaderList,
        hide_data: this.pushHideList
      }).then(function (res) {}).catch(function (res) {});
      // this.columnWidthArr[index] = newWidth;
      // this.setColumnsWidth();
    },
    getlabel: function getlabel() {
      var _this4 = this;
      (0, _basic.labelLstApi)({
        page: 1,
        limit: 50
      }).then(function (res) {
        _this4.labelarr = res.data.list;
        _this4.searchLabelarr = res.data.list.map(function (m) {
          return {
            value: m.label_id,
            label: m.label_name
          };
        });
        var json = {};
        res.data.list.forEach(function (item) {
          json[item.label_id] = item.label_name;
        });
        _this4.labeljson = json;
      }).catch(function (res) {
        // this.$message.error(res.message)
      });
    },
    // 商户标签分类；
    getCategoryLabelSelect: function getCategoryLabelSelect() {
      var _this5 = this;
      (0, _basic.labelCategoryListApi)().then(function (res) {
        _this5.categoryLabel = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    onSelectFilterChange: function onSelectFilterChange(val) {
      var _this6 = this;
      (0, _basic.labelLstApi)({
        page: 1,
        limit: 50,
        label_name: val
      }).then(function (res) {
        res.data.list.forEach(function (item) {
          item.label = item.label_name;
          item.value = item.label_id + '';
        });
        _this6.searchLabelarr = res.data.list;
      });
    },
    getInfoAction: function getInfoAction() {
      var _this7 = this;
      (0, _warehouse.getwarehouseOrderDetailApiV2)(this.id).then(function (res) {
        console.log(1, res.data);
        // this.formValidate.manage_admin_id = res.data.manage_admin_id;
        _this7.formValidate.warehouse_id = res.data.enterWarehouse.warehouse_id;
        _this7.$set(_this7.formValidate, 'remark', res.data.remark);
        _this7.$set(_this7.formValidate, 'enter_type', res.data.enter_type);
        if (res.data.enter_type == 'enter_warehouse_temp') {
          _this7.salesmanAdminId = res.data.salesman_admin_id;
        }
        // this.formValidate.push_type = res.data.type;
        _this7.pushshelf();
        res.data.warehouseOrderProduct.forEach(function (m) {
          m = (0, _objectSpread3.default)((0, _objectSpread3.default)((0, _objectSpread3.default)({}, m.erpProduct), m), {}, {
            label_ids: m.erpProduct.label_ids ? m.erpProduct.label_ids.split(',').map(function (item) {
              return Number(item);
            }) : [],
            id: m.erp_product_id
          });
          // m.auction_name = m.erpProduct.auction_name;
          // m.product_name = m.erpProduct.product_name;
          // m.image = m.erpProduct.image;
          // m.product_no = m.erpProduct.product_no;
          // m.label_ids = m.erpProduct.label_ids?m.erpProduct.label_ids.split(','):[];
          // m.attribute = m.erpProduct.attribute;
          // m.id = m.erp_product_id;
          m.edit = true;
          console.log('----', m);
          _this7.saleGoods(m, 'goods', '');
        });
      }).catch(function () {});
    },
    close: function close() {
      this.$router.back();
    },
    pushshelf: function pushshelf() {
      var _this8 = this;
      this.formValidate.push_warehouse_id = this.formValidate.warehouse_id;
      (0, _basic.shelfList)({
        warehouse_id: this.formValidate.push_warehouse_id,
        page: 1,
        limit: 200
      }).then(function (res) {
        res.data.list.unshift({
          shelf_id: 0,
          shelf_no: '请选择货架'
        });
        _this8.shelfList = res.data.list;
      });
    },
    leavetab: function leavetab() {
      if (this.locktype) {
        this.tab = this.locktype;
        return false;
      }
    },
    changetab: function changetab(e) {
      if (this.locktype && e.name != this.locktype) {
        return;
      }
      this.tab = e.name;
    },
    // 品牌筛选；
    getBrandListApi: function getBrandListApi() {
      var _this9 = this;
      brandListApi().then(function (res) {
        _this9.BrandList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getadminList: function getadminList() {
      var _this10 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this10.adminarr = res.data.list;
      });
    },
    // getstore() {
    // },
    submitForm: function submitForm(formName) {
      var _this11 = this;
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      this.loading = true;
      if (!this.enterGoodsList.length) {
        this.loading = false;
        return this.$message.error('请选择货品');
      }
      var json = {};
      json.enter_id = this.formValidate.warehouse_id;
      json.enter_type = this.formValidate.enter_type;
      json.remark = this.formValidate.remark;
      var error = '';
      json.warehouseProduct = this.enterGoodsList.map(function (item) {
        if (!item.product_name && !item.id) {
          error = 1;
        }
        return {
          erp_product_id: item.id,
          product_no: item.product_no,
          order_num: item.order_num,
          shelf_id: item.shelf_id,
          warehouse_image: item.slider_image.join(','),
          // slider_image: [item.image],
          product_name: item.product_name,
          author_name: item.author_name || '',
          label_ids: item.label_ids.join(','),
          attribute: item.attribute,
          cate_id: item.cate_id
        };
      });
      if (error) {
        this.$message.error('请输入初始名称');
        this.loading = false;
        return false;
      }
      if (this.reductjson.erp_v2_enter_warehouse == 1) {
        json.audit_admin_id = this.reviewAduitId;
      }
      if (this.formValidate.enter_type == 'enter_warehouse_temp') {
        json.salesman_admin_id = this.salesmanAdminId;
      }
      if (this.id) {
        (0, _warehouse.warehouseDetailUpdateApi)(this.id, json).then(function (res) {
          _this11.loading = false;
          if (res.status == 200) {
            _this11.$message.success('保存成功');
            _this11.$router.back();
          }
        }).catch(function (res) {
          _this11.loading = false;
          // this.$message.error(res.message);
        });
      } else {
        (0, _warehouse.createpushOrderApiV2)(json).then(function (res) {
          _this11.loading = false;
          if (res.status == 200) {
            _this11.$message.success('保存成功');
            _this11.$router.back();
          }
        }).catch(function (res) {
          _this11.loading = false;
          // this.$message.error(res.message);
        });
      }
      //   } else {
      //     return false;
      //   }
      // });
    },
    getDetail: function getDetail() {
      if (!this.id) {
        return;
      }
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        contract_status: 4,
        product_warehouse_status: 0,
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    onChange: function onChange() {
      this.tableFrom.page == 1;
      this.enterGoodsList = [];
      this.goodsIdArr = [];
      this.getList();
    },
    getList: function getList() {
      var _this12 = this;
      var filterData = Object.assign({}, this.tableFrom);
      if (this.formValidate.enter_type == 'enter_warehouse_refund' || this.formValidate.enter_type == 'enter_warehouse_return') filterData.product_warehouse_status = 4;else filterData.product_warehouse_status = 0;
      if (this.formValidate.enter_type == 'enter_warehouse_temp') {
        delete filterData.contract_status;
        filterData.contract_id = 0;
      }
      (0, _basic.getgoodsListV2Api)(filterData).then(function (res) {
        res.data.list.forEach(function (item) {
          if (item.label_ids) {
            item.label_ids = item.label_ids.split(',').map(function (item) {
              return Number(item);
            });
          } else {
            item.label_ids = [];
          }
          if (Array.isArray(item.attribute) && !item.attribute.length) {
            item.attribute = {};
          }
          // Array.isArray(variable)
        });
        //
        if (_this12.tableFrom.page == 1 && _this12.removeList.length > 0) {
          _this12.tableData.data = [].concat((0, _toConsumableArray2.default)(_this12.removeList), (0, _toConsumableArray2.default)(res.data.list));
        } else _this12.tableData.data = res.data.list;
        _this12.tableData.total = res.data.count;
        _this12.listLoading = false;
      }).catch(function (res) {
        _this12.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    saleGoodsAll: function saleGoodsAll() {
      var _this13 = this;
      this.tableData.data.forEach(function (m) {
        _this13.saleGoods(m, 'goods', '');
      });
    },
    saleGoods: function saleGoods(row, type, eid, inrow) {
      if (this.goodsIdArr.indexOf(row.id) > -1) {
        return;
      }
      // if (this.lockid && eid != this.lockid) {
      //   this.$message.error("不能选择不同委托人的货品");
      //   return;
      // }extend_data.auction_info.start_price
      var newrow = JSON.parse(JSON.stringify(row));
      console.log(newrow);
      if (newrow.extend_data && newrow.extend_data.auction_info) newrow.start_price = newrow.extend_data.auction_info.start_price;
      if (newrow.warehouse_image) {
        newrow.slider_image = newrow.warehouse_image;
      } else {
        newrow.slider_image = row.image ? [row.image] : [];
      }
      this.enterGoodsList.push(newrow);
      // console.log(this.enterGoodsList);
      this.locktype = type;
      this.lockid = eid || '';
      // if (this.lockid && !this.formValidate.manage_admin_id) {
      //   this.formValidate.manage_admin_id = this.lockid;
      // }
      // this.goodsArr.push(row);
      this.goodsIdArr.push(row.id);
    },
    delGoods: function delGoods(index, row, cindex) {
      console.log(row);
      if (row.edit) {
        this.tableData.data.unshift(row);
        this.removeList.push(row);
      }
      var nindex = this.goodsIdArr.indexOf(row.id);
      this.goodsIdArr.splice(nindex, 1);
      this.enterGoodsList.splice(index, 1);
      if (this.goodsIdArr.length <= 0) {
        this.locktype = '';
        this.lockid = '';
      }
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 点击货品图
    modalPicTap: function modalPicTap(tit, num, i, nitem) {
      var _this = this;
      var attr = [];
      this.$modalUpload(function (img) {
        if (tit === '1' && !num) {
          _this.formValidate.image = img[0];
          _this.OneattrValue[0].image = img[0];
        }
        if (tit === '2' && !num) {
          console.log(img);
          img.map(function (item) {
            attr.push(item.attachment_src);
            nitem.slider_image.push(item);
            if (nitem.slider_image.length > 10) {
              nitem.slider_image.length = 10;
            }
          });
        }
        if (tit === '1' && num === 'dan') {
          _this.OneattrValue[0].image = img[0];
        }
        if (tit === '1' && num === 'duo') {
          _this.ManyAttrValue[i].image = img[0];
        }
      }, '9');
    },
    onClickSize: function onClickSize(value, index, row, field) {
      var arr = value ? value.split('\n') : [''];
      this.formState = {
        index: index,
        row: row,
        field: field
      };
      var newArr = [];
      arr.forEach(function (item) {
        if (item.indexOf('×') != -1) {
          var valArr = item.split('×');
          var long = valArr[0];
          var vice = 1;
          var showVice = false;
          var width = '';
          if (valArr[2]) {
            var numArr = valArr[2].split(' ');
            vice = Number(numArr[0].replace('幅', ''));
            width = valArr[1].replace('cm', '');
            showVice = true;
          } else {
            var widArr = valArr[1].split(' ');
            width = widArr[0].replace('cm', '');
          }
          newArr.push({
            toNum: 1,
            long: long,
            width: width,
            height: '',
            diam: '',
            vice: vice,
            showVice: showVice,
            sizeStr: item
          });
        } else {
          var _valArr = item.split('  ');
          console.log(_valArr);
          var _vice = 1;
          var _showVice = false;
          var _long = '';
          var _width = '';
          var height = '';
          var diam = '';
          _valArr.forEach(function (ele) {
            if (ele.indexOf('长(L)') != -1) {
              var str = ele.replace('长(L): ', '');
              _long = str.replace('cm', '');
            }
            if (ele.indexOf('宽(W)') != -1) {
              var _str = ele.replace('宽(W): ', '');
              _width = _str.replace('cm', '');
            }
            if (ele.indexOf('高(H)') != -1) {
              var _str2 = ele.replace('高(H): ', '');
              height = _str2.replace('cm', '');
            }
            if (ele.indexOf('直径(D)') != -1) {
              var _str3 = ele.replace('直径(D): ', '');
              diam = _str3.replace('cm', '');
            }
            if (ele.indexOf('数量') != -1) {
              var _str4 = ele.replace('数量: ', '');
              _vice = _str4.replace('件', '');
              _showVice = true;
            }
          });
          newArr.push({
            toNum: 1,
            long: _long,
            width: _width,
            height: height,
            diam: diam,
            vice: _vice,
            showVice: _showVice,
            sizeStr: item
          });
        }
      });
      console.log(newArr);
      this.formArr = newArr;
      this.showVisible = true;
    },
    onSubmit: function onSubmit() {
      var key = this.formState.field;
      var arr = this.formArr.map(function (item) {
        return item.sizeStr;
      });
      this.formState.row.attribute = (0, _objectSpread3.default)((0, _objectSpread3.default)({}, this.formState.row.attribute), {}, (0, _defineProperty2.default)({}, key, arr.join('\n')));
      this.$set(this.enterGoodsList, this.formState.index, this.formState.row);
      this.showVisible = false;
    },
    onChangeSize: function onChangeSize(row) {
      // console.log(this.formState);
      if (Number(row.width) > 0 && Number(row.long) > 0 && Number(row.vice) > 0 && !row.height && !row.diam) {
        if (row.showVice) {
          var num = (Number(row.width) * Number(row.long) * 0.0009).toFixed(row.toNum);
          row.sizeStr = "".concat(Number(row.long), "\xD7").concat(Number(row.width), "cm\xD7").concat(Number(row.vice), "\u5E45 \u7EA6").concat(num, "\u5E73\u5C3A").concat(Number(row.vice) > 1 ? '/幅' : '');
        } else {
          var _num = (Number(row.width) * Number(row.long) * Number(row.vice) * 0.0009).toFixed(row.toNum);
          row.sizeStr = "".concat(Number(row.long), "\xD7").concat(Number(row.width), "cm \u7EA6").concat(_num, "\u5E73\u5C3A");
        }
      } else {
        var str = '';
        if (row.long) str += "\u957F(L): ".concat(row.long, "cm  ");
        if (row.width) str += "\u5BBD(W): ".concat(row.width, "cm  ");
        if (row.height) str += "\u9AD8(H): ".concat(row.height, "cm  ");
        if (row.diam) str += "\u76F4\u5F84(D): ".concat(row.diam, "cm  ");
        if (row.showVice) str += "\u6570\u91CF: ".concat(row.vice, "\u4EF6");
        row.sizeStr = str;
      }
    },
    onAdd: function onAdd() {
      var row = this.formArr[this.formArr.length - 1];
      if (row.sizeStr) {
        this.formArr.push({
          toNum: 1,
          long: '',
          width: '',
          height: '',
          diam: '',
          vice: 1,
          showVice: false,
          sizeStr: ''
        });
      }
    },
    createActiveGoods: function createActiveGoods() {
      var _this14 = this;
      if (this.addNum && !isNaN(this.addNum)) {
        var arr = Array.from({
          length: this.addNum
        }, function (m, i) {
          return {
            attribute: {},
            slider_image: [],
            order_num: i + 1 + _this14.enterGoodsList.length,
            cate_id: '',
            shelf_id: ''
          };
        });
        this.enterGoodsList = this.enterGoodsList.concat(arr);
        return false;
      }
      this.enterGoodsList.push({
        attribute: {},
        slider_image: [],
        order_num: 1 + this.enterGoodsList.length
      });
    },
    onHeadChange: function onHeadChange(filed) {
      var _this15 = this;
      this.enterGoodsList.forEach(function (item, index) {
        if (filed == 'cate_id' && _this15.headObj[filed] && _this15.headObj[filed].length) {
          item[filed] = _this15.headObj[filed][_this15.headObj[filed].length - 1];
        } else {
          item[filed] = _this15.headObj[filed];
        }
        _this15.$set(_this15.enterGoodsList, index, item);
      });
      this.$forceUpdate();
    },
    headClick: function headClick(val) {
      this.handName = val;
      // this.$refs[val][0].focus();
    }
  }
};