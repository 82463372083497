"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "attrList",
  data: function data() {
    return {
      props: {
        value: "store_brand_category_id",
        label: "cate_name",
        children: "children",
        emitPath: false
      },
      isChecked: false,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20
      },
      imgList: [],
      brandCategory: [],
      dialogEditAttrVisible: false,
      ruleForm: {
        name: "",
        attr_name_zh_tw: '',
        attr_name_en: '',
        type: 1,
        store_category_ids: []
      },
      rules: {
        name: [{
          required: true,
          message: "请输入属性名称",
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: "请选择属性类型",
          trigger: "change"
        }]
      },
      formValidate: [{
        brforeImg: "",
        afterImg: "",
        value: ""
      }],
      editId: '',
      btnLoading: false
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    (0, _product.storeCategoryListApi)().then(function (res) {
      _this2.brandCategory = res.data;
    });
    this.getList();
  },
  methods: {
    onChangeStatus: function onChangeStatus(row) {
      (0, _product.switchStatusIsUseApi)(row.attr_id, {
        status: row.is_use
      }).then(function (res) {
        // this.getList();
      });
    },
    // 列表
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _product.attrListApi)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.data.list;
        _this3.tableData.total = res.data.count;
        _this3.tableData.data.map(function (item) {
          _this3.imgList.push(item.pic);
        });
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      this.editId = '';
      this.formValidate = [{
        brforeImg: "",
        afterImg: "",
        value: ""
      }];
      this.ruleForm = {
        name: "",
        attr_name_zh_tw: '',
        attr_name_en: '',
        type: 1,
        store_category_ids: []
      };
      this.dialogEditAttrVisible = true;
      // this.$modalForm(attrCreateApi()).then(() => this.getList());
    },
    // 编辑
    onEdit: function onEdit(m) {
      this.editId = m.attr_id;
      var formValidate = [];
      if (m.attr_type != 1) {
        m.attrValue.forEach(function (val) {
          formValidate = formValidate.concat([{
            brforeImg: val.image,
            afterImg: val.after_image,
            value: val.attr_value_name,
            attr_value_id: val.attr_value_id
          }]);
        });
        this.formValidate = formValidate;
      } else {
        this.formValidate = [{
          brforeImg: "",
          afterImg: "",
          value: ""
        }];
      }
      var rForm = {
        name: m.attr_name,
        type: m.attr_type
      };
      if (m.storeCategoryParentIds) {
        var d = [];
        m.storeCategoryParentIds.split(',').forEach(function (item) {
          if (item.includes('-')) {
            d = d.concat([item.split('-').map(Number)]);
          } else {
            d.push([Number(item)]);
          }
        });
        rForm.store_category_ids = d;
      }
      if (m.attr_name_language) {
        rForm.attr_name_zh_tw = m.attr_name_language.zh_tw;
        rForm.attr_name_en = m.attr_name_language.en;
      }
      this.ruleForm = rForm;
      this.dialogEditAttrVisible = true;
      // this.$modalForm(attrCreateApi()).then(() => this.getList());
    },
    modalPicTap: function modalPicTap(tit, key, index) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.formValidate[index][key] = img[0];
      }, tit);
    },
    addAttr: function addAttr() {
      this.formValidate = this.formValidate.concat([{
        brforeImg: "",
        afterImg: "",
        value: ""
      }]);
    },
    removeAttr: function removeAttr(i) {
      this.formValidate.splice(i, 1);
    },
    goUpAttrValAction: function goUpAttrValAction(i) {
      this.formValidate.splice(i - 1, 0, this.formValidate[i]);
      this.formValidate.splice(i + 1, 1);
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var attrVal = [];
          if (_this4.ruleForm.type !== 1) {
            _this4.formValidate.forEach(function (res) {
              if (res.value) {
                attrVal = attrVal.concat([{
                  attr_value_name: res.value,
                  image: res.brforeImg,
                  after_image: res.afterImg,
                  attr_value_id: res.attr_value_id || ''
                }]);
              }
              if (attrVal.length <= 0) {
                _this4.$message.error("请设置属性值");
                return false;
              }
            });
          }
          var data = {
            attr_name: _this4.ruleForm.name,
            attr_name_zh_tw: _this4.ruleForm.attr_name_zh_tw,
            attr_name_en: _this4.ruleForm.attr_name_en,
            attr_type: _this4.ruleForm.type,
            attr_value_list: JSON.stringify(_this4.ruleForm.type !== 1 ? attrVal : [])
          };
          var d = [];
          if (_this4.ruleForm.store_category_ids && _this4.ruleForm.store_category_ids.length) {
            _this4.ruleForm.store_category_ids.forEach(function (item) {
              d.push(item[item.length - 1]);
            });
          }
          data.store_category_ids = d.length ? d.join(',') : '';
          _this4.btnLoading = true;
          if (_this4.editId) {
            (0, _product.attrUpdateApi)(_this4.editId, data).then(function () {
              _this4.getList();
              _this4.btnLoading = false;
              _this4.dialogEditAttrVisible = false;
            }).catch(function (msg) {
              _this4.$message.error(msg);
              _this4.btnLoading = false;
            });
            return false;
          }
          (0, _product.attrCreateApi)(data).then(function () {
            _this4.getList();
            _this4.btnLoading = false;
            _this4.dialogEditAttrVisible = false;
          }).catch(function (msg) {
            _this4.$message.error(msg);
            _this4.btnLoading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};