var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "配置", hasBack: true, isFixed: true },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": _vm.activeMenu,
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "5" } }, [
                _vm._v("规则配置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "4" } }, [
                _vm._v("分类设置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "6" } }, [
                _vm._v("鉴定结果"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  staticClass: "color-green create-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.createdAction },
                },
                [_vm._v("创建鉴定分类")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "mt20 no-height button-no-padding",
              attrs: { border: "", data: _vm.resultData, size: "small" },
            },
            [
              _c("template", { slot: "empty" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.loading,
                        expression: "!loading",
                      },
                    ],
                    staticClass: "tableEmpty",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tableDiv" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "分类图片", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.category_img
                          ? _c("img", {
                              staticStyle: {
                                width: "60px",
                                height: "60px",
                                "margin-top": "5px",
                              },
                              attrs: { src: scope.row.category_img + "!120a" },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "分类名称",
                  "min-width": "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v("ID：" + _vm._s(scope.row.id))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(scope.row.category_name))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "回答标签",
                  "min-width": "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.category_level &&
                        scope.row.category_level.length > 0
                          ? _c(
                              "div",
                              _vm._l(scope.row.category_level, function (m, i) {
                                return _c("span", { key: i }, [
                                  _vm._v(
                                    _vm._s(i > 0 ? "、" : "") +
                                      _vm._s(m.level_name)
                                  ),
                                ])
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "r" },
                          [
                            scope.$index !== 0
                              ? _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.descAction(
                                          $event,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("上移")]
                                )
                              : _vm._e(),
                            scope.$index !== 0
                              ? _c("span", [_vm._v("|")])
                              : _vm._e(),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.editAction($event, scope.$index)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c("span", [_vm._v("|")]),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.editLevelAction(
                                      $event,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [_vm._v("管理标签")]
                            ),
                            _c("span", [_vm._v("|")]),
                            _c("el-popconfirm", {
                              attrs: { title: "确定删除吗？" },
                              on: {
                                onConfirm: function ($event) {
                                  return _vm.removeAction($event, scope.$index)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "reference",
                                    fn: function () {
                                      return [_c("a", [_vm._v("删除")])]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "auction-modal",
              attrs: {
                visible: _vm.showDetail,
                title: _vm.categoryId ? "编辑分类" : "新增分类",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showDetail = $event
                },
                close: _vm.closeDetail,
              },
            },
            [
              _c(
                "div",
                { staticClass: "item-align-center mt20" },
                [
                  _c("span", { staticStyle: { width: "100px" } }, [
                    _vm._v("分类名称："),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "w200",
                    model: {
                      value: _vm.categoryName,
                      callback: function ($$v) {
                        _vm.categoryName = $$v
                      },
                      expression: "categoryName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-align-center mt20 h50 guanggao" },
                [
                  _c("span", { staticStyle: { width: "100px" } }, [
                    _vm._v("分类图片："),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.uploadImg,
                          expression: "!uploadImg",
                        },
                      ],
                      staticClass: "upload-list",
                      staticStyle: { border: "1px solid #eee" },
                      on: { click: _vm.onUpload },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-camera-solid",
                        attrs: { size: "20" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.uploadImg,
                          expression: "uploadImg",
                        },
                      ],
                      staticClass: "upload-list",
                    },
                    [
                      _c("img", { attrs: { src: _vm.uploadImg } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "upload-list-cover" }, [
                        _c("i", {
                          staticClass: "el-icon-view",
                          attrs: { size: "20" },
                          on: { click: _vm.handleView },
                        }),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          attrs: { size: "20" },
                          on: { click: _vm.handleRemoveNew },
                        }),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20 mb20" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "100px", width: "100px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.saveDetail },
                    },
                    [_vm._v(_vm._s(_vm.categoryId ? "保存" : "确认添加"))]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "auction-modal",
              attrs: {
                visible: _vm.showEditLevelDetail,
                title: "编辑标签",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showEditLevelDetail = $event
                },
                "on-cancel": _vm.closeEditLevelDetail,
              },
            },
            [
              _c("div", { staticClass: "item-align-center" }, [
                _c(
                  "span",
                  { staticClass: "mt20", staticStyle: { width: "100px" } },
                  [_vm._v("分类标签：")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-one" },
                  _vm._l(_vm.categoryLevel, function (m, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "mt20 item-align-center" },
                      [
                        _c("el-input", {
                          staticClass: "w200",
                          attrs: { placeholder: "输入标签名称" },
                          model: {
                            value: m.level_name,
                            callback: function ($$v) {
                              _vm.$set(m, "level_name", $$v)
                            },
                            expression: "m.level_name",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml20" }, [
                          i > 0
                            ? _c(
                                "a",
                                {
                                  staticClass: "mr10 color-b",
                                  on: {
                                    click: function ($event) {
                                      return _vm.pAction(i)
                                    },
                                  },
                                },
                                [_vm._v("上移")]
                              )
                            : _vm._e(),
                          i == 0
                            ? _c(
                                "a",
                                {
                                  staticClass: "mr10 color-b",
                                  on: { click: _vm.createLevel },
                                },
                                [_vm._v("新增")]
                              )
                            : _vm._e(),
                          _c(
                            "a",
                            {
                              staticClass: "color-b",
                              on: {
                                click: function ($event) {
                                  return _vm.delLevel(i)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20 mb20" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "100px", width: "100px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.saveLevelDetail },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { title: "查看图片", visible: _vm.visible },
              on: {
                "update:visible": function ($event) {
                  _vm.visible = $event
                },
              },
            },
            [
              _vm.visible
                ? _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: { src: _vm.uploadImg },
                  })
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }