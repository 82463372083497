"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _artai = require("@/api/artai");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      visible: false,
      iframeUrl: '',
      posterId: '',
      // ipUrl: 'http://127.0.0.1:5173',
      ipUrl: 'https://poster-design.yunzongbu.cn'
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this$$route$query = this.$route.query,
      id = _this$$route$query.id,
      out_record_id = _this$$route$query.out_record_id;
    this.posterId = id;
    this.iframeUrl = "".concat(this.ipUrl, "/amend?poster_record_id=").concat(out_record_id, "&token=").concat((0, _auth.getToken)(), "&form=saas");
    window.addEventListener('message', this.handleMessageFromIframe);
  },
  beforeDestroy: function beforeDestroy() {
    this.iframeUrl = '';
    // 移除事件监听器
    window.removeEventListener('message', this.handleMessageFromIframe);
  },
  methods: {
    handleMessageFromIframe: function handleMessageFromIframe(event) {
      //检查消息来源是否可信
      if (event.origin !== this.ipUrl) {
        return;
      }

      // 处理接收到的消息
      switch (event.data.type) {
        case 'SOME_EVENT':
          // 处理特定事件
          console.log('通信:');
          // this.$emit('result',this.posterId);
          (0, _artai.switchGenerateResultStatusApi)({
            id: Number(this.posterId),
            generate_result_status: 3
          }).then(function (res) {
            // this.$message.success('操作成功');
          });
          break;
        case 'replaceImg':
          // 处理特定事件
          console.log('通信图片:');
          var _this$$route$query2 = this.$route.query,
            venue_id = _this$$route$query2.venue_id,
            album_id = _this$$route$query2.album_id;
          var obj = {};
          if (venue_id) obj.venue_id = venue_id;
          if (album_id) obj.album_id = album_id;
          this.$artaiModalUpload(obj, function (img) {
            // console.log(img[0]);
            // 获取子iframe的引用
            var iframe = document.getElementById('poster-iframe');
            // 发送消息到子iframe
            iframe.contentWindow.postMessage({
              type: 'imgUrl',
              data: img[0]
            }, '*');
          }, 1);
          break;
        default:
          // 处理其他事件
          break;
      }
    },
    openPoster: function openPoster(id, poster_record_id) {
      this.visible = true;
      this.posterId = id;
      this.iframeUrl = "".concat(this.ipUrl, "/amend?poster_record_id=").concat(poster_record_id);
      window.addEventListener('message', this.handleMessageFromIframe);
    },
    onClose: function onClose() {
      // this.formValidate.is_pay = 1;
      // this.onSubmit();
      this.visible = false;
      this.iframeUrl = '';
      window.removeEventListener('message', this.handleMessageFromIframe);
    }
  }
};