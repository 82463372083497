var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "鉴定管理", "is-fixed": true },
        scopedSlots: _vm._u([
          {
            key: "btn",
            fn: function () {
              return [
                _c(
                  "el-dropdown",
                  { attrs: { type: "primary", placement: "bottom" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        staticStyle: { color: "#3480F4", cursor: "pointer" },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "16px",
                            height: "16px",
                            "margin-right": "5px",
                          },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/202464/image/1717490887632187719.png",
                            alt: "",
                          },
                        }),
                        _vm._v("\n          配置\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push(
                                    "/merchant/authenticate/praisalSetting"
                                  )
                                },
                              },
                            },
                            [_vm._v("规则配置")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push(
                                    "/merchant/authenticate/praisalCategory"
                                  )
                                },
                              },
                            },
                            [_vm._v("分类设置")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push(
                                    "/merchant/authenticate/praisalResult"
                                  )
                                },
                              },
                            },
                            [_vm._v("鉴定结果设置")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": _vm.activeMenu,
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [
                _vm._v("鉴定列表"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "2" } }, [
                _vm._v("专家列表"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "6" } }, [
                _vm._v("投诉管理"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "3" } }, [
                _vm._v("数据报表"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c(
                "div",
                {
                  staticClass: "flex align-items-c",
                  staticStyle: { "margin-bottom": "20px" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button", disabled: _vm.loading },
                      on: { change: _vm.changeStateEvent },
                      model: {
                        value: _vm.queryParams.question_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "question_status", $$v)
                        },
                        expression: "queryParams.question_status",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "5" } }, [
                        _vm._v("待分单"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("待回答"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "6" } }, [
                        _vm._v("追问待回答"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("已回答"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "7" } }, [
                        _vm._v("已失效"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "3" } }, [
                        _vm._v("已退款"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "4" } }, [
                        _vm._v("未支付"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "8" } }, [
                        _vm._v("即将超时"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "default-box mt10" }, [
                _c("div", { staticClass: "default-setting" }, [
                  _c(
                    "form",
                    { ref: "exportForm" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "问题ID/关键词搜索",
                            placement: "top",
                            disabled: "disabled",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "w200",
                            attrs: {
                              placeholder: "问题ID/关键词搜索",
                              name: "questionId",
                            },
                            model: {
                              value: _vm.questionId,
                              callback: function ($$v) {
                                _vm.questionId = $$v
                              },
                              expression: "questionId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "ml10",
                          attrs: {
                            content: "提问人名称",
                            placement: "top",
                            disabled: "disabled",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "w200",
                            attrs: {
                              placeholder: "提问人名称",
                              name: "user_keyword",
                            },
                            model: {
                              value: _vm.user_keyword,
                              callback: function ($$v) {
                                _vm.user_keyword = $$v
                              },
                              expression: "user_keyword",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "ml10",
                          attrs: {
                            content: "鉴定分类",
                            placement: "top",
                            disabled: "disabled",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "鉴定分类",
                                filterable: "filterable",
                                clearable: "",
                                name: "category",
                              },
                              on: { change: _vm.searchAction },
                              model: {
                                value: _vm.category,
                                callback: function ($$v) {
                                  _vm.category = $$v
                                },
                                expression: "category",
                              },
                            },
                            _vm._l(_vm.categoryList, function (m) {
                              return _c("el-option", {
                                key: "album" + m.id,
                                attrs: { value: m.id, label: m.category_name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "ml10",
                          attrs: {
                            content: "选择专家",
                            placement: "top",
                            disabled: "disabled",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w200",
                              attrs: {
                                clearable: "",
                                filterable: "filterable",
                                remote: "remote",
                                "remote-method": _vm.searchPost,
                                loading: _vm.searchLoading,
                                placeholder: "搜索专家",
                                name: "expert_uid",
                              },
                              on: { change: _vm.searchAction },
                              model: {
                                value: _vm.exportUid,
                                callback: function ($$v) {
                                  _vm.exportUid = $$v
                                },
                                expression: "exportUid",
                              },
                            },
                            _vm._l(_vm.announcerList, function (item) {
                              return _c("el-option", {
                                key: item.uid,
                                attrs: {
                                  value: item.uid,
                                  label: item.nickname,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt20" }),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "鉴定结果",
                            placement: "top",
                            disabled: "disabled",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w200",
                              attrs: {
                                clearable: "",
                                placeholder: "鉴定结果",
                                name: "collection_status",
                              },
                              on: { change: _vm.searchAction },
                              model: {
                                value: _vm.cursorResault,
                                callback: function ($$v) {
                                  _vm.cursorResault = $$v
                                },
                                expression: "cursorResault",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: "1", label: "真品" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: "2", label: "看新" },
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.resuleList, function (m, i) {
                                return _c("el-option", {
                                  key: i,
                                  attrs: { value: m.id, label: m.name },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "ml10",
                          attrs: {
                            content: "上拍类型",
                            placement: "top",
                            disabled: "disabled",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w200",
                              attrs: {
                                clearable: "",
                                placeholder: "推荐上拍类型",
                                name: "collection_status",
                              },
                              on: { change: _vm.searchAction },
                              model: {
                                value: _vm.auction_type,
                                callback: function ($$v) {
                                  _vm.auction_type = $$v
                                },
                                expression: "auction_type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: "1", label: "限时拍" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: "2", label: "即时拍" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: "3", label: "同步拍" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: "4", label: "即刻拍" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: "5", label: "抖音拍" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "250px", "margin-left": "10px" },
                        attrs: {
                          "value-format": "yyyy/MM/dd",
                          format: "yyyy/MM/dd",
                          type: "daterange",
                          placement: "bottom-end",
                          placeholder: "选择时间",
                        },
                        on: { change: _vm.onChangeDate },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml10",
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.searchAction($event)
                            },
                          },
                        },
                        [_vm._v("筛选")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml10",
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.exportAction($event)
                            },
                          },
                        },
                        [_vm._v("导出")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "text" },
                          on: { click: _vm.getExportFileList },
                        },
                        [_vm._v("导出记录\n            ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "mt40 no-height button-no-padding",
                  attrs: { border: "", data: _vm.resultData, size: "small" },
                },
                [
                  _c("template", { slot: "empty" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.loading,
                            expression: "!loading",
                          },
                        ],
                        staticClass: "tableEmpty",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "tableDiv" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "图片",
                      width: "80",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.image_url.length
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#0EA3F4",
                                      "text-align": "center",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "60px",
                                        height: "60px",
                                        "object-fit": "cover",
                                      },
                                      attrs: {
                                        src: row.image_url[0] + "!120a",
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.onBigImg(row)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  row.image_url.length + "图"
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      width: "80",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.is_refund === 1
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#DB1B1B" } },
                                  [
                                    _vm._v(
                                      "\n              已退款\n            "
                                    ),
                                  ]
                                )
                              : row.is_pay === 0
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#FFD015" } },
                                  [
                                    _vm._v(
                                      "\n              未支付\n            "
                                    ),
                                  ]
                                )
                              : row.is_answer === 1
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#0AAC28" } },
                                  [
                                    _vm._v(
                                      "\n              已回答\n            "
                                    ),
                                  ]
                                )
                              : !row.expert_uid &&
                                row.is_overdue === 0 &&
                                row.is_answer === 0
                              ? _c("div", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    "\n              待分单\n            "
                                  ),
                                ])
                              : row.is_overdue === 0 &&
                                row.nextLevelQuestion &&
                                row.nextLevelQuestion.is_answer === 0
                              ? _c("div", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    "\n              追问待回答\n            "
                                  ),
                                ])
                              : row.is_overdue === 0 && row.is_answer === 0
                              ? _c("div", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    "\n              待回答\n            "
                                  ),
                                ])
                              : row.is_overdue === 1
                              ? _c("div", { staticStyle: { color: "#999" } }, [
                                  _vm._v(
                                    "\n              已过期\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "提问人",
                      width: "200",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetails(row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.user.nickname))]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetails(row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.user.phone))]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "编号/分类",
                      width: "200",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              row.question_id
                                ? _c("div", [
                                    _vm._v("编号：" + _vm._s(row.question_id)),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.category && row.category.category_name
                                ? _c("div", [
                                    _vm._v(
                                      "\n                分类：" +
                                        _vm._s(row.category.category_name) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "问题",
                      "min-width": "150",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  [
                                    _vm._v("问题："),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onJiandingxiangqingi(
                                              row,
                                              2
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("查看提问内容 >>")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", [_vm._v(_vm._s(row.create_time))]),
                                _vm._v(" "),
                                row.nextLevelQuestion
                                  ? [
                                      _c(
                                        "div",
                                        [
                                          _vm._v("追问："),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onJiandingxiangqingi(
                                                    row,
                                                    3
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("查看追问内容 >>")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            row.nextLevelQuestion.create_time
                                          )
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "回答人/鉴定结果",
                      "min-width": "150",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _c("div", [
                                _c("div", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        row.designated_expert ? "指定" : "回答"
                                      ) +
                                      "：" +
                                      _vm._s(
                                        row.expertUser
                                          ? row.expertUser.nickname
                                          : ""
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "\n                  鉴定结果：" +
                                      _vm._s(row.identificationResultText) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "回答内容",
                      "min-width": "210",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              row.is_answer
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onLookHuida(row)
                                            },
                                          },
                                        },
                                        [_vm._v("查看回答内容 >>")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              "回答时间：" +
                                                row.answer_time +
                                                " "
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.nextLevelQuestion &&
                              row.nextLevelQuestion.is_answer
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onLookZhuida(row)
                                            },
                                          },
                                        },
                                        [_vm._v("查看追问回答 >>")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "追答时间：" +
                                            _vm._s(
                                              row.nextLevelQuestion.answer_time
                                            )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "是否上拍",
                      "min-width": "120",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.user_is_auction
                              ? _c("div", [
                                  _c("div", [_vm._v("要求上拍")]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(row.user.phone))]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.auction_recommend
                              ? _c("div", [_c("div", [_vm._v("推荐上拍")])])
                              : _vm._e(),
                            _vm._v(" "),
                            row.auction_type
                              ? _c("div", [
                                  _c("div", [
                                    _vm._v(
                                      "推荐类型：" + _vm._s(row.auctionTypeText)
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(_vm.getLevelName(row)))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "偷学",
                      width: "80",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#69d",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onLookTouxue(row)
                                  },
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(row.study_order_count + "人偷学")
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      row.study_order_pay_money / 100 + "元收益"
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "支付信息",
                      "min-width": "150",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.pay_price
                              ? _c("div", [
                                  _c("div", [
                                    _vm._v(
                                      "金额：" +
                                        _vm._s(row.pay_price / 100) +
                                        "元"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  row.is_pay
                                    ? _c("div", [_vm._v(_vm._s(row.pay_type))])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.is_pay
                                    ? _c("div", [_vm._v(_vm._s(row.pay_time))])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "100",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              { staticStyle: { "padding-top": "5px" } },
                              [
                                _c(
                                  "div",
                                  [
                                    row.expert_uid &&
                                    row.is_pay !== 0 &&
                                    row.is_overdue === 0 &&
                                    row.is_answer === 0
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "5px",
                                            },
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onHuidawenti(row)
                                              },
                                            },
                                          },
                                          [_vm._v("回答问题")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    !row.expert_uid &&
                                    row.is_pay !== 0 &&
                                    row.is_overdue === 0 &&
                                    row.is_answer === 0
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "5px",
                                            },
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onFendan(row)
                                              },
                                            },
                                          },
                                          [_vm._v("分单")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onJiandingxiangqingi(row)
                                          },
                                        },
                                      },
                                      [_vm._v("鉴定详情")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onXiugaifenlei(row)
                                          },
                                        },
                                      },
                                      [_vm._v("修改分类")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    !row.is_show
                                      ? _c("el-popconfirm", {
                                          attrs: { title: "确定展示问题吗?" },
                                          on: {
                                            onConfirm: function ($event) {
                                              return _vm.onShowTiwen(row)
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "reference",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "text",
                                                          size: "mini",
                                                        },
                                                      },
                                                      [_vm._v("展示提问")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _c("el-popconfirm", {
                                          attrs: { title: "确定隐藏问题吗?" },
                                          on: {
                                            onConfirm: function ($event) {
                                              return _vm.onHideTiwen(row)
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "reference",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "text",
                                                          size: "mini",
                                                        },
                                                      },
                                                      [_vm._v("隐藏提问")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-popconfirm", {
                                  attrs: { title: "确定删除吗?" },
                                  on: {
                                    onConfirm: function ($event) {
                                      return _vm.onDelete(row)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "reference",
                                        fn: function () {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "5px",
                                                },
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                row.is_pay != 0 && row.is_refund == 0
                                  ? _c("el-popconfirm", {
                                      attrs: { title: "确定退款吗?" },
                                      on: {
                                        onConfirm: function ($event) {
                                          return _vm.onTuikuan(row)
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "reference",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "5px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      size: "mini",
                                                    },
                                                  },
                                                  [_vm._v("退款")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "paginaBox flex" },
                [
                  _c("el-pagination", {
                    staticStyle: { "text-align": "right" },
                    attrs: {
                      total: _vm.total,
                      background: "",
                      "page-size": _vm.queryParams.limit,
                      "show-sizer": "show-sizer",
                      "show-total": "show-total",
                      "show-elevator": "show-elevator",
                      "current-page": _vm.queryParams.page,
                    },
                    on: {
                      "size-change": _vm.changePageSize,
                      "current-change": _vm.changePage,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("file-list", { ref: "exportList" }),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.showVideo,
                    title: "查看视频",
                    "footer-hide": "footer-hide",
                    width: "800",
                    align: "center",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showVideo = $event
                    },
                  },
                },
                [
                  _c("div", [
                    _vm.video
                      ? _c("video", {
                          staticStyle: { width: "750px", height: "421px" },
                          attrs: { src: _vm.video, controls: true },
                        })
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.showImg,
                    title: "查看图片",
                    "footer-hide": "footer-hide",
                    width: "800",
                    align: "center",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showImg = $event
                    },
                  },
                },
                [
                  _vm.img && _vm.img.length > 0
                    ? _c(
                        "div",
                        { staticClass: "h600" },
                        _vm._l(_vm.img, function (m, i) {
                          return _c(
                            "a",
                            {
                              key: "img" + i,
                              attrs: { href: m, target: "_blank" },
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "750px" },
                                attrs: { src: m + "!m640", controls: true },
                              }),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-drawer",
                {
                  attrs: {
                    title: "查看偷学",
                    visible: _vm.showStudy,
                    size: "1000px",
                    direction: "rtl",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showStudy = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "question",
                      staticStyle: { padding: "0 20px" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "no-height",
                          attrs: { data: _vm.studyList, size: "small" },
                        },
                        [
                          _c("template", { slot: "empty" }, [
                            _c("div", { staticClass: "tableEmpty" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "tableDiv" }, [
                                _vm._v("暂无数据"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "偷学用户",
                              prop: "",
                              align: "",
                              width: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDetails({
                                              uid: row.pay_uid,
                                              nickname: row.pay_uname,
                                            })
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(row.pay_uname))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "偷学订单号",
                              prop: "out_trade_no",
                              align: "",
                              width: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "支付时间",
                              prop: "payTimeFormat",
                              align: "",
                              width: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "支付金额",
                              prop: "",
                              align: "",
                              width: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s("￥" + row.pay_money / 100) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "提问人信息",
                              prop: "",
                              align: "",
                              width: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [
                                      _vm._v(_vm._s(row.commission_user)),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(_vm._s(row.commission_user_name)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "提问人分得",
                              prop: "",
                              align: "",
                              width: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [
                                      _vm._v(_vm._s("￥" + row.amount / 100)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt40" },
                    [
                      _c("el-pagination", {
                        staticStyle: { "text-align": "right" },
                        attrs: {
                          total: _vm.studyTotal,
                          "page-size": _vm.studyParams.limit,
                          "show-sizer": "show-sizer",
                          "show-total": "show-total",
                          "show-elevator": "show-elevator",
                          "current-page": _vm.studyParams.page,
                        },
                        on: { "current-change": _vm.changeStudyPage },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: {
                    visible: _vm.showCategory,
                    title: "修改问题分类",
                    width: "30%",
                    "class-name": "vertical-center-modal",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showCategory = $event
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        placeholder: "请选择分类",
                        filterable: "filterable",
                        name: "category",
                      },
                      model: {
                        value: _vm.showCategoryId,
                        callback: function ($$v) {
                          _vm.showCategoryId = $$v
                        },
                        expression: "showCategoryId",
                      },
                    },
                    _vm._l(_vm.categoryList, function (m) {
                      return _c("el-option", {
                        key: "cate" + m.id,
                        attrs: { value: m.id, label: m.category_name },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt20 item-flex-center" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.showCategory = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.changeCategory },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: {
                    visible: _vm.showSeparate,
                    title: "分单",
                    width: "30%",
                    "class-name": "vertical-center-modal",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showSeparate = $event
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择鉴定师", name: "nickname" },
                      model: {
                        value: _vm.separateUid,
                        callback: function ($$v) {
                          _vm.separateUid = $$v
                        },
                        expression: "separateUid",
                      },
                    },
                    _vm._l(_vm.expertList, function (m) {
                      return _c(
                        "el-option",
                        {
                          key: "cate" + m.id,
                          attrs: { value: m.uid, label: m.nickname },
                        },
                        [_vm._v(_vm._s(m.nickname))]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt20 item-flex-center" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.showSeparate = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.separateAction },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.showGoAnswer.show,
                    title: "快速回答",
                    closable: false,
                    "mask-closable": false,
                    width: "700",
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.showGoAnswer, "show", $event)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c("span", { staticClass: "w100" }, [
                        _vm._v("选择分类："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "选择分类",
                            filterable: "filterable",
                            name: "category",
                          },
                          on: { change: _vm.changeCategoryAction },
                          model: {
                            value: _vm.showGoAnswer.categoryIndex,
                            callback: function ($$v) {
                              _vm.$set(_vm.showGoAnswer, "categoryIndex", $$v)
                            },
                            expression: "showGoAnswer.categoryIndex",
                          },
                        },
                        _vm._l(_vm.categoryList, function (m, i) {
                          return _c("el-option", {
                            key: "album" + m.id,
                            attrs: { value: i, label: m.category_name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("span", { staticClass: "w100" }, [_vm._v("回答内容：")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { type: "textarea", rows: 3 },
                          model: {
                            value: _vm.showGoAnswer.answer_content,
                            callback: function ($$v) {
                              _vm.$set(_vm.showGoAnswer, "answer_content", $$v)
                            },
                            expression: "showGoAnswer.answer_content",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item-align-center mt20" },
                    [
                      _c("span", { staticClass: "w100" }, [
                        _vm._v("藏品真假："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.showGoAnswer.identification_result,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.showGoAnswer,
                                "identification_result",
                                $$v
                              )
                            },
                            expression: "showGoAnswer.identification_result",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("真品"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("看新"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.open_expert_can_auction
                    ? _c(
                        "div",
                        { staticClass: "item-align-center mt20" },
                        [
                          _c("span", { staticClass: "w100" }, [
                            _vm._v("推荐上拍："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.showGoAnswer.expertIsAuction,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.showGoAnswer,
                                    "expertIsAuction",
                                    $$v
                                  )
                                },
                                expression: "showGoAnswer.expertIsAuction",
                              },
                            },
                            [_vm._v("推荐")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showGoAnswer.expertIsAuction &&
                  _vm.open_expert_can_auction
                    ? _c(
                        "div",
                        { staticClass: "item-align-center mt20" },
                        [
                          _c("span", { staticClass: "w100" }, [
                            _vm._v("上拍类型："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "选择推荐上拍内容",
                                filterable: "filterable",
                              },
                              model: {
                                value: _vm.showGoAnswer.auction_type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.showGoAnswer,
                                    "auction_type",
                                    $$v
                                  )
                                },
                                expression: "showGoAnswer.auction_type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: "1", label: "限时拍" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: "2", label: "即时拍" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: "3", label: "同步拍" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: "4", label: "即刻拍" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: "5", label: "抖音拍" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showGoAnswer.expertIsAuction && _vm.open_category_level
                    ? _c(
                        "div",
                        { staticClass: "item-align-center mt20" },
                        [
                          _c("span", { staticClass: "w100" }, [
                            _vm._v("推荐等级："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "选择推荐等级",
                                filterable: "filterable",
                                name: "category",
                              },
                              model: {
                                value: _vm.showGoAnswer.category_level_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.showGoAnswer,
                                    "category_level_id",
                                    $$v
                                  )
                                },
                                expression: "showGoAnswer.category_level_id",
                              },
                            },
                            _vm._l(
                              _vm.categoryList[_vm.showGoAnswer.categoryIndex]
                                .category_level,
                              function (m) {
                                return _c("el-option", {
                                  key: "album" + m.id,
                                  attrs: { value: m.id, label: m.level_name },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { slot: "footer" }, slot: "footer" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "ml20",
                          on: {
                            click: function ($event) {
                              _vm.showGoAnswer.show = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.saveAnswerAction },
                        },
                        [_vm._v("立即回答")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title:
              _vm.showAnswerType == 1 ? "查看回答内容" : "查看追问回答内容",
            visible: _vm.showAnswer,
            size: "1000px",
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAnswer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "question", staticStyle: { padding: "0 20px" } },
            [
              _c(
                "el-table",
                {
                  staticClass: "no-height",
                  attrs: { data: _vm.answerList, size: "small" },
                },
                [
                  _c("template", { slot: "empty" }, [
                    _c("div", { staticClass: "tableEmpty" }, [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tableDiv" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "头像",
                      width: "80",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "54px",
                                  height: "54px",
                                  margin: "18px 0",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "54px",
                                    height: "54px",
                                    "border-radius": "50%",
                                  },
                                  attrs: { src: row.expertUser.expert_avatar },
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "信息",
                      "min-width": "100",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetails(row.expertUser)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.expertUser.nickname))]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "文字",
                      "min-width": "180",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.answer_content
                              ? _c("div", [_vm._v(_vm._s(row.answer_content))])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "图片",
                      "min-width": "80",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.answer_image && row.answer_image.length > 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onLookImg(row)
                                      },
                                    },
                                  },
                                  [_vm._v("查看图片")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "语音",
                      width: "220",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return _vm._l(
                            row.answer_radio,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: "audio" + index, staticClass: "m2" },
                                [
                                  _c("audio", {
                                    staticClass: "w200",
                                    attrs: { src: item.url, controls: "" },
                                  }),
                                ]
                              )
                            }
                          )
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "回答时间",
                      "min-width": "120",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.create_time))])]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.showDetailTitle,
            visible: _vm.showDetail,
            size: "1000px",
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDetail = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "0 20px" } },
            [
              _vm.showDetailType == 1 || _vm.showDetailType == 2
                ? [
                    _c("div", [
                      _vm._v("问：" + _vm._s(_vm.questionDetail.title)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      _vm._l(
                        _vm.questionDetail.image_url,
                        function (item, index) {
                          return _c("img", {
                            key: index,
                            staticClass: "w100 ml20 mt20",
                            staticStyle: { cursor: "pointer" },
                            attrs: { src: item + "!m640" },
                            on: {
                              click: function ($event) {
                                return _vm.onBigImg(_vm.questionDetail)
                              },
                            },
                          })
                        }
                      ),
                      0
                    ),
                    _vm._v(" "),
                    _vm.questionDetail.video_url
                      ? _c("div", [
                          _c("video", {
                            staticClass: "mt20",
                            staticStyle: { width: "400px" },
                            attrs: {
                              controls: true,
                              src: _vm.questionDetail.video_url,
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.questionDetail.supplementQuestion
                      ? [
                          _c("div", { staticClass: "heng-line" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt20" }),
                          _vm._v(" "),
                          _vm.questionDetail.supplementQuestion.title
                            ? _c("div", [
                                _vm._v(
                                  "补充问题：" +
                                    _vm._s(
                                      _vm.questionDetail.supplementQuestion
                                        .title
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            _vm._l(
                              _vm.questionDetail.supplementQuestion.image_url,
                              function (item, index) {
                                return _c("img", {
                                  key: index,
                                  staticClass: "w100 ml20 mt20",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { src: item + "!m640" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onBigImg(
                                        _vm.questionDetail.supplementQuestion
                                      )
                                    },
                                  },
                                })
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _vm.questionDetail.supplementQuestion.video_url
                            ? _c("div", [
                                _c("video", {
                                  staticClass: "mt20",
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    controls: true,
                                    src: _vm.questionDetail.supplementQuestion
                                      .video_url,
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.showDetailType == 1
                ? [
                    _c("div", { staticClass: "heng-line" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt20" }, [
                      _vm._v("\n          鉴定结果"),
                      _vm.questionDetail.identification_result == 1
                        ? _c("span", [_vm._v("真品")])
                        : _vm._e(),
                      _vm.questionDetail.identification_result == 2
                        ? _c("span", [_vm._v("看新")])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.questionDetail.answer, function (m, i) {
                      return _c(
                        "div",
                        { key: "answer" + i, staticClass: "mt20" },
                        [
                          _c("div", [
                            _vm._v("答： " + _vm._s(m.answer_content)),
                          ]),
                          _vm._v(" "),
                          _vm._l(m.answer_radio, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "mt20" },
                              [
                                _c("audio", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    src: item.url,
                                    controls: "controls",
                                  },
                                }),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mt20" },
                            _vm._l(m.answer_image, function (item, index) {
                              return _c("img", {
                                key: index,
                                staticClass: "w100 ml20 mt20",
                                attrs: { src: item },
                              })
                            }),
                            0
                          ),
                        ],
                        2
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "heng-line" }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.showDetailType == 1 || _vm.showDetailType == 3
                ? [
                    _c(
                      "div",
                      { class: _vm.showDetailType == 3 ? "" : "mt20" },
                      [
                        _vm.questionDetail.nextLevelQuestion
                          ? _c("div", [
                              _c(
                                "div",
                                [
                                  _vm._v(
                                    "\n              追问：" +
                                      _vm._s(
                                        _vm.questionDetail.nextLevelQuestion
                                          .title
                                      ) +
                                      "\n              "
                                  ),
                                  _c(
                                    "div",
                                    _vm._l(
                                      _vm.questionDetail.nextLevelQuestion
                                        .image_url,
                                      function (item, index) {
                                        return _c("img", {
                                          key: index,
                                          staticClass: "w100 ml20 mt20",
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { src: item + "!m640" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onBigImg(
                                                _vm.questionDetail
                                                  .nextLevelQuestion
                                              )
                                            },
                                          },
                                        })
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _vm.questionDetail.nextLevelQuestion.video_url
                                    ? _c("div", [
                                        _c("video", {
                                          staticClass: "mt20",
                                          staticStyle: { width: "400px" },
                                          attrs: {
                                            src: _vm.questionDetail
                                              .nextLevelQuestion.video_url,
                                            controls: true,
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.showDetailType == 1
                                    ? [
                                        _vm.questionDetail.nextLevelQuestion
                                          .answer &&
                                        _vm.questionDetail.nextLevelQuestion
                                          .answer[0]
                                          ? _c(
                                              "div",
                                              { staticClass: "mt20" },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    "答： " +
                                                      _vm._s(
                                                        _vm.questionDetail
                                                          .nextLevelQuestion
                                                          .answer[0]
                                                          .answer_content
                                                      )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.questionDetail
                                                    .nextLevelQuestion.answer[0]
                                                    .answer_radio,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: "__" + index,
                                                        staticClass: "mt20",
                                                      },
                                                      [
                                                        _c("audio", {
                                                          staticClass: "w200",
                                                          attrs: {
                                                            src: item.url,
                                                            controls:
                                                              "controls",
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "mt20" },
                                                  _vm._l(
                                                    _vm.questionDetail
                                                      .nextLevelQuestion
                                                      .answer[0].answer_image,
                                                    function (item, index) {
                                                      return _c("img", {
                                                        key: index,
                                                        staticClass:
                                                          "w100 ml20 mt20",
                                                        attrs: { src: item },
                                                      })
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showUserdetail
        ? [
            _c("userdetail", {
              attrs: {
                showdetail: _vm.showUserdetail,
                row: _vm.row,
                uid: _vm.userUid,
              },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }