var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "min-height": "100vh" } },
    [
      _c("header-breadcrumb", {
        attrs: { name: "库房相关配置", "has-back": "" },
      }),
      _vm._v(" "),
      _c("el-card", [
        _c(
          "div",
          [
            _c(
              "el-menu",
              {
                staticClass: "el-menu-demo default-tabs",
                attrs: { "default-active": _vm.tab_new, mode: "horizontal" },
                on: { select: _vm.changeTab },
              },
              [
                _c("el-menu-item", { attrs: { index: "1" } }, [
                  _vm._v("入库单"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "2" } }, [
                  _vm._v("出库单"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "3" } }, [
                  _vm._v("调拨单"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "4" } }, [
                  _vm._v("仓库配置"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "5" } }, [
                  _vm._v("规则配置"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-full overflow-hidden" },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.proofType,
                  callback: function ($$v) {
                    _vm.proofType = $$v
                  },
                  expression: "proofType",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "1" } }, [
                  _vm._v("创建" + _vm._s(_vm.typeName)),
                ]),
                _vm._v(" "),
                _c("el-radio-button", { attrs: { label: "2" } }, [
                  _vm._v(_vm._s(_vm.typeName) + "列表"),
                ]),
                _vm._v(" "),
                _c("el-radio-button", { attrs: { label: "3" } }, [
                  _vm._v("打印" + _vm._s(_vm.typeName)),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex align-items-s" }, [
              _c("div", { staticClass: "proof-table" }, [
                _c("div", { staticClass: "proof-title item-flex-center" }, [
                  _c("div", { staticClass: "w100 td" }, [_vm._v("是否启用")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one td" }, [
                    _vm._v("字段名称"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-one td" },
                    [
                      _vm._v("自定义名称 "),
                      _vm.proofType == "3" ? [_vm._v("(中文|英文)")] : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "w100 td" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "w140 td" }, [_vm._v("排序")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "proof-content" },
                  _vm._l(_vm.list, function (m, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "item-flex-center" },
                      [
                        _c(
                          "div",
                          { staticClass: "w100 td" },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: m.checked,
                                callback: function ($$v) {
                                  _vm.$set(m, "checked", $$v)
                                },
                                expression: "m.checked",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-one td" }, [
                          _vm._v(_vm._s(m.origin_name)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex-one td" },
                          [
                            _c("el-input", {
                              staticStyle: { "text-align": "center" },
                              on: {
                                focus: function ($event) {
                                  return _vm.focus($event)
                                },
                              },
                              model: {
                                value: m.name,
                                callback: function ($$v) {
                                  _vm.$set(m, "name", $$v)
                                },
                                expression: "m.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "w100 td" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(m.attr_type > 0 ? "属性" : "系统字段") +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "w140 td" },
                          [
                            _c("el-input-number", {
                              attrs: { controls: false },
                              on: {
                                focus: function ($event) {
                                  return _vm.focus($event)
                                },
                              },
                              model: {
                                value: m.sort,
                                callback: function ($$v) {
                                  _vm.$set(m, "sort", $$v)
                                },
                                expression: "m.sort",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _vm.proofType == 3 && _vm.tab == 1
                ? _c("div", { staticClass: "set-print-text" }, [
                    _c(
                      "div",
                      { staticClass: "t flex align-items-c" },
                      [
                        _vm._v("显示委托方信息：\n            "),
                        _c("el-switch", {
                          staticStyle: {
                            display: "block",
                            transform: "translateY(-1px)",
                          },
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                            "active-value": "1",
                            "inactive-value": "0",
                          },
                          model: {
                            value: _vm.setPrintColumns.storeViewEntrust,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.setPrintColumns,
                                "storeViewEntrust",
                                $$v
                              )
                            },
                            expression: "setPrintColumns.storeViewEntrust",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.proofType == 3 && _vm.tab == 2
                ? _c("div", { staticClass: "set-print-text" }, [
                    _c(
                      "div",
                      { staticClass: "t flex align-items-c" },
                      [
                        _vm._v("显示备注信息：\n            "),
                        _c("el-switch", {
                          staticStyle: {
                            display: "block",
                            transform: "translateY(-1px)",
                          },
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                            "active-value": "1",
                            "inactive-value": "0",
                          },
                          model: {
                            value: _vm.setPrintColumns.salesOutShowRemark,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.setPrintColumns,
                                "salesOutShowRemark",
                                $$v
                              )
                            },
                            expression: "setPrintColumns.salesOutShowRemark",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "t" }, [_vm._v("销售出库文案：")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "inp" },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", autosize: "" },
                          model: {
                            value: _vm.setPrintColumns.salesOutboundZH,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.setPrintColumns,
                                "salesOutboundZH",
                                $$v
                              )
                            },
                            expression: "setPrintColumns.salesOutboundZH",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "inp" },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", autosize: "" },
                          model: {
                            value: _vm.setPrintColumns.salesOutboundEN,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.setPrintColumns,
                                "salesOutboundEN",
                                $$v
                              )
                            },
                            expression: "setPrintColumns.salesOutboundEN",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "inp" },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", autosize: "" },
                          model: {
                            value: _vm.setPrintColumns.salesOutboundStatementZH,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.setPrintColumns,
                                "salesOutboundStatementZH",
                                $$v
                              )
                            },
                            expression:
                              "setPrintColumns.salesOutboundStatementZH",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "inp" },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", autosize: "" },
                          model: {
                            value: _vm.setPrintColumns.salesOutboundStatementEN,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.setPrintColumns,
                                "salesOutboundStatementEN",
                                $$v
                              )
                            },
                            expression:
                              "setPrintColumns.salesOutboundStatementEN",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "t" }, [_vm._v("退还出库文案：")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "inp" },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", autosize: "" },
                          model: {
                            value: _vm.setPrintColumns.returnOutboundZH,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.setPrintColumns,
                                "returnOutboundZH",
                                $$v
                              )
                            },
                            expression: "setPrintColumns.returnOutboundZH",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "inp" },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", autosize: "" },
                          model: {
                            value: _vm.setPrintColumns.returnOutboundEN,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.setPrintColumns,
                                "returnOutboundEN",
                                $$v
                              )
                            },
                            expression: "setPrintColumns.returnOutboundEN",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "inp" },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", autosize: "" },
                          model: {
                            value:
                              _vm.setPrintColumns.returnOutboundStatementZH,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.setPrintColumns,
                                "returnOutboundStatementZH",
                                $$v
                              )
                            },
                            expression:
                              "setPrintColumns.returnOutboundStatementZH",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "inp" },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", autosize: "" },
                          model: {
                            value:
                              _vm.setPrintColumns.returnOutboundStatementEN,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.setPrintColumns,
                                "returnOutboundStatementEN",
                                $$v
                              )
                            },
                            expression:
                              "setPrintColumns.returnOutboundStatementEN",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "div", staticStyle: { height: "30px" } }),
        _vm._v(" "),
        _c("div", { staticClass: "orderFoot flex align-items-c" }, [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.saveAction },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }