"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.split");
var _basic = require("@/api/basic");
var _warehouse = require("@/api/warehouse");
var _product = require("@/api/product");
var _setting = require("@/api/setting");
var _user = require("@/api/user");
var _utils = require("@/utils");
var _auction = require("@/api/auction");
var _sortablejs = _interopRequireDefault(require("sortablejs"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "inventorycrate",
  data: function data() {
    return {
      loading: false,
      rules: {
        venue_id: [{
          required: true,
          message: "请选择拍卖会",
          trigger: "change"
        }],
        album_id: [{
          required: true,
          message: "请选择专场",
          trigger: "change"
        }]
      },
      formValidate: {
        venue_id: "",
        album_id: ""
      },
      timeVal: [],
      goodsData: [],
      selectFlag: false,
      tableFrom: {
        page: 1,
        limit: 20,
        cate_id: "",
        keyword: "",
        product_id: ""
      },
      tableData: {
        data: [],
        total: 0
      },
      merSelect: [],
      editFlag: false,
      num: 0,
      id: this.$route.query.id || "",
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        }
      },
      disabled: false,
      dialogVisible: false,
      dialogImageUrl: "",
      storeArr: [],
      adminarr: [],
      goodsArr: [],
      goodsIdArr: [],
      BrandList: [],
      attrList: [],
      attr: [],
      handName: '',
      headObj: {},
      bidRList: [],
      venceList: [],
      albumArr: [],
      morejson: [{
        start_price: '',
        plus_price: '',
        retain_price: '',
        bond_price: '',
        bid_range_id: '',
        reference_price: '',
        order_num: '',
        postage_type: '',
        postage_price: '',
        browse: ''
      }],
      albumdetail: {},
      focusKey: '',
      focusData: null
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    // if (this.id) {
    //   this.getGoodsList();
    // }
    // this.getDetail();
    // this.getstore();
    // this.getadminList();
    // let ids = this.$route.query.ids || '';
    // if(ids.length){
    // document.addEventListener('click', this.hidePanel, false)
    (0, _warehouse.getPmDataApi)(this.$route.query).then(function (res) {
      _this2.goodsArr = res.data.list.map(function (item) {
        if (!item.auction_name) item.auction_name = item.product_name;
        delete item.product_name;
        var reference_price = null;
        if (item.reference_price) {
          reference_price = item.reference_price.split('-');
        }
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({
          refOne: reference_price && reference_price.length ? reference_price[0] : '',
          refTwo: reference_price && reference_price.length ? reference_price[1] : '',
          top_price: '',
          bond_price: '',
          postage_price: '',
          postage_type: '',
          browse: ''
        }, item), {}, {
          erp_product_id: item.id,
          bid_range_id: '',
          start_price: ''
        });
      });
      // console.log(this.goodsArr);
    });
    // getidsgoods({product_ids: ids.split(',')}).then(res=>{
    //   res.data.forEach((item,index)=>{
    //      item.order_num = index +1;
    //   })
    //   this.goodsArr = res.data;
    //   this.goodsIdArr = res.data.map(item=>{
    //     return item.product_id
    //   });
    //   setTimeout(() => {
    //     this.rowDrop();
    //   }, 1000);
    // })
    // }
    this.getBrandListApi();
    (0, _auction.bidRangeList)().then(function (res) {
      _this2.bidRList = res.data;
    });
    (0, _auction.meetingList)({
      page: 1,
      limit: 20
    }).then(function (res) {
      _this2.venceList = res.data.list;
      if (res.data.list.length) {
        _this2.formValidate.venue_id = res.data.list[0].venue_id;
        _this2.getalbum();
      }
    });
    // this.rowDrop()
    // this.getalbum();
  },
  methods: {
    inputPriceAction: function inputPriceAction(key, index) {
      var _this3 = this;
      if (this.priceT) clearTimeout(this.priceT);
      this.priceT = setTimeout(function () {
        if (_this3.goodsArr[index][key]) {
          var price = _this3.goodsArr[index][key].replace(/,/g, '');
          if (!isNaN(price)) {
            _this3.goodsArr[index][key] = (0, _utils.formatPriceNumber)(price, 2);
          }
        }
      }, 300);
    },
    remoteMethod: function remoteMethod(value, row) {
      var arr = ['', '', '固定运费', '包邮', '到付'];
      var index = arr.findIndex(function (item) {
        return item == value;
      });
      if (index > 1) {
        row.postage_type = index + '';
      }
    },
    inpFocusAction: function inpFocusAction(key, index) {
      if (key) {
        this.focusData = {
          key: key,
          index: index
        };
      } else {
        this.focusData = null;
      }
    },
    pasteExcel: function pasteExcel(eve) {
      var _this4 = this;
      // eve.preventDefault(); //阻止默认粘贴事件
      eve.stopPropagation(); //阻止事件冒泡
      if (!this.focusData) return false;
      var data = null;
      var clipboardData = window.clipboardData || eve.clipboardData; // IE || chrome
      data = clipboardData.getData('text/plain');
      data = data.replaceAll('\"', '');
      if (data.indexOf('\r\n') != -1) {
        var rowStrArray = data.split("\r\n"); // 拆成多行
        if (rowStrArray.length > 1) {
          eve.preventDefault();
          var fData = JSON.parse(JSON.stringify(this.focusData));
          var index = fData.index;
          var type = fData.key;
          rowStrArray.forEach(function (item, i) {
            var row = _this4.goodsArr[index + i];
            if (!row) return;
            item = item.replaceAll('\"', '');
            if (type.indexOf('.') === -1) {
              row[type] = item;
            } else {
              var typeObj = type.split('.');
              row[typeObj[0]][typeObj[1]] = item;
            }
            // this.saveoneflied(index + i, type, row.id, row );
            _this4.$set(_this4.goodsArr, index + i, row);
          });
          this.focusData = null;
        }
      }
    },
    oneBlue: function oneBlue() {
      if (this.headObj.refOne && this.headObj.refTwo) this.handName = '';
    },
    headClick: function headClick(val) {
      this.handName = val;
      this.$refs[val].focus();
    },
    onHeadChange: function onHeadChange(filed) {
      var _this5 = this;
      this.goodsArr.forEach(function (item, index) {
        if (filed == 'plus_price' || filed == 'start_price' || filed == 'bond_price' || filed == 'retain_price' || filed == 'top_price' || filed == 'postage_price') {
          item[filed] = (0, _utils.formatPriceNumber)(_this5.headObj[filed], 2);
        } else {
          item[filed] = _this5.headObj[filed];
        }
        _this5.$set(_this5.goodsArr, index, item);
      });
      if (filed == 'postage_type') this.handName = '';
      // console.log(this.headObj[filed]);
      // console.log(this.goodsArr);
      this.$forceUpdate();
      // this.handName = '';
    },
    //行拖拽
    rowDrop: function rowDrop() {
      var tbody = document.querySelector('.sorttable tbody');
      var _this = this;
      console.log(tbody);
      _sortablejs.default.create(tbody, {
        onEnd: function onEnd(_ref) {
          var newIndex = _ref.newIndex,
            oldIndex = _ref.oldIndex;
          var currRow = _this.goodsArr.splice(oldIndex, 1)[0];
          _this.goodsArr.splice(newIndex, 0, currRow);
        }
      });
    },
    selectalbum: function selectalbum(e) {
      var arr = this.albumArr.map(function (item) {
        return item.album_id;
      });
      var index = arr.indexOf(e);
      if (index > -1) {
        this.albumdetail = this.albumArr[index];
        console.log(this.albumdetail);
      }
    },
    changevenue: function changevenue(e) {
      this.getalbum();
    },
    alladd: function alladd() {
      var goodsArr = JSON.parse(JSON.stringify(this.goodsArr));
      var json = this.morejson[0];
      goodsArr.forEach(function (item) {
        if (json.start_price) {
          item.start_price = json.start_price;
        }
        if (json.plus_price) {
          item.plus_price = json.plus_price;
        }
        if (json.retain_price) {
          item.retain_price = json.retain_price;
        }
        if (json.bond_price) {
          item.bond_price = json.bond_price;
        }
        if (json.bid_range_id) {
          item.bid_range_id = json.bid_range_id;
        }
        if (json.reference_price) {
          item.reference_price = json.reference_price;
        }
        if (json.order_num) {
          item.order_num = json.order_num;
        }
        if (json.postage_type) {
          item.postage_type = json.postage_type;
        }
        if (json.postage_price) {
          item.postage_price = json.postage_price;
        }
        if (json.browse) {
          item.browse = json.browse;
        }
      });
      this.goodsArr = goodsArr;
    },
    getalbum: function getalbum() {
      var _this6 = this;
      (0, _auction.albumlist)({
        status: 3,
        venue_id: this.formValidate.venue_id,
        page: 1,
        limit: 20
      }).then(function (res) {
        _this6.albumArr = res.data.list;
        if (res.data.list.length) {
          _this6.formValidate.album_id = res.data.list[0].album_id;
          _this6.albumdetail = res.data.list[0];
        }
      });
    },
    // 品牌筛选；
    getBrandListApi: function getBrandListApi() {
      var _this7 = this;
      (0, _product.brandListApi)().then(function (res) {
        _this7.BrandList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getadminList: function getadminList() {
      var _this8 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this8.adminarr = res.data.list;
      });
    },
    getstore: function getstore() {
      var _this9 = this;
      (0, _product.getStoreList)().then(function (res) {
        _this9.storeArr = res.data.list;
      });
    },
    submitForm: function submitForm(formName) {
      var _this10 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // if(!this.goodsIdArr.length){
          //    return this.$message.error('请选择货品')
          // }
          if (!_this10.goodsArr.length) {
            return _this10.$message.error('请选择货品');
          }
          // bid_range_id
          var noBindRangeId = _this10.goodsArr.filter(function (item) {
            return !item.bid_range_id && !item.plus_price;
          });
          if (noBindRangeId && noBindRangeId.length) {
            return _this10.$message.error('请先设置拍品竞价阶梯或者加价幅度');
          }
          _this10.loading = true;
          var sortArr = _this10.goodsArr.filter(function (item) {
            return item.sort;
          });
          var sortArrOne = _this10.goodsArr.filter(function (item) {
            return !item.sort;
          });
          sortArr.sort(function (a, b) {
            return Number(a.sort) - Number(b.sort);
          });
          // 
          _this10.formValidate.productData = [].concat((0, _toConsumableArray2.default)(sortArr), (0, _toConsumableArray2.default)(sortArrOne));
          _this10.formValidate.productData.forEach(function (item) {
            if (item.refOne && item.refTwo) item.reference_price = "".concat(item.refOne, "-").concat(item.refTwo);else if (item.refOne) item.reference_price = "".concat(item.refOne);
          });
          (0, _warehouse.confirmPmApi)(_this10.formValidate).then(function (res) {
            _this10.loading = false;
            _this10.$message.success("保存成功");
            _this10.$router.back();
          }).catch(function () {
            _this10.loading = false;
          });
          // if (this.id) {
          //   confirmPmApi(this.id, this.formValidate)
          //     .then((res) => {
          //       this.loading = false;
          //       this.$message.success("保存成功");
          //       console.log(this.refser);
          //       if(this.refser){
          //         this.$emit('savesuccess', res.message)
          //       }else{
          //         this.$router.back();
          //       }
          //     })
          //     .catch((res) => {
          //       this.loading = false;
          //       // this.$message.error(res.message);
          //     });
          //   return;
          // }
          //   upgoods(this.formValidate)
          //   .then((res) => {
          //     this.loading = false;
          //     if(res.status == 200){
          //       this.$message.success("保存成功");
          //       this.$router.back();
          //     }
          //   })
          //   .catch((res) => {
          //     this.loading = false;
          //     // this.$message.error(res.message);
          //   });
        } else {
          return false;
        }
      });
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      //   this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.formValidate.stime = e[0];
      this.formValidate.etime = e[1];
    },
    getList: function getList() {
      var _this11 = this;
      var fun = _basic.goodsList;
      if (this.selectInventFlag) {
        this.tableFrom.product_keyword = this.tableFrom.keyword;
        fun = _warehouse.getinventorygoods;
      }
      this.tableFrom.warehouse_id = 0;
      fun(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          item.selected = false;
        });
        _this11.tableData.data = res.data.list;
        _this11.tableData.total = res.data.count;
        _this11.listLoading = false;
      }).catch(function (res) {
        _this11.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    // 商户列表；
    getMerSelect: function getMerSelect() {
      var _this12 = this;
      merSelectApi().then(function (res) {
        _this12.merSelect = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    selectInventGoods: function selectInventGoods() {
      this.selectFlag = true;
      this.getList();
      this.selectInventFlag = true;
    },
    selectActiveGoods: function selectActiveGoods() {
      this.selectFlag = true;
      this.selectInventFlag = false;
      this.getList();
    },
    saleGoods: function saleGoods(row, index) {
      if (this.goodsIdArr.indexOf(row.product_id) > -1) {
        return;
      }
      this.row = row;
      this.sindex = index;
      this.row.selected = true;
      this.goodsArr.push(row);
      this.goodsIdArr.push(row.product_id);
    },
    closeSelect: function closeSelect() {
      this.selectFlag = false;
    },
    delGoods: function delGoods(index, row, cindex) {
      var nindex = this.goodsIdArr.indexOf(row.product_id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
    },
    cancelSelect: function cancelSelect(index, row) {
      row.selected = false;
      var nindex = this.goodsIdArr.indexOf(row.product_id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
      return;
      row.selected = false;
      this.tableData.data.splice(index, 1);
      this.tableData.data.push(row);
    }
  }
};