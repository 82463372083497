var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "min-height": "100vh" } },
    [
      _c("header-breadcrumb", { attrs: { name: "盘点记录", "has-back": "" } }, [
        _c(
          "div",
          { attrs: { slot: "btn" }, slot: "btn" },
          [
            _c(
              "el-dropdown",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "merchantErpV2ProductDownloadAuctionImportTemplate",
                    expression:
                      "'merchantErpV2ProductDownloadAuctionImportTemplate'",
                  },
                ],
                attrs: { type: "primary", placement: "top" },
              },
              [
                _c("el-button", { attrs: { type: "primary", size: "small" } }, [
                  _vm._v("导出"),
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: { click: _vm.exportOrder },
                          },
                          [_vm._v("导出当前数据")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: { click: _vm.getExportFileList },
                          },
                          [_vm._v("查看导出记录")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.id
                },
                data: _vm.tableData.data,
                size: "mini",
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "图片", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            scope.row.erpProduct.warehouse_image &&
                            scope.row.erpProduct.warehouse_image.length
                              ? _c("el-image", {
                                  attrs: {
                                    src:
                                      scope.row.erpProduct.warehouse_image[0] +
                                      "!120a",
                                    "preview-src-list":
                                      scope.row.erpProduct.warehouse_image,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.erpProduct.contract_image &&
                            scope.row.erpProduct.contract_image.length
                              ? _c("el-image", {
                                  attrs: {
                                    src:
                                      scope.row.erpProduct.contract_image[0] +
                                      "!120a",
                                    "preview-src-list":
                                      scope.row.erpProduct.contract_image,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "erpProduct.product_name",
                  label: "货品名称",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "属性", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          _vm._l(
                            scope.row.erpProduct.attribute,
                            function (m, i) {
                              return _c("span", { key: "attr_" + i }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(m) +
                                    ",\n                "
                                ),
                              ])
                            }
                          ),
                          0
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "标签", "min-width": "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.erpProduct &&
                          scope.row.erpProduct.labelNames
                          ? _vm._l(
                              scope.row.erpProduct.labelNames,
                              function (item, index) {
                                return _c("span", { key: "l" + index }, [
                                  _vm._v(
                                    "\n            # " +
                                      _vm._s(item) +
                                      "\n          "
                                  ),
                                ])
                              }
                            )
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "货架", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                scope.row.status == 0 ? "待盘点" : "已盘点"
                              ) +
                              " "
                          ),
                          scope.row.abnormal_status == 1
                            ? _c("span", [_vm._v("-异常")])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "盘库照片", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.credentials
                          ? _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: scope.row.credentials,
                                    "preview-src-list": [scope.row.credentials],
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div", [_vm._v("未上传")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block paginaBox" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total,  prev, pager, next, sizes",
                  total: _vm.tableData.total,
                  background: "",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }