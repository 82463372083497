var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout", staticStyle: { background: "#f7f7f7" } },
    [
      _c(
        "el-container",
        { staticStyle: { width: "1345px", margin: "0 auto" } },
        [
          _c("el-main", { staticStyle: { "box-sizing": "border-box" } }, [
            _c(
              "div",
              { staticClass: "layout-tool" },
              [
                _c(
                  "el-scrollbar",
                  { style: _vm.scrollHeight },
                  [
                    _c(
                      "el-collapse",
                      {
                        attrs: {
                          value: [
                            "basics",
                            "advanced",
                            "enterprise",
                            "other",
                            "marketing",
                            "company",
                          ],
                        },
                      },
                      _vm._l(_vm.panels, function (mounted, index) {
                        return _c(
                          "el-collapse-item",
                          { key: index, attrs: { name: index } },
                          [
                            _c("template", { slot: "title" }, [
                              _c("i", { staticClass: "le-icon le-icon-arrow" }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(mounted.title))]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "fm-collapse-tool" },
                              [
                                _vm._l(
                                  mounted.children,
                                  function (item, index) {
                                    return [
                                      item.name == "task" &&
                                      _vm.$manifest("task", "status")
                                        ? _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass: "widget",
                                              attrs: {
                                                id:
                                                  item.title == "视频"
                                                    ? "guide001"
                                                    : "",
                                                draggable: true,
                                                "data-type": "module",
                                              },
                                              on: {
                                                dragstart: function ($event) {
                                                  return _vm.dragStart(item)
                                                },
                                                dragend: _vm.dragEnd,
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "widget-icon",
                                                attrs: { src: item.icon },
                                              }),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(_vm._s(item.title)),
                                              ]),
                                            ]
                                          )
                                        : _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass: "widget",
                                              attrs: {
                                                id:
                                                  item.title == "视频"
                                                    ? "guide001"
                                                    : "",
                                                draggable: true,
                                                "data-type": "module",
                                              },
                                              on: {
                                                dragstart: function ($event) {
                                                  return _vm.dragStart(item)
                                                },
                                                dragend: _vm.dragEnd,
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "widget-icon",
                                                attrs: { src: item.icon },
                                              }),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(_vm._s(item.title)),
                                              ]),
                                            ]
                                          ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "clears" }),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          2
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "layout-body" },
              [
                _c(
                  "el-scrollbar",
                  {
                    ref: "scrollbar",
                    style: _vm.scrollHeight,
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handlePackage($event)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "layout-phone",
                        style: {
                          minHeight:
                            !_vm.pageInfo.open_header ||
                            _vm.pageInfo.headerSetting.header_air
                              ? "700px"
                              : "765px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "layout-phone-body",
                            attrs: { id: "guide002" },
                          },
                          [_c("router-view", { ref: "childView" })],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.is_lock
              ? _c(
                  "div",
                  {
                    staticClass: "layout-attribute display-flex",
                    attrs: { id: "guide004" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c(
                          "el-scrollbar",
                          {
                            ref: "scrollMenuRef",
                            staticStyle: { position: "relative" },
                            style: _vm.scrollHeight,
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "h-t-btn flex" },
                              [
                                _c("div", { staticClass: "flex-one" }),
                                _vm._v(" "),
                                _vm.temid
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: _vm.handlePageModelSave,
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "btn-icon",
                                            attrs: {
                                              src: "https://saas.cdn.yunzongbu.cn/merchant/333425/20241226/image/1735197846298725951.png",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("另存新模版")]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handlePageModelSave(1)
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "btn-icon",
                                            attrs: {
                                              src: "https://saas.cdn.yunzongbu.cn/merchant/333425/20241226/image/1735197846298725951.png",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("保存模版")]),
                                        ]
                                      ),
                                    ]
                                  : _vm.type == "manage"
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: _vm.handlePageModelSave,
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "btn-icon",
                                            attrs: {
                                              src: "https://saas.cdn.yunzongbu.cn/merchant/333425/20241226/image/1735197846298725951.png",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("保存")]),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: { click: _vm.awaitAction },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "btn-icon",
                                            attrs: {
                                              src: "https://saas.cdn.yunzongbu.cn/merchant/333425/20241226/image/1735197846298725951.png",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("同步到公众号")]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      !_vm.is_publish
                                        ? [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click:
                                                    _vm.handlePageModelSave,
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "btn-icon",
                                                  attrs: {
                                                    src: "https://saas.cdn.yunzongbu.cn/merchant/333425/20241226/image/1735197846298725951.png",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v("另存新模版"),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: _vm.handlePageSave,
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "btn-icon",
                                                  attrs: {
                                                    src: "https://saas.cdn.yunzongbu.cn/merchant/333425/20241226/image/1735197846298725951.png",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("保存")]),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "r-tab" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "item",
                                  class: { active: _vm.tabActive == 0 },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setActiveTitleAction(0)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "btn-icon",
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/merchant/333425/20241226/image/1735197846300476330.png",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("内容")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "item",
                                  class: { active: _vm.tabActive == 1 },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setActiveTitleAction(1)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "btn-icon",
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/merchant/333425/20241226/image/1735197846300874161.png",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("样式")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "item",
                                  class: { active: _vm.tabActive == 2 },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setActiveTitleAction(2)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "btn-icon",
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/merchant/333425/20241226/image/1735197846299673146.png",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("组件样式")]),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.temid
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "item",
                                      class: { active: _vm.tabActive == 3 },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setActiveTitleAction(3)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "btn-icon",
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/merchant/333425/20241226/image/1735197846293865198.png",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("模版设置")]),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "r-scroll-h" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.tabActive == 0,
                                    expression: "tabActive == 0",
                                  },
                                ],
                                staticStyle: { position: "relative" },
                              },
                              [
                                _c("pageAttribute", {
                                  attrs: {
                                    configuration: _vm.configuration,
                                    "color-configuration":
                                      _vm.color_configuration,
                                  },
                                  on: {
                                    setTheme: _vm.setThemeAction,
                                    setConfiguration: _vm.setConfiguration,
                                    generateColors: _vm.generateColors,
                                  },
                                  model: {
                                    value: _vm.pageInfo.commonData,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.pageInfo, "commonData", $$v)
                                    },
                                    expression: "pageInfo.commonData",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "r-main-title" }, [
                                  _vm._v("文章主体内容"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "r-main-content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "area" },
                                      [
                                        _c(
                                          "draggable",
                                          _vm._b(
                                            {
                                              attrs: { list: _vm.pages },
                                              on: {
                                                start: _vm.start,
                                                end: _vm.end,
                                              },
                                            },
                                            "draggable",
                                            _vm.$attrs,
                                            false
                                          ),
                                          [
                                            _vm._l(_vm.pages, function (m, i) {
                                              return [
                                                m.name !== "blank"
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          key: "area_" + i,
                                                          staticClass:
                                                            "area-item",
                                                          class: {
                                                            show:
                                                              i == _vm.showI,
                                                          },
                                                          on: {
                                                            mouseenter:
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.showI = i
                                                              },
                                                            mouseleave:
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.showI = -1
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: "https://saas.cdn.yunzongbu.cn/merchant/23/20241223/image/1734947020513869844.png",
                                                              alt: "",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ]
                                            }),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.pages, function (m, i) {
                                      return [
                                        m.name !== "blank"
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  key: "pages_" + i,
                                                  staticClass: "item",
                                                  class: {
                                                    "menu-active":
                                                      i == _vm.index ||
                                                      i == _vm.showI,
                                                  },
                                                  attrs: {
                                                    id: "page_attribute_" + i,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "t",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setColumnIndex(
                                                            i
                                                          )
                                                        },
                                                        mouseenter: function (
                                                          $event
                                                        ) {
                                                          _vm.showI = i
                                                        },
                                                        mouseleave: function (
                                                          $event
                                                        ) {
                                                          _vm.showI = -1
                                                        },
                                                      },
                                                    },
                                                    [
                                                      m.name == "auction_weChat"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "t-name",
                                                              class: {
                                                                active:
                                                                  i ==
                                                                  _vm.index,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "拍品导览 "
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    m.content
                                                                      .data
                                                                      .length
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "set-field",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.showSetFieldAction(
                                                                          i
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "设置字段"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "t-name",
                                                              class: {
                                                                active:
                                                                  i ==
                                                                  _vm.index,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(m.title)
                                                              ),
                                                            ]
                                                          ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        { staticClass: "jt" },
                                                        [
                                                          _c("i", {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  i !=
                                                                  _vm.index,
                                                                expression:
                                                                  "i != index",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "el-icon-arrow-down",
                                                          }),
                                                          _vm._v(" "),
                                                          _c("i", {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  i ==
                                                                  _vm.index,
                                                                expression:
                                                                  "i == index",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "el-icon-arrow-up",
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  i == _vm.index
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "c" },
                                                        [
                                                          _vm.modules[
                                                            _vm.attribute
                                                          ]
                                                            ? _c(
                                                                _vm.modules[
                                                                  _vm.attribute
                                                                ].attr_content,
                                                                {
                                                                  tag: "component",
                                                                  on: {
                                                                    editText:
                                                                      function (
                                                                        e
                                                                      ) {
                                                                        return _vm.editTextAction(
                                                                          e,
                                                                          _vm.index
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.tabActive == 1,
                                    expression: "tabActive == 1",
                                  },
                                ],
                              },
                              [
                                _c("pageAttrStyle", {
                                  attrs: {
                                    configuration: _vm.configuration,
                                    "color-configuration":
                                      _vm.color_configuration,
                                  },
                                  on: {
                                    setTheme: _vm.setThemeAction,
                                    setConfiguration: _vm.setConfiguration,
                                    generateColors: _vm.generateColors,
                                  },
                                  model: {
                                    value: _vm.pageInfo.commonData,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.pageInfo, "commonData", $$v)
                                    },
                                    expression: "pageInfo.commonData",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.tabActive == 2
                              ? _c("div", [
                                  _vm.index >= 0
                                    ? _c(
                                        "div",
                                        { staticStyle: { padding: "0 24px" } },
                                        [
                                          _vm.modules[_vm.attribute]
                                            ? _c(
                                                _vm.modules[_vm.attribute]
                                                  .attr_style,
                                                {
                                                  tag: "component",
                                                  on: {
                                                    editText: function (e) {
                                                      return _vm.editTextAction(
                                                        e,
                                                        _vm.index
                                                      )
                                                    },
                                                  },
                                                }
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-weight": "500",
                                            padding: "24px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                请选择组件\n              "
                                          ),
                                        ]
                                      ),
                                ])
                              : _vm.tabActive == 3
                              ? _c("div", [
                                  _vm.index >= 0
                                    ? _c(
                                        "div",
                                        { staticStyle: { padding: "0 24px" } },
                                        [
                                          _vm.modules[_vm.attribute]
                                            ? _c(
                                                _vm.modules[_vm.attribute]
                                                  .attr_template,
                                                {
                                                  tag: "component",
                                                  on: {
                                                    editText: function (e) {
                                                      return _vm.editTextAction(
                                                        e,
                                                        _vm.index
                                                      )
                                                    },
                                                  },
                                                }
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-weight": "500",
                                            padding: "24px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                请选择组件\n              "
                                          ),
                                        ]
                                      ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "preview-show" },
        [
          _c("preview", {
            attrs: {
              pages: _vm.pages,
              "page-info": _vm.pageInfo.commonData,
              title: _vm.pageInfo.commonData
                ? _vm.pageInfo.commonData.title
                : "",
              price: _vm.modelprice,
              marketid: _vm.marketid,
            },
            on: { closeAction: _vm.closeAction },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.showModelFlag,
            width: "960px",
            top: "2vh",
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showModelFlag = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c(
              "div",
              { staticClass: "poster-dialog" },
              [
                _vm.temid ? [_vm._v("修改模版")] : [_vm._v("创建模版")],
                _vm._v(" "),
                _c("img", {
                  staticClass: "posterImg",
                  attrs: {
                    src: require("@/assets/images/poster/close.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showModelFlag = false
                    },
                  },
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.saveLoading,
                  expression: "saveLoading",
                },
              ],
              staticStyle: { "padding-bottom": "20px" },
            },
            [
              _c(
                "div",
                { staticClass: "poster-content" },
                [
                  _c("div", { staticClass: "poster-formtit" }, [
                    _vm._v("公众号文章类型"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "poster-type-container" },
                    _vm._l(_vm.temTypeList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "poster-type-item",
                          class:
                            _vm.formValidate.template_tag == index
                              ? "type-active"
                              : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangeType(index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "poster-formtit" }, [
                    _vm._v("文章风格"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "poster-type-container" },
                    _vm._l(_vm.templateStyle, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "poster-type-item",
                          class:
                            _vm.formValidate.template_style == index
                              ? "type-active"
                              : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangeStyle(index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  !_vm.formValidate.noEditMer
                    ? [
                        _c("div", { staticClass: "poster-formtit" }, [
                          _vm._v("模版类型"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "poster-type-container" }, [
                          _c(
                            "div",
                            {
                              staticClass: "poster-type-item",
                              class:
                                _vm.formValidate.template_level == 1
                                  ? "type-active"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.onChangeQuality(1)
                                },
                              },
                            },
                            [_vm._v("普通")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "poster-type-item",
                              class:
                                _vm.formValidate.template_level == 2
                                  ? "type-active"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.onChangeQuality(2)
                                },
                              },
                            },
                            [_vm._v("高级")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "poster-type-item",
                              class:
                                _vm.formValidate.template_level == 3
                                  ? "type-active"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.onChangeQuality(3)
                                },
                              },
                            },
                            [_vm._v("定制")]
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formValidate.template_level == 3 &&
                  !_vm.formValidate.noEditMer
                    ? [
                        _c("div", { staticClass: "poster-formtit" }, [
                          _vm._v("选择商户"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "poster-type-container" },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请选择商户",
                                  filterable: "",
                                  remote: "",
                                  "remote-method": _vm.getMerchant,
                                },
                                model: {
                                  value: _vm.formValidate.mer_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "mer_id", $$v)
                                  },
                                  expression: "formValidate.mer_id",
                                },
                              },
                              _vm._l(_vm.merList, function (item, index) {
                                return _c("el-option", {
                                  key: item.mer_id,
                                  attrs: {
                                    label: item.mer_name,
                                    value: item.mer_id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "poster-formtit" }, [
                    _vm._v("模版名称"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "poster-type-container" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入模板名称" },
                        model: {
                          value: _vm.formValidate.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "title", $$v)
                          },
                          expression: "formValidate.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "poster-formtit" }, [
                    _vm._v("模版封面"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "poster-type-container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "upload-img-view item-flex-center",
                        staticStyle: { width: "60px", height: "60px" },
                        on: { click: _vm.modalPicTap },
                      },
                      [
                        _vm.formValidate.image
                          ? _c("img", {
                              staticStyle: {
                                width: "60px",
                                height: "60px",
                                display: "block",
                              },
                              attrs: { src: _vm.formValidate.image },
                            })
                          : _c("img", {
                              staticClass: "upload-icon",
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                alt: "",
                              },
                            }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.formValidate.noEditMer
                    ? _c("div", { staticClass: "poster-formtit" }, [
                        _vm._v("文章标签"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.formValidate.noEditMer
                    ? _c(
                        "div",
                        { staticClass: "poster-type-container" },
                        _vm._l(_vm.tagList, function (item, i) {
                          return _c(
                            "div",
                            {
                              key: "tag_" + i,
                              staticClass: "poster-type-item",
                              class:
                                _vm.formValidate.template_label == item.id
                                  ? "type-active"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.onChangeLabel(item.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex align-items-c",
                  staticStyle: { width: "700px", margin: "20px auto 0 auto" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "poster-btn",
                      on: { click: _vm.modelSubmit },
                    },
                    [_vm._v("确认")]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置字段",
            visible: _vm.showSetField,
            width: "350px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSetField = $event
            },
            close: function ($event) {
              _vm.showSetField = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "field" },
            [
              _vm.modules[_vm.attribute]
                ? _c(_vm.modules[_vm.attribute].attr_list, {
                    tag: "component",
                    on: {
                      editText: function (e) {
                        return _vm.editTextAction(e, _vm.index)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c(
                "el-button",
                {
                  staticClass: "w300",
                  attrs: { type: "info" },
                  on: {
                    click: function ($event) {
                      _vm.showSetField = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }