"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AutolabelCreateApi = AutolabelCreateApi;
exports.AutolabelDeleteApi = AutolabelDeleteApi;
exports.AutolabelLstApi = AutolabelLstApi;
exports.AutolabelSyncApi = AutolabelSyncApi;
exports.AutolabelUpdateApi = AutolabelUpdateApi;
exports.auctionRadio = auctionRadio;
exports.batchChangeGroupApi = batchChangeGroupApi;
exports.captchaApi = captchaApi;
exports.changeGroupApi = changeGroupApi;
exports.changeLabelsApi = changeLabelsApi;
exports.checkUserIdCardImage = checkUserIdCardImage;
exports.createUserApi = createUserApi;
exports.createUserForm = createUserForm;
exports.createVerifyApi = createVerifyApi;
exports.editFormApi = editFormApi;
exports.editVerifyApi = editVerifyApi;
exports.feedbackListApi = feedbackListApi;
exports.getBaseInfo = getBaseInfo;
exports.getInfo = getInfo;
exports.getMenusApi = getMenusApi;
exports.getUserDetail = getUserDetail;
exports.getUserNumApi = getUserNumApi;
exports.groupDeleteApi = groupDeleteApi;
exports.groupEditApi = groupEditApi;
exports.groupFormApi = groupFormApi;
exports.groupLstApi = groupLstApi;
exports.issueIntegralApi = issueIntegralApi;
exports.labelDeleteApi = labelDeleteApi;
exports.labelEditApi = labelEditApi;
exports.labelFormApi = labelFormApi;
exports.labelLstApi = labelLstApi;
exports.login = login;
exports.loginConfigApi = loginConfigApi;
exports.logout = logout;
exports.merchantUpdate = merchantUpdate;
exports.passwordFormApi = passwordFormApi;
exports.payauctionRadio = payauctionRadio;
exports.reduceIntegralApi = reduceIntegralApi;
exports.registercodeCreatedApi = registercodeCreatedApi;
exports.registercodeDelApi = registercodeDelApi;
exports.registercodeGroupList = registercodeGroupList;
exports.registercodeListApi = registercodeListApi;
exports.searchUserLstApi = searchUserLstApi;
exports.setRegisterCodeStatus = setRegisterCodeStatus;
exports.stafflogin = stafflogin;
exports.switchUserStatusApi = switchUserStatusApi;
exports.unlockOrderAllApi = unlockOrderAllApi;
exports.unlockOrderApi = unlockOrderApi;
exports.updateUserApi = updateUserApi;
exports.updateUserForm = updateUserForm;
exports.updateUserMarkApi = updateUserMarkApi;
exports.upgradeList = upgradeList;
exports.upgradeReject = upgradeReject;
exports.upgradeRejectSure = upgradeRejectSure;
exports.userAddressAddApi = userAddressAddApi;
exports.userAddressListApi = userAddressListApi;
exports.userAddressUpdApi = userAddressUpdApi;
exports.userBidAllstatics = userBidAllstatics;
exports.userCouponApi = userCouponApi;
exports.userLookRadio = userLookRadio;
exports.userLstApi = userLstApi;
exports.userOrderApi = userOrderApi;
exports.userUpAuctionAllstatics = userUpAuctionAllstatics;
exports.userUpAuctionList = userUpAuctionList;
exports.userVerifyApi = userVerifyApi;
exports.userVerifyListApi = userVerifyListApi;
var _request = _interopRequireDefault(require("./request"));
/**
  * @description 验证码
  */
function captchaApi() {
  return _request.default.get("captcha");
}
/**
  * @description 登录
  */
function login(data) {
  return _request.default.post("login", data);
}
/**
  * @description 登录
  */
function stafflogin(data) {
  return _request.default.post("staff_login", data);
}
/**
 * @description 登录页配置
 */
function loginConfigApi() {
  return _request.default.get("login_config");
}

/**
 * @description 退出登录
 */
function logout() {
  return _request.default.get("logout");
}

/**
 * @description 修改密码
 */
function passwordFormApi() {
  return _request.default.get("system/admin/edit/password/form");
}

/**
 * @description 修改自己的信息
 */
function editFormApi() {
  return _request.default.get("system/admin/edit/form");
}
/**
 * @description 菜单
 */
function getMenusApi(data) {
  return _request.default.get("menus", data);
}
function getInfo(token) {
  return (0, _request.default)({
    url: '/vue-element-admin/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}
/**
 * @description 标签
 */
function getBaseInfo() {
  return _request.default.get("info");
}
/**
 * @description 用户标签 -- 列表
 */
function labelLstApi(data) {
  return _request.default.get('user/label/lst', data);
}
/**
 * @description 用户标签 -- 添加表单
 */
function labelFormApi() {
  return _request.default.get('user/label/form');
}
/**
 * @description 用户标签 -- 编辑表单
 */
function labelEditApi(id) {
  return _request.default.get('user/label/form/' + id);
}
/**
 * @description 用户标签 -- 删除
 */
function labelDeleteApi(id) {
  return _request.default.delete("user/label/".concat(id));
}
/**
 * @description 自动标签 -- 添加
 */
function AutolabelCreateApi(data) {
  return _request.default.post('auto_label/create', data);
}
/**
 * @description 自动标签 -- 列表
 */
function AutolabelLstApi(data) {
  return _request.default.get('auto_label/lst', data);
}
/**
 * @description 自动标签 -- 编辑
 */
function AutolabelUpdateApi(id, data) {
  return _request.default.post('auto_label/update/' + id, data);
}
/**
 * @description 自动标签 -- 删除
 */
function AutolabelDeleteApi(id) {
  return _request.default.delete("auto_label/delete/".concat(id));
}
/**
 * @description 自动标签 -- 更新
 */
function AutolabelSyncApi(id) {
  return _request.default.post('auto_label/sync/' + id);
}
/**
 * @description 用户 -- 列表
 */
function userLstApi(data) {
  return _request.default.get('user/list', data);
}
/**
 * @description 用户 -- 列表
 */
function searchUserLstApi(data) {
  return _request.default.get('user/searchList', data);
}
/**
 * @description 用户 -- 详情消费记录
 */
function userOrderApi(uid, data) {
  return _request.default.get("user/order/".concat(uid), data);
}
/**
 * @description 用户 -- 详情优惠券
 */
function userCouponApi(uid, data) {
  return _request.default.get("user/coupon/".concat(uid), data);
}
/**
 * @description 用户 -- 批量设置分组
 */
function changeGroupApi(id) {
  return _request.default.get('user/change_label/form/' + id);
}
function changeLabelsApi(data) {
  return _request.default.post('user/batch_change_label', data);
}
/**
 * @description 修改商户基本信息
 */
function merchantUpdate(data) {
  return _request.default.post('/info/update', data);
}

/**
 * @description 创建用户Api
 */
function createUserApi(data) {
  return _request.default.post('user/createUser', data);
}
/**
 * @description 创建用户
 */
function createUserForm(data) {
  return _request.default.get('user/createForm');
}
/**
 * @description 修改用户
 */
function updateUserForm(id) {
  return _request.default.get('user/createForm');
}
/**
 * @description 获取用户详情
 */
function getUserDetail(id) {
  return _request.default.get("user/detail/".concat(id));
}
/**
 * @description 用户分组 -- 编辑表单
 * @param {Object} param params {Object} 传值参数
 */
function groupEditApi(id) {
  return _request.default.get('user/group/form/' + id);
}
/**
 * @description 用户分组 -- 添加表单
 */
function groupFormApi() {
  return _request.default.get('user/group/form');
}
/**
 * @description 用户分组 -- 列表
 */
function groupLstApi(data) {
  return _request.default.get('user/group/lst', data);
}
/**
 * @description 用户分组 -- 删除
 */
function groupDeleteApi(id) {
  return _request.default.delete("user/group/".concat(id));
}
/**
 * @description 用户列表 -- 批量设置分组
 */
function batchChangeGroupApi(data) {
  return _request.default.get("user/batch_change_group/form", data);
}
/**
 * @description 用户参拍统计
 */
function userBidAllstatics(id) {
  return _request.default.get("user/statistical/bidRecordData/".concat(id));
}
/**
 * @description 用户送拍记录统计-委托标的清单
 */
function userUpAuctionAllstatics(id) {
  return _request.default.get("user/statistical/entrustProductData/".concat(id));
}
/**
 * @description 用户送拍记录列表
 */
function userUpAuctionList(data) {
  return _request.default.get("erp/v2/product/userEntrustList", data);
  // return request.get(`erp/entrust/order/uidByList`, data);
}
/**
 * @description 用户浏览拍品类目占比
 */
function userLookRadio(id) {
  return _request.default.get("user/statistical/visitAuctionCategoryChartsData/".concat(id));
}
/**
 * @description 用户参拍类目占比
 */
function auctionRadio(id) {
  return _request.default.get("user/statistical/bidRecordCategoryChartsData/".concat(id));
}
/**
 * @description 用户成交类目占比
 */
function payauctionRadio(id) {
  return _request.default.get("user/statistical/dealCategoryChartsData/".concat(id));
}
/**
 * @description 用户审核列表
 */
function userVerifyListApi(data) {
  return _request.default.get("user/verify/list", data);
}
/**
 * @description 用户审核
 */
function userVerifyApi(id, data) {
  return _request.default.post("user/verify/switch/".concat(id), data);
}

/**
 * 身份证照片校验
 */
function checkUserIdCardImage(data) {
  return _request.default.post("user/checkUserIdCardImage", data);
}

/**
 * 修改用户状态 0 禁用  1正常
 */
function switchUserStatusApi(uid, data) {
  return _request.default.post("user/switchStatus/".concat(uid), data);
}

/**
 * 获取用户数量
 */
function getUserNumApi(data) {
  return _request.default.get("system/sms/getUserNum", data);
}

/**
 * 创建实名认证
 */
function createVerifyApi(data) {
  return _request.default.post("user/verify/create", data);
}

/**
 * 修改实名认证
 */
function editVerifyApi(data) {
  return _request.default.post("user/verify/edit", data);
}

/**
 * 解锁
 */
function unlockOrderApi(data) {
  return _request.default.post("user/unlockOrder", data);
}

/**
 * 全部解锁
 */
function unlockOrderAllApi(data) {
  return _request.default.post("user/unlockOrderAll", data);
}

/**
 * 发放积分
 */
function issueIntegralApi(data) {
  return _request.default.post("UserIntegral/issueIntegral", data);
}

/**
 * 扣减积分
 */
function reduceIntegralApi(data) {
  return _request.default.post("UserIntegral/reduceIntegral", data);
}

/**
 * 设置注册码开关
 */
function setRegisterCodeStatus(data) {
  return _request.default.post("/user/registercode/setRegisterCodeStatus", data);
}

/**
 * 注册码组列表
 */
function registercodeGroupList(data) {
  return _request.default.get("/user/registercode/groupList", data);
}

/**
 * 创建注册码 输入数量
 */
function registercodeCreatedApi(data) {
  return _request.default.post("/user/registercode/create", data);
}

/**
 * 删除注册码组
 */
function registercodeDelApi(data) {
  return _request.default.post("/user/registercode/del", data);
}

/**
 * 查看注册码列表
 */
function registercodeListApi(data) {
  return _request.default.get("/user/registercode/codeList", data);
}

/**
 * 用户收货地址列表
 */
function userAddressListApi(data) {
  return _request.default.get("/user/address/list", data);
}

/**
 * 
 */
function userAddressUpdApi(id, data) {
  return _request.default.post("/user/address/update/".concat(id), data);
}

/**
 * 
 */
function userAddressAddApi(data) {
  return _request.default.post("/user/address/create", data);
}

/**
 * 获取注销申请列表
 */
function upgradeList(data) {
  return _request.default.post("/user/logout/getList", data);
}
/**
 * 注销申请驳回注销
 */

function upgradeReject(data) {
  return _request.default.post("/user/logout/reject", data);
}

/**
 * 确认注销
 */
function upgradeRejectSure(data) {
  return _request.default.post("/user/logout/agree", data);
}

/**
 * 修改备注
 */
function updateUserMarkApi(id, data) {
  return _request.default.post("/user/updateUserMark/".concat(id), data);
}
function updateUserApi(id, data) {
  return _request.default.post("/user/updateUser/".concat(id), data);
}
function feedbackListApi(data) {
  return _request.default.get("/user/feedback/lst", data);
}