var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wallet-common" },
    [
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 170px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "auctionRef",
              staticStyle: { width: "100%", color: "#000" },
              attrs: {
                data: _vm.tableData.data,
                border: "",
                height: "100%",
                "highlight-current-row": "",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "30", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            staticClass: "tabPop",
                            attrs: {
                              placement: "top-start",
                              width: "100",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "spBlock onHand",
                                  class: { check: _vm.chkName === "dan" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandle("dan", scope.$index)
                                    },
                                  },
                                },
                                [_vm._v("选中本页")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", {
                              attrs: {
                                slot: "reference",
                                value:
                                  (_vm.chkName === "dan" &&
                                    _vm.checkedPage.indexOf(
                                      _vm.tableFrom.page
                                    ) > -1) ||
                                  _vm.chkName === "duo",
                              },
                              on: { change: _vm.changeType },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            value:
                              _vm.checkedIds.indexOf(scope.row.extract_id) >
                                -1 ||
                              (_vm.chkName === "duo" &&
                                _vm.noChecked.indexOf(scope.row.extract_id) ===
                                  -1),
                          },
                          on: {
                            change: function (v) {
                              return _vm.changeOne(v, scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "头像",
                  width: "50",
                  align: "center",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticStyle: {
                            width: "25px",
                            height: "25px",
                            "margin-top": "6px",
                          },
                          attrs: {
                            src:
                              scope.row.user.avatar +
                              (scope.row.user.avatar.indexOf(
                                "https://saas.cdn.yunzongbu.cn"
                              ) === -1
                                ? ""
                                : "!120a"),
                            alt: "",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "收款人昵称",
                  width: "150",
                  "search-key": "user_nickname",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "color-lan",
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(scope.row.user.uid)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.user.nickname))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "真实姓名",
                  width: "100",
                  "search-key": "user_real_name",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.user.real_name) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "手机号",
                  width: "100",
                  "search-key": "user_phone",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.user.phone) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "付款金额",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.extract_price
                          ? _c("div", { staticClass: "num" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    scope.row.extract_price,
                                    2
                                  )
                                ) + "元"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "付款状态",
                  width: "100",
                  "search-key": "status",
                  "search-type": "radio",
                  prop: "",
                  options: [
                    { label: "已作废", value: "-1" },
                    { label: "未支付", value: "0" },
                    { label: "已支付", value: "1" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 0
                          ? _c("div", [_vm._v("未支付")])
                          : scope.row.status === 1
                          ? _c("div", [_vm._v("已支付")])
                          : scope.row.status === 2
                          ? _c("div", [_vm._v("待确认")])
                          : scope.row.status === -1
                          ? _c("div", [_vm._v("已作废")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "收款信息",
                  "min-width": "400",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrustUser &&
                        scope.row.entrustUser.account_bank
                          ? _c("span", [
                              _vm._v(
                                "开户行：" +
                                  _vm._s(scope.row.entrustUser.account_bank)
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.entrustUser &&
                        scope.row.entrustUser.account_name
                          ? _c("span", [
                              _vm._v(
                                "户名：" +
                                  _vm._s(scope.row.entrustUser.account_name)
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.entrustUser &&
                        scope.row.entrustUser.account_bank_card_number
                          ? _c("span", [
                              _vm._v(
                                "卡号：" +
                                  _vm._s(
                                    scope.row.entrustUser
                                      .account_bank_card_number
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "付款单号",
                  width: "180",
                  "search-key": "order_no",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.order_no))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "凭证",
                  width: "50",
                  align: "center",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.payment_evidence
                          ? _c("el-image", {
                              staticStyle: { width: "25px", height: "25px" },
                              attrs: {
                                src: scope.row.payment_evidence,
                                "preview-src-list": [
                                  scope.row.payment_evidence || "",
                                ],
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "付款单创建时间",
                  width: "180",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.create_time))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "制单人",
                  width: "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row.admin ? scope.row.admin.real_name : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  prop: "",
                  "search-query": _vm.searchQuery,
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "打印付款单",
                              placement: "top",
                              effect: "light",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-printer",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.printAllAction(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "操作",
                  width: "120",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status != 1 && scope.row.status != -1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "font-size": "14px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onOpenVoid(scope.row)
                                  },
                                },
                              },
                              [_vm._v("作废")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 0 || scope.row.status == 2
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "font-size": "14px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onOpenPay(scope.row)
                                  },
                                },
                              },
                              [_vm._v("确认付款")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-b orderFoot" },
        [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.allCheck
                        ? "已选择  " + _vm.tableData.total + "  条"
                        : "已选择  " + _vm.checkedIds.length + "  条"
                    ) +
                    " "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: {
                    size: "small",
                    disabled: !(_vm.checkedIds.length > 0 || _vm.allCheck),
                    type: "primary",
                  },
                  on: { click: _vm.onOpenPayAll },
                },
                [_vm._v("批量确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: {
                    size: "small",
                    disabled: !(_vm.checkedIds.length > 0 || _vm.allCheck),
                    type: "primary",
                  },
                  on: { click: _vm.onOpenVoidAll },
                },
                [_vm._v("批量作废")]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown",
                { attrs: { type: "primary", placement: "top" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary", size: "small" },
                    },
                    [_vm._v("导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { size: "small", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.exportOrder(2)
                                },
                              },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { size: "small", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.getExportFileList(2)
                                },
                              },
                            },
                            [_vm._v("导出记录")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            center: "",
            title: "查看凭证",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl + "!m640", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.viodVisble,
            "close-on-click-modal": false,
            center: "",
            title: "",
            width: "300px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.viodVisble = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { "label-width": "70px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "作废理由" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入作废理由" },
                    model: {
                      value: _vm.viodForm.fail_msg,
                      callback: function ($$v) {
                        _vm.$set(_vm.viodForm, "fail_msg", $$v)
                      },
                      expression: "viodForm.fail_msg",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.onViodSubmit()
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.payVisble,
            "close-on-click-modal": false,
            center: "",
            title: "",
            width: "300px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.payVisble = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { "label-width": "70px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("el-form-item", { attrs: { label: "付款凭证" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "upLoadPicBox",
                        attrs: { title: "750*750px" },
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap()
                          },
                        },
                      },
                      [
                        _vm.payForm.payment_evidence
                          ? _c("div", { staticClass: "pictrue" }, [
                              _c("img", {
                                attrs: { src: _vm.payForm.payment_evidence },
                              }),
                            ])
                          : _c("div", { staticClass: "upLoad" }, [
                              _c("i", {
                                staticClass: "el-icon-camera cameraIconfont",
                              }),
                            ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.onPaySubmit()
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c("SettlementPrint", { ref: "printView", on: { load: _vm.getList } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }