var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "all-h-print" }, [
        _vm.collectMoney.show
          ? _c(
              "div",
              { attrs: { id: "printorder" } },
              _vm._l(_vm.printBotArr, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticStyle: {
                      "page-break-after": "always",
                      position: "relative",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          width: "100%",
                          left: "0",
                          top: "1300px",
                          "text-align": "center",
                          "font-weight": "550",
                        },
                      },
                      [_vm._v(_vm._s(item))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "detail-box" }, [
                      _c(
                        "div",
                        {
                          staticClass: "flex",
                          staticStyle: { "padding-right": "250px" },
                        },
                        [
                          _vm.merData.config &&
                          _vm.merData.config.mer_print_logo
                            ? _c("img", {
                                staticStyle: {
                                  height: "60px",
                                  "margin-right": "20px",
                                },
                                attrs: {
                                  src:
                                    _vm.merData.config.mer_print_logo + "!m640",
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "detail-left" }, [
                            _c("div", { staticClass: "detail-left-title" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.collectMoney.data.merConfig.company_name
                                  ) +
                                  " | " +
                                  _vm._s(_vm.detail.title.china) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "detail-left-desc",
                                staticStyle: {
                                  "font-size": "14px",
                                  "font-family": "'printEN'",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collectMoney.data.merConfig
                                        .company_english_name
                                    ) +
                                      " " +
                                      _vm._s(_vm.detail.title.english)
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.collectMoney.data.recharge_order ||
                      _vm.collectMoney.data.paid == 1
                        ? _c("div", { staticClass: "qrcodeBox" }, [
                            _vm.qrcodeImg
                              ? _c("img", {
                                  staticClass: "qrcimg",
                                  attrs: {
                                    src:
                                      "data:image/png;base64," + _vm.qrcodeImg,
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-abso" }, [
                              _c("div", [_vm._v("No." + _vm._s(_vm.no))]),
                              _vm._v(" "),
                              _vm.collectMoney.data.recharge_order
                                ? _c(
                                    "div",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.switchLang("CollectionTime")
                                        ) +
                                          "：" +
                                          _vm._s(
                                            _vm.collectMoney.data.recharge_order
                                              .create_time
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "font-size": "14px" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collectMoney.data.recharge_order ||
                                        _vm.collectMoney.data.paid == 1
                                        ? _vm.switchLang("Settled")
                                        : _vm.switchLang("Unsettled")
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _c("div", { staticClass: "qrcodeBox" }, [
                            _vm.qrcodeImg
                              ? _c("img", {
                                  staticClass: "qrcimg",
                                  attrs: {
                                    src:
                                      "data:image/png;base64," + _vm.qrcodeImg,
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "detail-abso" }, [
                              _c("div", [_vm._v("No." + _vm._s(_vm.no))]),
                            ]),
                          ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "secTit" }, [
                      _vm._v(_vm._s(_vm.detail.buyerCompany.china)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-desc",
                        staticStyle: {
                          "font-size": "13px",
                          "font-family": "'printEN'",
                          "font-weight": "500",
                          "word-break": "break-word",
                        },
                      },
                      [_vm._v(_vm._s(_vm.detail.buyerCompany.english))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-title",
                        staticStyle: { "margin-top": "40px" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.collectMoney.data.venue_info.title) +
                            " "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "detail-list flex-wrap" },
                      [
                        _vm._l(_vm.detail.buyerColumn, function (m, i) {
                          return [
                            m.open &&
                            (i == "buyerName" || i == "number" || i == "date")
                              ? [
                                  _c(
                                    "div",
                                    {
                                      key: i,
                                      style:
                                        "width: " +
                                        (m.w == "auto" ? "auto" : m.w + "%") +
                                        ";margin-top: 5px;white-space:nowrap;",
                                    },
                                    [
                                      m.showName
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "padding-right": "5px",
                                              },
                                            },
                                            [_vm._v(_vm._s(m.showName))]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      i == "buyerName"
                                        ? [
                                            _vm.collectMoney.data.userVerifyInfo
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.collectMoney.data
                                                        .userVerifyInfo
                                                        .real_name
                                                    )
                                                  ),
                                                ])
                                              : _vm.collectMoney.data.userInfo
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.collectMoney.data
                                                        .userInfo.nickname
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        : i == "number"
                                        ? [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.collectMoney.data
                                                    .numberPlate
                                                    ? _vm.collectMoney.data
                                                        .numberPlate.number
                                                    : ""
                                                )
                                              ),
                                            ]),
                                          ]
                                        : i == "date"
                                        ? [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.collectMoney.data
                                                    .order_time
                                                )
                                              ),
                                            ]),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-list flex-wrap",
                        staticStyle: { "justify-content": "flex-start" },
                      },
                      [
                        _vm._l(_vm.detail.buyerColumn, function (m, i) {
                          return [
                            m.open &&
                            (i == "address" ||
                              i == "address_user_phone" ||
                              i == "address_real_name")
                              ? [
                                  _c(
                                    "div",
                                    {
                                      key: i,
                                      style:
                                        "width: " +
                                        (m.w == "auto" ? "auto" : m.w + "%") +
                                        ";margin-top: 5px;white-space:nowrap;margin-right: 5px;",
                                    },
                                    [
                                      m.showName
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "padding-right": "5px",
                                              },
                                            },
                                            [_vm._v(_vm._s(m.showName))]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      i == "address"
                                        ? [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.collectMoney.data
                                                    .address_info.user_address
                                                )
                                              ),
                                            ]),
                                          ]
                                        : i == "address_user_phone"
                                        ? [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.collectMoney.data
                                                    .address_info.user_phone
                                                )
                                              ),
                                            ]),
                                          ]
                                        : i == "address_real_name"
                                        ? [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.collectMoney.data
                                                    .address_info.real_name
                                                )
                                              ),
                                            ]),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm._l(
                      _vm.isNo ? [_vm.collectMoney.data.orderList] : _vm.result,
                      function (item, ind) {
                        return _c(
                          "div",
                          {
                            key: ind,
                            staticClass: "ptd-box all-print-main-el",
                            style:
                              ind <
                              (_vm.isNo
                                ? _vm.collectMoney.data.orderList.length
                                : _vm.result.length) -
                                1
                                ? "page-break-after: always;"
                                : "",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "ptd",
                                staticStyle: { "font-weight": "bold" },
                              },
                              [
                                _vm._l(_vm.detail.tableColumn, function (m, i) {
                                  return [
                                    m.open && m.name == "金额"
                                      ? [
                                          _vm.fee_type !=
                                          _vm.feeTypeInfo.mer_fee_type
                                            ? _c(
                                                "div",
                                                {
                                                  key: "col" + i + "_1",
                                                  staticClass: "ptd-title",
                                                  style: m.w
                                                    ? "width: " + m.w + "px;"
                                                    : "flex: 1",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.feeTypeInfo.name
                                                      ) +
                                                        _vm._s(
                                                          m.showName || m.name
                                                        ) +
                                                        "(" +
                                                        _vm._s(
                                                          _vm.fee_type.replace(
                                                            "CNY",
                                                            "RMB"
                                                          )
                                                        ) +
                                                        ")"
                                                    ),
                                                  ]),
                                                  m.english
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-family":
                                                              "'printEN'",
                                                            "white-space":
                                                              "nowrap",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.feeTypeInfo
                                                                .type
                                                            ) +
                                                              " " +
                                                              _vm._s(m.english)
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              key: "col" + i,
                                              staticClass: "ptd-title",
                                              style: m.w
                                                ? "width: " + m.w + "px;"
                                                : "flex: 1",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(m.showName || m.name) +
                                                    _vm._s(
                                                      _vm.fee_type !=
                                                        _vm.feeTypeInfo
                                                          .mer_fee_type
                                                        ? "(" +
                                                            _vm.feeTypeInfo.mer_fee_type.replace(
                                                              "CNY",
                                                              "RMB"
                                                            ) +
                                                            ")"
                                                        : _vm.feeTypeInfo
                                                            .mer_fee_type !=
                                                          "CNY"
                                                        ? "(" +
                                                          _vm.feeTypeInfo
                                                            .mer_fee_type +
                                                          ")"
                                                        : ""
                                                    )
                                                ),
                                              ]),
                                              m.english
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-family":
                                                          "'printEN'",
                                                        "white-space": "nowrap",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(m.english))]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      : m.open
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              key: "col" + i,
                                              staticClass: "ptd-title",
                                              style: m.w
                                                ? "width: " + m.w + "px;"
                                                : "flex: 1",
                                            },
                                            [
                                              _c(
                                                "span",
                                                [
                                                  _vm._v(
                                                    _vm._s(m.showName || m.name)
                                                  ),
                                                  m.name != "图录号" &&
                                                  m.name != "拍品名称"
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.fee_type !=
                                                              _vm.feeTypeInfo
                                                                .mer_fee_type
                                                              ? "(" +
                                                                  _vm.fee_type.replace(
                                                                    "CNY",
                                                                    "RMB"
                                                                  ) +
                                                                  ")"
                                                              : _vm.fee_type !=
                                                                "CNY"
                                                              ? "(" +
                                                                _vm.fee_type +
                                                                ")"
                                                              : ""
                                                          )
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                              m.english
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-family":
                                                          "'printEN'",
                                                        "white-space": "nowrap",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(m.english))]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm._l(item, function (m, i) {
                              return _c(
                                "div",
                                { key: "order" + i, staticClass: "ptd calcul" },
                                [
                                  _vm.detail.tableColumn[0] &&
                                  _vm.detail.tableColumn[0].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[0].w +
                                            "px;",
                                        },
                                        [_vm._v(_vm._s(m.auction_info.lot))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[1] &&
                                  _vm.detail.tableColumn[1].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          staticStyle: { flex: "1" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(m.auction_info.store_name)
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[2] &&
                                  _vm.detail.tableColumn[2].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[2].w +
                                            "px;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                m.product_price,
                                                2
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[3] &&
                                  _vm.detail.tableColumn[3].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[3].w +
                                            "px;text-align: center;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                m.commission_price,
                                                2
                                              )
                                            )
                                          ),
                                          m.commission_rate
                                            ? [
                                                _vm._v(
                                                  " / " +
                                                    _vm._s(m.commission_rate) +
                                                    "%"
                                                ),
                                              ]
                                            : _vm._e(),
                                          m.extend_data &&
                                          m.extend_data
                                            .memberCommissionDiscountPercent &&
                                          m.extend_data
                                            .memberCommissionDiscountPercent !=
                                            100
                                            ? [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(
                                                      _vm._f("filterPercent")(
                                                        m.extend_data
                                                          .memberCommissionDiscountPercent
                                                      )
                                                    ) +
                                                    "折)"
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[4] &&
                                  _vm.detail.tableColumn[4].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[4].w +
                                            "px;",
                                        },
                                        [
                                          m.total_postage &&
                                          m.delivery_type == 1
                                            ? _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.switchLang("Freight")
                                                  ) +
                                                    "：" +
                                                    _vm._s(
                                                      _vm.formatPriceNumber(
                                                        m.total_postage,
                                                        2
                                                      )
                                                    )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          m.admin_discount_price
                                            ? _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.switchLang("Discount")
                                                  ) +
                                                    "：-" +
                                                    _vm._s(
                                                      _vm.formatPriceNumber(
                                                        m.admin_discount_price,
                                                        2
                                                      )
                                                    )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[5] &&
                                  _vm.detail.tableColumn[5].open &&
                                  _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[5].w +
                                            "px;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                m.fee_origin_payable_price,
                                                2
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[5] &&
                                  _vm.detail.tableColumn[5].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[5].w +
                                            "px;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                m.payable_price,
                                                2
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ptd-box",
                        staticStyle: {
                          "margin-top": "0",
                          "border-top": "none",
                        },
                      },
                      [
                        _c("div", { staticClass: "ptd calcul" }, [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: {
                                width: "86px",
                                "flex-direction": "column",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.switchLang("Total"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("TOTAL")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.collectMoney.orderLength) + "件"
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.detail.tableColumn[2] &&
                          _vm.detail.tableColumn[2].open
                            ? _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style:
                                    "width: " +
                                    _vm.detail.tableColumn[2].w +
                                    "px;",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(_vm.total_price, 2)
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.detail.tableColumn[3] &&
                          _vm.detail.tableColumn[3].open
                            ? _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style:
                                    "width: " +
                                    _vm.detail.tableColumn[3].w +
                                    "px;",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.data
                                          .total_commission_price,
                                        2
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.detail.tableColumn[4] &&
                          _vm.detail.tableColumn[4].open
                            ? _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style:
                                    "width: " +
                                    _vm.detail.tableColumn[4].w +
                                    "px;",
                                },
                                [
                                  _vm.collectMoney.data.total_postage
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.switchLang("Freight")) +
                                            "：" +
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                _vm.collectMoney.data
                                                  .total_postage,
                                                2
                                              )
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.collectMoney.data
                                    .total_admin_discount_price
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.switchLang("Discount")) +
                                            "：-" +
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                _vm.collectMoney.data
                                                  .total_admin_discount_price,
                                                2
                                              )
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.detail.tableColumn[5] &&
                          _vm.detail.tableColumn[5].open &&
                          _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                            ? _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style:
                                    "width: " +
                                    _vm.detail.tableColumn[5].w +
                                    "px;",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.data
                                          .total_fee_origin_payable_price,
                                        2
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.detail.tableColumn[5] &&
                          _vm.detail.tableColumn[5].open
                            ? _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style:
                                    "width: " +
                                    _vm.detail.tableColumn[5].w +
                                    "px;",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.data
                                          .total_payable_price,
                                        2
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.collectMoney.data.total_admin_discount_price == 0
                          ? _c("div", { staticClass: "ptd calcul" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style:
                                    _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                      ? "flex: 1;flex-direction: column;"
                                      : "flex: 1;flex-direction: column;",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "text-align": "left" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.collectMoney.data
                                            .recharge_order ||
                                            _vm.collectMoney.data.paid == 1
                                            ? _vm.switchLang("PaidAmount")
                                            : _vm.switchLang("BuyerPending")
                                        ) +
                                          _vm._s(
                                            _vm.fee_type !=
                                              _vm.feeTypeInfo.mer_fee_type
                                              ? "（" +
                                                  _vm.feeTypeInfo.mer_fee_type.replace(
                                                    "CNY",
                                                    "RMB"
                                                  ) +
                                                  "）"
                                              : ""
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                        "text-align": "left",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.collectMoney.data
                                            .recharge_order ||
                                            _vm.collectMoney.data.paid == 1
                                            ? "ACTUAL PAYMENT AMOUNT"
                                            : "AMOUNT PENDING SETTLEMENT"
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style: _vm.getForeWidth + ";display:flex;",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collectMoney.data.total_pay_price == 0
                                        ? ""
                                        : _vm.collectMoney.data
                                            .total_refund_price > 0
                                        ? _vm.formatPriceNumber(
                                            _vm.collectMoney.data
                                              .total_pay_price,
                                            2
                                          ) +
                                          "（付" +
                                          _vm.formatPriceNumber(
                                            _vm.collectMoney.data
                                              .total_origin_pay_price,
                                            2
                                          ) +
                                          "、已退" +
                                          _vm.formatPriceNumber(
                                            _vm.collectMoney.data
                                              .total_refund_price,
                                            2
                                          ) +
                                          "）"
                                        : _vm.formatPriceNumber(
                                            _vm.collectMoney.data
                                              .total_pay_price,
                                            2
                                          )
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _c("div", { staticClass: "ptd calcul" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style:
                                    _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                      ? "flex: 1;flex-direction: column;"
                                      : "flex: 1;flex-direction: column;",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "text-align": "left" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.switchLang("DiscountAmount"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                        "text-align": "left",
                                      },
                                    },
                                    [_vm._v("DISCOUNT AMOUNT")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { style: _vm.getForeWidth + ";display:flex;" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      style:
                                        "width: " +
                                        _vm.detail.tableColumn[2].w +
                                        "px",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.collectMoney.data
                                            .total_admin_discount_price
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      staticStyle: {
                                        flex: "1",
                                        "flex-direction": "column",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "text-align": "left" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.collectMoney.data
                                                .recharge_order ||
                                                _vm.collectMoney.data.paid == 1
                                                ? _vm.switchLang("PaidAmount")
                                                : _vm.switchLang("BuyerPending")
                                            ) +
                                              _vm._s(
                                                _vm.fee_type !=
                                                  _vm.feeTypeInfo.mer_fee_type
                                                  ? "（" +
                                                      _vm.feeTypeInfo.mer_fee_type.replace(
                                                        "CNY",
                                                        "RMB"
                                                      ) +
                                                      "）"
                                                  : ""
                                              )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-family": "'printEN'",
                                            "text-align": "left",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.collectMoney.data
                                                .recharge_order ||
                                                _vm.collectMoney.data.paid == 1
                                                ? "ACTUAL PAYMENT AMOUNT"
                                                : "AMOUNT PENDING SETTLEMENT"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      style:
                                        "width: " +
                                        _vm.detail.tableColumn[5].w +
                                        "px;font-weight: bold;",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.collectMoney.data
                                            .total_pay_price == 0
                                            ? ""
                                            : _vm.collectMoney.data
                                                .total_refund_price > 0
                                            ? _vm.formatPriceNumber(
                                                _vm.collectMoney.data
                                                  .total_pay_price,
                                                2
                                              ) +
                                              "（付" +
                                              _vm.formatPriceNumber(
                                                _vm.collectMoney.data
                                                  .total_origin_pay_price,
                                                2
                                              ) +
                                              "、已退" +
                                              _vm.formatPriceNumber(
                                                _vm.collectMoney.data
                                                  .total_refund_price,
                                                2
                                              ) +
                                              "）"
                                            : _vm.formatPriceNumber(
                                                _vm.collectMoney.data
                                                  .total_pay_price,
                                                2
                                              )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                        _vm._v(" "),
                        _vm.feeTypeInfo.mer_fee_type == "CNY"
                          ? _c("div", { staticClass: "ptd calcul" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style:
                                    _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                      ? "flex: 1;flex-direction: column;"
                                      : "flex: 1;flex-direction: column;",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "text-align": "left" } },
                                    [_vm._v("人民币（大写）")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                        "text-align": "left",
                                      },
                                    },
                                    [_vm._v("CAPITAL IN RMB")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style:
                                    _vm.getForeWidth + ";font-weight: bold;",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collectMoney.data.total_pay_price == 0
                                        ? ""
                                        : _vm.collectMoney.data
                                            .total_pay_price_rmb
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "secTit",
                        staticStyle: { "margin-top": "30px" },
                      },
                      [_vm._v(_vm._s(_vm.detail.settleText.china))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-desc",
                        staticStyle: {
                          "font-size": "13px",
                          "font-family": "'printEN'",
                          "font-weight": "500",
                        },
                      },
                      [_vm._v(_vm._s(_vm.detail.settleText.english))]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "print-line",
                      staticStyle: { "margin-top": "30px" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "detail-sign" }, [
                      _vm._v(_vm._s(_vm.switchLang("SettleSign"))),
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'printEN'" } },
                        [_vm._v("（SIGNATURE）")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "print-line" }),
                    _vm._v(" "),
                    _vm.detail.signColumnTab == "system"
                      ? _c(
                          "div",
                          {
                            staticClass: "detail-list",
                            staticStyle: {
                              "margin-top": "30px",
                              "flex-wrap": "wrap",
                            },
                          },
                          [
                            _vm._l(_vm.detail.signColumn, function (m, i) {
                              return [
                                m.open
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          key: i,
                                          style:
                                            "width: " +
                                            (m.w == "auto"
                                              ? "auto"
                                              : m.w + "%") +
                                            ";margin-bottom: 10px;padding-right: 5px;white-space: nowrap;" +
                                            (m.name == "打印时间"
                                              ? "text-align: right;"
                                              : ""),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(m.showName || m.name) + ":"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          i == "accountName"
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml5" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.merData.config
                                                          .bank_name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : i == "accountBank"
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml5" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.merData.config.bank
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : i == "accountNumber"
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml5" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.merData.config
                                                          .bank_number
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : i == "accountAddress"
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml5" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.merData.config
                                                          .company_address
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : i == "printTime"
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml5" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.parseTime(
                                                          new Date()
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : i == "creator" || i == "recipient"
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml5" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.collectMoney.data
                                                          .admin_name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : i == "documentNumber"
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml5" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.collectMoney.data
                                                          .recharge_order
                                                          ? _vm.collectMoney
                                                              .data
                                                              .recharge_order
                                                              .out_trade_no
                                                          : ""
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "detail-list",
                            staticStyle: {
                              "margin-top": "30px",
                              "flex-wrap": "wrap",
                            },
                          },
                          [
                            _c("div", {
                              staticStyle: { flex: "1", overflow: "hidden" },
                              domProps: {
                                innerHTML: _vm._s(_vm.detail.signColumnCustome),
                              },
                            }),
                          ]
                        ),
                  ],
                  2
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm.isOutbound
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.onPrintOut },
                },
                [_vm._v("打印并创建出库单")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintordOne },
            },
            [_vm._v("打印三份")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印一份")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }