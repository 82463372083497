var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showSelectAuction,
            width: "720px",
            title: "选择拍卖专场",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectAuction = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "search" },
            [
              !_vm.query
                ? _c(
                    "el-select",
                    {
                      staticClass: "w200 mr10",
                      attrs: {
                        placeholder: "拍卖会名称",
                        filterable: "",
                        remote: "",
                        size: "small",
                        clearable: "",
                        "remote-method": _vm.searchVenueAction,
                        loading: _vm.venueLoading,
                      },
                      on: { change: _vm.searchAAction },
                      model: {
                        value: _vm.venue_id,
                        callback: function ($$v) {
                          _vm.venue_id = $$v
                        },
                        expression: "venue_id",
                      },
                    },
                    _vm._l(_vm.venueList, function (m, i) {
                      return _c("el-option", {
                        key: "user" + i,
                        attrs: { value: m.venue_id, label: m.title },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: { clearable: "", placeholder: "专场名称搜索" },
                  on: { change: _vm.searchAAction },
                  model: {
                    value: _vm.auctionName,
                    callback: function ($$v) {
                      _vm.auctionName = $$v
                    },
                    expression: "auctionName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: { change: _vm.searchAAction },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mt20",
              staticStyle: {
                "border-top": "1px solid #eee",
                height: "400px",
                "overflow-y": "auto",
              },
            },
            [
              _c(
                "table",
                { staticClass: "spec-table mt20" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: { height: "30px" },
                          attrs: { width: "40%", align: "left" },
                        },
                        [_vm._v("专场信息")]
                      ),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "30%", align: "left" } }, [
                        _vm._v("专场拍卖时间"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "15%", align: "center" } }, [
                        _vm._v("专场状态"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "15%", align: "center" } }, [
                        _c(
                          "a",
                          {
                            staticStyle: { color: "#28ab29" },
                            attrs: { href: "javascript:;" },
                            on: { click: _vm.auctionSelectedAllAction },
                          },
                          [_vm._v(_vm._s(_vm.isAll ? "取消全选" : "全选"))]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.resultData, function (m, i) {
                    return _c("tbody", { key: i, staticClass: "list" }, [
                      _c("tr", { staticClass: "border-t" }, [
                        _c("td", { staticClass: "bgWhite" }, [
                          _c(
                            "div",
                            { staticClass: "display-flex item-align-center" },
                            [
                              m.album_image
                                ? _c("img", {
                                    staticStyle: {
                                      width: "50px",
                                      height: "50px",
                                      display: "block",
                                      "flex-shrink": "0",
                                    },
                                    attrs: { src: m.album_image + "!120a" },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ml10 flex-one textAlignL" },
                                [
                                  _c("p", [
                                    _vm._v("专场标识：" + _vm._s(m.album_id)),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "mt5" }, [
                                    _vm._v(_vm._s(m.album_name)),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _c(
                              "p",
                              [
                                _vm._v(_vm._s(m.stime_format[2]) + " "),
                                m.album_end_time ? [_vm._v("至")] : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            m.album_end_time
                              ? _c("p", [_vm._v(_vm._s(m.etime_format[2]))])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            m.album_status == 1
                              ? _c("div", [_vm._v("预展中")])
                              : m.album_status == 2
                              ? _c("div", [_vm._v("进行中")])
                              : _c("div", [_vm._v("已结束")]),
                            _vm._v(" "),
                            m.is_show === 1
                              ? _c("div", [_vm._v("展示中")])
                              : m.is_show === 0
                              ? _c("div", [_vm._v("不展示")])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _vm.selectedAuctionIdList.indexOf(m.album_id) == -1
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.auctionSelectedAction(
                                            $event,
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择")]
                                  ),
                                ])
                              : _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          _vm.auctionCancelAction(
                                            $event,
                                            _vm.selectedAuctionIdList.indexOf(
                                              m.album_id
                                            )
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("取消选择")]
                                  ),
                                ]),
                          ]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-e mt20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: { "current-change": _vm.pageChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr20",
                  attrs: { type: "primary" },
                  on: { click: _vm.cursorSelect },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showSelectAuction = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }